import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Timestamp from './components/Timestamp';
import InitialDetail from './components/InitialDetail';
import SystemDetail from './components/SystemDetail';
import UpdateDetail from './components/UpdateDetail';
import TimelineSkeleton from './components/TimelineSkeleton';

import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Timeline from '@mui/lab/Timeline';
import Typography from '@mui/material/Typography';

const _systemDetailTypes = ['assignment', 'status'];

const _getTimelineComponent = (detailType) => {
	if (_systemDetailTypes.includes(detailType)) {
		return SystemDetail;
	} else {
		return UpdateDetail;
	}
};

const _isSameDate = (createdOn, previousCreatedOn) => {
	if (previousCreatedOn) {
		return dayjs(createdOn)
			.startOf('day')
			.isSame(dayjs(previousCreatedOn).startOf('day'));
	} else {
		return false;
	}
};

const NotificationTimeline = ({ t }) => {
	const {
		data: notificationDetail,
		hasBeenLoaded: hasLoadedNotificationDetail,
		error: notificationDetailError
	} = useSelector(state => state.notificationDetailSlice.notificationDetail);
	const {
		isLoading: isChangingAssignedUser
	} = useSelector(state => state.notificationDetailSlice.changeAssignedUser);
	const {
		isLoading: isAddingNotificationTimelineEvent
	} = useSelector(state => state.notificationDetailSlice.addNotificationTimelineEvent);
	const {
		isLoading: isChangingStatus
	} = useSelector(state => state.notificationDetailSlice.changeStatus);

	if (!hasLoadedNotificationDetail || notificationDetailError) {
		return null;
	}

	const initialNotification = notificationDetail?.notificationDetails.find((detail) => {
		return detail.detailType === 'initial';
	});
	const remainingDetails = notificationDetail?.notificationDetails.slice(1);

	return (
		<Stack justifyContent='center' sx={{ margin: '0 auto' }}>
			<Divider orientation='horizontal' sx={{ width: '100%' }}>
				<Typography variant='overline'>
					{t('notifications:updates')}
				</Typography>
			</Divider>
			<Timeline position='right' align='center'>
				{isChangingAssignedUser || isChangingStatus || isAddingNotificationTimelineEvent
					? <TimelineSkeleton />
					: null
				}
				<Stack direction='column-reverse'>
					<Timestamp
						createdOn={initialNotification.createdOn}
						isInitial={true}
					/>
					<InitialDetail
						detail={initialNotification}
						severity={notificationDetail.severity}
					/>
					{ remainingDetails.map((detail, index) => {
						const TimelineComponent = _getTimelineComponent(
							detail.detailType
						);

						return (
							<React.Fragment key={`timeline-fragment-${index}`}>
								<Timestamp
									key={`timestamp-${index}`}
									createdOn={detail.createdOn}
									isHidden={_isSameDate(
										detail.createdOn,
										notificationDetail?.notificationDetails[index]?.createdOn
									)}
								/>
								<TimelineComponent
									key={index}
									detail={detail}
									notification={notificationDetail}
								/>
							</React.Fragment>
						);
					})
					}
				</Stack>
			</Timeline>
		</Stack>
	);
};

NotificationTimeline.propTypes = {
	isAddingToTimeline: PropTypes.bool,
	notification: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	t: PropTypes.func
};

export default withTranslation('notifications')(NotificationTimeline);
