import * as OrganizationEpics from './epic';
import * as organizationActions from './actions';
import * as organizationActiontypes from './actionTypes';
import { organizationReducer } from './reducer';

const organizationEpics = Object.values({ ...OrganizationEpics });

export {
	organizationActions,
	organizationActiontypes,
	organizationEpics,
	organizationReducer
};
