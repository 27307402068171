import React from 'react';
import Branding from './components/Branding';
import Profile from './components/Profile';

import Stack from '@mui/material/Stack';

const Header = () => {
	return (
		<Stack
			direction='row'
			justifyContent='space-between'
			sx={{
				m: 0,
				zIndex: 101,
				width: '100%',
				height: 80,
				bgcolor: 'layout.header.background'
			}}
		>
			<Branding />
			<Profile />
		</Stack>
	);
};

export default Header;
