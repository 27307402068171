import { styled } from '@mui/material/styles';

import Stack from '@mui/material/Stack';

export default styled(Stack)(({ theme }) => ({
	margin: theme.spacing(1),
	padding: theme.spacing(1),
	borderRadius: 10,
	border: `1px solid ${theme.palette.secondary.alt}`
}));
