import React from 'react';
import LoginForm from '../../components/Login/LoginForm';
import RegistrationPageWrapper from '../../components/RegistrationPageWrapper';

const Login = () => {
	return (
		<RegistrationPageWrapper>
			<LoginForm />
		</RegistrationPageWrapper>
	);
};

export default Login;
