import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import BuilderSectionHeader from '../BuilderSectionHeader';
import RepresentmentBuilderBox from '../RepresentmentBuilderStack';
import RepresentmentTextField from './components/RepresentmentTextField';

import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';

export const Overview = (props) => {
	const { t, data } = props;

	const [collapsedDescription, setCollapsedDescription] = React.useState(false);

	return (
		<Grid item xs={12} sm={12} md={6} lg={6}>
			<RepresentmentBuilderBox>
				<BuilderSectionHeader>{t('overview.label')}</BuilderSectionHeader>
				<Stack direction='row'>
					<RepresentmentTextField
						label={t('overview.mid')}
						id='mid'
						defaultValue={data.mid}
					/>
					<RepresentmentTextField
						label={t('overview.billingDescriptor')}
						id='billingDescriptor'
						defaultValue={data.billingDescriptor}
					/>
				</Stack>
				<Stack direction='row'>
					<RepresentmentTextField
						id='customerServicePhone'
						label={t('overview.billingPhoneNumber')}
						defaultValue={data.customerServicePhone}
					/>
					<Collapse
						in={collapsedDescription}
						collapsedSize={40}
						sx={{ flexGrow: 1 }}
					>
						<RepresentmentTextField
							id='programDescription'
							label={t('overview.programDescription')}
							defaultValue={data.programDescription}
							isMultiline={true}
							isFlexed={true}
						/>
					</Collapse>
					<div
						onClick={() => {
							setCollapsedDescription(!collapsedDescription);
						}}
					>
						{!collapsedDescription ? <UnfoldMoreIcon /> : <UnfoldLessIcon />}
					</div>
				</Stack>
			</RepresentmentBuilderBox>
		</Grid>
	);
};

Overview.propTypes = {
	data: PropTypes.object,
	t: PropTypes.func
};

export default withTranslation('representments')(Overview);
