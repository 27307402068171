import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	reportViews: {
		data: [],
		isLoading: false,
		hasBeenLoaded: false,
		error: undefined
	}
};

export const reportViewsSlice = createSlice({
	name: 'reportViews',
	initialState,
	reducers: {
		getReportViews: (state) => {
			state.reportViews.isLoading = true;
		},
		getReportViewsCompleted: (state, action) => {
			state.reportViews.isLoading = false;
			state.reportViews.hasBeenLoaded = true;
			state.reportViews.data = action.payload;
		},
		getReportViewsFailed: (state) => {
			state.reportViews.isLoading = false;
		}
	}
});

export const {
	getReportViews,
	getReportViewsCompleted,
	getReportViewsFailed
} = reportViewsSlice.actions;

export default reportViewsSlice.reducer;
