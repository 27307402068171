import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { withTranslation } from 'react-i18next';
import { displaySections } from '../../RepresentmentBuilder/constants/displaySections';
import DetailDisplay from '../../RepresentmentBuilder/DetailDisplay';
import PageError from '../../errors/PageError';

import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';

const ViewDetailsPaper = styled(Paper)({
	display: 'flex',
	flexFlow: 'column',
	padding: '10px',
	maxHeight: '80%',
	overflowY: 'auto',
	width: '50%'
});

const ViewDetailsStyledModal = styled(Modal)({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	flex: 1
});

export const ViewDetailsModal = (props) => {
	const {
		t,
		isOpen,
		onCloseModal,
		representment,
		representmentIsLoading,
		representmentHasBeenLoaded,
		representmentError
	} = props;

	if (representmentError && isOpen) {
		return <PageError errorText={t('representments:representmentsTable.error')} />;
	}

	return (
		<ViewDetailsStyledModal
			open={isOpen}
			onClose={onCloseModal}
			aria-labelledby='modal-modal-title'
			aria-describedby='modal-modal-description'
		>
			<ViewDetailsPaper>
				<Stack direction='row' justifyContent='flex-end'>
					{ !representmentIsLoading
						? <Button variant='outlined' onClick={onCloseModal}>
							{t('common:close')}
						</Button> : null
					}
				</Stack>
				{representmentIsLoading || !representmentHasBeenLoaded ? (
					<Stack direction='row' justifyContent='center'>
						<CircularProgress />
					</Stack>
				) : (
					<DetailDisplay
						representmentData={representment}
						displayedSections={displaySections}
					/>
				)}
			</ViewDetailsPaper>
		</ViewDetailsStyledModal>
	);
};

ViewDetailsModal.propTypes = {
	t: PropTypes.func,
	isOpen: PropTypes.bool,
	onCloseModal: PropTypes.func,
	representment: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	representmentIsLoading: PropTypes.bool,
	representmentHasBeenLoaded: PropTypes.bool,
	representmentError: PropTypes.string,
	error: PropTypes.string
};

export default withTranslation(['representments', 'common'])(ViewDetailsModal);
