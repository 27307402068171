import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

export const NoDataRow = (props) => {
	const { t, columnSpan } = props;

	return (
		<TableRow>
			<TableCell colSpan={columnSpan}>
				<Stack direction='row' justifyContent='center'>
					<Typography>{t('noResults')}</Typography>
				</Stack>
			</TableCell>
		</TableRow>
	);
};

NoDataRow.propTypes = {
	t: PropTypes.func,
	columnSpan: PropTypes.number
};

export default withTranslation('common')(NoDataRow);
