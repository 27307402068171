import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import accountEN from './locales/en/account.json';
import commonEN from './locales/en/common.json';
import groupsEN from './locales/en/groups.json';
import insightReportsEN from './locales/en/insightReports.json';
import layoutEN from './locales/en/layout.json';
import modalsEN from './locales/en/modals.json';
import navigationEN from './locales/en/navigation.json';
import notificationsEN from './locales/en/notifications.json';
import popEN from './locales/en/pop.json';
import registrationEN from './locales/en/registration.json';
import reportsEN from './locales/en/reports.json';
import representmentsEN from './locales/en/representments.json';
import rulesEN from './locales/en/rules.json';
import supportEN from './locales/en/support.json';
import usersEN from './locales/en/users.json';

const resources = {
	en: {
		account: accountEN,
		common: commonEN,
		groups: groupsEN,
		insightReports: insightReportsEN,
		layout: layoutEN,
		modals: modalsEN,
		navigation: navigationEN,
		notifications: notificationsEN,
		pop: popEN,
		registration: registrationEN,
		reports: reportsEN,
		representments: representmentsEN,
		rules: rulesEN,
		support: supportEN,
		users: usersEN
	}
};

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		fallbackLng: 'en',
		debug: false,
		initImmediate: false,
		ns: ['slyce360-translation'],
		defaultNS: 'slyce360-translation',
		react: {
			useSuspense: false,
			wait: true
		},
		resources,
		interpolation: {
			escapeValue: false
		}
	});
