import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import NavigationCategory from '../shared/NavigationCategory';
import NavigationItem from '../shared/NavigationItem';
import PopoutMenuNavigationItem from '../shared/PopoutMenuNavigationItem';

import { toggleRepresentmentsExpansion } from '../../../../../containers/sideNavigation/sideNavigationSlice';
import { getRepresentmentReports } from '../../../../../containers/Representments/representmentReportSlice';

import Skeleton from '@mui/material/Skeleton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const CurrentInventory = ({ t }) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [activeMenuIndex, setActiveMenuIndex] = React.useState();

	const {
		isRepresentmentsExpanded
	} = useSelector(state => state.sideNavigationSlice);
	const {
		data: representmentReports,
		isLoading: isLoadingRepresentmentReports,
		hasBeenLoaded: representmentReportsHaveBeenLoaded,
		error: representmentReportsError
	} = useSelector(state => state.representmentReportsSlice.representmentReports);

	const onToggleExpanded = () => {
		dispatch(toggleRepresentmentsExpansion());
	};

	React.useEffect(() => {
		const isOnRepresentmentsPage = location.pathname.includes('representments');

		if (isOnRepresentmentsPage && !isRepresentmentsExpanded) {
			dispatch(toggleRepresentmentsExpansion());
		}
	}, [location]);

	React.useEffect(() => {
		dispatch(getRepresentmentReports());
	}, []);

	const open = Boolean(anchorEl);

	const handleClick = (anchorEl, index) => {
		setActiveMenuIndex(index);
		setAnchorEl(anchorEl);
	};
	const handleClose = () => {
		setActiveMenuIndex();
		setAnchorEl(null);
	};

	const onClickNavigationItem = (reportUniversalUniqueIdentifier) => {
		navigate(`/reports/${reportUniversalUniqueIdentifier}`);
	};

	return (
		<NavigationCategory
			label={t('navigation:representments')}
			isExpanded={isRepresentmentsExpanded}
			setIsExpanded={onToggleExpanded}
		>
			<PopoutMenuNavigationItem
				label={t('navigation:representmentReports')}
				onClickOpenMenu={handleClick}
				index={0}
				activeMenuIndex={activeMenuIndex}
			/>
			<NavigationItem
				label={t('navigation:opportunities')}
				linkTo={'/representments/opportunities'}
			/>
			<NavigationItem
				label={t('navigation:resolved')}
				linkTo={'/representments/resolved'}
			/>
			<NavigationItem
				label={t('navigation:notChallenged')}
				linkTo={'/representments/not-challenged'}
			/>
			<Menu
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				onClick={handleClose}
				slotProps={{
					paper: {
						elevation: 3,
						sx: { px: '2px' }
					}
				}
				}
				transformOrigin={{ horizontal: 0, vertical: 10 }}
				anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
			>
				{representmentReportsHaveBeenLoaded && representmentReports?.length > 0
					? representmentReports.map((menuItem) => (
						<MenuItem
							key={menuItem.universalUniqueIdentifier}
							onClick={() => {
								onClickNavigationItem(menuItem.universalUniqueIdentifier);
							}}
						>
							{menuItem.label}
						</MenuItem>
					)) : null}
				{isLoadingRepresentmentReports && !representmentReportsHaveBeenLoaded ? (
					<Skeleton width={'100%'} height={20} />
				) : null}
			</Menu>
		</NavigationCategory>
	);
};

CurrentInventory.propTypes = {
	t: PropTypes.func
};

export default withTranslation('navigation')(CurrentInventory);
