import React from 'react';
import PropTypes from 'prop-types';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

export function useWindowWidth() {
	const [width, setWidth] = React.useState(window.innerWidth);

	React.useEffect(() => {
		const handleResize = () => setWidth(window.innerWidth);

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return width;
}

export const RepresentmentViewer = (props) => {
	const {
		numberOfPages,
		pdfData,
		onDocumentLoadSuccess,
		onDocumentLoadError
	} = props;

	const width = useWindowWidth();

	const renderPages = React.useCallback(() => {
		const pagesToRender = [];
		for (let pageCount = 1; pageCount <= numberOfPages; pageCount++) {
			pagesToRender.push(
				<Page
					pageNumber={pageCount}
					key={pageCount}
					width={(width - 450) * 0.9}
				/>
			);
		}

		return pagesToRender;
	}, [numberOfPages, width]);

	return (
		<Box mt={2} ml={'220px'} width={'100%'} sx={{ overflow: 'auto' }}>
			<Document
				file={{ data: pdfData }}
				onLoadSuccess={onDocumentLoadSuccess}
				onLoadError={onDocumentLoadError}
			>
				<Stack direction='column' spacing={2}>
					{renderPages().map((page) => {
						return page;
					})}
				</Stack>
			</Document>
		</Box>
	);
};

RepresentmentViewer.propTypes = {
	numberOfPages: PropTypes.number,
	pdfData: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	onDocumentLoadSuccess: PropTypes.func,
	onDocumentLoadError: PropTypes.func
};

export default RepresentmentViewer;
