import Logger from '@slyce360/logger';
import pack from '../../package.json';

const DashboardLogger = new Logger({
	appName: pack.name,
	appVersion: pack.version,
	level: process.env.REACT_APP_STAGE === 'development' ? 'debug' : 'error'
});

export default DashboardLogger;
