import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { capitalCase } from 'change-case';
import TableSummary from './TableSummary';
import columnDefinitions from '../../constants/columnDefinitions';
import { AlternatingBackgroundTableRow } from '../../../../ReportStylings';
import { formatDateForDisplay } from '../../../../../utilities/dateWrapper';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

const ReportTableContainer = styled(TableContainer)(({ theme }) => ({
	borderRadius: '5px',
	border: `1px solid ${theme.palette.common.offWhite}`
}));

const MidDataTable = (props) => {
	const {
		processorId,
		lookupDate,
		rowData,
		rowSummary,
		cardType,
		t
	} = props;

	const navigate = useNavigate();

	const handleDailyRedirect = (merchantId) => {
		const formattedLookupDate = formatDateForDisplay(lookupDate);

		navigate(
			`/reports/f6b37d9d-97e7-4a9c-90a4-2606eaedf227?processorId=${processorId}&merchantIds=${merchantId}&lookupDate=${formattedLookupDate}&autoApply=true`
		);
	};

	const handle14DayRedirect = (merchantId) => {
		// HACK - Have the backend send back the abbrevation also
		const formattedCardType = capitalCase(cardType[0]);

		navigate(
			`/reports/ee60d3ce-fcc9-49a6-9c50-3d790fd037c6?cardBrand=${formattedCardType}&processorId=${processorId}&merchantId=${merchantId}&viewBy=bank&autoApply=true`
		);
	};

	const formatAbbreviations = (abbreviations) => {
		let split = [];

		if (abbreviations) {
			split = abbreviations.split(',');
		}

		return split;
	};

	return (
		<ReportTableContainer>
			<Table size='small'>
				<TableHead>
					<TableRow>
						<TableCell sx={{ fontWeight: 700, fontSize: '12px', width: '25%' }}>
							{t('chargebackSummary.columnNames.mid')}
						</TableCell>
						{columnDefinitions.map((column, index) => {
							return (
								<TableCell key={index} style={column.headerStyle}>
									{column.titleTranslationPath
										? t(column.titleTranslationPath)
										: ''}
								</TableCell>
							);
						})}
						<TableCell sx={{ width: '7%' }} />
						<TableCell sx={{ width: '7%' }} />
					</TableRow>
				</TableHead>
				<TableBody>
					{rowData.map((row, rowIndex) => {
						return (
							<AlternatingBackgroundTableRow key={`mid-row-${rowIndex}`}>
								<TableCell component='th'>
									<Stack direction='column'>
										<Typography sx={{ whiteSpace: 'nowrap', fontWeight: 600, fontsize: '13px' }}>
											{row.merchantLabel}
										</Typography>
										<Stack direction='row' spacing={1} alignItems='center'>
											<Typography variant='caption'>
												{row.merchantId}
											</Typography>
											{row.managedListAbbreviation && (
												<React.Fragment>
													<span> - </span>
													{formatAbbreviations(row.managedListAbbreviation).map(
														(renderItem, abbreviationIndex) => {
															return (
																<Typography
																	variant='caption'
																	key={`${row.merchantId}-abbreviation-${abbreviationIndex}`}
																>
																	{renderItem}
																</Typography>
															);
														}
													)}
												</React.Fragment>
											)}
										</Stack>
									</Stack>
								</TableCell>
								{columnDefinitions.map((column, columnIndex) => {
									return (
										<TableCell key={`row-${rowIndex}-column-${columnIndex}`}>
											{column.formatter
												? column.formatter(row[column.field])
												: row[column.field]}
										</TableCell>
									);
								})}
								<TableCell>
									<Stack
										direction='column'
										justifyContent='center'
										alignItems='center'
									>
										<Button
											size='small'
											variant='outlined'
											color='primary'
											onClick={() => handleDailyRedirect(row.merchantId)}
										>
											{t('chargebackSummary.actions.daily')}
										</Button>
									</Stack>
								</TableCell>
								<TableCell>
									<Stack
										direction='column'
										justifyContent='center'
										alignItems='center'
									>
										<Button
											size='small'
											variant='outlined'
											color='primary'
											onClick={() => handle14DayRedirect(row.merchantId)}
										>
											{t('chargebackSummary.actions.fourteenDay')}
										</Button>
									</Stack>
								</TableCell>
							</AlternatingBackgroundTableRow>
						);
					})}
				</TableBody>
				<TableSummary
					summary={rowSummary}
					lookupDate={lookupDate}
					processorId={processorId}
					mids={'all'}
				/>
			</Table>
		</ReportTableContainer>
	);
};

MidDataTable.propTypes = {
	cardType: PropTypes.string,
	processorId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	lookupDate: PropTypes.string,
	rowData: PropTypes.array,
	rowSummary: PropTypes.object,
	t: PropTypes.func
};

export default withTranslation('reports')(MidDataTable);
