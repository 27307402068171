import * as PermissionsEpics from './epics';
import * as permissionsActions from './actions';
import * as permissionsActionTypes from './actionTypes';
import { permissionsReducer } from './reducer';

const permissionsEpics = Object.values({ ...PermissionsEpics });

export {
	permissionsActions,
	permissionsActionTypes,
	permissionsReducer,
	permissionsEpics
};
