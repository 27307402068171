export const GET_GROUPS = 'groups/GET_GROUPS';
export const GET_GROUPS_COMPLETED = 'groups/GET_GROUPS_COMPLETED';
export const GET_GROUPS_FAILED = 'groups/GET_GROUPS_FAILED';

export const GET_GROUP = 'groups/GET_GROUP';
export const GET_GROUP_COMPLETED = 'groups/GET_GROUP_COMPLETED';
export const GET_GROUP_FAILED = 'groups/GET_GROUP_FAILED';

export const ADD_GROUP = 'groups/ADD_GROUP';
export const ADD_GROUP_COMPLETED = 'groups/ADD_GROUP_COMPLETED';
export const ADD_GROUP_FAILED = 'groups/ADD_GROUP_FAILED';

export const UPDATE_GROUP = 'groups/UPDATE_GROUP';
export const UPDATE_GROUP_COMPLETED = 'groups/UPDATE_GROUP_COMPLETED';
export const UPDATE_GROUP_FAILED = 'groups/UPDATE_GROUP_FAILED';

export const DELETE_GROUP = 'groups/DELETE_GROUP';
export const DELETE_GROUP_COMPLETED = 'groups/DELETE_GROUP_COMPLETED';
export const DELETE_GROUP_FAILED = 'groups/DELETE_GROUP_FAILED';

export const CLEAR_GROUPS = 'groups/CLEAR_GROUPS';
