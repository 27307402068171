import axios from '../../utilities/axios';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { refreshToken } from '../../utilities/refreshToken';
import { primaryRestGateway } from '../../utilities/apiEndpointUtility';

import {
	getNotChallenged,
	getNotChallengedCompleted,
	getNotChallengedFailed,
	revertNotChallenged,
	revertNotChallengedCompleted,
	revertNotChallengedFailed
} from './notChallengedSlice';

export const getNotChallengedEpic = (action$) =>
	action$.pipe(
		ofType(getNotChallenged),
		mergeMap(async (action) => {
			await refreshToken();

			const { startDate, endDate, processorId } = action.payload;

			const response = await axios.get(
				`${primaryRestGateway()}/api/representments/inventory/not-challenged`, {
					params: {
						startDate,
						endDate,
						processorId
					}
				}
			);

			return response.data;
		}),
		switchMap((res) => [
			getNotChallengedCompleted(res)
		]),
		catchError((error, source) =>
			merge(of(getNotChallengedFailed(error.message)), source)
		)
	);

export const revertNotChallengedEpic = (action$) =>
	action$.pipe(
		ofType(revertNotChallenged),
		mergeMap(async (action) => {
			await refreshToken();

			const {
				startDate,
				endDate,
				processorId,
				chargebackIdList
			} = action.payload;

			await axios.put(
				`${primaryRestGateway()}/api/representments/inventory/open/status`,
				{
					chargebackIdList
				}
			);

			return {
				startDate,
				endDate,
				processorId
			};
		}),
		switchMap((res) => [
			revertNotChallengedCompleted(res),
			getNotChallenged(res)
		]),
		catchError((error, source) =>
			merge(of(revertNotChallengedFailed(error.message)), source)
		)
	);

export default [
	getNotChallengedEpic,
	revertNotChallengedEpic
];
