import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';

import LightbulbIcon from '@mui/icons-material/Lightbulb';

const StyledIconContainer = styled('div')(({ theme }) => ({
	display: 'flex',
	padding: '3px',
	marginRight: '5px',
	flexDirection: 'column',
	borderRight: `1px solid ${theme.palette.secondary.alt}`
}));

const SuggestedActions = (props) => {
	const { t, suggestedActions } = props;

	const navigate = useNavigate();

	const onSuggestedActionClicked = (actionType, payload) => {
		if (actionType === 'report') {
			navigate(`/insights/${payload}`);
		}
	};

	const getTranslationPath = (actionType) => {
		if (actionType === 'report') {
			return 'insightReport';
		}
	};

	return (
		<Stack direction='row' p={1}>
			<StyledIconContainer>
				<Tooltip
					title={t('suggestedActions')}
				>
					<LightbulbIcon sx={{ color: 'primary.alt', fontSize: '1.5rem' }} />
				</Tooltip>
			</StyledIconContainer>
			<Stack direction='row' spacing={1} justifyContent='flex-start' alignItems='center'>
				{suggestedActions.map((suggestedAction, index) => {
					return (
						<Chip
							size='medium'
							variant='primary'
							sx={{ m: '0px 3px', cursor: 'pointer' }}
							onClick={() => {
								onSuggestedActionClicked(
									suggestedAction.actionType,
									suggestedAction.actionPayload
								);
							}}
							key={`${suggestedAction.id}-${index}`}
							label={t(getTranslationPath(suggestedAction.actionType))}
						/>
					);
				})}
			</Stack>
		</Stack>
	);
};

SuggestedActions.propTypes = {
	suggestedActions: PropTypes.array,
	t: PropTypes.func
};

export default withTranslation('notifications')(SuggestedActions);
