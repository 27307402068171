export const GET_USER = 'user/GET_USER';
export const GET_USER_COMPLETED = 'user/GET_USER_COMPLETED';
export const GET_USER_FAILED = 'user/GET_USER_FAILED';

export const UPDATE_USER = 'user/UPDATE_USER';
export const UPDATE_USER_COMPLETED = 'user/UPDATE_USER_COMPLETED';
export const UPDATE_USER_FAILED = 'user/UPDATE_USER_FAILED';

export const RESET_UPDATE_USER = 'user/RESET_UPDATE_USER';

export const GET_USER_PERMISSIONS = 'user/GET_USER_PERMISSIONS';
export const GET_USER_PERMISSIONS_COMPLETED =
	'user/GET_USER_PERMISSIONS_COMPLETED';
export const GET_USER_PERMISSIONS_FAILED = 'user/GET_USER_PERMISSIONS_FAILED';

export const UPDATE_USER_PERMISSIONS = 'user/UPDATE_USER_PERMISSIONS';
export const UPDATE_USER_PERMISSIONS_COMPLETED =
	'user/UPDATE_USER_PERMISSIONS_COMPLETED';
export const UPDATE_USER_PERMISSIONS_FAILED =
	'user/UPDATE_USER_PERMISSIONS_FAILED';

export const UPDATE_USER_STATUS = 'user/UPDATE_USER_STATUS';
export const UPDATE_USER_STATUS_COMPLETED = 'user/UPDATE_USER_STATUS_COMPLETED';
export const UPDATE_USER_STATUS_FAILED = 'user/UPDATE_USER_STATUS_FAILED';

export const DELETE_USER = 'user/DELETE_USER';
export const DELETE_USER_COMPLETED = 'user/DELETE_USER_COMPLETED';
export const DELETE_USER_FAILED = 'user/DELETE_USER_FAILED';

export const RESET_USER_AVATAR_UPLOAD = 'user/RESET_USER_AVATAR_UPLOAD';
export const RESET_USER_PERMISSION_PROCESS =
	'user/RESET_USER_PERMISSION_PROCESS';
export const RESET_USER_STATUS_PROCESS = 'user/RESET_USER_STATUS_PROCESS';
export const RESET_DELETE_USER_PROCESS = 'user/RESET_DELETE_USER_PROCESS';

export const LOGOUT = 'user/LOGOUT';
