import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import querier from '../../../../utilities/querier';
import { groupsActions } from '../../../../containers/Groups';
import isDataEmpty from '../../../../helpers/shared/isDataEmpty';
import DisabledSelectInput from './DisabledSelectInput';
import SubtextError from '../../../errors/SubtextError';

import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';

const GroupSelect = (props) => {
	const {
		onChange,
		value,
		t,
		groups,
		groupsAreLoading,
		groupsHaveBeenLoaded,
		groupsListsError,
		getGroups,
		clearGroups,
		temporaryListValues,
		setTemporaryListValues,
		isStageDisabled
	} = props;

	const [inputValue, setInputValue] = React.useState('');
	const [open, setOpen] = React.useState(false);
	const [options, setOptions] = React.useState([]);
	const loading = options.length === 0 && open;

	React.useEffect(() => {
		if (!groupsHaveBeenLoaded && !groupsAreLoading) {
			getGroups();
		}

		setOptions([]);

		return () => {
			clearGroups();
		};
	}, []);

	React.useEffect(() => {
		if (open) {
			setOptions(groups);
		}
	}, [open, groups]);

	React.useEffect(() => {
		if (!open) {
			setOptions([]);
		}
	}, [open]);

	React.useEffect(() => {
		if (temporaryListValues && !isDataEmpty(groups)) {
			const matchedGroup = querier(groups, `$[id = ${temporaryListValues}]`);

			onChange(matchedGroup);
			setTemporaryListValues();
		}
	}, [temporaryListValues, groups]);

	if (groupsListsError) {
		return (
			<SubtextError errorText={t('rules:failedToLoadGroups')} />
		);
	}

	if (
		isDataEmpty(groups) ||
		!isDataEmpty(temporaryListValues) ||
		isStageDisabled
	) {
		return (
			<DisabledSelectInput
				value={value}
				valueLabelKey={'groupLabel'}
				isStageDisabled={isStageDisabled}
				labelTranslationPath={'rules:selectSingleGroup'}
			/>
		);
	}

	return (
		<FormControl sx={{ flex: 1 }}>
			<Autocomplete
				filterSelectedOptions
				options={options}
				open={open}
				onOpen={() => {
					setOpen(true);
				}}
				onClose={() => {
					setOpen(false);
				}}
				loading={loading}
				isOptionEqualToValue={(option, item) => {
					return option?.id === item?.id;
				}}
				renderOption={(props, option) => (
					<li {...props} key={option?.id}>
						{option?.groupLabel}
					</li>
				)}
				getOptionLabel={(option) => {
					return option ? option.groupLabel : '';
				}}
				value={value || ''}
				onChange={(event, newValue) => {
					onChange(newValue);
				}}
				inputValue={inputValue}
				onInputChange={(event, newInputValue) => {
					setInputValue(newInputValue);
				}}
				sx={{ flex: 1 }}
				renderInput={(params) => (
					<TextField
						{...params}
						label={t('rules:selectSingleGroup')}
						variant='outlined'
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<React.Fragment>
									{loading ? (
										<CircularProgress color='inherit' size={20} />
									) : null}
									{params.InputProps.endAdornment}
								</React.Fragment>
							)
						}}
					/>
				)}
			/>
		</FormControl>
	);
};

GroupSelect.propTypes = {
	onChange: PropTypes.func,
	value: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.string,
		PropTypes.number
	]),
	t: PropTypes.func,
	groups: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	groupsAreLoading: PropTypes.bool,
	groupsHaveBeenLoaded: PropTypes.bool,
	groupsListsError: PropTypes.string,
	getGroups: PropTypes.func,
	clearGroups: PropTypes.func,
	temporaryListValues: PropTypes.array,
	setTemporaryListValues: PropTypes.func,
	isStageDisabled: PropTypes.bool
};

const mapStateToProps = (state) => {
	return {
		groups: state.groupsReducer.getIn(['groups', 'data']),
		groupsAreLoading: state.groupsReducer.getIn(['groups', 'isLoading']),
		groupsHaveBeenLoaded: state.groupsReducer.getIn([
			'groups',
			'hasBeenLoaded'
		]),
		groupsListsError: state.groupsReducer.getIn(['groups', 'error'])
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getGroups: (payload) => {
			dispatch(groupsActions.getGroups(payload));
		},
		clearGroups: (payload) => {
			dispatch(groupsActions.clearGroups(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation('rules')(GroupSelect));
