import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';

const Tooltip = (props) => {
	const { value = [] } = props;

	const validatedEntries = value.filter((entry) => entry.label);

	return (
		<React.Fragment>
			{validatedEntries.map((entry) => {
				return (
					<Typography key={entry.id} variant='caption' paragraph={true} m={0}>
						{entry.label}
					</Typography>
				);
			})}
		</React.Fragment>
	);
};

Tooltip.propTypes = {
	value: PropTypes.array
};

export default Tooltip;
