import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import * as sorting from '../../constants/sorting';
import makeArray from '../../utilities/makeArray';
import EnhancedTableHead from './components/EnhancedTableHead';
import isDataEmpty from '../../helpers/shared/isDataEmpty';
import NoDataRow from './components/NoDataRow';
import filterRows from '../../helpers/shared/filterRows';
import TableErrorRow from '../errors/TableErrorRow';

import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

export const SortableTable = (props) => {
	const {
		t,
		initialSortColumn,
		data,
		isLoading,
		hasBeenLoaded,
		columnDefinitions,
		TableRowComponent,
		rowProps,
		translationContext,
		searchTerm,
		searchableColumns,
		defaultSortOrder,
		error
	} = props;

	const [sortOrder, setSortOrder] = React.useState(defaultSortOrder || sorting.sortOrder.asc);
	const [orderBy, setOrderBy] = React.useState(initialSortColumn);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(20);

	const handleSortRequest = (event, property) => {
		if (property !== orderBy) {
			setSortOrder(sorting.sortOrder.asc);
			setOrderBy(property);
		} else {
			setSortOrder(sortOrder === sorting.sortOrder.asc ? sorting.sortOrder.desc : sorting.sortOrder.asc);
		}
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const preparedData = React.useMemo(() => {
		if (!isDataEmpty(data)) {
			const orderedData = _.orderBy(makeArray(data), [orderBy], [sortOrder]);

			let preparedData = orderedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

			if (searchableColumns && searchTerm !== '') {
				preparedData = filterRows(preparedData, searchTerm, searchableColumns);
			}

			return preparedData;
		} else {
			return [];
		}
	}, [data, sortOrder, orderBy, page, searchTerm]);

	return (
		<Box sx={{ width: '100%', p: 1, mb: 2 }}>
			<TableContainer>
				<Table
					sx={{ minWidth: 750, pr: 1 }}
					aria-labelledby='tableTitle'
					size={'small'}
					stickyHeader
				>
					<EnhancedTableHead
						order={sortOrder}
						orderBy={orderBy}
						handleSortRequest={handleSortRequest}
						rowCount={data?.length}
						columnDefinitions={columnDefinitions}
						translationContext={translationContext}
					/>
					<TableBody>
						{!hasBeenLoaded && !isLoading && !error ? (
							<TableRow>
								<TableCell colSpan={columnDefinitions.length} align='center'>
									<Typography color='secondary'>
										{t('submitToLoad')}
									</Typography>
								</TableCell>
							</TableRow>
						) : null}
						{isLoading ? (
							<TableRow>
								<TableCell colSpan={columnDefinitions.length}>
									<LinearProgress width={'100%'} />
								</TableCell>
							</TableRow>
						) : null}
						{ error
							? <TableErrorRow errorText={t('error.tableError')} columnSpan={columnDefinitions.length} />
							: null
						}
						{hasBeenLoaded && preparedData
							? preparedData.map((row, index) => {
								return (
									<TableRowComponent
										key={index}
										row={row}
										{...rowProps}
									/>
								);
							})
							: null
						}
						{hasBeenLoaded && !isLoading && data.length === 0 && (
							<NoDataRow columnSpan={columnDefinitions.length} />
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[20, 40, 60]}
				component='div'
				count={data?.length || 0}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
		</Box>
	);
};

SortableTable.propTypes = {
	data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	isLoading: PropTypes.bool,
	hasBeenLoaded: PropTypes.bool,
	initialSortColumn: PropTypes.string,
	columnDefinitions: PropTypes.array,
	TableRowComponent: PropTypes.any,
	rowProps: PropTypes.object,
	defaultSortOder: PropTypes.string,
	translationContext: PropTypes.string,
	searchTerm: PropTypes.string,
	searchableColumns: PropTypes.array,
	defaultSortOrder: PropTypes.string,
	error: PropTypes.string,
	t: PropTypes.func
};

export default withTranslation('common')(SortableTable);
