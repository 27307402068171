import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	resolved: {
		data: [],
		isLoading: false,
		hasBeenLoaded: false,
		error: undefined
	}
};

export const resolvedSlice = createSlice({
	name: 'resolved',
	initialState,
	reducers: {
		getResolved: (state) => {
			state.resolved.isLoading = true;
			state.resolved.error = undefined;
		},
		getResolvedCompleted: (state, action) => {
			state.resolved.isLoading = false;
			state.resolved.hasBeenLoaded = true;
			state.resolved.data = action.payload;
		},
		getResolvedFailed: (state, action) => {
			state.resolved.isLoading = false;
			state.resolved.error = action.payload;
		}
	}
});

export const {
	getResolved,
	getResolvedCompleted,
	getResolvedFailed
} = resolvedSlice.actions;

export default resolvedSlice.reducer;
