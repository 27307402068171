import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import StageAccordion from '../StageAccordion';

import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

const MAX_DESCRIPTION_LENGTH = 50;

export const NameGroup = (props) => {
	const {
		t,
		groupLabel,
		setGroupLabel,
		abbreviation,
		setAbbreviation,
		completedLabel,
		isPreviousStageCompleted,
		isStageCompleted,
		manualDisable
	} = props;

	const [isNameGroupExpanded, setIsNameGroupExpanded] = React.useState(false);

	React.useEffect(() => {
		if (isPreviousStageCompleted && !manualDisable) {
			setIsNameGroupExpanded(true);
		} else {
			setIsNameGroupExpanded(false);
		}
	}, [isPreviousStageCompleted]);

	const onGroupNameChange = (value) => {
		const truncatedValue = value
			? value.slice(0, MAX_DESCRIPTION_LENGTH)
			: value;

		setGroupLabel(truncatedValue);
	};

	return (
		<StageAccordion
			stageLabel={t('groupWizard.groupDetails')}
			collapsedSummaryText={completedLabel}
			isAccordionExpanded={isNameGroupExpanded}
			setisAccordionExpanded={setIsNameGroupExpanded}
			isCompletedFlag={isStageCompleted}
			isDisabled={!isPreviousStageCompleted || manualDisable}
		>
			<Stack direction='row' spacing={2}>
				<TextField
					value={abbreviation}
					label={t('groupWizard.label')}
					onChange={(e) => {
						setAbbreviation(e.target.value);
					}}
					inputProps={{
						maxLength: 5
					}}
					helperText={t('groupWizard.maxCharacters')}
				/>
				<TextField
					value={groupLabel}
					sx={{ flex: 1 }}
					label={t('groupWizard.groupDescription')}
					onChange={(e) => {
						onGroupNameChange(e.target.value);
					}}
					helperText={t('groupWizard.maxGroupDescription')}
				/>
			</Stack>
		</StageAccordion>
	);
};

NameGroup.propTypes = {
	t: PropTypes.func,
	groupLabel: PropTypes.string,
	setGroupLabel: PropTypes.func,
	abbreviation: PropTypes.string,
	setAbbreviation: PropTypes.func,
	completedLabel: PropTypes.string,
	isPreviousStageCompleted: PropTypes.bool,
	isStageCompleted: PropTypes.bool,
	manualDisable: PropTypes.bool
};

export default withTranslation('groups')(NameGroup);
