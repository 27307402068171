import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PageHeader from '../../../components/headers/PageHeader';
import Restricted from '../../../permissionProvider/Restricted';
import RestrictedFallback from '../../../components/shared/RestrictedFallback';
import RepresentmentFilters from '../../../components/Representments/RepresentmentFilters';
import SortableTable from '../../../components/SortableTable';
import EnhancedTableToolbar from '../../../components/Representments/Resolved/WorkedCasesTable/EnhancedTableToolbar';
import WorkedCasesTableRow from '../../../components/Representments/Resolved/WorkedCasesTable/WorkedCasesTableRow';
import searchableColumns from '../../../components/Representments/Resolved/WorkedCasesTable/constants/searchableColumns';
import columnDefinitions from '../../../components/Representments/Resolved/WorkedCasesTable/constants/columnDefinitions';
import * as sorting from '../../../constants/sorting';

import { getResolved } from '../../../containers/Representments/resolvedSlice';

import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';

const DEFAULT_DAYS_BACK = 30;

export const ResolvedCasesPage = (props) => {
	const { t, data, isLoading, hasBeenLoaded, error, getResolved } = props;

	const [searchTerm, setSearchTerm] = React.useState('');

	return (
		<Container maxWidth={false}>
			<Restricted
				to='representments.workedCases.view'
				Fallback={
					<RestrictedFallback overrideMessage={t('error.notAllowed')} />
				}
			>
				<Stack spacing={2} p={2}>
					<PageHeader label={t('representments:resolved')} />
					<RepresentmentFilters
						isLoading={isLoading}
						hasBeenLoaded={hasBeenLoaded}
						dataGetterFunction={getResolved}
						defaultDaysBack={DEFAULT_DAYS_BACK}
					/>
					<EnhancedTableToolbar
						searchTerm={searchTerm}
						setSearchTerm={setSearchTerm}
					/>
					<SortableTable
						data={data}
						isLoading={isLoading}
						hasBeenLoaded={hasBeenLoaded}
						error={error}
						initialSortColumn={'sentToProcessorOn'}
						columnDefinitions={columnDefinitions}
						TableRowComponent={WorkedCasesTableRow}
						translationContext={'representments'}
						searchTerm={searchTerm}
						searchableColumns={searchableColumns}
						defaultSortOrder={sorting.sortOrder.desc}
					/>
				</Stack>
			</Restricted>
		</Container>
	);
};

ResolvedCasesPage.propTypes = {
	t: PropTypes.func,
	data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	isLoading: PropTypes.bool,
	hasBeenLoaded: PropTypes.bool,
	error: PropTypes.string,
	getResolved: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		isLoading: state.resolvedSlice.resolved.isLoading,
		hasBeenLoaded: state.resolvedSlice.resolved.hasBeenLoaded,
		error: state.resolvedSlice.resolved.error,
		data: state.resolvedSlice.resolved.data
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getResolved: (payload) => {
			dispatch(getResolved(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation('common')(ResolvedCasesPage));
