import React from 'react';
import PropTypes from 'prop-types';
import dynamicRuleTextBuilder from '../helpers/dynamicRuleTextBuilder';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const RuleDisplay = ({
	selectedFormula,
	selectedMeasurement,
	selectedPaymentMethod,
	selectedComparator,
	selectedComparisonUnit,
	selectedTimeFrame,
	userInput
}) => {
	const displayText = dynamicRuleTextBuilder(
		[
			selectedFormula,
			selectedMeasurement,
			selectedPaymentMethod,
			selectedComparator,
			userInput,
			selectedTimeFrame
		],
		selectedMeasurement,
		selectedComparisonUnit
	);

	return (
		<Box
			mt={2}
			mb={2}
			py={1}
			sx={(theme) => ({
				borderBottom: `1px solid ${theme.palette.primary.main}`,
				width: '100%',
				minHeight: 20
			})}>
			<Typography>{displayText}</Typography>
		</Box>
	);
};

RuleDisplay.propTypes = {
	selectedFormula: PropTypes.object,
	selectedMeasurement: PropTypes.object,
	selectedPaymentMethod: PropTypes.object,
	selectedComparator: PropTypes.object,
	selectedComparisonUnit: PropTypes.object,
	selectedTimeFrame: PropTypes.object,
	userInput: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default RuleDisplay;
