import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	notificationDetail: {
		data: {},
		isLoading: false,
		hasBeenLoaded: false,
		error: undefined
	},
	timeline: {
		data: [],
		isLoading: false,
		hasBeenLoaded: false,
		error: undefined
	},
	changeAssignedUser: {
		isLoading: false,
		hasCompleted: false,
		error: undefined
	},
	changeStatus: {
		isLoading: false,
		hasCompleted: false,
		error: undefined
	},
	addNotificationTimelineEvent: {
		isLoading: false,
		hasCompleted: false,
		error: undefined
	}
};

export const notificationDetailSlice = createSlice({
	name: 'notificationDetail',
	initialState,
	reducers: {
		getNotificationDetail: (state) => {
			state.notificationDetail.isLoading = true;
			state.notificationDetail.error = undefined;
		},
		getNotificationDetailCompleted: (state, action) => {
			state.notificationDetail.isLoading = false;
			state.notificationDetail.hasBeenLoaded = true;
			state.notificationDetail.data = action.payload;
		},
		getNotificationDetailFailed: (state, action) => {
			state.notificationDetail.isLoading = false;
			state.notificationDetail.error = action.payload;
		},
		resetGetNotificationDetail: (state) => {
			state.notificationDetail = initialState.notificationDetail;
		},
		changeAssignedUser: (state) => {
			state.changeAssignedUser.isLoading = true;
			state.changeAssignedUser.error = undefined;
		},
		changeAssignedUserCompleted: (state, action) => {
			state.changeAssignedUser.isLoading = false;
			state.changeAssignedUser.hasCompleted = true;
			state.notificationDetail.data = action.payload;
		},
		changeAssignedUserFailed: (state, action) => {
			state.changeAssignedUser.isLoading = true;
			state.changeAssignedUser.error = action.payload;
		},
		resetChangeAssignedUser: (state) => {
			state.changeAssignedUser = initialState.changeAssignedUser;
		},
		changeNotificationStatus: (state) => {
			state.changeStatus.isLoading = true;
			state.changeStatus.error = undefined;
		},
		changeNotificationStatusCompleted: (state, action) => {
			state.changeStatus.isLoading = false;
			state.changeStatus.hasCompleted = true;
			state.notificationDetail.data = action.payload;
		},
		changeNotificationStatusFailed: (state, action) => {
			state.changeStatus.isLoading = true;
			state.changeStatus.error = action.payload;
		},
		resetChangeNotificationStatus: (state) => {
			state.changeStatus = initialState.changeStatus;
		},
		addNotificationTimelineEvent: (state, action) => {
			state.addNotificationTimelineEvent.isLoading = true;
			state.addNotificationTimelineEvent.error = undefined;
		},
		addNotificationTimelineEventCompleted: (state, action) => {
			state.addNotificationTimelineEvent.isLoading = false;
			state.addNotificationTimelineEvent.hasCompleted = true;
			state.notificationDetail.data = action.payload;
		},
		addNotificationTimelineEventFailed: (state, action) => {
			state.addNotificationTimelineEvent.isLoading = false;
			state.addNotificationTimelineEvent.error = action.payload;
		},
		resetAddNotificationTimelineEvent: (state) => {
			state.addNotificationTimelineEvent = initialState.addNotificationTimelineEvent;
		},
		resetNotificationDetail: (state) => {
			state.notificationDetail = initialState.notificationDetail;
		},
		resetTimeline: (state) => {
			state.timeline = initialState.timeline;
		}
	}
});

export const {
	getNotificationDetail,
	getNotificationDetailCompleted,
	getNotificationDetailFailed,
	resetGetNotificationDetail,
	changeAssignedUser,
	changeAssignedUserCompleted,
	changeAssignedUserFailed,
	resetChangeAssignedUser,
	changeNotificationStatus,
	changeNotificationStatusCompleted,
	changeNotificationStatusFailed,
	resetChangeNotificationStatus,
	addNotificationTimelineEvent,
	addNotificationTimelineEventCompleted,
	addNotificationTimelineEventFailed,
	resetAddNotificationTimelineEvent
} = notificationDetailSlice.actions;

export default notificationDetailSlice.reducer;
