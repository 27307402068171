import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import columnDefinitions from '../constants/columnDefinitions';
import Restricted from '../../../../permissionProvider/Restricted';

import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Checkbox from '@mui/material/Checkbox';
import { visuallyHidden } from '@mui/utils';

export const EnhancedTableHead = (props) => {
	const {
		t,
		numSelected,
		rowCount,
		order,
		orderBy,
		onSelectAllClick,
		handleSortRequest
	} = props;

	const createSortHandler = (property) => (event) => {
		handleSortRequest(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				<TableCell padding='checkbox'>
					<Checkbox
						color='primary'
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={rowCount > 0 && numSelected === rowCount}
						onChange={onSelectAllClick}
						inputProps={{
							'aria-label': t('representmentsTable.checkAllCases')
						}}
					/>
				</TableCell>
				{columnDefinitions.map((headCell) => {
					if (headCell.sortable) {
						return (
							<TableCell
								key={headCell.id}
								align={headCell.align || 'center'}
								padding={headCell.disablePadding ? 'none' : 'normal'}
								width={headCell.width || null}
								sx={{ pb: 1 }}
								sortDirection={orderBy === headCell.id ? order : false}
							>
								<TableSortLabel
									active={orderBy === headCell.id}
									direction={orderBy === headCell.id ? order : 'asc'}
									onClick={createSortHandler(headCell.id)}
								>
									{headCell.labelTranslationPath
										? t(headCell.labelTranslationPath)
										: ''}
									{orderBy === headCell.id ? (
										<Box component='span' sx={visuallyHidden}>
											{order === 'desc'
												? 'sorted descending'
												: 'sorted ascending'}
										</Box>
									) : null}
								</TableSortLabel>
							</TableCell>
						);
					} else {
						return (
							<TableCell
								key={headCell.id}
								align={headCell.align || 'center'}
								padding={headCell.disablePadding ? 'none' : 'normal'}
								sx={{ pb: 1 }}
							>
								{headCell.labelTranslationPath
									? t(headCell.labelTranslationPath)
									: ''}
							</TableCell>
						);
					}
				})}
				<TableCell align={'center'} padding={'none'} sx={{ pb: 1 }} />
				<Restricted to={'representments.representmentBuilder.view'}>
					<TableCell align={'center'} padding={'none'} sx={{ pb: 1 }}>
						{t('representmentsTable.columns.build')}
					</TableCell>
				</Restricted>
				<Restricted to={'representments.pdfGenerator.view'}>
					<TableCell align={'center'} padding={'none'} sx={{ pb: 1 }}>
						{t('representmentsTable.columns.generate')}
					</TableCell>
				</Restricted>
			</TableRow>
		</TableHead>
	);
};

EnhancedTableHead.propTypes = {
	t: PropTypes.func,
	numSelected: PropTypes.number,
	rowCount: PropTypes.number,
	order: PropTypes.string,
	orderBy: PropTypes.string,
	onSelectAllClick: PropTypes.func,
	handleSortRequest: PropTypes.func
};

export default withTranslation('representments')(EnhancedTableHead);
