const _includesDecimal = (value) => {
	if (value) {
		const text = value.toString();
		const index = text.indexOf('.');

		if (index > -1) {
			return true;
		}
	}
};

const _getNumberInputError = (value, t) => {
	let numberError;

	const parsedValue = parseFloat(value);

	if (value && value?.includes('-')) {
		numberError = t('rules:inputs.noNegativeValues');
	} else if (parsedValue < 0) {
		numberError = t('rules:inputs.noNegativeValues');
	} else if (_includesDecimal(value)) {
		numberError = t('rules:inputs.noDecimals');
	}

	return numberError;
};

const _getDecimalDigits = (value) => {
	let digitCount = 0;

	if (value) {
		const text = value.toString();
		const index = text.indexOf('.');

		if (index > -1) {
			digitCount = text.length - index - 1;
		}
	}

	return digitCount;
};

const _getPercentInputError = (value, t) => {
	let percentageError;

	const parsedValue = parseFloat(value);

	if (_getDecimalDigits(value) > 2) {
		percentageError = t('rules:inputs.tooManyDecimalDigits');
	} else if (parsedValue < 0) {
		percentageError = t('rules:inputs.noNegativeValues');
	} else if (value && value?.includes('-')) {
		percentageError = t('rules:inputs.noNegativeValues');
	}

	return percentageError;
};

export const getUserInputError = (userInput, inputType, t) => {
	let error;

	if (inputType === 'number') {
		error = _getNumberInputError(userInput, t);
	} else if (inputType === 'percent') {
		error = _getPercentInputError(userInput, t);
	}

	return error;
};
