import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import isDataEmpty from '../../../../helpers/shared/isDataEmpty';

import CustomTableRow from './TableRow';
import EnhancedHeader from './EnhancedHeader';
import EnhancedFooter from './EnhancedFooter';
import PageError from '../../../errors/PageError';
import NestedRow from './NestedRow';
import LoadingTable from './LoadingTable';

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';

const FourteenDayChargebacksTable = (props) => {
	const { data, t, isLoading, hasBeenLoaded, error } = props;

	if (!hasBeenLoaded) {
		return (
			<Stack direction='row' mt={2} justifyContent='center' alignItems='center'>
				<Typography variant='body1' sx={{ color: 'secondary.alt' }}>
					{t('fourteenDayChargebacks.submitToLoad')}
				</Typography>
			</Stack>
		);
	}

	if (error || isDataEmpty(data)) {
		return <PageError />;
	}

	if (isLoading && !hasBeenLoaded) {
		return (
			<Paper>
				<Stack direction='column' alignItems='center' spacing={1}>
					<LoadingTable />
				</Stack>
			</Paper>
		);
	}

	return (
		<Paper>
			<TableContainer sx={{ flex: 1 }}>
				<Table size='small'>
					<EnhancedHeader columns={data.columns} />
					<TableBody>
						{isLoading && (
							<TableRow>
								<TableCell colSpan={16}>
									<LinearProgress width={'100%'} />
								</TableCell>
							</TableRow>
						)}
						{isDataEmpty(data?.results) && (
							<TableRow>
								<TableCell colSpan={16}>
									<Stack direction='row' justifyContent='center'>
										<Typography>{t('shared.noData')}</Typography>
									</Stack>
								</TableCell>
							</TableRow>
						)}
						{data?.results.map((row, rowIndex) => {
							if (row.nestedRows) {
								return (
									<NestedRow key={row.key} columns={data.columns} row={row} />
								);
							} else {
								const isAlternateRow = rowIndex % 2 === 0;
								return (
									<CustomTableRow
										key={row.key}
										rowIndex={rowIndex}
										isAlternateRow={isAlternateRow}
										columns={data.columns}
										row={row}
									/>
								);
							}
						})}
					</TableBody>
					<EnhancedFooter
						columns={data.columns}
						totals={data.totals}
						startDate={data.columns[data.columns.length - 1].key}
						endDate={data.columns[0].key}
					/>
				</Table>
			</TableContainer>
		</Paper>
	);
};

FourteenDayChargebacksTable.propTypes = {
	data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	isLoading: PropTypes.bool,
	hasBeenLoaded: PropTypes.bool,
	error: PropTypes.string,
	t: PropTypes.func
};

export default withTranslation('reports')(FourteenDayChargebacksTable);
