import { setAuth } from '../../utilities/axios';
import { Auth } from 'aws-amplify';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import Logger from '../../utilities/logger';
import { getUser } from '../User/actions';
import {
	multiFactorAuthActions,
	multiFactorAuthConstants
} from '../MultiFactorAuth';

import * as actions from './actions.js';
import * as actionTypes from './actionTypes.js';

export const authUserEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.AUTH_USER),
		mergeMap(async (action) => {
			const { username, password } = action.payload;

			let user;
			let challengeName;

			try {
				user = await Auth.signIn(username, password);

				if (user.challengeName) {
					Logger.info('User requires challenge');

					challengeName = user.challengeName;
				} else {
					Logger.info('User Logged in', { username });
					const session = await Auth.currentSession();

					setAuth(session.getAccessToken().getJwtToken());
				}

				Logger.sessionMetrics = { key: 'username', value: username };
				Logger.sessionMetrics = {
					key: 'loggedInAt',
					value: String(new Date())
				};
			} catch (error) {
				if (error?.code === 'PasswordResetRequiredException') {
					user = {
						username
					};

					challengeName = 'PasswordResetRequiredException';
				} else {
					throw new Error('Invalid User/Password');
				}
			}

			return {
				user,
				challengeName,
				preferredMFA:
					challengeName === multiFactorAuthConstants.softwareMfa
						? multiFactorAuthConstants.softwareMfa
						: multiFactorAuthConstants.noMfa
			};
		}),
		switchMap((res) => {
			if (res.challengeName) {
				return [
					actions.authUserCompleted(res),
					multiFactorAuthActions.setPreferredMultiFactorAuth(res.preferredMFA)
				];
			} else {
				return [actions.authUserCompleted(res), getUser(res.user.username)];
			}
		}),
		catchError((error, source) => {
			Logger.error('User Auth Failure', error);

			return merge(of(actions.authUserFailed(error.message)), source);
		})
	);

export const authUserSessionEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.AUTH_USER_SESSION),
		mergeMap(async (action) => {
			const session = await Auth.currentSession();
			const user = await Auth.currentAuthenticatedUser({
				bypassCache: true
			});

			setAuth(session.getAccessToken().getJwtToken());

			return {
				user,
				username: user.username,
				preferredMFA: user.preferredMFA
			};
		}),
		switchMap((res) => [
			actions.authUserSessionCompleted(res.user),
			multiFactorAuthActions.setPreferredMultiFactorAuth(res.preferredMFA),
			getUser(res.username)
		]),
		catchError((error, source) =>
			merge(of(actions.authUserSessionFailed(error)), source)
		)
	);

export const completePasswordSetupEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.COMPLETE_PASSWORD_SETUP),
		mergeMap(async (action) => {
			const { user, newPassword } = action.payload;

			Auth.completeNewPassword(user, newPassword);

			return { user, newPassword };
		}),
		switchMap((res) => [
			actions.completePasswordSetupCompleted(res),
			actions.authUser({
				username: res.user.username,
				password: res.newPassword
			})
		]),
		catchError((error, source) =>
			merge(of(actions.completePasswordSetupFailed(error)), source)
		)
	);

export const sendPasswordResetCodeEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.SEND_PASSWORD_RESET_CODE),
		mergeMap(async (action) => {
			const { username } = action.payload;

			await Auth.forgotPassword(username);

			return [];
		}),
		switchMap((res) => [actions.sendPasswordResetCodeCompleted(res)]),
		catchError((error, source) =>
			merge(of(actions.sendPasswordResetCodeFailed(error.message)), source)
		)
	);

export const submitResetPasswordEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.RESET_PASSWORD),
		mergeMap(async (action) => {
			const { password, code, username } = action.payload;

			await Auth.forgotPasswordSubmit(username, code, password);

			return true;
		}),
		switchMap((res) => [actions.resetPasswordCompleted(res)]),
		catchError((error, source) =>
			merge(of(actions.resetPasswordFailed(error.message)), source)
		)
	);
