import React from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';

const TextButton = (props) => {
	const { bgcolor, children, color, disabled, endIcon, onClick, sx } = props;

	return (
		<Button
			sx={{ bgcolor, color, m: 0.5, ...sx }}
			disabled={disabled}
			onClick={onClick}
			endIcon={endIcon}
		>
			{children}
		</Button>
	);
};

TextButton.propTypes = {
	bgcolor: PropTypes.string,
	children: PropTypes.node,
	color: PropTypes.string,
	disabled: PropTypes.bool,
	endIcon: PropTypes.element,
	onClick: PropTypes.func,
	sx: PropTypes.object
};

export default TextButton;
