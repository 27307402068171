import React from 'react';
import { styled } from '@mui/material/styles';
import TableauWrapper from '../../../components/staticReportViews/TableauWrapper';

import Container from '@mui/material/Container';

const StyledContainer = styled(Container)({
	margin: 0,
	padding: 0,
	display: 'flex',
	flexFlow: 'column',
	height: '100%',
	overflow: 'hidden'
});

const TableauReport = () => {
	return (
		<StyledContainer maxWidth={false}>
			<TableauWrapper />
		</StyledContainer>
	);
};

export default TableauReport;
