import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	savedRules: {
		data: [],
		currentPage: 1,
		availablePageCount: 0,
		hasBeenLoaded: false,
		isLoading: false,
		error: undefined
	}
};

export const savedRulesSlice = createSlice({
	name: 'savedRules',
	initialState,
	reducers: {
		getSavedRules: (state) => {
			state.savedRules.isLoading = true;
			state.savedRules.error = undefined;
		},
		getSavedRulesCompleted: (state, action) => {
			state.savedRules.isLoading = false;
			state.savedRules.hasBeenLoaded = true;
			state.savedRules.data = action.payload.rules;
			state.savedRules.availablePageCount = action.payload.availablePageCount;
			state.savedRules.currentPage = action.payload.page;
		},
		getSavedRulesFailed: (state, action) => {
			state.savedRules.isLoading = true;
			state.savedRules.error = action.payload;
		},
		resetSavedRules: (state) => {
			state.savedRules = initialState.savedRules;
		}
	}
});

export const {
	getSavedRules,
	getSavedRulesCompleted,
	getSavedRulesFailed,
	resetSavedRules
} = savedRulesSlice.actions;

export default savedRulesSlice.reducer;
