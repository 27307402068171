import AddCardIcon from '@mui/icons-material/AddCard';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import CheckIcon from '@mui/icons-material/Check';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

export const ruleBuilderCategoryIcons = {
	disputes: CreditCardIcon,
	authorizations: CheckIcon,
	fraud: GppMaybeIcon,
	refunds: AddCardIcon,
	representments: CurrencyExchangeIcon,
	sales: AttachMoneyIcon
};

export const utilityIcons = {
	edit: EditIcon,
	delete: DeleteIcon
};

export const menuNavigationIcons = {
	inbox: MailOutlineIcon,
	insights: LightbulbIcon,
	support: HelpOutlineIcon,
	tools: BuildCircleIcon,
	users: PeopleAltIcon
};

export const notificationIcons = {
	alerts: NotificationsIcon,
	collapsedMenu: KeyboardArrowLeftIcon,
	expandedMenu: KeyboardArrowRightIcon,
	help: HelpOutlineIcon
};
