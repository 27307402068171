import * as actionTypes from './actionTypes';

export const activateMultiFactorAuth = (payload) => ({
	type: actionTypes.ACTIVATE_MULTI_FACTOR_AUTH,
	payload
});

export const activateMultiFactorAuthCompleted = (payload) => ({
	type: actionTypes.ACTIVATE_MULTI_FACTOR_AUTH_COMPLETED,
	payload
});

export const activateMultiFactorAuthFailed = (payload) => ({
	type: actionTypes.ACTIVATE_MULTI_FACTOR_AUTH_FAILED,
	payload
});

export const verifyMultiFactorAuthCode = (payload) => ({
	type: actionTypes.VERIFY_MULTI_FACTOR_AUTH_CODE,
	payload
});

export const verifyMultiFactorAuthCodeCompleted = (payload) => ({
	type: actionTypes.VERIFY_MULTI_FACTOR_AUTH_CODE_COMPLETED,
	payload
});

export const verifyMultiFactorAuthCodeFailed = (payload) => ({
	type: actionTypes.VERIFY_MULTI_FACTOR_AUTH_CODE_FAILED,
	payload
});

export const setPreferredMultiFactorAuth = (payload) => ({
	type: actionTypes.SET_PREFERRED_MULTI_FACTOR_AUTH,
	payload
});

export const disableMultiFactorAuth = (payload) => ({
	type: actionTypes.DISABLE_MULTI_FACTOR_AUTH,
	payload
});

export const disableMultiFactorAuthCompleted = (payload) => ({
	type: actionTypes.DISABLE_MULTI_FACTOR_AUTH_COMPLETED,
	payload
});

export const disableMultiFactorAuthFailed = (payload) => ({
	type: actionTypes.DISABLE_MULTI_FACTOR_AUTH_FAILED,
	payload
});

export const logout = (payload) => ({
	type: actionTypes.LOGOUT,
	payload
});
