const runFrequencies = [
	{
		id: '1d',
		name: 'oneDay'
	},
	{
		id: '1w',
		name: 'oneWeek'
	},
	{
		id: '1m',
		name: 'oneMonth'
	}
];

export default runFrequencies;
