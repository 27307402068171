export const AUTH_USER = 'auth/AUTH_USER';
export const AUTH_USER_COMPLETED = 'auth/AUTH_USER_COMPLETED';
export const AUTH_USER_FAILED = 'auth/AUTH_USER_FAILED';

export const AUTH_USER_SESSION = 'auth/AUTH_USER_SESSION';
export const AUTH_USER_SESSION_COMPLETED = 'auth/AUTH_USER_SESSION_COMPLETED';
export const AUTH_USER_SESSION_FAILED = 'auth/AUTH_USER_SESSION_FAILED';

export const DE_AUTH_USER = 'auth/DE_AUTH_USER';

export const COMPLETE_PASSWORD_SETUP = 'auth/COMPLETE_PASSWORD_SETUP';
export const COMPLETE_PASSWORD_SETUP_COMPLETED =
	'auth/COMPLETE_PASSWORD_SETUP_COMPLETED';
export const COMPLETE_PASSWORD_SETUP_FAILED =
	'auth/COMPLETE_PASSWORD_SETUP_FAILED';

export const SEND_PASSWORD_RESET_CODE = 'auth/SEND_PASSWORD_RESET_CODE';
export const SEND_PASSWORD_RESET_CODE_COMPLETED =
	'auth/SEND_PASSWORD_RESET_CODE_COMPLETED';
export const SEND_PASSWORD_RESET_CODE_FAILED =
	'auth/SEND_PASSWORD_RESET_CODE_FAILED';

export const RESET_PASSWORD = 'auth/RESET_PASSWORD';
export const RESET_PASSWORD_COMPLETED = 'auth/RESET_PASSWORD_COMPLETED';
export const RESET_PASSWORD_FAILED = 'auth/RESET_PASSWORD_FAILED';
