import React from 'react';
import PropTypes from 'prop-types';

import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';

const Loader = (props) => {
	const { label, type } = props;

	const _renderProgressType = () => {
		switch (type) {
			case 'linear':
				return <LinearProgress sx={{ width: '50%' }} color='secondary' />;
			default:
				return <CircularProgress color='secondary' />;
		}
	};

	return (
		<Container
			maxWidth={false}
			disableGutters
			sx={{
				display: 'flex!important',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				width: '98vw!important',
				height: '98vh'
			}}
		>
			{label ? <p>{label}</p> : null}
			{_renderProgressType()}
		</Container>
	);
};

Loader.propTypes = {
	label: PropTypes.string,
	type: PropTypes.string
};

export default Loader;
