export default [
	{
		id: 'processor',
		numeric: false,
		disablePadding: true,
		sortable: true,
		align: 'left',
		width: '250px',
		labelTranslationPath: 'representmentsTable.columns.processor'
	},
	{
		id: 'caseId',
		numeric: false,
		disablePadding: true,
		sortable: true,
		align: 'left',
		width: '150px',
		labelTranslationPath: 'representmentsTable.columns.caseNumber'
	},
	{
		id: 'billingLastName',
		numeric: false,
		disablePadding: true,
		sortable: true,
		width: '150',
		labelTranslationPath: 'representmentsTable.columns.customerName'
	},
	{
		id: 'amount',
		numeric: true,
		disablePadding: true,
		sortable: true,
		labelTranslationPath: 'representmentsTable.columns.amount'
	},
	{
		id: 'paymentCardBrand',
		numeric: false,
		disablePadding: true,
		sortable: true,
		labelTranslationPath: 'representmentsTable.columns.cardDetails'
	},
	{
		id: 'chargebackDate',
		numeric: false,
		disablePadding: true,
		sortable: true,
		labelTranslationPath: 'representmentsTable.columns.chargebackDate'
	},
	{
		id: 'notChallengedOn',
		numeric: false,
		disablePadding: true,
		sortable: true,
		labelTranslationPath: 'representmentsTable.markNotChallenged'
	},
	{
		id: 'viewDetails',
		numeric: false,
		disablePadding: true,
		sortable: false,
		labelTranslationPath: 'representmentsTable.viewRepresentment'
	},
	{
		id: 'revert',
		numeric: false,
		disablePadding: true,
		sortable: false,
		labelTranslationPath: 'representmentsTable.revertToOpportunities',
		restrictedTo: 'representments.notChallenged.edit'
	}
];
