import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { StaticReportInputLabel } from '../../../ReportStylings';
import viewByOptions from '../constants/viewBy';

import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';

const ViewBySelect = (props) => {
	const { onChange, value, t } = props;

	return (
		<FormControl sx={{ flex: 1, maxWidth: '250px' }}>
			<StaticReportInputLabel id={'view-by-select-label'}>
				{t('filterController.viewBy')}
			</StaticReportInputLabel>
			<Select
				value={value}
				onChange={(e) => {
					onChange(e.target.value);
				}}
			>
				{viewByOptions.map((option) => {
					return (
						<MenuItem key={option.value} value={option.value}>
							{option.label}
						</MenuItem>
					);
				})}
			</Select>
		</FormControl>
	);
};

ViewBySelect.propTypes = {
	t: PropTypes.func,
	onChange: PropTypes.func,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default withTranslation('reports')(ViewBySelect);
