import React from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';

const ContainedButton = (props) => {
	const { bgcolor, children, color, disabled, onClick, sx, type } = props;

	return (
		<Button
			sx={{ bgcolor, color, m: 0.5, ...sx }}
			disabled={disabled}
			onClick={onClick}
			variant='contained'
			type={type}
		>
			{children}
		</Button>
	);
};

ContainedButton.propTypes = {
	bgcolor: PropTypes.string,
	children: PropTypes.node,
	color: PropTypes.string,
	disabled: PropTypes.bool,
	onClick: PropTypes.func,
	sx: PropTypes.object,
	type: PropTypes.string
};

export default ContainedButton;
