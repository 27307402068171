import { fromJS } from 'immutable';
import * as actionTypes from './actionTypes';

export const initialState = fromJS({
	data: '',
	isLoading: false,
	hasBeenLoaded: false,
	error: undefined
});

export const servicePdfReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_PDF:
			return state.set('isLoading', true);

		case actionTypes.GET_PDF_COMPLETED:
			return state
				.set('data', action.payload)
				.set('isLoading', false)
				.set('hasBeenLoaded', true);

		case actionTypes.GET_PDF_FAILED:
			return state.set('error', action.payload).set('isLoading', false);

		case actionTypes.CLEAR_PDF:
			return state
				.set('data', '')
				.set('isLoading', false)
				.set('hasBeenLoaded', false);

		default:
			return state;
	}
};
