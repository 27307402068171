import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { withTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

const StyledPaper = styled(Paper)({
	display: 'flex',
	flexDirection: 'column',
	width: '900px'
});

export const Submit = (props) => {
	const { t, onSubmit, onCancel, isPreviousStageCompleted } = props;

	return (
		<StyledPaper>
			<Stack direction='row' spacing={2} justifyContent='flex-end' p={2}>
				<Button variant='outlined' onClick={onCancel}>
					{t('buttons.cancel')}
				</Button>
				<Box sx={{ width: 150 }}>
					<Button
						variant='contained'
						disabled={!isPreviousStageCompleted}
						onClick={onSubmit}
						fullWidth
					>
						{t('buttons.submit')}
					</Button>
				</Box>
			</Stack>
		</StyledPaper>
	);
};

Submit.propTypes = {
	t: PropTypes.func,
	onSubmit: PropTypes.func,
	onCancel: PropTypes.func,
	isPreviousStageCompleted: PropTypes.bool
};

export default withTranslation('common')(Submit);
