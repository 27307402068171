import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { groupsActions } from '../../../containers/Groups';
import { mapGroupItemValues } from '../shared/mapGroupItems';
import defaultGroupConfiguration from '../constants/defaultGroupConfiguration';
import GroupWizard from '../GroupWizard';
import { getCurrentTimestamp } from '../../../utilities/dateWrapper';

export const CreateGroup = (props) => {
	const [groupConfiguration, setGroupConfiguration] = React.useState(
		defaultGroupConfiguration
	);

	const navigate = useNavigate();

	const onCancel = () => {
		navigate('/groups');
	};

	const onSubmit = () => {
		const { user, addGroup } = props;

		const mappedItems = mapGroupItemValues(groupConfiguration.groupItems);

		const groupDefinition = {
			groupLabel: groupConfiguration.groupLabel,
			abbreviation: groupConfiguration.abbreviation,
			type: groupConfiguration.buildType?.id,
			userId: user.id,
			createdOn: getCurrentTimestamp(),
			groupItems: mappedItems
		};

		addGroup({
			groupDefinition
		});
		navigate('/groups');
	};

	return (
		<GroupWizard
			groupConfiguration={groupConfiguration}
			setGroupConfiguration={setGroupConfiguration}
			onSubmit={onSubmit}
			onCancel={onCancel}
		/>
	);
};

CreateGroup.propTypes = {
	addGroup: PropTypes.func,
	user: PropTypes.object
};

const mapStateToProps = (state) => {
	return {
		user: state.userReducer.get('user')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		addGroup: (payload) => {
			dispatch(groupsActions.addGroup(payload));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateGroup);
