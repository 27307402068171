import { fromJS } from 'immutable';
import * as actionTypes from './actionTypes';

export const initialState = fromJS({
	isLoadingUser: false,
	isUpdatingUser: false,
	userHasBeenLoaded: false,
	userHasBeenUpdated: false,
	preferences: {},
	user: {},
	userAvatar: undefined,
	isUpdatingAvatar: false,
	avatarHasBeenUpdated: false,
	isGettingUserPermissions: false,
	selectedUserPermissions: [],
	isUpdatingUserPermissions: false,
	successfullyUpdatedPermissions: false,
	isUpdatingUserStatus: false,
	successfullyUpdatedUserStatus: false,
	isDeletingUser: false,
	successfullyDeletedUser: false
});

export const userReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_USER:
			return state.set('isLoadingUser', true);

		case actionTypes.GET_USER_COMPLETED:
			return state
				.set('user', action.payload)
				.set('userAvatar', action.payload.avatar)
				.set('preferences', action.payload.preferences)
				.set('isLoadingUser', false)
				.set('userHasBeenLoaded', true);

		case actionTypes.GET_USER_FAILED:
			return state
				.set('user', {})
				.set('isLoadingUser', false)
				.set('userHasBeenLoaded', false);

		case actionTypes.UPDATE_USER:
			return state.set('isUpdatingUser', true)
				.set('userHasBeenUpdated', false);

		case actionTypes.UPDATE_USER_COMPLETED:
			return state
				.set('isUpdatingUser', false)
				.set('user', action.payload)
				.set('userHasBeenUpdated', true)
				.set('preferences', action.payload.preferences);

		case actionTypes.UPDATE_USER_FAILED:
			return state.set('isUpdatingUser', false);

		case actionTypes.RESET_UPDATE_USER:
			return state.set('userHasBeenUpdated', false);

		case actionTypes.RESET_USER_AVATAR_UPLOAD:
			return state.set('avatarHasBeenUpdated', false);

		case actionTypes.GET_USER_PERMISSIONS:
			return state.set('isGettingUserPermissions', true);

		case actionTypes.GET_USER_PERMISSIONS_COMPLETED:
			return state
				.set('isGettingUserPermissions', false)
				.set('selectedUserPermissions', action.payload);

		case actionTypes.GET_USER_PERMISSIONS_FAILED:
			return state.set('isGettingUserPermissions', false);

		case actionTypes.UPDATE_USER_PERMISSIONS:
			return state.set('isUpdatingUserPermissions', true);

		case actionTypes.UPDATE_USER_PERMISSIONS_COMPLETED:
			return state
				.set('isUpdatingUserPermissions', false)
				.set('successfullyUpdatedPermissions', true);

		case actionTypes.UPDATE_USER_PERMISSIONS_FAILED:
			return state.set('isUpdatingUserPermissions', false);

		case actionTypes.UPDATE_USER_STATUS:
			return state.set('isUpdatingUserStatus', true);

		case actionTypes.UPDATE_USER_STATUS_COMPLETED:
			return state
				.set('isUpdatingUserStatus', false)
				.set('successfullyUpdatedUserStatus', true);

		case actionTypes.UPDATE_USER_STATUS_FAILED:
			return state.set('isUpdatingUserStatus', false);

		case actionTypes.DELETE_USER:
			return state.set('isDeletingUser', true);

		case actionTypes.DELETE_USER_COMPLETED:
			return state
				.set('isDeletingUser', false)
				.set('successfullyDeletedUser', true);

		case actionTypes.DELETE_USER_FAILED:
			return state.set('isDeletingUser', false);

		case actionTypes.RESET_USER_PERMISSION_PROCESS:
			return state.set('successfullyUpdatedPermissions', false);

		case actionTypes.RESET_USER_STATUS_PROCESS:
			return state.set('successfullyUpdatedUserStatus', false);

		case actionTypes.RESET_DELETE_USER_PROCESS:
			return state.set('successfullyDeletedUser', false);

		case actionTypes.LOGOUT:
			return initialState;

		default:
			return state;
	}
};
