import React from 'react';
import PasswordSetupManager from '../../components/PasswordSetup/PasswordSetupManager';

import RegistrationPageWrapper from '../../components/RegistrationPageWrapper';

const PasswordSetup = () => {
	return (
		<RegistrationPageWrapper>
			<PasswordSetupManager />
		</RegistrationPageWrapper>
	);
};

export default PasswordSetup;
