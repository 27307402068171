module.exports = [
	{
		id: 'abbreviation',
		numeric: true,
		sortable: true,
		align: 'left',
		width: 50,
		labelTranslationPath: 'groupManager.columns.label'
	},
	{
		id: 'groupLabel',
		numeric: false,
		sortable: true,
		align: 'left',
		labelTranslationPath: 'groupManager.columns.description'
	},
	{
		id: 'itemCount',
		numeric: true,
		sortable: true,
		align: 'center',
		width: 150,
		labelTranslationPath: 'groupManager.columns.groupItemCount'
	},
	{
		id: 'type',
		numeric: false,
		sortable: true,
		align: 'center',
		labelTranslationPath: 'groupManager.columns.type'
	},
	{
		id: 'actionOne',
		numeric: false,
		sortable: false,
		align: 'center'
	},
	{
		id: 'actionTwo',
		numeric: false,
		sortable: false,
		align: 'center'
	}
];
