export default [
	{
		id: 'processor',
		numeric: false,
		disablePadding: true,
		sortable: true,
		align: 'left',
		width: '200px',
		labelTranslationPath: 'representmentsTable.columns.processor'
	},
	{
		id: 'caseId',
		numeric: false,
		disablePadding: true,
		sortable: true,
		align: 'left',
		width: '150px',
		labelTranslationPath: 'representmentsTable.columns.caseNumber'
	},
	{
		id: 'billingLastName',
		numeric: false,
		disablePadding: true,
		sortable: true,
		width: '150',
		align: 'left',
		labelTranslationPath: 'representmentsTable.columns.customerName'
	},
	{
		id: 'amount',
		numeric: true,
		disablePadding: true,
		sortable: true,
		align: 'right',
		labelTranslationPath: 'representmentsTable.columns.amount'
	},
	{
		id: 'paymentCardBrand',
		numeric: false,
		disablePadding: true,
		sortable: true,
		labelTranslationPath: 'representmentsTable.columns.cardDetails'
	},
	{
		id: 'billingDescriptor',
		numeric: false,
		sortable: true,
		disablePadding: true,
		align: 'left',
		labelTranslationPath: 'representmentsTable.columns.billingDescriptor'
	},
	{
		id: 'chargebackDate',
		numeric: false,
		disablePadding: true,
		sortable: true,
		labelTranslationPath: 'representmentsTable.columns.chargebackDate'
	},
	{
		id: 'code',
		numeric: true,
		sortable: true,
		disablePadding: true,
		align: 'right',
		labelTranslationPath: 'representmentsTable.columns.responseCode'
	},
	{
		id: 'expiration',
		numeric: false,
		disablePadding: true,
		sortable: true,
		labelTranslationPath: 'representmentsTable.columns.expiration'
	}
];
