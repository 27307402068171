import React from 'react';
import PropTypes from 'prop-types';
import TimelineIcon from './TimelineIcon';
import { formatDateForDisplay } from '../../../../utilities/dateWrapper';

import Typography from '@mui/material/Typography';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

const Timestamp = ({ createdOn, isInitial, isHidden }) => {
	if (isHidden) {
		return null;
	}

	return (
		<TimelineItem sx={{ mt: '20px' }}>
			<TimelineOppositeContent sx={{ flex: 0.1 }} />
			<TimelineSeparator sx={{ ml: !isInitial ? '10px' : null }}>
				{isInitial ? (
					<TimelineDot variant='outlined'>{TimelineIcon()}</TimelineDot>
				) : (
					<TimelineDot />
				)}
				{!isInitial && <TimelineConnector sx={{ minHeight: '40px' }} />}
			</TimelineSeparator>
			<TimelineContent sx={{ flex: 1 }}>
				<Typography variant='h5'>
					{formatDateForDisplay(createdOn, 'MMMM DD, YYYY')}
				</Typography>
			</TimelineContent>
		</TimelineItem>
	);
};

Timestamp.propTypes = {
	createdOn: PropTypes.string,
	isInitial: PropTypes.bool,
	isHidden: PropTypes.bool
};

export default Timestamp;
