import React from 'react';
import PropTypes from 'prop-types';
import querier from '../../../../../../utilities/querier';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const PartSelect = ({
	label,
	ruleConfiguration,
	dependentSelection,
	options,
	configurationKey,
	selectedOption,
	updateOption,
	hideUntilDependentSelected,
	extraPadding,
	hasError,
	children
}) => {
	const [availableOptions, setAvailableOptions] = React.useState();

	React.useEffect(() => {
		const filteredOptions = options
			? options.filter((option) => {
				const isRequiredCategorySelected = true;
				let isRequiredOptionIdSelected = true;

				const selectedCategoryId = querier(ruleConfiguration, 'category.id');
				const selectedOptionIds = querier(
					ruleConfiguration,
					'[$.*.optionId]'
				);

				if (option.requiredCategoryIds?.length > 0 && selectedCategoryId) {
					isRequiredOptionIdSelected = option.requiredCategoryIds.includes(
						selectedCategoryId
					);
				}

				if (option.requiredOptionIds?.length > 0) {
					isRequiredOptionIdSelected = option.requiredOptionIds.some(
						(dependentSelectionId) => {
							return selectedOptionIds.includes(dependentSelectionId);
						}
					);
				}

				return isRequiredCategorySelected && isRequiredOptionIdSelected;
			}) : [];

		if (JSON.stringify(availableOptions) !== JSON.stringify(filteredOptions)) {
			setAvailableOptions(filteredOptions);
		}
	}, [options, ruleConfiguration]);

	React.useEffect(() => {
		if (availableOptions && availableOptions.length === 1) {
			updateOption(configurationKey, availableOptions[0]);
		}
	}, [availableOptions]);

	const onOptionSelect = (value) => {
		updateOption(configurationKey, value);
	};

	if (!options || (hideUntilDependentSelected && !dependentSelection)) {
		return null;
	}

	return (
		<Stack direction='row' m={1} spacing={2} height={extraPadding ? 85 : 40}>
			<Box sx={{ width: '20%' }}>
				<Typography variant='overline' color={hasError && 'error'}>
					{label}
				</Typography>
			</Box>
			<Stack direction='row' sx={{ overflowX: 'auto' }} spacing={2} px={1}>
				{availableOptions
					? availableOptions.map((option) => (
						<Button
							sx={{ height: '35px', width: 'fit-content' }}
							p={0.5}
							key={option.optionId}
							variant={
								selectedOption?.optionId === option.optionId
									? 'contained'
									: 'outlined'
							}
							size='small'
							onClick={() => {
								onOptionSelect(option);
							}}
						>
							{option.ruleDisplayText}
						</Button>
					)) : null}
				{children}
			</Stack>
		</Stack>
	);
};

PartSelect.propTypes = {
	label: PropTypes.string,
	ruleConfiguration: PropTypes.object,
	dependentSelection: PropTypes.bool,
	options: PropTypes.array,
	configurationKey: PropTypes.string,
	selectedOption: PropTypes.object,
	updateOption: PropTypes.func,
	hideUntilDependentSelected: PropTypes.bool,
	extraPadding: PropTypes.bool,
	hasError: PropTypes.bool,
	children: PropTypes.element
};

export default PartSelect;
