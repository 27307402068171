import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { groupsActions } from '../../../containers/Groups';
import SortableTable from '../../SortableTable';
import GroupsTableRow from './Table/GroupsTableRow';
import PageError from '../../errors/PageError';
import ConfirmGroupDeleteModal from './ConfirmGroupDeleteModal';
import EnhancedTableToolbar from './Table/EnhancedTableToolbar';
import columnDefinitions from './Table/constants/columnDefinitions';
import searchableColumns from './Table/constants/searchableColumns';

import Box from '@mui/material/Box';

const GroupManager = (props) => {
	const {
		groups,
		groupsAreLoading,
		groupsHaveBeenLoaded,
		groupsError,
		isAddingGroup,
		isDeletingGroup,
		getGroups,
		deleteGroup
	} = props;

	const [groupMarkedForDeletion, setGroupMarkedForDeletion] = React.useState();
	const [searchTerm, setSearchTerm] = React.useState('');

	React.useEffect(() => {
		if (!groupsAreLoading && !groupsError) {
			getGroups();
		}
	}, []);

	const onConfirmDelete = () => {
		deleteGroup({
			groupId: groupMarkedForDeletion
		});
		setGroupMarkedForDeletion();
	};

	if (groupsError) {
		return <PageError />;
	}

	return (
		<Box>
			<EnhancedTableToolbar
				searchTerm={searchTerm}
				setSearchTerm={setSearchTerm}
			/>
			<SortableTable
				data={groups}
				isLoading={isAddingGroup || isDeletingGroup || groupsAreLoading}
				hasBeenLoaded={groupsHaveBeenLoaded}
				initialSortColumn={'abbreviation'}
				columnDefinitions={columnDefinitions}
				TableRowComponent={GroupsTableRow}
				searchTerm={searchTerm}
				rowProps={{
					onMarkGroupForDeletion: setGroupMarkedForDeletion
				}}
				translationContext='groups'
				searchableColumns={searchableColumns}
			/>
			<ConfirmGroupDeleteModal
				isOpen={!!groupMarkedForDeletion}
				onConfirmDelete={onConfirmDelete}
				groupMarkedForDeletion={groupMarkedForDeletion}
				closeModal={() => setGroupMarkedForDeletion()}
			/>
		</Box>
	);
};

GroupManager.propTypes = {
	groups: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	groupsAreLoading: PropTypes.bool,
	groupsHaveBeenLoaded: PropTypes.bool,
	groupsError: PropTypes.string,
	isAddingGroup: PropTypes.bool,
	isDeletingGroup: PropTypes.bool,
	getGroups: PropTypes.func,
	deleteGroup: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		groups: state.groupsReducer.getIn(['groups', 'data']),
		groupsAreLoading: state.groupsReducer.getIn(['groups', 'isLoading']),
		groupsError: state.groupsReducer.getIn(['groups', 'error']),
		groupsHaveBeenLoaded: state.groupsReducer.getIn([
			'groups',
			'hasBeenLoaded'
		]),
		isDeletingGroup: state.groupsReducer.get('isDeletingGroup'),
		isAddingGroup: state.groupsReducer.get('isAddingGroup')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getGroups: (payload) => {
			dispatch(groupsActions.getGroups(payload));
		},
		deleteGroup: (payload) => {
			dispatch(groupsActions.deleteGroup(payload));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupManager);
