import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import PermissionContext from './PermissionContext';

const validatePermission = (userPermissions, requiredPermission) => {
	return userPermissions.includes(requiredPermission);
};

const PermissionProvider = ({ children }) => {
	const cache = {};

	const user = useSelector((state) => {
		return state.userReducer.get('user');
	});

	const isAllowedTo = (permission) => {
		if (Object.keys(cache).includes(permission)) {
			return cache[permission];
		}

		const isAllowed = validatePermission(user?.permissions || [], permission);

		cache[permission] = isAllowed;

		return isAllowed;
	};

	return (
		<PermissionContext.Provider value={{ isAllowedTo }}>
			{children}
		</PermissionContext.Provider>
	);
};

PermissionProvider.propTypes = {
	children: PropTypes.element
};

export default PermissionProvider;
