import { fromJS } from 'immutable';
import * as actionTypes from './actionTypes';

export const initialState = fromJS({
	data: {},
	isLoading: false,
	hasBeenLoaded: false,
	error: undefined
});

export const insightsReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_INSIGHT_REPORT:
			return state.set('isLoading', true);

		case actionTypes.GET_INSIGHT_REPORT_COMPLETED:
			return state
				.set('data', action.payload)
				.set('isLoading', false)
				.set('hasBeenLoaded', true);

		case actionTypes.GET_INSIGHT_REPORT_FAILED:
			return state.set('isLoading', false).set('error', action.payload);

		case actionTypes.RESET_INSIGHT_REPORT:
			return initialState;

		default:
			return state;
	}
};
