import React from 'react';
import { withTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const Help = (props) => {
	const { t } = props;

	const navigate = useNavigate();
	const location = useLocation();

	const handleOnClick = () => {
		navigate('/support');
	};

	return (
		<MenuItem
			onClick={handleOnClick}
			selected={location.pathname.includes('support')}
		>
			<ListItemIcon>
				<HelpOutlineIcon sx={{ color: 'primary.alt', fontSize: '1.2rem' }} />
			</ListItemIcon>
			<ListItemText>
				<Typography sx={{ fontWeight: 600 }}>
					{t('help')}
				</Typography>
			</ListItemText>
		</MenuItem>
	);
};

Help.propTypes = {
	t: PropTypes.func
};

export default withTranslation('navigation')(Help);
