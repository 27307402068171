import axios from '../../utilities/axios';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { refreshToken } from '../../utilities/refreshToken';
import { primaryRestGateway } from '../../utilities/apiEndpointUtility';

import * as actionTypes from './actionTypes';
import * as actions from './actions';

export const submitSupportFormEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.SUBMIT_SUPPORT_FORM),
		mergeMap(async (action) => {
			await refreshToken();

			await axios.post(
				`${primaryRestGateway()}/services/monday/support/form`,
				action.payload
			);

			return true;
		}),
		switchMap((res) => [actions.submitSupportFormCompleted(res)]),
		catchError((error, source) =>
			merge(of(actions.submitSupportFormFailed(error.message)), source)
		)
	);
