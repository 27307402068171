import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { capitalCase } from 'change-case';
import { getRuleBuilderCategories } from '../../../containers/Rules/ruleBuilderSlice';
import { ruleBuilderCategoryIcons } from '../../../icons';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

export const RuleCategorySelect = ({ t, selectedRuleCategory, onChangeSelection }) => {
	const dispatch = useDispatch();

	const {
		data,
		isLoading,
		error
	} = useSelector(state => state.ruleBuilderSlice.builderCategories);

	React.useEffect(() => {
		if (!isLoading && !error) {
			dispatch(getRuleBuilderCategories());
		}
	}, []);

	return (
		<FormControl sx={{ flex: 1 }} fullWidth>
			<InputLabel id='rule-category-select-label'>
				{t('category')}
			</InputLabel>
			<Select
				labelId='rule-category-select-label'
				label={t('rules:category')}
				id='rule-category-select'
				variant='outlined'
				value={selectedRuleCategory}
				disabled={isLoading}
				onChange={(e) => {
					onChangeSelection(e.target.value);
				}}
				renderValue={(category) => {
					return category === 'all' ? t('common:all') : t(`rules:${category?.name}`);
				}}
				endAdornment={
					<IconButton
						sx={{ display: selectedRuleCategory ? null : 'none', mr: 1 }}
						onClick={() => onChangeSelection('all')}
					>
						<ClearOutlinedIcon />
					</IconButton>
				}
			>
				<MenuItem value={'all'}>
					<Stack direction='row' spacing={2}>
						<Box sx={{ width: 25 }} />
						<Typography>{capitalCase(t('common:all'))}</Typography>
					</Stack>
				</MenuItem>
				{data.map((category) => {
					const CategoryIcon = ruleBuilderCategoryIcons[category.name];

					return (
						<MenuItem key={category.id} value={category}>
							<Stack direction='row' spacing={2}>
								<CategoryIcon color='secondary' />
								<Typography>{capitalCase(t(category.name))}</Typography>
							</Stack>
						</MenuItem>
					);
				})}
			</Select>
		</FormControl>
	);
};

RuleCategorySelect.propTypes = {
	selectedRuleCategory: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	onChangeSelection: PropTypes.func,
	t: PropTypes.func
};

export default withTranslation(['rules', 'common'])(RuleCategorySelect);
