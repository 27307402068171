import * as actionTypes from './actionTypes';

export const authUser = (username, password) => ({
	type: actionTypes.AUTH_USER,
	payload: {
		username,
		password
	}
});
export const authUserCompleted = (payload) => ({
	type: actionTypes.AUTH_USER_COMPLETED,
	payload
});

export const authUserFailed = (payload) => ({
	type: actionTypes.AUTH_USER_FAILED,
	payload
});

export const authUserSession = (payload) => ({
	type: actionTypes.AUTH_USER_SESSION,
	payload
});
export const authUserSessionCompleted = (payload) => ({
	type: actionTypes.AUTH_USER_SESSION_COMPLETED,
	payload
});

export const authUserSessionFailed = (payload) => ({
	type: actionTypes.AUTH_USER_SESSION_FAILED,
	payload
});

export const deAuthUser = (payload) => ({
	type: actionTypes.DE_AUTH_USER,
	payload
});

export const completePasswordSetup = (payload) => ({
	type: actionTypes.COMPLETE_PASSWORD_SETUP,
	payload
});

export const completePasswordSetupCompleted = (payload) => ({
	type: actionTypes.COMPLETE_PASSWORD_SETUP_COMPLETED,
	payload
});

export const completePasswordSetupFailed = (payload) => ({
	type: actionTypes.COMPLETE_PASSWORD_SETUP_FAILED,
	payload
});

export const sendPasswordResetCode = (payload) => ({
	type: actionTypes.SEND_PASSWORD_RESET_CODE,
	payload
});

export const sendPasswordResetCodeCompleted = (payload) => ({
	type: actionTypes.SEND_PASSWORD_RESET_CODE_COMPLETED,
	payload
});

export const sendPasswordResetCodeFailed = (payload) => ({
	type: actionTypes.SEND_PASSWORD_RESET_CODE_FAILED,
	payload
});

export const resetPassword = (payload) => ({
	type: actionTypes.RESET_PASSWORD,
	payload
});

export const resetPasswordCompleted = (payload) => ({
	type: actionTypes.RESET_PASSWORD_COMPLETED,
	payload
});

export const resetPasswordFailed = (payload) => ({
	type: actionTypes.RESET_PASSWORD_FAILED,
	payload
});
