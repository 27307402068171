import isDataEmpty from '../shared/isDataEmpty';

const includesString = (columnValue, searchString) => {
	const doesInclude = columnValue
		.toString()
		.toLowerCase()
		.indexOf(searchString.toString().toLowerCase()) !== -1;

	return doesInclude;
};

const _validateColumn = (columns = [], key) => {
	let result = true;

	if (columns.length > 0) {
		result = columns.includes(key);
	}

	return result;
};

const filterRows = (data, searchTerm, columns) => {
	let filteredData = [];

	if (searchTerm && searchTerm.length > 0 && !isDataEmpty(data)) {
		data.forEach((row) => {
			let includeRow = false;

			Object.keys(row).forEach((key) => {
				if (
					_validateColumn(columns, key) &&
					row[key] !== null &&
					includesString(row[key], searchTerm)
				) {
					includeRow = true;
				}
			});

			if (includeRow) {
				filteredData.push(row);
			}
		});
	} else {
		filteredData = data;
	}

	return filteredData;
};

export default filterRows;
