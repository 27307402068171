import { setAuth, getAuth } from './axios';
import { Auth } from 'aws-amplify';
import Logger from './logger';

export const refreshToken = async () => {
	const session = await Auth.currentSession();
	const user = await Auth.currentUserInfo();
	const sessionToken = session.getAccessToken().getJwtToken();
	const currentToken = getAuth();

	if (currentToken !== sessionToken) {
		Logger.info('User Token Refresh', {
			username: user.username,
			email: user.attributes.email
		});
		setAuth(sessionToken);
	}
};
