import ListAltIcon from '@mui/icons-material/ListAlt';
import StorefrontIcon from '@mui/icons-material/Storefront';
import TollIcon from '@mui/icons-material/Toll';
import BusinessIcon from '@mui/icons-material/Business';

const ruleListTypes = [
	{
		id: 1,
		name: 'group',
		icon: ListAltIcon
	},
	{
		id: 2,
		name: 'processor',
		icon: TollIcon
	},
	{
		id: 3,
		name: 'merchantIds',
		icon: StorefrontIcon
	},
	{
		id: 4,
		name: 'affiliateIds',
		icon: BusinessIcon
	}
];

export default ruleListTypes;
