import React from 'react';
import PropTypes from 'prop-types';
import PendingUsersActionList from './PendingUsersActionList';
import { formatDateForDisplay } from '../../../utilities/dateWrapper';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

export const PendingUsersTableRow = (props) => {
	const { row } = props;

	return (
		<TableRow key={row.createdOn}>
			<TableCell component='th' scope='row'>
				{row.email}
			</TableCell>
			<TableCell align='left'>
				{formatDateForDisplay(row.createdOn)}
			</TableCell>
			<TableCell align='left'>{row.invitedBy}</TableCell>
			<TableCell align='right'>
				<PendingUsersActionList invitationId={row.invitationId} />
			</TableCell>
		</TableRow>
	);
};

PendingUsersTableRow.propTypes = {
	row: PropTypes.object
};

export default PendingUsersTableRow;
