import React from 'react';
import { styled } from '@mui/material/styles';

import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const styles = {
	display: 'flex',
	justifyContent: 'center',
	width: '100%',
	margin: 10
};

const StyledPaper = styled(Paper)(styles);

const StyledStack = styled(Stack)(styles);

const LoadingTable = () => {
	return (
		<StyledPaper>
			<StyledStack direction='column'>
				<Stack
					direction='row'
					justifyContent='space-between'
					alignItems='center'
					pr={2}
					pl={2}
				>
					<Skeleton width={110} height={50} />
					<Skeleton width={20} height={30} />
				</Stack>
				<Stack direction='row' justifyContent='space-around' pr={2} pl={2}>
					<Skeleton width={200} height={40} />
				</Stack>
				<Stack direction='column' alignItems='center' pr={2} pl={2}>
					<Skeleton width={'100%'} height={60} />
					<Skeleton width={'100%'} height={60} />
				</Stack>
			</StyledStack>
		</StyledPaper>
	);
};

export default LoadingTable;
