import { noCase, capitalCase } from 'change-case';

export const noCasing = (value) => {
	if (value) {
		return noCase(value);
	}
};

export const capitalCasing = (value) => {
	if (value) {
		return capitalCase(value);
	}
};
