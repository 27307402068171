import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import HeaderRow from '../../../modals/shared/HeaderRow';
import { getAssetUri } from '../../../../utilities/assetEndpointUtility';
import { getAuth } from '../../../../utilities/axios';
import isDataEmpty from '../../../../helpers/shared/isDataEmpty';

import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

import DownloadIcon from '@mui/icons-material/Download';

export const AttachmentViewerModal = ({ t, attachmentsModalData, onCloseModal }) => {
	const [img, setImg] = React.useState();

	const fetchImage = async () => {
		const res = await fetch(
			`${getAssetUri()}${attachmentsModalData.assetData}`,
			{
				method: 'GET',
				headers: {
					Authorization: `Bearer ${getAuth()}`
				}
			}
		);
		const imageBlob = await res.blob();
		const imageObjectURL = URL.createObjectURL(imageBlob);
		setImg(imageObjectURL);
	};

	React.useEffect(() => {
		if (attachmentsModalData?.assetData) {
			fetchImage();
		}

		return () => {
			setImg();
		};
	}, [attachmentsModalData]);

	if (isDataEmpty(attachmentsModalData)) {
		return null;
	}

	return (
		<Modal
			open={!isDataEmpty(attachmentsModalData)}
			onClose={onCloseModal}
			aria-labelledby='modal-modal-title'
			aria-describedby='modal-modal-description'
			sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flex: 1 }}
		>
			{!isDataEmpty(attachmentsModalData) && (
				<Paper sx={{ display: 'flex', flexDirection: 'column', p: 1, maxWidth: '80vw' }} elevation={1}>
					<HeaderRow
						title={attachmentsModalData.label}
						onClickCancel={onCloseModal}
					/>
					{attachmentsModalData.assetData && <img src={img} />}
					{/* TODO - BECAUSE IMAGES ARE PROTECTED WE NEED A DIFFERENT APPROACH TO DOWNLOAD */}
					{/* <Stack direction='row' justifyContent='flex-end' mt={1}>
						<Button
							variant='outlined'
							startIcon={<DownloadIcon />}
							href={img}
							download
						>
							{t('common:download')}
						</Button>
					</Stack> */}
				</Paper>
			)}
		</Modal>
	);
};

AttachmentViewerModal.propTypes = {
	t: PropTypes.func,
	attachmentsModalData: PropTypes.object,
	onCloseModal: PropTypes.func
};

export default withTranslation(['common'])(AttachmentViewerModal);
