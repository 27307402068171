import * as actionTypes from './actionTypes';

export const getWorkedCases = (payload) => ({
	type: actionTypes.GET_WORKED_CASES,
	payload
});

export const getWorkedCasesCompleted = (payload) => ({
	type: actionTypes.GET_WORKED_CASES_COMPLETED,
	payload
});

export const getWorkedCasesFailed = (payload) => ({
	type: actionTypes.GET_WORKED_CASES_FAILED,
	payload
});

export const removeNotChallengedDate = (payload) => ({
	type: actionTypes.REMOVE_NOT_CHALLENGED_DATE,
	payload
});

export const removeNotChallengedDateCompleted = (payload) => ({
	type: actionTypes.REMOVE_NOT_CHALLENGED_DATE_COMPLETED,
	payload
});

export const removeNotChallengedDateFailed = (payload) => ({
	type: actionTypes.REMOVE_NOT_CHALLENGED_DATE_FAILED,
	payload
});
