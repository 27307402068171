import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import ContainedButton from '../../../buttons/ContainedButton';
import TextButton from '../../../buttons/TextButton';
import OverlineTextError from '../../../errors/OverlineTextError';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

export const ModifyPage = (props) => {
	const { t, user, onCloseModal, onDisableMfa, mfaSetupError } = props;

	const [auththenticationCode, setAuththenticationCode] = React.useState();

	return (
		<Stack spacing={2}>
			<Stack spacing={3} alignItems='center'>
				<Typography>{t('modals:multiFactorAuth.disableMfa')}</Typography>
				<Box sx={{ display: 'flex', alignItems: 'center' }}>
					{t('modals:multiFactorAuth.authenticationCode')}
					<TextField
						onChange={(e) => setAuththenticationCode(e.target.value)}
						sx={{ ml: 2.5 }}
						id='outlined-basic'
						label={t('modals:multiFactorAuth.code')}
						variant='outlined'
						size='small'
					/>
				</Box>
			</Stack>
			{mfaSetupError && (
				<Stack alignItems='center'>
					<OverlineTextError>{mfaSetupError}</OverlineTextError>
				</Stack>
			)}
			<Stack direction='row' justifyContent='flex-end'>
				<TextButton color='secondary.main' onClick={onCloseModal}>
					{t('common:buttons.cancel')}
				</TextButton>
				<ContainedButton
					onClick={() => {
						onDisableMfa(user, auththenticationCode);
					}}
				>
					{t('modals:multiFactorAuth.deactivate')}
				</ContainedButton>
			</Stack>
		</Stack>
	);
};

ModifyPage.propTypes = {
	user: PropTypes.object,
	onCloseModal: PropTypes.func,
	onDisableMfa: PropTypes.func,
	mfaSetupError: PropTypes.string,
	t: PropTypes.func
};

export default withTranslation(['common', 'modals'])(ModifyPage);
