import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { getFullCardByBrandName } from '../../../../helpers/shared/cardBrandHelper';
import Restricted from '../../../../permissionProvider/Restricted';
import { formatDateForDisplay } from '../../../../utilities/dateWrapper';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';

import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ArticleIcon from '@mui/icons-material/Article';
import BuildIcon from '@mui/icons-material/Build';
import Typography from '@mui/material/Typography';

export const CurrentInventoryTableRow = (props) => {
	const { t, row, isItemSelected, handleRowClick, labelId } = props;

	const expiringSoon = dayjs().add(3, 'days').isAfter(dayjs(row.dueDate));

	return (
		<TableRow
			hover
			onClick={(event) => handleRowClick(event, row.chargebackId)}
			role='checkbox'
			aria-checked={isItemSelected}
			tabIndex={-1}
			key={row.caseId}
			selected={isItemSelected}
		>
			<TableCell padding='checkbox'>
				<Checkbox
					color='default'
					checked={isItemSelected}
					inputProps={{
						'aria-labelledby': labelId
					}}
				/>
			</TableCell>
			<TableCell component='th' scope='row' padding='none'>
				{row.paymentEnablerAcquiringBanksLabel}
			</TableCell>
			<TableCell component='th' id={labelId} scope='row' padding='none'>
				{row.caseId}
			</TableCell>
			<TableCell align='left'>
				{row.billingLastName ? `${row.billingLastName}` : '-'}
			</TableCell>
			<TableCell align='right'>
				{row.amount ? row.amount.toFixed(2) : '-'}
			</TableCell>
			<TableCell align='center'>
				<Stack direction='column' alignItems='center'>
					<Box
						component='img'
						src={getFullCardByBrandName(row.paymentCardBrand)}
						height={25}
						width={45}
					/>
					{row.paymentCardLast4 ? (
						<Tooltip
							placement='right'
							title={`${row.paymentCardBankIdentificationNumber}****${row.paymentCardLast4}`}
						>
							<Typography>{`****${row.paymentCardLast4}`}</Typography>
						</Tooltip>
					) : (
						'-'
					)}

				</Stack>
			</TableCell>
			<TableCell align='left'>
				{row.billingDescriptor ? row.billingDescriptor : '-'}
			</TableCell>
			<TableCell align='center'>
				{formatDateForDisplay(row.receivedByMerchantOn)}
			</TableCell>
			<TableCell align='right'>{row.code}</TableCell>
			<TableCell align='center'>
				<Stack direction='row' spacing={1} justifyContent='center'>
					<Typography>{formatDateForDisplay(row.dueDate)}</Typography>
					<Tooltip title={t('representmentsTable.expiringSoon')}>
						<WarningAmberIcon
							color='warning'
							sx={{ visibility: !expiringSoon && 'hidden' }}
						/>
					</Tooltip>
				</Stack>
			</TableCell>
			<TableCell align='center'>

			</TableCell>
			<Restricted to='representments.representmentBuilder.view'>
				<TableCell align='center'>
					<Link to={`/representments/build/${row.chargebackId}`}>
						<Tooltip
							title={t(
								row.inProgress
									? 'representmentsTable.inProgress'
									: 'representmentsTable.representmentBuilder'
							)}
						>
							<BuildIcon
								sx={{ color: row.inProgress ? 'warning.main' : 'icons.default' }}
							/>
						</Tooltip>
					</Link>
				</TableCell>
			</Restricted>
			<Restricted to='representments.pdfGenerator.view'>
				<TableCell align='center'>
					<Link to={`/representments/view/${row.chargebackId}`}>
						<Tooltip title={t('representmentsTable.generate')}>
							<ArticleIcon sx={{ color: 'primary.alt' }} />
						</Tooltip>
					</Link>
				</TableCell>
			</Restricted>
		</TableRow>
	);
};

CurrentInventoryTableRow.propTypes = {
	isItemSelected: PropTypes.bool,
	row: PropTypes.object,
	handleRowClick: PropTypes.func,
	labelId: PropTypes.string,
	t: PropTypes.func
};

export default withTranslation('representments')(CurrentInventoryTableRow);
