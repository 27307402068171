import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { reporterActions } from '../../../containers/Reporter';
import { withTranslation } from 'react-i18next';
import Loader from '../../Loader';
import isDataEmpty from '../../../helpers/shared/isDataEmpty';
import findReportItemInList from '../../../helpers/reporter/findReportItemInList';
import FilterController from './FilterController';
import Restricted from '../../../permissionProvider/Restricted';
import RestrictedFallback from '../../shared/RestrictedFallback';

import Stack from '@mui/material/Stack';
import ReportContainer from './ReportContainer';

const DailyChargebackActivity = (props) => {
	const { executeReport, reportDataList, clearReportData, t } = props;

	const location = useLocation();

	const componentWillUnmount = React.useRef(false);

	const [activeReportViewId, setActiveReportViewId] = React.useState();

	const reportViewUuid = location.pathname.split('/')[2];
	const reportData = findReportItemInList(reportDataList, reportViewUuid);

	React.useEffect(() => {
		return () => {
			componentWillUnmount.current = true;
		};
	}, []);

	React.useEffect(() => {
		if (reportViewUuid) {
			setActiveReportViewId(reportViewUuid);
		}

		return () => {
			if (componentWillUnmount.current) {
				clearReportData({
					reportUniversalUniqueIdentifier: activeReportViewId
				});
			}
		};
	}, []);

	React.useEffect(() => {
		return () => {
			if (componentWillUnmount.current) {
				clearReportData({
					reportUniversalUniqueIdentifier: activeReportViewId
				});
			}
		};
	}, [activeReportViewId]);

	const onGetReportData = (lookupDate, processorId, merchantIds) => {
		if (isDataEmpty(reportData) || !reportData?.isLoading) {
			executeReport({
				queryParameters: {
					lookupDate,
					processorId,
					merchantIds
				},
				reportUniversalUniqueIdentifier: reportViewUuid
			});
		}
	};

	// HACK FOR NOW - FIGURE OUT HOW THIS ISN"T SWITCHING COMPONENTS ON BACK BUTTON
	if (reportViewUuid !== 'c76246f6-a943-4765-9f8a-f441b87290b5') {
		return <Loader />;
	}

	return (
		<Restricted
			to='reports.twelveMonthChargebackComplianceReport.view'
			Fallback={<RestrictedFallback overrideMessage={t('error.notAllowed')} />}
		>
			<Stack
				direction='column'
				spacing={2}
				mt={1}
				sx={{ height: 'calc(100% - 80px)', overflow: 'auto' }}
				mb={2}
				p={1}
			>
				<FilterController
					onGetReportData={onGetReportData}
					isLoading={reportData?.isLoading || false}
					hasBeenLoaded={reportData?.hasBeenLoaded || false}
				/>
				<ReportContainer
					data={reportData?.data || []}
					hasBeenLoaded={reportData?.hasBeenLoaded || false}
					isLoading={reportData?.isLoading || false}
				/>
			</Stack>
		</Restricted>
	);
};

DailyChargebackActivity.propTypes = {
	executeReport: PropTypes.func,
	reportDataList: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	clearReportData: PropTypes.func,
	t: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		reportDataList: state.reporterReducer.get('reportData')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		executeReport: (payload) => {
			dispatch(reporterActions.executeReport(payload));
		},
		clearReportData: (payload) => {
			dispatch(reporterActions.clearReport(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation('common')(DailyChargebackActivity));
