import dayjs from 'dayjs';
import { formatMonthlySummaryByMidCell } from '../../../../../helpers/shared/reportDataFormatter';

const actualTranslationPath = 'dailyChargebackReport.subheaders.actual';
const forecastTranslationPath = 'dailyChargebackReport.subheaders.forecast';
const deltaIntegerTranslationPath = 'dailyChargebackReport.subheaders.delta';
const deltaPercentTranslationPath = 'dailyChargebackReport.subheaders.percent';

// TODO - TRY GET RID OF DAYJS HERE AND MOVE INTO DATE WRAPPER
export const groupedColumns = [
	{
		key: 'daily',
		columnSpan: 4,
		translationPath: 'dailyChargebackActivity.table.daily',
		dateGenerator: (date) => {
			return dayjs(date).format('MMMM DD');
		}
	},
	{
		key: 'weekToDate',
		translationPath: 'dailyChargebackActivity.table.weekToDate',
		columnSpan: 4,
		dateGenerator: (date) => {
			return `${dayjs(date).endOf('week').format('MMMM DD')}`;
		}
	},
	{
		key: 'monthToDate',
		columnSpan: 4,
		translationPath: 'dailyChargebackActivity.table.monthToDate',
		dateGenerator: (date) => {
			return `${dayjs(date).startOf('month').format('MMMM YYYY')}`;
		}
	}
];

export const rowColumns = [
	{
		basePath: 'daily',
		valuePath: 'actual',
		valuePathRounded: 'actualRounded',
		translationPath: actualTranslationPath,
		formatter: formatMonthlySummaryByMidCell,
		isFirstColumnInGroup: true
	},
	{
		basePath: 'daily',
		valuePath: 'forecast',
		valuePathRounded: 'forecastRounded',
		translationPath: forecastTranslationPath,
		formatter: formatMonthlySummaryByMidCell
	},
	{
		basePath: 'daily',
		valuePath: 'deltaInteger',
		valuePathRounded: 'deltaInteger',
		translationPath: deltaIntegerTranslationPath,
		formatter: formatMonthlySummaryByMidCell
	},
	{
		basePath: 'daily',
		valuePath: 'deltaPercent',
		valuePathRounded: 'deltaPercent',
		translationPath: deltaPercentTranslationPath,
		formatter: formatMonthlySummaryByMidCell
	},
	{
		basePath: 'weekToDate',
		valuePath: 'actual',
		valuePathRounded: 'actualRounded',
		translationPath: actualTranslationPath,
		formatter: formatMonthlySummaryByMidCell,
		isFirstColumnInGroup: true
	},
	{
		basePath: 'weekToDate',
		valuePath: 'forecast',
		valuePathRounded: 'forecastRounded',
		translationPath: forecastTranslationPath,
		formatter: formatMonthlySummaryByMidCell
	},
	{
		basePath: 'weekToDate',
		valuePath: 'deltaInteger',
		valuePathRounded: 'deltaInteger',
		translationPath: deltaIntegerTranslationPath,
		formatter: formatMonthlySummaryByMidCell
	},
	{
		basePath: 'weekToDate',
		valuePath: 'deltaPercent',
		valuePathRounded: 'deltaPercent',
		translationPath: deltaPercentTranslationPath,
		formatter: formatMonthlySummaryByMidCell
	},
	{
		basePath: 'monthToDate',
		valuePath: 'actual',
		valuePathRounded: 'actualRounded',
		translationPath: actualTranslationPath,
		formatter: formatMonthlySummaryByMidCell,
		isFirstColumnInGroup: true
	},
	{
		basePath: 'monthToDate',
		valuePath: 'forecast',
		valuePathRounded: 'forecastRounded',
		translationPath: forecastTranslationPath,
		formatter: formatMonthlySummaryByMidCell
	},
	{
		basePath: 'monthToDate',
		valuePath: 'deltaInteger',
		valuePathRounded: 'deltaInteger',
		translationPath: deltaIntegerTranslationPath,
		formatter: formatMonthlySummaryByMidCell
	},
	{
		basePath: 'monthToDate',
		valuePath: 'deltaPercent',
		valuePathRounded: 'deltaPercent',
		translationPath: deltaPercentTranslationPath,
		formatter: formatMonthlySummaryByMidCell
	}
];
