import axios from '../../utilities/axios';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import dayjs from 'dayjs';
import tableauTimeout from '../../constants/tableauTimeout';
import { refreshToken } from '../../utilities/refreshToken';
import { primaryRestGateway } from '../../utilities/apiEndpointUtility';

import * as actionTypes from './actionTypes';
import * as actions from './actions';

export const generateTokenEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.GENERATE_TOKEN),
		mergeMap(async (action) => {
			await refreshToken();

			const response = await axios.get(
				`${primaryRestGateway()}/services/tableau/generateToken`
			);

			// TODO - WE SHOULD BE RETURNING THIS AS A DATE IF WE NEED IT AS A DATE
			return {
				...response.data,
				expiration: dayjs().add(
					tableauTimeout.timeoutInMilliseconds,
					'millisecond'
				)
			};
		}),
		switchMap((res) => [actions.generateTokenCompleted(res)]),
		catchError((error, source) =>
			merge(of(actions.generateTokenFailed(error.message)), source)
		)
	);
