import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Restricted from '../../permissionProvider/Restricted';
import RestrictedFallback from '../../components/shared/RestrictedFallback';

import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';

const PopTransactions = ({ t }) => {
	return (
		<Container maxWidth={false}>
			<Restricted
				to='pop.transactions.view'
				Fallback={
					<RestrictedFallback overrideMessage={t('common:error.notAllowed')} />
				}
			>
				<Stack spacing={2} p={2}>

				</Stack>
			</Restricted>
		</Container>
	);
};

PopTransactions.propTypes = {
	t: PropTypes.func
};

export default (withTranslation(['pop', 'common'])(PopTransactions));
