import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { withTranslation } from 'react-i18next';
import AvatarDisplay from '../AvatarDisplay';
import ValidationInput from '../../inputs/ValidationInput';
import registrationStages from '../constants/registrationStages';
import BoldLargeHeader from '../../headers/BoldLargeHeader';
import ContainedButton from '../../buttons/ContainedButton';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

const StyledUploadText = styled(Typography)(({ theme }) => ({
	textDecoration: 'underline',
	fontSize: '.8rem',
	color: theme.palette.primary.alt,
	cursor: 'pointer'
}));

export const SlyceInformation = (props) => {
	const {
		newUserConfiguration,
		onConfigurationChange,
		setCurrentStage,
		onSubmit,
		t
	} = props;

	return (
		<Box sx={{ maxWidth: 400 }}>
			<Stack direction='row' alignItems='center'>
				<Box sx={{ flex: 1, pt: 1 }}>
					<IconButton onClick={() => setCurrentStage(registrationStages.employeeInformation)}>
						<KeyboardArrowLeftIcon />
					</IconButton>
				</Box>
				<Box sx={{ flex: 2, whitespace: 'nowrap', px: 2 }}>
					<BoldLargeHeader py={2}>
						{t('registrationForm.newUserRegistration')}
					</BoldLargeHeader>
				</Box>
				<Box sx={{ flex: 1 }} />
			</Stack>
			<Stack direction='column' justifyContent='center' px={4}>
				<Stack direciont='column' alignItems='center' spacing={2}>
					<AvatarDisplay uploadedAvatar={newUserConfiguration.avatar} />
					<StyledUploadText
						onClick={() => {
							setCurrentStage(registrationStages.avatarUpload);
						}}
					>
						{t('registrationForm.uploadProfilePhoto')}
					</StyledUploadText>
				</Stack>
				<Stack direction='column' alignItems='center' spacing={3} px={4} mt={2}>
					<FormControl sx={{ width: '100%' }}>
						<label>{t('registrationForm.input.username')}</label>
						<ValidationInput
							value={newUserConfiguration.username}
							onChange={(value) => {
								onConfigurationChange(value, 'username');
							}}
							displayFormatter={
								newUserConfiguration.username
									? (value) => value.toLocaleLowerCase()
									: undefined
							}
							helperText={t('registrationForm.caseInsensitive')}
						/>
					</FormControl>
				</Stack>
				<Box my={1} p={2} sx={{ textAlign: 'center' }}>
					<Typography className={{ fontsize: '.8rem', fontWeight: 600, alignSelf: 'center' }}>
						{t('registrationForm.verificationText')}
					</Typography>
				</Box>
				<Stack direction='row' justifyContent='center' my={2}>
					<ContainedButton
						disabled={newUserConfiguration.username.length < 5}
						onClick={onSubmit}
					>
						{t('registrationForm.register')}
					</ContainedButton>
				</Stack>
			</Stack>
		</Box>
	);
};

SlyceInformation.propTypes = {
	newUserConfiguration: PropTypes.object,
	onConfigurationChange: PropTypes.func,
	setCurrentStage: PropTypes.func,
	onSubmit: PropTypes.func,
	t: PropTypes.func
};

export default withTranslation('registration')(SlyceInformation);
