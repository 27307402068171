import isDataEmpty from '../isDataEmpty';

export default function (list, key) {
	let matchedItem = {};

	if (!isDataEmpty(list)) {
		matchedItem = list.get(key) || {};
	}

	return matchedItem;
}
