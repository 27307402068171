import React from 'react';
import { styled } from '@mui/material/styles';

import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const StyledStack = styled(Stack)({
	display: 'flex',
	justifyContent: 'center',
	width: '100%',
	margin: 10
});

const HeaderGroup = () => {
	return (
		<Stack
			direction='row'
			justifyContent='space-between'
			sx={{ width: '100%' }}
			px={2}
		>
			<Skeleton width={125} height={50} />
			<Skeleton width={120} height={50} />
			<Skeleton width={200} height={50} />
			<Skeleton width={200} height={50} />
			<Skeleton width={200} height={50} />
			<Skeleton width={200} height={50} />
		</Stack>
	);
};

const LoadingTable = () => {
	return (
		<StyledStack direction='column' alignItems='center' m={1}>
			<HeaderGroup />
			<Skeleton width={'98%'} height={50} />
			<Skeleton width={'98%'} height={50} />
			<Skeleton width={'98%'} height={50} />
			<Skeleton width={'98%'} height={50} />
		</StyledStack>
	);
};

export default LoadingTable;
