import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { organizationActions } from '../../containers/Organization';
import GenericTextInput from '../inputs/GenericTextInput';

import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

export const OrganizationInformationSection = (props) => {
	const { t, organization, getOrganization } = props;

	React.useEffect(() => {
		getOrganization();
	}, []);

	return (
		<Stack p={1} spacing={2}>
			<Stack direction='row' alignItems='center'>
				<Typography variant='h5'>
					{t('organizationInformation.header')}
				</Typography>
			</Stack>
			<Stack direction='row' alignItems='center' spacing={2}>
				<Avatar
					alt={`${organization.friendlyName}`}
					src={organization.avatar}
					variant='rounded'
					sx={{ width: 100, height: 100 }}
				/>
				<Stack>
					<Grid container spacing={2}>
						<Grid item>
							<GenericTextInput
								label={t('organizationInformation.organization')}
								value={organization.organization}
								isDisabled={true}
							/>
						</Grid>
						<Grid item>
							<GenericTextInput
								label={t('organizationInformation.techContact')}
								value={organization.techContact}
								isDisabled={true}
							/>
						</Grid>
						<Grid item>
							<GenericTextInput
								label={t('organizationInformation.techContactEmail')}
								value={organization.techContactEmail}
								isDisabled={true}
							/>
						</Grid>
						<Grid item>
							<GenericTextInput
								label={t('organizationInformation.techContactPhone')}
								value={organization.techContactPhone}
								isDisabled={true}
							/>
						</Grid>
					</Grid>
				</Stack>
			</Stack>
		</Stack>
	);
};

OrganizationInformationSection.propTypes = {
	organization: PropTypes.object,
	getOrganization: PropTypes.func,
	t: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		user: state.userReducer.get('user'),
		organization: state.organizationReducer.get('organization')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getOrganization: (payload) => {
			dispatch(organizationActions.getOrganization(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation('account')(OrganizationInformationSection));
