import axios from '../../utilities/axios';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { refreshToken } from '../../utilities/refreshToken';
import { primaryRestGateway } from '../../utilities/apiEndpointUtility';

import * as actions from './actions';
import * as actionTypes from './actionTypes';

export const getGroupsEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.GET_GROUPS),
		mergeMap(async (action) => {
			await refreshToken();

			const groups = await axios.get(`${primaryRestGateway()}/api/groups`);

			return groups;
		}),
		switchMap((res) => [actions.getGroupsCompleted(res.data)]),
		catchError((error, source) =>
			merge(of(actions.getGroupsFailed(error.message)), source)
		)
	);

export const getGroupEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.GET_GROUP),
		mergeMap(async (action) => {
			await refreshToken();

			const { groupId } = action.payload;

			const group = await axios.get(
				`${primaryRestGateway()}/api/groups/${groupId}`
			);

			return group;
		}),
		switchMap((res) => [actions.getGroupCompleted(res.data)]),
		catchError((error, source) =>
			merge(of(actions.getGroupFailed(error.message)), source)
		)
	);

export const addGroupEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.ADD_GROUP),
		mergeMap(async (action) => {
			await refreshToken();

			const { groupDefinition } = action.payload;

			const res = await axios.post(
				`${primaryRestGateway()}/api/groups`,
				groupDefinition
			);

			return res;
		}),
		switchMap((res) => [
			actions.addGroupCompleted(res.data),
			actions.getGroups()
		]),
		catchError((error, source) =>
			merge(of(actions.addGroupFailed(error.message)), source)
		)
	);

export const updateGroupEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.UPDATE_GROUP),
		mergeMap(async (action) => {
			await refreshToken();

			const { groupDefinition, groupId } = action.payload;

			const res = await axios.put(
				`${primaryRestGateway()}/api/groups/${groupId}`,
				groupDefinition
			);

			return res;
		}),
		switchMap((res) => [
			actions.updateGroupCompleted(res.data),
			actions.getGroups()
		]),
		catchError((error, source) =>
			merge(of(actions.updateGroupFailed(error.message)), source)
		)
	);

export const deleteGroupEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.DELETE_GROUP),
		mergeMap(async (action) => {
			await refreshToken();

			const { groupId } = action.payload;

			const res = await axios.delete(
				`${primaryRestGateway()}/api/groups/${groupId}`
			);

			return res;
		}),
		switchMap((res) => [
			actions.deleteGroupCompleted(res.data),
			actions.getGroups()
		]),
		catchError((error, source) =>
			merge(of(actions.deleteGroupFailed(error.message)), source)
		)
	);
