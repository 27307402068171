const usersTableColumns = [
	{
		id: 'user',
		numeric: false,
		sortable: false,
		align: 'left',
		width: '15%',
		labelTranslationPath: 'usersTable.columns.user'
	},
	{
		id: 'email',
		numeric: false,
		sortable: false,
		align: 'left',
		width: '15%',
		labelTranslationPath: 'usersTable.columns.email'
	},
	{
		id: 'phone',
		numeric: false,
		sortable: false,
		align: 'left',
		width: '15%',
		labelTranslationPath: 'usersTable.columns.phone'
	},
	{
		id: 'action',
		numeric: false,
		sortable: false,
		width: '15%'
	}
];

const pendingUsersTableColumns = [
	{
		id: 'email',
		numeric: false,
		sortable: false,
		align: 'left',
		width: '15%',
		labelTranslationPath: 'usersTable.pendingColumns.email'
	},
	{
		id: 'createdOn',
		numeric: false,
		sortable: false,
		align: 'left',
		width: '15%',
		labelTranslationPath: 'usersTable.pendingColumns.invitedOn'
	},
	{
		id: 'invitedBy',
		numeric: false,
		sortable: false,
		align: 'left',
		width: '15%',
		labelTranslationPath: 'usersTable.pendingColumns.invitedBy'
	},
	{
		id: 'action',
		numeric: false,
		sortable: false,
		width: '15%'
	}
];

export { usersTableColumns, pendingUsersTableColumns };
