import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import Toolbar from '@mui/material/Toolbar';
import TextField from '@mui/material/TextField';

import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

const EnhancedTableToolbar = (props) => {
	const { t, searchTerm, setSearchTerm, onClearSearchTerm } = props;

	return (
		<Toolbar
			sx={{
				pl: { sm: 2 },
				pr: { xs: 1, sm: 1 },
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'flex-end'
			}}
		>
			<TextField
				label={t('search')}
				value={searchTerm}
				onChange={(e) => {
					setSearchTerm(e.target.value);
				}}
				variant='standard'
				InputProps={{
					startAdornment: <SearchIcon />,
					endAdornment: (
						<ClearIcon onClick={onClearSearchTerm} sx={{ cursor: 'pointer' }} />
					)
				}}
			/>
		</Toolbar>
	);
};

EnhancedTableToolbar.propTypes = {
	searchTerm: PropTypes.string,
	setSearchTerm: PropTypes.func,
	onClearSearchTerm: PropTypes.func,
	t: PropTypes.func
};

export default withTranslation('common')(EnhancedTableToolbar);
