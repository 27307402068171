import { fromJS } from 'immutable';
import * as actionTypes from './actionTypes';

export const initialState = fromJS({
	availablePermissions: {
		isLoading: false,
		hasBeenLoaded: false,
		error: undefined,
		data: {}
	}
});

export const permissionsReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_AVAILABLE_PERMISSIONS:
			return state.setIn(['availablePermissions', 'isLoading'], true);

		case actionTypes.GET_AVAILABLE_PERMISSIONS_COMPLETED:
			return state
				.setIn(['availablePermissions', 'isLoading'], false)
				.setIn(['availablePermissions', 'hasBeenLoaded'], true)
				.setIn(['availablePermissions', 'data'], action.payload);

		case actionTypes.GET_AVAILABLE_PERMISSIONS_FAILED:
			return state
				.setIn(['availablePermissions', 'isLoading'], false)
				.setIn(['availablePermissions', 'error'], action.payload);

		default:
			return state;
	}
};
