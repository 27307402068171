import React from 'react';

export default () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			id='Layer_6'
			data-name='Layer 6'
			viewBox='0 0 665.22 88.59'
		>
			<defs>
				<style>
					{'.cls-1{fill:none;}.cls-2{fill:#C1C6C8;}.cls-3{fill:#C1C6C8;}'}
				</style>
			</defs>
			<polygon
				className='cls-1'
				points='190.15 39.17 194.23 32.8 185.74 32.06 190.15 39.17'
			/>
			<path
				className='cls-2'
				d='M33.51,17.64a21.78,21.78,0,0,1,7.92-1.25,19.2,19.2,0,0,1,8.93,2,16.36,16.36,0,0,1,4.15,3l2.15,2,2-1.72,7.07-6.44A22.66,22.66,0,0,0,54.65,7.58a45.54,45.54,0,0,0-13.22-1.9A33,33,0,0,0,28.39,8a28,28,0,0,0-8.87,5.77,23,23,0,0,0-3.59,4.66L30,19.53A16.15,16.15,0,0,1,33.51,17.64Z'
				transform='translate(-10.35 -5.68)'
			/>
			<path
				className='cls-3'
				d='M12.86,29.25q0,8.22,3.09,13A24.48,24.48,0,0,0,23.63,50a40.87,40.87,0,0,0,10,4.46q5.41,1.62,10,3.34a26.82,26.82,0,0,1,7.67,4.28q3.11,2.56,3.1,7.45a11.74,11.74,0,0,1-1.55,6.07,14.64,14.64,0,0,1-4,4.41,17.53,17.53,0,0,1-5.41,2.67,20.14,20.14,0,0,1-5.84.9,23.65,23.65,0,0,1-9.94-2.21,18.7,18.7,0,0,1-7.92-6.84l-9.41,8.09A25.32,25.32,0,0,0,21.9,91.41a41.69,41.69,0,0,0,15.36,2.85,35.91,35.91,0,0,0,11.43-1.78A27.71,27.71,0,0,0,58,87.36a24.27,24.27,0,0,0,6.25-8.22,25.12,25.12,0,0,0,2.32-11q0-7.62-3.09-12a23.15,23.15,0,0,0-7.68-6.9,42.63,42.63,0,0,0-10-4,94.16,94.16,0,0,1-10-3.22,24,24,0,0,1-7.68-4.64Q25,34.61,25,29.25a13.13,13.13,0,0,1,.77-4.17,10.16,10.16,0,0,1,1-2.1l-12.5-1.09A21.67,21.67,0,0,0,12.86,29.25Z'
				transform='translate(-10.35 -5.68)'
			/>
			<polygon
				className='cls-2'
				points='98.83 2.14 87.4 2.14 87.4 19.14 98.83 20.04 98.83 2.14'
			/>
			<polygon
				className='cls-3'
				points='87.4 86.44 137.53 86.44 137.53 75.73 98.83 75.73 98.83 24.49 87.4 23.49 87.4 86.44'
			/>
			<polygon
				className='cls-2'
				points='229.55 2.14 215.62 2.14 199.24 27.74 212 28.74 229.55 2.14'
			/>
			<polygon
				className='cls-3'
				points='190.15 39.17 185.74 32.06 171.46 30.82 184.44 50.48 184.44 86.44 195.87 86.44 195.87 50.48 206.81 33.9 194.23 32.8 190.15 39.17'
			/>
			<polygon
				className='cls-2'
				points='168.94 2.14 154.3 2.14 169.67 25.43 184.1 26.56 168.94 2.14'
			/>
			<path
				className='cls-3'
				d='M259.21,50.68a44.5,44.5,0,0,0,11.61,29.77,43.53,43.53,0,0,0,13.69,10,41.6,41.6,0,0,0,18,3.81,40.49,40.49,0,0,0,12.8-1.84,44.61,44.61,0,0,0,9.46-4.29,32.86,32.86,0,0,0,6.31-4.88,39.94,39.94,0,0,0,3.34-3.75l-9.65-7.26a27.29,27.29,0,0,1-9.28,8.33,26.32,26.32,0,0,1-12.74,3,32,32,0,0,1-13.22-2.62,28.94,28.94,0,0,1-9.88-7.15,31.3,31.3,0,0,1-6.19-10.47,37,37,0,0,1-2.15-12.63,41,41,0,0,1,.5-6.33l-12.1-1.06A54,54,0,0,0,259.21,50.68Z'
				transform='translate(-10.35 -5.68)'
			/>
			<path
				className='cls-2'
				d='M275.39,37.29a33.28,33.28,0,0,1,6.37-10.9,29.87,29.87,0,0,1,9.88-7.32,30.29,30.29,0,0,1,12.92-2.68,27,27,0,0,1,11.31,2.5A23.58,23.58,0,0,1,324.92,26l9.53-7.27a30.61,30.61,0,0,0-13.16-9.88,43.81,43.81,0,0,0-16.37-3.21A46.49,46.49,0,0,0,287.06,9a40.25,40.25,0,0,0-13.88,9.35,42.64,42.64,0,0,0-9,14.28,45.44,45.44,0,0,0-1.57,4.92l12.37,1C275.14,38.12,275.24,37.69,275.39,37.29Z'
				transform='translate(-10.35 -5.68)'
			/>
			<polygon
				className='cls-2'
				points='407.8 37.98 367.79 37.98 367.79 12.86 410.77 12.86 410.77 2.14 356.36 2.14 356.36 40.02 407.8 44.03 407.8 37.98'
			/>
			<polygon
				className='cls-3'
				points='354.59 86.44 411.14 86.44 411.14 75.73 366.02 75.73 366.02 48.7 376.48 48.7 354.59 46.79 354.59 86.44'
			/>
			<path
				className='cls-2'
				d='M504.12,55.33a18.78,18.78,0,0,0-5.24-4.76,20.09,20.09,0,0,0-7-2.62v-.24a19.8,19.8,0,0,0,10.42-7.15,19.12,19.12,0,0,0,4-11.9,23.42,23.42,0,0,0-2.08-10.18,20.41,20.41,0,0,0-5.66-7.21,23.72,23.72,0,0,0-8.27-4.22,34.74,34.74,0,0,0-9.83-1.37,30.58,30.58,0,0,0-15.3,3.87,23.48,23.48,0,0,0-10.18,12l11.67,3.93a16.63,16.63,0,0,1,5.66-7.08,14.05,14.05,0,0,1,8.39-2.68,15.06,15.06,0,0,1,10.12,3.45q4.05,3.45,4.05,10.24a15.74,15.74,0,0,1-.95,5.6,8.94,8.94,0,0,1-3.34,4.16A17.79,17.79,0,0,1,484,41.69a52.21,52.21,0,0,1-10.71.9v10a71.54,71.54,0,0,1,8.21.48,24.34,24.34,0,0,1,7.62,2.08l.23.12,15.57,1.22C504.66,56.09,504.4,55.7,504.12,55.33Z'
				transform='translate(-10.35 -5.68)'
			/>
			<path
				className='cls-3'
				d='M495.2,68.31a14.63,14.63,0,0,1-1.31,6.25,14.25,14.25,0,0,1-3.55,4.76,17.69,17.69,0,0,1-5.16,3.1A17.14,17.14,0,0,1,479,83.55q-6.65,0-10-3t-5.4-9l-12.15,3.69q2.87,9.53,10.18,13.93a32.27,32.27,0,0,0,17,4.41A36.35,36.35,0,0,0,489.19,92a27.27,27.27,0,0,0,8.93-4.58,22.54,22.54,0,0,0,6.19-7.62,23.28,23.28,0,0,0,2.32-10.66,26.88,26.88,0,0,0-.36-4.35l-11.58-1A19,19,0,0,1,495.2,68.31Z'
				transform='translate(-10.35 -5.68)'
			/>
			<path
				className='cls-3'
				d='M578.13,73.07a15.83,15.83,0,0,1-3.57,5.54A16.61,16.61,0,0,1,569,82.24a20.14,20.14,0,0,1-14.29,0,16.66,16.66,0,0,1-5.53-3.63,15.83,15.83,0,0,1-3.57-5.54,18.63,18.63,0,0,1-1.15-4.95l-11.5-1a26,26,0,0,0,2.29,10.47,26.38,26.38,0,0,0,6.25,8.58,27.16,27.16,0,0,0,9.17,5.47,32.47,32.47,0,0,0,11.19,1.91,34.44,34.44,0,0,0,11.19-1.79,25.91,25.91,0,0,0,17.09-19.65l-11.41-1A16,16,0,0,1,578.13,73.07Z'
				transform='translate(-10.35 -5.68)'
			/>
			<path
				className='cls-2'
				d='M547.4,59A15.83,15.83,0,0,1,551,53.48a16.46,16.46,0,0,1,5.54-3.63,20.14,20.14,0,0,1,14.29,0,16.51,16.51,0,0,1,5.53,3.63A15.83,15.83,0,0,1,579.9,59a17.44,17.44,0,0,1,1,3.4l11.65.91a26.59,26.59,0,0,0-2.3-10.09,24.9,24.9,0,0,0-6.13-8.33,24.41,24.41,0,0,0-8.39-4.82,29,29,0,0,0-9.17-1.55,21.24,21.24,0,0,0-9.29,1.9h-.47L577.94,7.82H564.48l-21.31,33.7q-1.9,3.09-3.45,5.89A46.88,46.88,0,0,0,537,53.12a34.63,34.63,0,0,0-1.64,5.75l11.74.92C547.23,59.53,547.3,59.27,547.4,59Z'
				transform='translate(-10.35 -5.68)'
			/>
			<path
				className='cls-3'
				d='M654.22,79.86a14,14,0,0,1-18.94,0,22.2,22.2,0,0,1-3.07-4.09l-12.54-1.09c.49,1.27,1,2.53,1.62,3.75a30,30,0,0,0,8.87,10.83q5.65,4.29,14.59,4.29t14.59-4.29A30,30,0,0,0,668,78.89l-12-1A23.47,23.47,0,0,1,654.22,79.86Z'
				transform='translate(-10.35 -5.68)'
			/>
			<path
				className='cls-2'
				d='M629.44,59.85q-.54-5.72-.54-9.88,0-2.86.18-6.49a59.54,59.54,0,0,1,.83-7.38,48.25,48.25,0,0,1,1.85-7.26,24.35,24.35,0,0,1,3.15-6.31,15.14,15.14,0,0,1,4.83-4.47,14.62,14.62,0,0,1,13.57,0,15.21,15.21,0,0,1,4.82,4.47,24.69,24.69,0,0,1,3.16,6.31,48.23,48.23,0,0,1,1.84,7.26,59.58,59.58,0,0,1,.84,7.38c.12,2.42.17,4.58.17,6.49,0,2.78-.17,6.07-.53,9.88a49.28,49.28,0,0,1-1.68,8.9l11.29.89a49.36,49.36,0,0,0,1.16-5.38A92.09,92.09,0,0,0,675.58,50c0-3.1-.16-6.31-.48-9.65a62.49,62.49,0,0,0-1.73-9.82A52,52,0,0,0,670,21.27a27.49,27.49,0,0,0-5.36-7.68,25.13,25.13,0,0,0-7.74-5.24,28.68,28.68,0,0,0-20.84,0,25.37,25.37,0,0,0-7.74,5.24A27.46,27.46,0,0,0,623,21.27a52.84,52.84,0,0,0-3.34,9.23A62.47,62.47,0,0,0,618,40.32c-.32,3.34-.48,6.55-.48,9.65a93.27,93.27,0,0,0,1.19,14.29c.06.38.15.75.22,1.13l11.61.91A47.4,47.4,0,0,1,629.44,59.85Z'
				transform='translate(-10.35 -5.68)'
			/>
		</svg>
	);
};
