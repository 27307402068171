import React from 'react';
import PropTypes from 'prop-types';
import usePermission from './usePermission';

const Restricted = ({ to, Fallback, LoadingComponent, children }) => {
	const [loading, allowed] = usePermission(to);

	if (loading) {
		return <>{LoadingComponent}</>;
	}

	if (allowed) {
		return <>{children}</>;
	}

	return <>{Fallback}</>;
};

Restricted.propTypes = {
	to: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
	Fallback: PropTypes.element,
	LoadingComponent: PropTypes.element,
	children: PropTypes.oneOfType([PropTypes.element, PropTypes.array, PropTypes.node])
};

export default Restricted;
