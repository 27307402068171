import { fromJS } from 'immutable';
import * as actionTypes from './actionTypes';

export const initialState = fromJS({
	theme: 'light',

	// BELOW IS REQUIRED FOR SCREENSHOTTER
	email: {
		error: undefined,
		hasBeenSent: false,
		isSending: false
	},
	text: {
		error: undefined,
		hasBeenLoaded: false,
		hasBeenSent: false,
		isSending: false
	}
});

export const systemReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.TOGGLE_THEME:
			return state.set(
				'theme',
				state.get('theme') === 'dark' ? 'light' : 'dark'
			);
			// BELOW ARE REQUIRED FOR SCREENSHOTTER

		case actionTypes.SEND_EMAIL:
			return state.setIn('email.isSending'.split('.'), true);

		case actionTypes.SEND_EMAIL_COMPLETED:
			return state
				.setIn('email.hasBeenSent'.split('.'), true)
				.setIn('email.isSending'.split('.'), false)
				.setIn('email.error'.split('.'), '');

		case actionTypes.SEND_EMAIL_FAILED:
			return state
				.setIn('email.hasBeenSent'.split('.'), false)
				.setIn('email.isSending'.split('.'), false)
				.setIn('email.error'.split('.'), action.payload);

		case actionTypes.SEND_TEXT:
			return state.setIn('text.isSending'.split('.'), true);

		case actionTypes.SEND_TEXT_COMPLETED:
			return state
				.setIn('text.hasBeenSent'.split('.'), true)
				.setIn('text.isSending'.split('.'), false);

		case actionTypes.SEND_TEXT_FAILED:
			return state
				.setIn('text.hasBeenSent'.split('.'), false)
				.setIn('text.isSending'.split('.'), false)
				.setIn('text.error'.split('.'), action.payload);

		case actionTypes.RESET_EMAIL_VALUES:
			return state
				.setIn('email.hasBeenSent'.split('.'), false)
				.setIn('email.error'.split('.'), '');

		case actionTypes.RESET_TEXT_VALUES:
			return state
				.setIn('text.hasBeenSent'.split('.'), false)
				.setIn('text.error'.split('.'), '');

		default:
			return state;
	}
};
