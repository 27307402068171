import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import isDataEmpty from '../../../../helpers/shared/isDataEmpty';
import validateMerchantDataSource from '../../../../helpers/reporter/validateMerchantDatasource';
import querier from '../../../../utilities/querier';
import { dataSourceActions } from '../../../../containers/DataSource';
import getDynamicDataFromList from '../../../../helpers/shared/dynamicReducers/getDynamicDataFromList';

import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import ListItem from '@mui/material/ListItem';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const StyledListItem = styled(ListItem)(({ theme }) => ({
	marginLeft: '3px',
	marginTop: '4px',
	marginBottom: '4px',
	cursor: 'pointer',
	'&:hover': {
		backgroundColor: theme.palette.reports.filters.merchantSelectHighlight,
		color: theme.palette.common.white
	}
}));

const MerchantSelect = (props) => {
	const {
		onChange,
		translationPath,
		value,
		dataSource,
		t,
		selectedProcessor,
		dataSources,
		getDataSource,
		disabled,
		widthOverride,
		urlMerchant,
		setUrlMerchant
	} = props;

	const [inputValue, setInputValue] = React.useState('');
	const [open, setOpen] = React.useState(false);
	const [options, setOptions] = React.useState([]);
	const loading = options.length === 0 && open;

	const matchedDataSource = getDynamicDataFromList(dataSources, dataSource);

	React.useEffect(() => {
		const queryParameters = `?processorId=${selectedProcessor}`;

		if (selectedProcessor) {
			getDataSource({
				dataSourceKey: dataSource,
				queryParameters
			});
			setOptions([]);

			if (value?.paymentEnablerAcquiringBankId !== selectedProcessor) {
				onChange(null);
			}
		}
	}, [selectedProcessor]);

	React.useEffect(() => {
		if (open && matchedDataSource?.data) {
			setOptions(matchedDataSource.data);
		}
	}, [open, matchedDataSource]);

	React.useEffect(() => {
		if (!open) {
			setOptions([]);
		}
	}, [open]);

	React.useEffect(() => {
		if (urlMerchant.length > 0 && !isDataEmpty(matchedDataSource?.data)) {
			const firstMerchant = urlMerchant[0];

			const match = querier(
				matchedDataSource,
				`data.$[merchantId = "${firstMerchant}"]`
			);

			onChange(match || null);
			setUrlMerchant([]);
		}
	}, [urlMerchant, matchedDataSource]);

	const handleOptionSelect = (newValue) => {
		onChange(newValue);
	};

	if (
		urlMerchant.length > 0 ||
		isDataEmpty(matchedDataSource?.data) ||
		!validateMerchantDataSource(matchedDataSource.data, selectedProcessor)
	) {
		return (
			<TextField
				label={t(translationPath)}
				disabled={true}
				variant='outlined'
				sx={{ width: widthOverride || null }}
				InputProps={{
					endAdornment: (
						<React.Fragment>
							<CircularProgress color='inherit' size={20} />
						</React.Fragment>
					)
				}}
			/>
		);
	}

	return (
		<FormControl>
			<Autocomplete
				filterSelectedOptions
				options={options}
				disabled={disabled}
				open={open}
				onOpen={() => {
					setOpen(true);
				}}
				onClose={() => {
					setOpen(false);
				}}
				loading={loading}
				isOptionEqualToValue={(option, item) => {
					return isDataEmpty(item)
						? false
						: option.merchantId === item.merchantId;
				}}
				getOptionLabel={(option) => {
					return isDataEmpty(option)
						? ''
						: `${option.label} - ${option.merchantId}`;
				}}
				value={value}
				onChange={(event, newValue) => {
					handleOptionSelect(newValue);
				}}
				inputValue={inputValue}
				onInputChange={(event, newInputValue) => {
					setInputValue(newInputValue);
				}}
				renderOption={(props, option) => {
					return (
						<StyledListItem
							{...props}
							key={option.merchantId}
						>
							<Stack direction='column'>
								<Typography>{option.label}</Typography>
								<Typography variant='caption'>{option.merchantId}</Typography>
							</Stack>
						</StyledListItem>
					);
				}}
				sx={{ ml: 1, flex: 1 }}
				renderInput={(params) => (
					<TextField
						{...params}
						label={t(translationPath)}
						variant='outlined'
						sx={{ width: widthOverride || null }}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<React.Fragment>
									{loading ? (
										<CircularProgress color='inherit' size={20} />
									) : null}
									{params.InputProps.endAdornment}
								</React.Fragment>
							)
						}}
					/>
				)}
			/>
		</FormControl>
	);
};

MerchantSelect.propTypes = {
	onChange: PropTypes.func,
	translationPath: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	dataSource: PropTypes.string,
	t: PropTypes.func,
	selectedProcessor: PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.string]),
	dataSources: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	getDataSource: PropTypes.func,
	disabled: PropTypes.bool,
	widthOverride: PropTypes.number,
	urlMerchant: PropTypes.array,
	setUrlMerchant: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		dataSources: state.dataSourceReducer.get('dataSources')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getDataSource: (payload) => {
			dispatch(dataSourceActions.getDataSource(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation('reports')(MerchantSelect));
