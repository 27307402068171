import React from 'react';
import PropTypes from 'prop-types';
import SortableTableCell from './SortableTableCell';
import StaticTableCell from './StaticTableCell';

import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Restricted from '../../../permissionProvider/Restricted';

export const EnhancedTableHead = (props) => {
	const { order, orderBy, handleSortRequest, columnDefinitions, translationContext } = props;

	const renderHeaderCell = React.useCallback((headCell, order, orderBy) => {
		if (headCell.sortable) {
			return (
				<SortableTableCell
					translationContext={translationContext}
					headCell={headCell}
					order={order}
					orderBy={orderBy}
					handleSortRequest={handleSortRequest}
				/>
			);
		} else {
			return (
				<StaticTableCell
					key={headCell.id}
					translationContext={translationContext}
					headCell={headCell}
				/>
			);
		}
	}, [order, orderBy]);

	return (
		<TableHead>
			<TableRow>
				{columnDefinitions.map((headCell) => (
					headCell.restrictedTo
						? (
							<Restricted key={headCell.id} to={headCell.restrictedTo}>
								{ renderHeaderCell(headCell, order, orderBy)}
							</Restricted>
						) : (
							<React.Fragment key={headCell.id}>
								{ renderHeaderCell(headCell, order, orderBy) }
							</React.Fragment>
						)
				))}
			</TableRow>
		</TableHead>
	);
};

EnhancedTableHead.propTypes = {
	t: PropTypes.func,
	order: PropTypes.string,
	orderBy: PropTypes.string,
	isLoading: PropTypes.bool,
	handleSortRequest: PropTypes.func,
	columnDefinitions: PropTypes.array.isRequired,
	translationContext: PropTypes.string
};

export default EnhancedTableHead;
