import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import deepCopy from '../../../utilities/deepCopy';
import { groupBuildTypes } from '../constants/groupBuildTypes';
import { groupsActions } from '../../../containers/Groups';
import { mapGroupItemsForUpdate } from '../shared/mapGroupItems';
import PageError from '../../errors/PageError';
import defaultGroupConfiguration from '../constants/defaultGroupConfiguration';
import GroupWizard from '../GroupWizard';
import { getCurrentTimestamp } from '../../../utilities/dateWrapper';

import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

export const EditGroup = (props) => {
	const { group, groupIsLoading, groupError, getGroup, t } = props;

	const [groupConfiguration, setGroupConfiguration] = React.useState();

	const [temporaryGroupValues, setTemporaryGroupValues] = React.useState([]);

	const location = useLocation();
	const navigate = useNavigate();

	React.useEffect(() => {
		const groupId = location.pathname.split('/')[4];

		if (groupId) {
			getGroup({ groupId });
		}
	}, [location]);

	React.useEffect(() => {
		const groupId = location.pathname.split('/')[4];

		if (group?.id === parseInt(groupId)) {
			const initialGroupConfiguration = deepCopy(defaultGroupConfiguration);

			initialGroupConfiguration.buildType = groupBuildTypes[group.type];
			initialGroupConfiguration.groupLabel = group.groupLabel;
			initialGroupConfiguration.abbreviation = group.abbreviation;

			setTemporaryGroupValues(group.groupItems);
			setGroupConfiguration(initialGroupConfiguration);
		}
	}, [group]);

	const onCancel = () => {
		navigate('/groups');
	};

	const onSubmit = () => {
		const { user, updateGroup } = props;

		const groupId = location.pathname.split('/')[4];

		const mappedItems = mapGroupItemsForUpdate(groupConfiguration.groupItems);

		const groupDefinition = {
			groupLabel: groupConfiguration.groupLabel,
			abbreviation: groupConfiguration.abbreviation,
			type: groupConfiguration.buildType?.id,
			userId: user.id,
			updatedOn: getCurrentTimestamp(),
			groupItems: mappedItems
		};

		updateGroup({
			groupId,
			groupDefinition
		});
		navigate('/groups');
	};

	if (groupError) {
		return <PageError errorText={t('groups:groupWizard.editGroupError')} />;
	}

	if (groupIsLoading || !groupConfiguration) {
		return (
			<Stack direction='row' justifyContent='center' mt={2} pb={2}>
				<CircularProgress />
			</Stack>
		);
	}

	return (
		<GroupWizard
			groupConfiguration={groupConfiguration}
			setGroupConfiguration={setGroupConfiguration}
			onSubmit={onSubmit}
			onCancel={onCancel}
			temporaryGroupValues={temporaryGroupValues}
			setTemporaryGroupValues={setTemporaryGroupValues}
			disableTypeSelect={true}
		/>
	);
};

EditGroup.propTypes = {
	user: PropTypes.object,
	group: PropTypes.object,
	groupIsLoading: PropTypes.bool,
	groupHasBeenLoaded: PropTypes.bool,
	groupError: PropTypes.string,
	getGroup: PropTypes.func,
	t: PropTypes.func,
	updateGroup: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		user: state.userReducer.get('user'),
		group: state.groupsReducer.getIn(['group', 'data']),
		groupIsLoading: state.groupsReducer.getIn(['group', 'isLoading']),
		groupHasBeenLoaded: state.groupsReducer.getIn(['group', 'hasBeenLoaded']),
		groupError: state.groupsReducer.getIn(['group', 'error'])
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getGroup: (payload) => {
			dispatch(groupsActions.getGroup(payload));
		},
		updateGroup: (payload) => {
			dispatch(groupsActions.updateGroup(payload));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditGroup));
