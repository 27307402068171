import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { representmentActions } from '../../containers/Representment';
import isDataEmpty from '../../helpers/shared/isDataEmpty';
import DisplayControls from './DisplayControls';
import DetailDisplay from './DetailDisplay';
import PageError from '../errors/PageError';
import { displaySections } from './constants/displaySections';
import ReversalReasonBuilder from './ReversalReasonBuilder';
import ActionRow from './ActionRow';
import { getCurrentTimestamp } from '../../utilities/dateWrapper';

import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

export const RepresentmentBuilder = (props) => {
	const {
		representmentData,
		isLoading,
		hasBeenLoaded,
		error,
		getRepresentment,
		t,
		updateRepresentment
	} = props;
	const location = useLocation();
	const navigate = useNavigate();

	const [displayedSections, setDisplayedSections] = React.useState(
		displaySections
	);

	React.useEffect(() => {
		const chargebackId = location.pathname.split('/')[3];

		if (chargebackId) {
			getRepresentment({
				chargebackId
			});
		}
	}, [location]);

	const onMarkAsSentToProcessor = () => {
		const chargebackId = location.pathname.split('/')[3];

		const representmentUpdate = {
			isManual: representmentData.chargebackReversal.isManual ? 1 : 0,
			reversalReason: representmentData.chargebackReversal.reason || [],
			sentToProcessorOn: getCurrentTimestamp()
		};

		updateRepresentment({
			chargebackId,
			representmentUpdate
		});
	};

	const onUpdateClicked = (reversalReason) => {
		const chargebackId = location.pathname.split('/')[3];

		const representmentUpdate = {
			reversalReason,
			sentToProcessorOn: null
		};

		updateRepresentment({
			chargebackId,
			representmentUpdate
		});
	};

	const onBuildDocumentClicked = (reversalReason) => {
		const chargebackId = location.pathname.split('/')[3];

		navigate(`/representments/view/${chargebackId}`);
	};

	if (error) {
		return <PageError />;
	}

	if (!hasBeenLoaded || isLoading) {
		return (
			<Stack direction='row' justifyContent='center' mt={2}>
				<CircularProgress />
			</Stack>
		);
	}

	if (isDataEmpty(representmentData)) {
		return <PageError errorText={t('representments:failedToLoad')} />;
	}

	return (
		<Box sx={{ height: '100%', width: '100%' }}>
			<ActionRow
				representmentData={representmentData}
				onMarkAsSentToProcessor={onMarkAsSentToProcessor}
				onBuildDocument={onBuildDocumentClicked}
				isLoading={isLoading}
			/>
			<Box sx={{ height: 'calc(100% - 200px)' }} mt={2} mb={3}>
				<Stack direction='column' alignItems='center' spacing={3}>
					<DisplayControls
						representmentData={representmentData}
						displayedSections={displayedSections}
						setDisplayedSections={setDisplayedSections}
					/>
					<DetailDisplay
						representmentData={representmentData}
						displayedSections={displayedSections}
					/>
					<ReversalReasonBuilder
						representmentData={representmentData}
						onUpdateClicked={onUpdateClicked}
						hasBeenLoaded={hasBeenLoaded}
						isLoading={isLoading}
						reversalReason={representmentData.chargebackReversal.reason}
					/>
				</Stack>
			</Box>
		</Box>
	);
};

RepresentmentBuilder.propTypes = {
	representmentData: PropTypes.object,
	isLoading: PropTypes.bool,
	hasBeenLoaded: PropTypes.bool,
	error: PropTypes.string,
	getRepresentment: PropTypes.func,
	t: PropTypes.func,
	updateRepresentment: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		representmentData: state.representmentReducer.getIn([
			'representment',
			'data'
		]),
		isLoading: state.representmentReducer.getIn(['representment', 'isLoading']),
		hasBeenLoaded: state.representmentReducer.getIn([
			'representment',
			'hasBeenLoaded'
		]),
		error: state.representmentReducer.getIn(['representment', 'error'])
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getRepresentment: (payload) => {
			dispatch(representmentActions.getRepresentment(payload));
		},
		updateRepresentment: (payload) => {
			dispatch(representmentActions.updateRepresentment(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation()(RepresentmentBuilder));
