import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { modalActions } from '../../../containers/Modal';
import { userActions } from '../../../containers/User';
import { usersActions } from '../../../containers/Users';
import ModalBody from './ModalBody';
import CompletionPanel from './CompletionPanel';
import GenericModalPaperWrapper from '../../modals/GenericModalPaperWrapper';

import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

const DeleteUserModal = (props) => {
	const {
		deleteUser,
		getUsers,
		isDeletingUser,
		resetDeleteUserProcess,
		selectUserInfo,
		setDeleteUserModal,
		successfullyDeletedUser
	} = props;

	React.useEffect(() => {
		if (!isDeletingUser && successfullyDeletedUser) {
			getUsers();
		}
	}, [isDeletingUser, successfullyDeletedUser]);

	const handleOnClose = () => {
		setDeleteUserModal();
	};

	const onDeleteUser = () => {
		deleteUser({
			userId: selectUserInfo?.id
		});
	};

	const handleSuccessClose = () => {
		resetDeleteUserProcess();
		setDeleteUserModal();
	};

	return (
		<GenericModalPaperWrapper
			open={!!selectUserInfo}
			onClose={handleOnClose}
			isLoading={isDeletingUser}
		>
			{isDeletingUser ? (
				<Stack
					direction='row'
					justifyContent='center'
					alignItems='center'
					sx={{ height: '350px' }}
				>
					<CircularProgress color='primary' />
				</Stack>
			) : null}
			{!successfullyDeletedUser && !isDeletingUser ? (
				<ModalBody
					handleOnClose={handleOnClose}
					onDelete={onDeleteUser}
					selectUserInfo={selectUserInfo}
				/>
			) : null}
			{successfullyDeletedUser ? (
				<CompletionPanel
					onClose={handleSuccessClose}
				/>
			) : null}
		</GenericModalPaperWrapper>
	);
};

DeleteUserModal.propTypes = {
	deleteUser: PropTypes.func,
	getUsers: PropTypes.func,
	isDeletingUser: PropTypes.bool,
	resetDeleteUserProcess: PropTypes.func,
	selectUserInfo: PropTypes.object,
	setDeleteUserModal: PropTypes.func,
	successfullyDeletedUser: PropTypes.bool
};

const mapStateToProps = (state) => {
	return {
		isDeletingUser: state.userReducer.get('isDeletingUser'),
		successfullyDeletedUser: state.userReducer.get('successfullyDeletedUser'),
		selectUserInfo: state.modalReducer.get('deleteUserModal')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		deleteUser: (payload) => {
			dispatch(userActions.deleteUser(payload));
		},
		getUsers: (payload) => {
			dispatch(usersActions.getUsers(payload));
		},
		resetDeleteUserProcess: (payload) => {
			dispatch(userActions.resetDeleteUserProcess(payload));
		},
		setDeleteUserModal: (payload) => {
			dispatch(modalActions.setDeleteUserModal(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(DeleteUserModal);
