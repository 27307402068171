import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import NotificationInformation from '../../components/notificationDetail/NotificationInformation';
import NotificationUpdate from '../../components/notificationDetail/NotificationUpdate';
import NotificationTimeline from '../../components/notificationDetail/NotificationTimeline';
import Restricted from '../../permissionProvider/Restricted';
import RestrictedFallback from '../../components/shared/RestrictedFallback';

import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';

const NotificationDetails = ({ t }) => {
	return (
		<Restricted
			to='notifications.notificationsList.view'
			Fallback={
				<RestrictedFallback overrideMessage={t('common:error.notAllowed')} />
			}
		>
			<Grid pt={2} container sx={{ maxWidth: 1200, margin: '0 auto' }} rowSpacing={2}>
				<Grid item xs={12} md={12}>
					<Stack spacing={2} mx={1}>
						<NotificationInformation />
						<NotificationUpdate />
					</Stack>
				</Grid>
				<Grid item xs={12} md={12}>
					<NotificationTimeline />
				</Grid>
			</Grid>
		</Restricted>
	);
};

NotificationDetails.propTypes = {
	t: PropTypes.func
};

export default (
	withTranslation('common')(NotificationDetails)
);
