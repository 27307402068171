import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import querier from '../../../../utilities/querier';
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FourteenDayChargebacksTotalTableCell } from '../../../ReportStylings';

import Button from '@mui/material/Button';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import Typography from '@mui/material/Typography';

const EnhancedFooter = (props) => {
	const { columns, totals, startDate, endDate, t } = props;

	const navigate = useNavigate();

	const onTotalValueClicked = (startDate, endDate) => {
		const { cardBrand, processorId, merchantId } = queryString.parse(
			location.search
		);

		if (cardBrand && processorId && merchantId) {
			navigate(
				`/reports/064de551-43a8-40a0-8663-f9b8bb9ef8d4?processorId=${processorId}&startDate=${startDate}&endDate=${endDate}&cardBrands=${cardBrand}&merchantIds=${merchantId}&autoApply=true`
			);
		}
	};

	return (
		<TableFooter>
			<TableRow>
				<TableCell sx={{ color: 'common.black' }}>
					<Typography variant='subtitle2' sx={{ fontWeight: 600, fontsize: '1rem' }}>
						{t('fourteenDayChargebacks.grandTotals')}
					</Typography>
				</TableCell>
				{columns.map((column) => {
					const value = querier(totals, `$[key = "${column.key}"].chargebacks`);
					return (
						<TableCell key={`totals-${column.key}`} align='center'>
							{value === 0 ? (
								'-'
							) : (
								<Button
									onClick={() => {
										onTotalValueClicked(column.key, column.key);
									}}
									size={'small'}
									sx={{ m: 0, p: 0, minWidth: 0 }}
								>
									{value}
								</Button>
							)}
						</TableCell>
					);
				})}
				<FourteenDayChargebacksTotalTableCell align='center'>
					{querier(totals, '$[key = "total"].chargebacks') === 0 ? (
						0
					) : (
						<Button
							onClick={() => {
								onTotalValueClicked(startDate, endDate);
							}}
						>
							{querier(totals, '$[key = "total"].chargebacks')}
						</Button>
					)}
				</FourteenDayChargebacksTotalTableCell>
			</TableRow>
		</TableFooter>
	);
};

EnhancedFooter.propTypes = {
	columns: PropTypes.array,
	totals: PropTypes.array,
	startDate: PropTypes.string,
	endDate: PropTypes.string,
	t: PropTypes.func
};

export default withTranslation('reports')(EnhancedFooter);
