import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import deepCopy from '../../../utilities/deepCopy';
import AddChargebackReason from './AddChargebackReason';
import ReversalReasonItem from './ReversalReasonItem';
import { reversalReasons } from './constants/reversalReasons';
import prepareIncomingReversalReaons from '../../../helpers/currentInventory/prepareIncomingReversalReasons';
import hydrateReversalReason from '../../../helpers/currentInventory/hydrateReversalReason';
import RepresentmentBuilderStack from '../RepresentmentBuilderStack';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

export const ReversalReasonBuilder = (props) => {
	const {
		t,
		representmentData,
		onUpdateClicked,
		reversalReason,
		hasBeenLoaded,
		isLoading
	} = props;

	const [manualReversalReason, setManualReversalReason] = React.useState();

	React.useEffect(() => {
		if (hasBeenLoaded) {
			const preparedReversalReason = prepareIncomingReversalReaons(
				reversalReason,
				reversalReasons
			);
			setManualReversalReason(preparedReversalReason);
		}
	}, [reversalReason, hasBeenLoaded]);

	const onAddChargebackReason = (label, value) => {
		const updatedReason = deepCopy(manualReversalReason);

		const enrichedReason = hydrateReversalReason(
			value,
			representmentData,
			label
		);

		updatedReason.push({
			key: label,
			value: enrichedReason
		});

		setManualReversalReason(updatedReason);
	};

	const onRemoveChargebackReason = (removeIndex) => {
		let updatedReason = deepCopy(manualReversalReason);

		updatedReason = updatedReason.filter((reason, index) => {
			return index !== removeIndex;
		});

		setManualReversalReason(updatedReason);
	};

	const onReorderReason = (oldIndex, newIndex) => {
		const updatedReason = deepCopy(manualReversalReason);

		const [item] = updatedReason.splice(oldIndex, 1);

		updatedReason.splice(newIndex, 0, item);

		setManualReversalReason(updatedReason);
	};

	const onUpdateManualReason = (index, newValue) => {
		const updatedReasons = deepCopy(manualReversalReason);

		updatedReasons[index].value = newValue;

		setManualReversalReason(updatedReasons);
	};

	const onUpdateReversalReason = () => {
		const formattedReason = manualReversalReason.map((reasonItem) => {
			return reasonItem.value;
		});

		onUpdateClicked(formattedReason);
	};

	return (
		<RepresentmentBuilderStack maxWidth={1200} sx={{ width: '100%' }} spacing={1}>
			<Stack direction='row' justifyContent='space-between' alignItems='center'>
				<Typography variant='h6'>
					{t('representments:chargebackReversalReasons.pageTitle')}
				</Typography>
				<Button
					variant='outlined'
					color='secondary'
					onClick={onUpdateReversalReason}
					disabled={!manualReversalReason}
				>
					{t('common:buttons.update')}
				</Button>
			</Stack>
			{!manualReversalReason || isLoading ? (
				<Skeleton height={40} width={250} />
			) : (
				<React.Fragment>
					<AddChargebackReason onAddChargebackReason={onAddChargebackReason} />
					{manualReversalReason.map((reason, index) => {
						if (reason.key === 'Manual Entry') {
							return (
								<ReversalReasonItem
									key={index}
									onRemoveItem={onRemoveChargebackReason}
									itemIndex={index}
									totalItems={manualReversalReason.length}
									onReorderReason={onReorderReason}
								>
									<Box mx={1} sx={{ display: 'flex', flex: 1 }}>
										<TextField
											id='reason-text-field'
											sx={{ flex: 1 }}
											multiline
											value={reason.value}
											onChange={(e) => {
												onUpdateManualReason(index, e.target.value);
											}}
										/>
									</Box>
								</ReversalReasonItem>
							);
						} else {
							return (
								<ReversalReasonItem
									key={index}
									data={reason.value}
									onRemoveItem={onRemoveChargebackReason}
									itemIndex={index}
									totalItems={manualReversalReason.length}
									onReorderReason={onReorderReason}
								>
									<Stack my={1} px={1.2} sx={{ flex: 1 }}>{reason.value}</Stack>
								</ReversalReasonItem>
							);
						}
					})}
				</React.Fragment>
			)}
		</RepresentmentBuilderStack>
	);
};

ReversalReasonBuilder.propTypes = {
	representmentData: PropTypes.object,
	onUpdateClicked: PropTypes.func,
	reversalReason: PropTypes.array,
	hasBeenLoaded: PropTypes.bool,
	isLoading: PropTypes.bool,
	t: PropTypes.func
};

export default withTranslation(['common', 'representments'])(ReversalReasonBuilder);
