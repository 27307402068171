import { fromJS } from 'immutable';
import * as actionTypes from './actionTypes';

export const initialState = fromJS({
	invitation: {
		isValidating: false,
		validatedInvitationId: undefined,
		error: undefined
	},
	registration: {
		isRegistering: false,
		hasBeenRegistered: false,
		error: undefined
	},
	isCancellingInvitation: false,
	hasCancelledInvitation: false
});

export const registrationReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.VALIDATE_INVITATION_ID:
			return state
				.setIn(['invitation', 'isValidating'], true)
				.setIn(['invitation', 'validatedInvitationId'], undefined);

		case actionTypes.VALIDATE_INVITATION_ID_COMPLETED:
			return state
				.setIn(['invitation', 'isValidating'], false)
				.setIn(['invitation', 'validatedInvitationId'], action.payload);

		case actionTypes.VALIDATE_INVITATION_ID_FAILED:
			return state
				.setIn(['invitation', 'isValidating'], false)
				.setIn(['invitation', 'error'], action.payload);

		case actionTypes.SUBMIT_REGISTRATION:
			return state.setIn(['registration', 'isRegistering'], true);

		case actionTypes.SUBMIT_REGISTRATION_COMPLETED:
			return state
				.setIn(['registration', 'isRegistering'], false)
				.setIn(['registration', 'hasBeenRegistered'], true);

		case actionTypes.SUBMIT_REGISTRATION_FAILED:
			return state
				.setIn(['registration', 'isRegistering'], false)
				.setIn(['registration', 'error'], action.payload);

		case actionTypes.CANCEL_INVITATION:
			return state.set('isCancellingInvitation', true);

		case actionTypes.CANCEL_INVITATION_COMPLETED:
			return state
				.set('isCancellingInvitation', false)
				.set('hasCancelledInvitation', true);

		case actionTypes.CANCEL_INVITATION_FAILED:
			return state.set('isCancellingInvitation', false);

		default:
			return state;
	}
};
