import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { noCase } from 'change-case';
import { notificationSeverityLevelsNamed } from '../../constants/notificationSeverityLevels';

import Box from '@mui/material/Box';

const NotificationStatusIndicatorBox = styled(Box)(({ color, theme }) => ({
	borderTopLeftRadius: 5,
	borderBottomLeftRadius: 5,
	backgroundColor: theme.palette[color].main,
	marginLeft: -2,
	width: 6
}));

const NotificationSeverityIndicatorBox = ({ severity }) => {
	if (!severity) {
		return null;
	}

	const severityLevel = notificationSeverityLevelsNamed[noCase(severity)];

	return (
		<NotificationStatusIndicatorBox color={severityLevel.colorThemePath} />
	);
};

NotificationSeverityIndicatorBox.propTypes = {
	severity: PropTypes.string
};

export default NotificationSeverityIndicatorBox;
