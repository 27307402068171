import axios from '../../utilities/axios';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { refreshToken } from '../../utilities/refreshToken';
import { primaryRestGateway } from '../../utilities/apiEndpointUtility';

import * as actions from './actions.js';
import * as actionTypes from './actionTypes.js';
import {
	getAvatarSrc
} from './userAvatarSlice';

export const getUserEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.GET_USER),
		mergeMap(async (action) => {
			await refreshToken();

			const username = action.payload;

			const response = await axios.get(
				`${primaryRestGateway()}/api/users/${username}`
			);

			return response.data;
		}),
		switchMap((res) => [
			actions.getUserCompleted(res),
			getAvatarSrc(res)
		]),
		catchError((error, source) =>
			merge(of(actions.getUserFailed(error.message)), source)
		)
	);

export const updateUserEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.UPDATE_USER),
		mergeMap(async (action) => {
			await refreshToken();

			const { userId, username, userData } = action.payload;

			const response = await axios.put(
				`${primaryRestGateway()}/api/users/${userId}`,
				{
					userData,
					username
				}
			);

			return response.data;
		}),
		switchMap((res) => [
			actions.updateUserCompleted(res),
			getAvatarSrc(res)
		]),
		catchError((error, source) =>
			merge(of(actions.updateUserFailed(error.message)), source)
		)
	);

export const getUserPermissionsEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.GET_USER_PERMISSIONS),
		mergeMap(async (action) => {
			await refreshToken();

			const { userId } = action.payload;

			const response = await axios.get(
				`${primaryRestGateway()}/api/users/permissions/user/${userId}`
			);

			return response.data;
		}),
		switchMap((res) => [actions.getUserPermissionsCompleted(res)]),
		catchError((error, source) => {
			return merge(of(actions.getUserPermissionsFailed(error.message)), source);
		})
	);

export const updateUserPermissionsEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.UPDATE_USER_PERMISSIONS),
		mergeMap(async (action) => {
			await refreshToken();

			const { username, userId, roles, permissions } = action.payload;

			const response = await axios.post(
				`${primaryRestGateway()}/api/users/permissions/user/${userId}`,
				{
					username,
					roles,
					permissions
				}
			);

			return response.data;
		}),
		switchMap((res) => [actions.updateUserPermissionsCompleted(res)]),
		catchError((error, source) => {
			return merge(
				of(actions.updateUserPermissionsFailed(error.message)),
				source
			);
		})
	);

export const updateUserStatusEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.UPDATE_USER_STATUS),
		mergeMap(async (action) => {
			await refreshToken();

			const { isEnabled, userId, username } = action.payload;

			const response = await axios.put(
				`${primaryRestGateway()}/api/users/status/${userId}`,
				{
					isEnabled,
					username
				}
			);

			return response.data;
		}),
		switchMap((res) => [actions.updateUserStatusCompleted(res)]),
		catchError((error, source) => {
			return merge(of(actions.updateUserStatusFailed(error.message)), source);
		})
	);

export const deleteUserEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.DELETE_USER),
		mergeMap(async (action) => {
			await refreshToken();

			const { userId } = action.payload;

			const response = await axios.delete(
				`${primaryRestGateway()}/api/users/${userId}`
			);

			return response.data;
		}),
		switchMap((res) => [actions.deleteUserCompleted(res)]),
		catchError((error, source) => {
			return merge(of(actions.deleteUserFailed(error.message)), source);
		})
	);
