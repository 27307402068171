import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import RepresentmentTextField from './components/RepresentmentTextField';
import ScanInfoListRow from './components/ScanInfoListRow';
import isDataEmpty from '../../../helpers/shared/isDataEmpty';
import BuilderSectionHeader from '../BuilderSectionHeader';
import RepresentmentBuilderBox from '../RepresentmentBuilderStack';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const _cellColumnDefinition = [
	'proofOfDelivery.uniqueId',
	'proofOfDelivery.scanDate',
	'proofOfDelivery.optCode',
	'proofOfDelivery.description',
	'proofOfDelivery.scfEntry'
];

export const ProofOfDelivery = (props) => {
	const { t, data } = props;

	if (isDataEmpty(data)) {
		return (
			<Grid item xs={12} sm={12} md={12} lg={12}>
				<RepresentmentBuilderBox>
					<BuilderSectionHeader>{t('proofOfDelivery.label')}</BuilderSectionHeader>
					<Stack direction='row' justifyContent='center' my={0.5}>
						{t('proofOfDelivery.noInformation')}
					</Stack>
				</RepresentmentBuilderBox>
			</Grid>
		);
	}

	return (
		<Grid item xs={12} sm={12} md={12} lg={12}>
			<RepresentmentBuilderBox>
				<BuilderSectionHeader>{t('proofOfDelivery.label')}</BuilderSectionHeader>
				<Stack direction='column' spacing={1}>
					<Grid container>
						<Grid item>
							<RepresentmentTextField
								id='uniqueId'
								label={t('proofOfDelivery.uniqueId')}
								defaultValue={data.uniqueId}
							/>
						</Grid>
						<Grid item>
							<RepresentmentTextField
								id='customerNumber'
								label={t('proofOfDelivery.customerNumber')}
								defaultValue={data.customerNumber}
							/>
						</Grid>
						<Grid item>
							<RepresentmentTextField
								id='orderNumber'
								label={t('proofOfDelivery.orderNumber')}
								defaultValue={data.orderNumber}
							/>
						</Grid>
						<Grid item>
							<RepresentmentTextField
								id='name'
								label={t('proofOfDelivery.name')}
								defaultValue={data.name}
							/>
						</Grid>
						<Grid item>
							<RepresentmentTextField
								id='estimatedDeliveryDate'
								label={t('proofOfDelivery.estimatedDeliveryDate')}
								defaultValue={data.estimatedDeliveryDate}
							/>
						</Grid>
					</Grid>
					<Stack direction='row'>
						<RepresentmentTextField
							id='status'
							label={t('proofOfDelivery.status')}
							defaultValue={data.status}
						/>
						<RepresentmentTextField
							id='address'
							isFlexed={true}
							shouldFitContent={true}
							label={t('proofOfDelivery.address')}
							defaultValue={data.address}
						/>
					</Stack>
					<TableContainer>
						<Table sx={{ minWidth: 650 }} size='small'>
							<TableHead>
								<TableRow>
									{_cellColumnDefinition.map((label, index) => {
										return <TableCell key={index}>{t(label)}</TableCell>;
									})}
								</TableRow>
							</TableHead>
							<TableBody>
								{data.scanInfo.length === 0 ? (
									<TableRow
										key={'no-data'}
										sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
									>
										<TableCell>{t('transactionsList.noData')}</TableCell>
									</TableRow>
								) : (
									data.scanInfo.map((row, index) => {
										return (
											<ScanInfoListRow
												key={index}
												row={row}
												uniqueId={data.uniqueId}
											/>
										);
									})
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</Stack>
			</RepresentmentBuilderBox>
		</Grid>
	);
};

ProofOfDelivery.propTypes = {
	data: PropTypes.object,
	t: PropTypes.func
};

export default withTranslation('representments')(ProofOfDelivery);
