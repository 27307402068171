import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	runningUserRuleId: undefined,
	runningUserRuleCompleted: false,
	runningUserRuleError: undefined
};

export const rulesRunnerUntrackedSlice = createSlice({
	name: 'rulesRunnerUntracked',
	initialState,
	reducers: {
		runUntrackedUserRule: (state, action) => {
			state.runningUserRuleId = action.payload.userRuleId;
			state.runningUserRuleCompleted = false;
			state.runningUserRuleError = undefined;
		},
		runUntrackedUserRuleCompleted: (state) => {
			state.runningUserRuleCompleted = true;
		},
		runUntrackedUserRuleFailed: (state, action) => {
			state.runningUserRuleError = action.payload;
		},
		resetRunUntrackedUserRule: (state) => {
			state.runningUserRuleId = undefined;
			state.runningUserRuleCompleted = false;
			state.runningUserRuleError = undefined;
		}
	}
});

export const {
	runUntrackedUserRule,
	runUntrackedUserRuleCompleted,
	runUntrackedUserRuleFailed,
	resetRunUntrackedUserRule
} = rulesRunnerUntrackedSlice.actions;

export default rulesRunnerUntrackedSlice.reducer;
