import { fromJS } from 'immutable';
import * as actionTypes from './actionTypes';

export const initialState = fromJS({
	supportForm: {
		isSubmitting: false,
		hasBeenSuccessfullySubmitted: false,
		error: undefined
	}
});

export const supportReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SUBMIT_SUPPORT_FORM:
			return state.setIn(['supportForm', 'isSubmitting'], true);

		case actionTypes.SUBMIT_SUPPORT_FORM_COMPLETED:
			return state
				.setIn(['supportForm', 'isSubmitting'], false)
				.setIn(['supportForm', 'hasBeenSuccessfullySubmitted'], true);

		case actionTypes.SUBMIT_SUPPORT_FORM_FAILED:
			return state
				.setIn(['supportForm', 'isSubmitting'], false)
				.setIn(['supportForm', 'error'], action.payload);

		case actionTypes.CLEAR_SUPPORT_FORM:
			return state
				.setIn(['supportForm', 'isSubmitting'], false)
				.setIn(['supportForm', 'hasBeenSuccessfullySubmitted'], false)
				.setIn(['supportForm', 'error'], undefined);

		default:
			return state;
	}
};
