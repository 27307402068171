import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import QRCode from 'qrcode.react';
import { Auth } from 'aws-amplify';
import { getLocalStorage } from '../../../../utilities/localStorageUtility';
import ContainedButton from '../../../buttons/ContainedButton';
import TextButton from '../../../buttons/TextButton';
import OverlineTextError from '../../../errors/OverlineTextError';

import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const SetupPage = (props) => {
	const { t, user, onActivateMfa, mfaSetupError, onCloseModal	} = props;

	const [setupCode, setSetupCode] = React.useState({});
	const [expanded, setExpanded] = React.useState(false);
	const [auththenticationCode, setAuththenticationCode] = React.useState();

	React.useEffect(() => {
		if (!setupCode.code) {
			const cpi = getLocalStorage('CPI');

			Auth.setupTOTP(user).then((code) => {
				const issuer = `https://cognito-idp.${cpi.REG}.amazonaws.com/${user.pool.userPoolId}`;

				const codeString = `otpauth://totp/AWSCognito:${user.username}?secret=${code}&issuer=${issuer}`;

				setSetupCode({
					code,
					fullSetupString: codeString
				});
			});
		}
	}, [setupCode, setSetupCode, user]);

	return (
		<Stack spacing={1}>
			<Stack direction='row'>{t('modals:multiFactorAuth.scanWithApp')}</Stack>
			<Stack direction='row' justifyContent='center' px={2}>
				{setupCode.code ? (
					<QRCode size={256} value={setupCode.fullSetupString} />
				) : (
					<CircularProgress />
				)}
			</Stack>
			<Accordion
				sx={{ flex: 1 }}
				onChange={(_, expanded) => {
					setExpanded(expanded);
				}}
			>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls='secret-configuration-header'
					id='secret-configuration-header'
				>
					<Typography sx={{ color: 'primary.dark' }}>
						{expanded ? (
							<span>{t('modals:multiFactorAuth.hideSecretKey')}</span>
						) : (
							<span>{t('modals:multiFactorAuth.showSecretKey')}</span>
						)}
					</Typography>
				</AccordionSummary>
				<AccordionDetails
					sx={{
						bgcolor: 'primary.light',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						px: 2,
						py: 1
					}}
				>
					<Typography color='white'>{setupCode.code}</Typography>
				</AccordionDetails>
			</Accordion>
			<Typography sx={{ p: 0.7 }}>
				{t('modals:multiFactorAuth.afterConfiguredApplication')}
			</Typography>
			<Stack direction='row' spacing={2} alignItems='center'>
				<Typography>
					{t('modals:multiFactorAuth.authenticationCode')}
				</Typography>
				<TextField
					onChange={(e) => setAuththenticationCode(e.target.value)}
					sx={{ ml: 2.5 }}
					id='outlined-basic'
					variant='outlined'
					size='small'
				/>
			</Stack>
			{mfaSetupError && (
				<Stack direction='row' justifyContent='center'>
					<OverlineTextError>{mfaSetupError}</OverlineTextError>
				</Stack>
			)}
			<Stack direction='row' justifyContent='flex-end' spacing={2}>
				<TextButton color='secondary.main' onClick={onCloseModal}>
					{t('common:buttons.cancel')}
				</TextButton>
				<ContainedButton
					onClick={() => {
						onActivateMfa(user, auththenticationCode);
					}}
				>
					{t('modals:multiFactorAuth.activate')}
				</ContainedButton>
			</Stack>
		</Stack>
	);
};

SetupPage.propTypes = {
	user: PropTypes.object,
	onActivateMfa: PropTypes.func,
	mfaSetupError: PropTypes.string,
	onCloseModal: PropTypes.func,
	t: PropTypes.func
};

export default withTranslation(['common', 'modals'])(SetupPage);
