import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';

import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

const ValidationItem = (props) => {
	const { t, validationResult, descriptionTranslationPath } = props;

	return (
		<ListItem alignItems='center' >
			{validationResult ? (
				<ClearIcon color='error' />
			) : (
				<CheckIcon color='success' />
			)}
			<Typography variant='caption'>{t(descriptionTranslationPath)}</Typography>
		</ListItem>
	);
};

ValidationItem.propTypes = {
	validationResult: PropTypes.bool,
	descriptionTranslationPath: PropTypes.string,
	t: PropTypes.func
};

export default withTranslation('registration')(ValidationItem);
