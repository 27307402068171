import React from 'react';
import PropTypes from 'prop-types';

import FormControl from '@mui/material/FormControl';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

// TODO - MOVED TO inputs FOLDER - DELETE FROM HERE AND ENSURE NOTHING BREAKS
const DisabledSelectInput = (props) => {
	const {
		value,
		label,
		isStageDisabled,
		valueLabelKey
	} = props;

	return (
		<FormControl sx={{ flex: 1 }}>
			<InputLabel
				sx={{ bgcolor: !isStageDisabled && 'common.white', padding: '2px' }}
				id={'title-label'}
			>
				{isStageDisabled ? null : label}
			</InputLabel>
			<Select
				value={value ? value[valueLabelKey] : ''}
				disabled={true}
				endAdornment={
					<InputAdornment sx={{ mr: 3 }} position='end'>
						{isStageDisabled ? null : <CircularProgress size={20} />}
					</InputAdornment>
				}
			>
				{isStageDisabled && value ? (
					<MenuItem value={value[valueLabelKey]}>
						{value[valueLabelKey]}
					</MenuItem>
				) : null}
			</Select>
		</FormControl>
	);
};

DisabledSelectInput.propTypes = {
	value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	label: PropTypes.string,
	isStageDisabled: PropTypes.bool,
	valueLabelKey: PropTypes.string
};

export default DisabledSelectInput;
