import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { withTranslation } from 'react-i18next';
import { capitalCase } from 'change-case';
import { getLabel } from '../../../../helpers/shared/cardBrandHelper';
import { TwelveMonthReportCell } from '../../../ReportStylings';
import ComplianceTableRow from './components/ComplianceTableRow';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
	borderRadius: '5px',
	border: `1px solid ${theme.palette.common.black}`
}));

const StyledCombinedTableCell = styled(TableCell)(({ theme }) => ({
	borderLeft: `1px solid ${theme.palette.common.black}`,
	borderRight: `1px solid ${theme.palette.common.black}`
}));

const StyledFirstCell = styled(TableCell)(({ theme }) => ({
	[theme.breakpoints.up('md')]: {
		fontSize: '1.2rem'
	},
	[theme.breakpoints.down('md')]: {
		fontSize: '1rem'
	},
	fontWeight: 600
}));

const MonthLabel = styled(Typography)({
	fontWeight: 600,
	fontSize: '.8rem'
});

const TwelveMonthChargebackComplianceTable = (props) => {
	const { cardBrand, data, tableIndex, t } = props;

	return (
		<StyledTableContainer>
			<Table size='small'>
				<TableHead>
					{tableIndex === 0 && (
						<TableRow>
							<TableCell width={250} />
							<TableCell colSpan={6} width={400} align='center'>
								{t('twelveMonthChargebackCompliance.headers.historical')}
							</TableCell>
							<StyledCombinedTableCell width={100} align='center'>
								{t('twelveMonthChargebackCompliance.headers.combined')}
							</StyledCombinedTableCell>
							<TableCell colSpan={6} width={400} align='center'>
								{t('twelveMonthChargebackCompliance.headers.forecast')}
							</TableCell>
						</TableRow>
					)}
					<TableRow>
						<StyledFirstCell width={250}>
							{getLabel(cardBrand)}
						</StyledFirstCell>
						{data.map((monthData, index) => (
							<TwelveMonthReportCell
								sx={{ fontWeight: 600 }}
								align='center'
								key={monthData.month}
								columnIndex={index}
							>
								<MonthLabel>
									{capitalCase(monthData.monthLabel)}
								</MonthLabel>
							</TwelveMonthReportCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					<ComplianceTableRow
						rowKey={'chargebacksReceivedRounded'}
						rowLabel={t('dailyChargebackReport.received')}
						data={data}
					/>
					<ComplianceTableRow
						rowKey={'transactionsRounded'}
						rowLabel={t('dailyChargebackReport.transactions')}
						data={data}
					/>
					<ComplianceTableRow
						rowKey={'chargebackPercent'}
						rowLabel={t('dailyChargebackReport.compliancePercent')}
						data={data}
					/>
					<ComplianceTableRow
						rowKey={'chargebackUnitsOverRounded'}
						rowLabel={t('dailyChargebackReport.chargebackUnitsOver')}
						data={data}
					/>
					<ComplianceTableRow
						rowKey={'transactionsOverRounded'}
						rowLabel={t('dailyChargebackReport.transactionsOver')}
						data={data}
					/>
				</TableBody>
			</Table>
		</StyledTableContainer>
	);
};

TwelveMonthChargebackComplianceTable.propTypes = {
	cardBrand: PropTypes.string,
	data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	tableIndex: PropTypes.number,
	t: PropTypes.func
};

export default withTranslation('reports')(TwelveMonthChargebackComplianceTable);
