import axios from '../../utilities/axios';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { refreshToken } from '../../utilities/refreshToken';
import Logger from '../../utilities/logger';
import { primaryRestGateway } from '../../utilities/apiEndpointUtility';

import * as actions from './actions.js';
import * as actionTypes from './actionTypes.js';

export const getWorkedCasesEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.GET_WORKED_CASES),
		mergeMap(async (action) => {
			await refreshToken();

			const { startDate, endDate, processorId } = action.payload;

			const response = await axios.get(
				`${primaryRestGateway()}/api/representments/inventory/worked`, {
					params: {
						startDate,
						endDate,
						processorId
					}
				}
			);

			return response.data;
		}),
		switchMap((res) => [actions.getWorkedCasesCompleted(res)]),
		catchError((error, source) => {
			Logger.error('Get Worked Cases Failure', error);

			return merge(of(actions.getWorkedCasesFailed(error.message)), source);
		})
	);
