import axios from '../../utilities/axios';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { refreshToken } from '../../utilities/refreshToken';

import { primaryRestGateway } from '../../utilities/apiEndpointUtility';

import {
	getSavedRules,
	getSavedRulesCompleted,
	getSavedRulesFailed
} from './savedRulesSlice';

export const getSavedRulesEpic = (action$) =>
	action$.pipe(
		ofType(getSavedRules),
		mergeMap(async (action) => {
			await refreshToken();

			const { queryParameterString } = action.payload;

			const response = await axios.get(`${primaryRestGateway()}/api/rules`, {
				params: queryParameterString
			});

			return response.data;
		}),
		switchMap((res) => [getSavedRulesCompleted(res)]),
		catchError((error, source) =>
			merge(of(getSavedRulesFailed(error.message)), source)
		)
	);

export default [
	getSavedRulesEpic
];
