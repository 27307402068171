import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';

import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import Restricted from '../../../../permissionProvider/Restricted';

const EnhancedTableToolbar = (props) => {
	const {
		t,
		searchTerm,
		setSearchTerm
	} = props;

	const navigate = useNavigate();

	const onCreateGroupClick = () => {
		navigate('/groups/create');
	};

	const onClearSearchTerm = () => {
		setSearchTerm('');
	};

	return (
		<Toolbar
			sx={{
				flex: 1,
				pl: { sm: 2 },
				pr: { xs: 1, sm: 1 },
				pb: 2
			}}
		>
			<Stack
				direction='row'
				p={1}
				spacing={2}
				flex={1}
				alignItems='flex-end'
				justifyContent='flex-end'
			>
				<TextField
					placeholder={t('common:search')}
					value={searchTerm}
					onChange={(e) => {
						setSearchTerm(e.target.value);
					}}
					variant='standard'
					InputProps={{
						startAdornment: <SearchIcon />,
						endAdornment: (
							<ClearIcon
								onClick={onClearSearchTerm}
								sx={{ visibility: searchTerm.length === 0 ? 'hidden' : 'visible', cursor: 'pointer' }}
							/>
						)
					}}
				/>
				<Restricted to='groups.groups.add'>
					<Button variant='outlined' onClick={onCreateGroupClick}>
						{t('groups:groupManager.createGroup')}
					</Button>
				</Restricted>
			</Stack>
		</Toolbar>
	);
};

EnhancedTableToolbar.propTypes = {
	searchTerm: PropTypes.string,
	setSearchTerm: PropTypes.func,
	onCreateGroup: PropTypes.func,
	t: PropTypes.func
};

export default withTranslation(['common', 'groups'])(EnhancedTableToolbar);
