import React from 'react';
import MultiFactorAuthChallenge from '../../components/MultiFactorAuthentication/MultiFactorAuthChallenge';

import RegistrationPageWrapper from '../../components/RegistrationPageWrapper';

const MultiFactorAuthentication = () => {
	return (
		<RegistrationPageWrapper>
			<MultiFactorAuthChallenge />
		</RegistrationPageWrapper>
	);
};

export default MultiFactorAuthentication;
