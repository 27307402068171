const {
	standardPrefixes,
	trendingComparator,
	trendingComparatorPrefix,
	trendingTimeFrame
} = require('../constants/ruleOptionConstants');

const _formatOptionPrefixValue = (index, parserKey) => {
	let prefixValue = '';

	if (parserKey) {
		if (index === 3 && parserKey === trendingComparator) {
			prefixValue = `${trendingComparatorPrefix} `;
		} else if (index !== 5 || (index === 5 && trendingTimeFrame !== parserKey)) {
			prefixValue = standardPrefixes[index] !== null ? `${standardPrefixes[index]} ` : '';
		}
	}

	return prefixValue;
};

const renderValue = (inputValue, selectedMeasurement, selectedComparisonUnit) => {
	let formattedValue = '';

	if (selectedMeasurement && selectedComparisonUnit && inputValue) {
		if (selectedMeasurement.optionId === 14 && selectedComparisonUnit.optionId === 26) {
			formattedValue = `$${inputValue}`;
		} else if (selectedComparisonUnit.optionId === 27) {
			formattedValue = `${inputValue}%`;
		} else {
			formattedValue = inputValue;
		}
	}

	return formattedValue;
};

export default function (selectedValues, selectedMeasurement,
	selectedComparisonUnit) {
	return selectedValues.map((value, index) => {
		let formattedValue = _formatOptionPrefixValue(index, value?.parserKey);

		if (index === 4) {
			formattedValue = formattedValue.concat(renderValue(value, selectedMeasurement, selectedComparisonUnit));
		} else {
			formattedValue = formattedValue.concat((value ? value.ruleDisplayText : ''));
		}

		return formattedValue;
	}).join(' ');
}
