import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import fileUploadHandler from '../../../utilities/fileUploadHandler';
import HeaderRow from '../shared/HeaderRow';
import GenericModalPaperWrapper from '../GenericModalPaperWrapper';

import { userActions } from '../../../containers/User';
import { modalActions } from '../../../containers/Modal';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import CircularProgress from '@mui/material/CircularProgress';

export const EditAvatarModal = (props) => {
	const {
		t,
		user,
		isUpdatingUser,
		userHasBeenUpdated,
		userAvatarSrc,
		isUpdateAvatarModalOpen,
		resetUpdateUser,
		closeModal
	} = props;

	const [uploadedAvatar, setUploadedAvatar] = React.useState(undefined);

	React.useEffect(() => {
		if (userHasBeenUpdated && uploadedAvatar) {
			setUploadedAvatar(undefined);
			resetUpdateUser();
			closeModal();
		}
	}, [userHasBeenUpdated]);

	const onUpdateAvatar = () => {
		const { updateUser, user } = props;

		updateUser({
			userId: user.id,
			username: user.username,
			userData: {
				avatar: uploadedAvatar
			}
		});
	};

	const onUploadFinished = (data) => {
		setUploadedAvatar(data);
	};

	const onUpload = async (file) => {
		await fileUploadHandler(file, onUploadFinished);
	};

	const onCloseModal = () => {
		const { closeModal } = props;

		setUploadedAvatar(undefined);
		closeModal();
	};

	return (
		<GenericModalPaperWrapper
			open={isUpdateAvatarModalOpen}
			onClose={closeModal}
		>
			<Stack spacing={1} p={2}>
				<HeaderRow
					translationPath={'editAvatar.updateAvatar'}
					onClickCancel={onCloseModal}
				/>
				<Stack direction='row' justifyContent='space-evenly'>
					<Avatar
						alt={user.firstName}
						src={uploadedAvatar || userAvatarSrc}
						variant='rounded'
						sx={{ width: 200, height: 200 }}
					/>
					{isUpdatingUser ? (
						<Box
							p={2}
							mx={1}
							sx={{
								width: 200,
								height: 200,
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center'
							}}
						>
							<CircularProgress />
						</Box>
					) : (
						<Dropzone onDrop={(acceptedFiles) => onUpload(acceptedFiles[0])}>
							{({ getRootProps, getInputProps }) => (
								<section>
									<Stack
										p={2}
										mx={1}
										spacing={3}
										alignItems='center'
										{...getRootProps()}
										sx={{
											height: 200,
											width: 300,
											border: 1,
											borderStyle: 'dashed',
											borderColor: 'primary.main'
										}}
									>
										<input {...getInputProps()} />
										<Typography>
											{t('modals:editAvatar.dragNDrop')}
										</Typography>
										<Typography>{t('modals:editAvatar.or')}</Typography>
										<Typography>{t('modals:editAvatar.click')}</Typography>
									</Stack>
								</section>
							)}
						</Dropzone>
					)}
				</Stack>
				<Stack direction='row' justifyContent='flex-end' mt={2}>
					<Button
						variant={!uploadedAvatar ? 'outlined' : 'contained'}
						color='primary'
						type='submit'
						onClick={
							userHasBeenUpdated && uploadedAvatar
								? onCloseModal
								: onUpdateAvatar
						}
						disabled={isUpdatingUser || !uploadedAvatar}
					>
						{userHasBeenUpdated && uploadedAvatar
							? t('common:buttons.close')
							: t('common:buttons.confirm')}
					</Button>
				</Stack>
			</Stack>
		</GenericModalPaperWrapper>
	);
};

EditAvatarModal.propTypes = {
	user: PropTypes.object,
	isUpdatingUser: PropTypes.bool,
	userHasBeenUpdated: PropTypes.bool,
	userAvatarSrc: PropTypes.string,
	isUpdatingAvatar: PropTypes.bool,
	avatarHasBeenUpdated: PropTypes.bool,
	updateUser: PropTypes.func,
	isUpdateAvatarModalOpen: PropTypes.bool,
	closeModal: PropTypes.func,
	resetUpdateUser: PropTypes.func,
	t: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		user: state.userReducer.get('user'),
		userHasBeenUpdated: state.userReducer.get('userHasBeenUpdated'),
		isUpdatingUser: state.userReducer.get('isUpdatingUser'),
		userAvatarSrc: state.userAvatarSlice.userAvatar.src,
		isUpdatingAvatar: state.userReducer.get('isUpdatingAvatar'),
		avatarHasBeenUpdated: state.userReducer.get('avatarHasBeenUpdated'),
		isUpdateAvatarModalOpen: state.modalReducer.get('isUpdateAvatarModalOpen')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateUser: (payload) => {
			dispatch(userActions.updateUser(payload));
		},
		resetUpdateUser: (payload) => {
			dispatch(userActions.resetUpdateUser(payload));
		},
		closeModal: (payload) => {
			dispatch(modalActions.toggleUpdateAvatarModal(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation(['common', 'modals'])(EditAvatarModal));
