import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { capitalCase } from 'change-case';

import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

export const NotificationGeneratedSelect = ({ t, selectedOption, onChangeSelection }) => {
	return (
		<FormControl sx={{ flex: 1 }} fullWidth>
			<InputLabel id='notification-generated-select-label'>
				{t('reports:notificationRuleReport.notificationGenerated')}
			</InputLabel>
			<Select
				labelId='notification-generated-select-label'
				label={t('reports:notificationRuleReport.notificationGenerated')}
				id='notification-generated-select'
				variant='outlined'
				value={selectedOption}
				onChange={(e) => {
					onChangeSelection(e.target.value);
				}}
				renderValue={(option) => {
					return t(`common:${option}`);
				}}
				endAdornment={
					<IconButton
						sx={{ display: selectedOption ? null : 'none', mr: 1 }}
						onClick={() => onChangeSelection('all')}
					>
						<ClearOutlinedIcon />
					</IconButton>
				}
			>
				<MenuItem value={'all'}>
					<Stack direction='row' spacing={2} alignItems='center'>
						<Typography>{capitalCase(t('common:all'))}</Typography>
					</Stack>
				</MenuItem>
				<MenuItem value={'yes'}>
					<Stack direction='row' spacing={2} alignItems='center'>
						<Typography>{capitalCase(t('common:yes'))}</Typography>
					</Stack>
				</MenuItem>
				<MenuItem value={'no'}>
					<Stack direction='row' spacing={2} alignItems='center'>
						<Typography>{capitalCase(t('common:no'))}</Typography>
					</Stack>
				</MenuItem>
			</Select>
		</FormControl>
	);
};

NotificationGeneratedSelect.propTypes = {
	selectedOption: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	onChangeSelection: PropTypes.func,
	t: PropTypes.func
};

export default withTranslation(['reports', 'common'])(NotificationGeneratedSelect);
