import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export const PageTitle = (props) => {
	const { t } = props;

	return (
		<Stack direction='row' justifyContent='center' mt={2}>
			<Typography variant='h4'>{t('builderPageTitle')}</Typography>
		</Stack>
	);
};

PageTitle.propTypes = {
	t: PropTypes.func
};

export default withTranslation('representments')(PageTitle);
