// THIS WILL EVENTUALLY BE PULLED FROM THE DATABASE SO WE NEED
// THE DUPLICATE ID FOR NOW SO WE HAVE THE STRUCTURE IN PLACE
export const notificationStatuses = [
	{
		id: 'new',
		name: 'new'
	},
	{
		id: 'inProgress',
		name: 'inProgress'
	},
	{
		id: 'closed',
		name: 'closed'
	},
	{
		id: 'resolved',
		name: 'resolved'
	}
];

export const notificationStatusesNamed = {
	new: notificationStatuses[0],
	inProgress: notificationStatuses[1],
	closed: notificationStatuses[2],
	resolved: notificationStatuses[3]
};
