import * as actionTypes from './actionTypes';

export const submitSupportForm = (payload) => ({
	type: actionTypes.SUBMIT_SUPPORT_FORM,
	payload
});

export const submitSupportFormCompleted = (payload) => ({
	type: actionTypes.SUBMIT_SUPPORT_FORM_COMPLETED,
	payload
});

export const submitSupportFormFailed = (payload) => ({
	type: actionTypes.SUBMIT_SUPPORT_FORM_FAILED,
	payload
});

export const clearSupportForm = (payload) => ({
	type: actionTypes.CLEAR_SUPPORT_FORM,
	payload
});
