import React from 'react';
import PropTypes from 'prop-types';

import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const PopoutMenuNavigationItem = (props) => {
	const {
		index,
		label,
		menuItems,
		onClickOpenMenu,
		setActiveMenuItems,
		activeMenuIndex
	} = props;

	const handleOpenMenu = (event) => {
		if (setActiveMenuItems) {
			setActiveMenuItems(menuItems);
		}

		onClickOpenMenu(event.currentTarget, index);
	};

	return (
		<MenuItem
			onClick={handleOpenMenu}
			selected={activeMenuIndex === index}
		>
			<ListItemText>
				{label}
			</ListItemText>
			<KeyboardArrowRightIcon color='secondary'/>
		</MenuItem>
	);
};

PopoutMenuNavigationItem.propTypes = {
	index: PropTypes.number,
	activeMenuIndex: PropTypes.number,
	label: PropTypes.string,
	menuItems: PropTypes.array,
	onClickOpenMenu: PropTypes.func,
	setActiveMenuItems: PropTypes.func
};

export default PopoutMenuNavigationItem;
