export const GET_ORGANIZATION = 'organization/GET_ORGANIZATION';
export const GET_ORGANIZATION_COMPLETED = 'organization/GET_ORGANIZATION_COMPLETED';
export const GET_ORGANIZATION_FAILED = 'organization/GET_ORGANIZATION_FAILED';

export const UPDATE_ORGANIZATION = 'organization/UPDATE_ORGANIZATION';
export const UPDATE_ORGANIZATION_COMPLETED = 'organization/UPDATE_ORGANIZATION_COMPLETED';
export const UPDATE_ORGANIZATION_FAILED = 'organization/UPDATE_ORGANIZATION_FAILED';

export const GET_ORGANIZATION_USERS = 'organization/GET_ORGANIZATION_USERS';
export const GET_ORGANIZATION_USERS_COMPLETED = 'organization/GET_ORGANIZATION_USERS_COMPLETED';
export const GET_ORGANIZATION_USERS_FAILED = 'organization/GET_ORGANIZATION_USERS_FAILED';
