import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SummaryTableByCardType from './SummaryTableByCardType';
import LoadingTable from './LoadingTable';
import isDataEmpty from '../../../../helpers/shared/isDataEmpty';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const ReportContainer = (props) => {
	const { t, data, hasBeenLoaded, isLoading } = props;

	if (!hasBeenLoaded && !isLoading) {
		return (
			<Stack direction='row' mt={2} justifyContent='center' alignItems='center'>
				<Typography variant='body1' sx={{ color: 'secondary.alt' }}>
					{t('shared.submitToLoad')}
				</Typography>
			</Stack>
		);
	}

	if (isLoading) {
		return (
			<Stack direction='column' alignItems='center' sx={{ width: '100%' }}>
				<LoadingTable />
				<LoadingTable />
				<LoadingTable />
				<LoadingTable />
			</Stack>
		);
	}

	if (isDataEmpty(data)) {
		return (
			<Stack direction='row' mt={2} justifyContent='center'>
				<Typography variant='body1'>{t('chargebackSummary.noData')}</Typography>
			</Stack>
		);
	}

	return (
		<Stack sx={{ width: '100%' }}>
			{data.map((cardData) => {
				return (
					<SummaryTableByCardType
						key={cardData.key}
						cardType={cardData.key}
						processorId={cardData.processorId}
						lookupDate={cardData.lookupDate}
						rowData={cardData.rowData}
						rowSummary={cardData.rowSummary}
						rollups={cardData.rollupData}
					/>
				);
			})}
		</Stack>
	);
};

ReportContainer.propTypes = {
	data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	hasBeenLoaded: PropTypes.bool,
	isLoading: PropTypes.bool,
	t: PropTypes.func
};

export default withTranslation('reports')(ReportContainer);
