import axios from '../../utilities/axios';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { refreshToken } from '../../utilities/refreshToken';
import { primaryRestGateway, serviceApiEndpoint } from '../../utilities/apiEndpointUtility';

import * as actions from './actions';
import * as actionTypes from './actionTypes';

export const getOrganizationEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.GET_ORGANIZATION),
		mergeMap(async (action) => {
			await refreshToken();
			const getOrganization = await axios.get(
				`${serviceApiEndpoint()}/api/v1/organizations/organization`
			);

			return getOrganization;
		}),
		switchMap((res) => [actions.getOrganizationCompleted(res.data)]),
		catchError((error, source) =>
			merge(of(actions.getOrganizationFailed(error.message)), source)
		)
	);

export const updateOrganizationEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.UPDATE_ORGANIZATION),
		mergeMap(async (action) => {
			await refreshToken();
			const getOrganization = await axios.put(
				`${serviceApiEndpoint()}/api/v1/organizations/organization`
			);

			return getOrganization;
		}),
		switchMap((res) => [
			actions.updateOrganizationCompleted(res.data)
		]),
		catchError((error, source) =>
			merge(of(actions.updateOrganizationFailed(error.message)), source)
		)
	);

export const getOrganizationUsersEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.GET_ORGANIZATION_USERS),
		mergeMap(async (action) => {
			await refreshToken();

			const response = await axios.get(`${primaryRestGateway()}/api/users`);

			return response.data;
		}),
		switchMap((res) => [actions.getOrganizationUsersCompleted(res)]),
		catchError((error, source) =>
			merge(of(actions.getOrganizationUsersFailed(error.message)), source)
		)
	);
