import React from 'react';
import PropTypes from 'prop-types';
import ImageAttachment from './ImageAttachment';
import AttachmentViewerModal from './AttachmentViewerModal';

import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';

const _attachmentComponents = {
	image: ImageAttachment
};

const Attachments = ({ attachments }) => {
	const [attachmentsModalData, setAttachmentViewerData] = React.useState();

	const onOpenAttachmentViewerModal = (attachmentsModalData) => {
		setAttachmentViewerData(attachmentsModalData);
	};

	return (
		<Stack p={1} spacing={1}>
			<Divider flexItem width='80%' />
			<Stack
				direction='row'
				spacing={1}
				justifyContent='flex-start'
				alignItems='center'
				divider={<Divider orientation='vertical' variant='middle' />}
			>
				{attachments.map((attachment) => {
					const AttachmentComponent = _attachmentComponents[attachment.type];

					if (AttachmentComponent) {
						return (
							<AttachmentComponent
								key={attachment.id}
								attachment={attachment}
								onOpenAttachmentViewerModal={onOpenAttachmentViewerModal}
							/>
						);
					};
				})}
			</Stack>
			<AttachmentViewerModal
				attachmentsModalData={attachmentsModalData}
				onCloseModal={() => setAttachmentViewerData()}
			/>
		</Stack>
	);
};

Attachments.propTypes = {
	attachments: PropTypes.array,
	viewAttachment: PropTypes.func
};

export default Attachments;
