import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { reporterActions } from '../../../containers/Reporter';
import isDataEmpty from '../../../helpers/shared/isDataEmpty';
import findReportItemInList from '../../../helpers/reporter/findReportItemInList';
import FilterController from './FilterController';
import ReportContainer from './components/ReportContainer';

import Stack from '@mui/material/Stack';

const ChargebackSummary = (props) => {
	const {
		reportOptions,
		executeReport,
		reportDataList,
		clearReportData
	} = props;

	const location = useLocation();

	const componentWillUnmount = React.useRef(false);

	const [activeReportViewId, setActiveReportViewId] = React.useState();

	const reportViewUuid = location.pathname.split('/')[2];
	const reportViewConfiguration = findReportItemInList(
		reportOptions,
		reportViewUuid
	);
	const reportData = findReportItemInList(reportDataList, reportViewUuid);

	React.useEffect(() => {
		return () => {
			componentWillUnmount.current = true;
		};
	}, []);

	React.useEffect(() => {
		if (reportViewUuid) {
			setActiveReportViewId(reportViewUuid);
		}
	}, []);

	React.useEffect(() => {
		return () => {
			if (componentWillUnmount.current) {
				clearReportData({
					reportUniversalUniqueIdentifier: activeReportViewId
				});
			}
		};
	}, [reportViewUuid, reportViewConfiguration]);

	const onGetReportData = (lookupDate, selectedProcessor) => {
		if (isDataEmpty(reportData) || !reportData?.isLoading) {
			executeReport({
				queryParameters: {
					lookupDate,
					processorId: selectedProcessor
				},
				reportUniversalUniqueIdentifier: reportViewUuid
			});
		}
	};

	return (
		<Stack direction='column' spacing={2} mt={1} mb={2} p={2}>
			<FilterController
				onGetReportData={onGetReportData}
				isLoading={reportData?.isLoading || false}
				hasBeenLoaded={reportData?.hasBeenLoaded || false}
			/>
			<ReportContainer
				data={reportData?.data || []}
				hasBeenLoaded={reportData?.hasBeenLoaded || false}
				isLoading={reportData?.isLoading || false}
			/>
		</Stack>
	);
};

ChargebackSummary.propTypes = {
	reportOptions: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	executeReport: PropTypes.func,
	reportDataList: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	clearReportData: PropTypes.func,
	t: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		reportOptions: state.reporterReducer.get('reportOptions'),
		reportDataList: state.reporterReducer.get('reportData')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		executeReport: (payload) => {
			dispatch(reporterActions.executeReport(payload));
		},
		clearReportData: (payload) => {
			dispatch(reporterActions.clearReport(payload));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ChargebackSummary);
