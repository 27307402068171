const mapConfigurationForSubmit = (user, ruleConfiguration) => {
	return {
		userSelections: [
			{
				key: 'formula',
				fieldId: ruleConfiguration.formula.fieldId,
				optionId: ruleConfiguration.formula.optionId
			}, {
				key: 'measurement',
				fieldId: ruleConfiguration.measurement.fieldId,
				optionId: ruleConfiguration.measurement.optionId
			}, {
				key: 'paymentMethod',
				fieldId: ruleConfiguration.paymentMethod.fieldId,
				optionId: ruleConfiguration.paymentMethod.optionId
			}, {
				key: 'comparator',
				fieldId: ruleConfiguration.comparator.fieldId,
				optionId: ruleConfiguration.comparator.optionId
			}, {
				key: 'comparisonUnit',
				fieldId: ruleConfiguration.comparisonUnit.fieldId,
				optionId: ruleConfiguration.comparisonUnit.optionId,
				userInput: ruleConfiguration.userInput
			}, {
				key: 'timeFrame',
				fieldId: ruleConfiguration.timeFrame.fieldId,
				optionId: ruleConfiguration.timeFrame.optionId
			}
		],
		label: ruleConfiguration.label,
		categoryId: ruleConfiguration.category?.id,
		ruleListTypeId: ruleConfiguration.ruleListType?.id,
		groupId: ruleConfiguration.group?.id,
		processorId: ruleConfiguration.processor?.id,
		merchantIds: ruleConfiguration.merchantIds,
		affiliateIds: ruleConfiguration.affiliateIds?.map((affiliate) => {
			return affiliate.id;
		}),
		severityId: ruleConfiguration.severity?.id,
		runFrequency: ruleConfiguration.runFrequency,
		ignoreNonTriggered: ruleConfiguration.ignoreNonTriggered,
		autoAssignUserId: ruleConfiguration.autoAssignUserId?.id,
		userId: user.id
	};
};

export default mapConfigurationForSubmit;
