import React from 'react';
import { withTranslation } from 'react-i18next';
import Restricted from '../../permissionProvider/Restricted';
import RestrictedFallback from '../../components/shared/RestrictedFallback';
import PropTypes from 'prop-types';

import TableauDashboard from '../../components/TableauDashboard';

const Dashboard = (props) => {
	const { t } = props;

	return (
		<Restricted
			to={'reports.authorizationAndFraudSummary.view'}
			Fallback={<RestrictedFallback overrideMessage={t('error.notAllowed')} />}
		>
			<TableauDashboard />
		</Restricted>
	);
};

Dashboard.propTypes = {
	t: PropTypes.func
};

export default withTranslation('common')(Dashboard);
