import React from 'react';
import PropTypes from 'prop-types';

import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';

import DownloadIcon from '@mui/icons-material/Download';

const ActionRow = (props) => {
	const { downloadReport } = props;

	return (
		<Stack
			direction='row'
			justifyContent='flex-end'
			p={2}
			m={1}
			sx={{ height: 60 }}
		>
			<LoadingButton variant='outlined' onClick={downloadReport}>
				{<DownloadIcon />}
			</LoadingButton>
		</Stack>
	);
};

ActionRow.propTypes = {
	downloadReport: PropTypes.func
};

export default ActionRow;
