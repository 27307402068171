import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import RepresentmentTextField from './components/RepresentmentTextField';
import BuilderSectionHeader from '../BuilderSectionHeader';
import RepresentmentBuilderBox from '../RepresentmentBuilderStack';
import { formatDateForDisplay } from '../../../utilities/dateWrapper';

import Grid from '@mui/material/Grid';

export const RefundInformation = (props) => {
	const { t, data, saleData } = props;

	return (
		<Grid item xs={12} sm={12} md={12} lg={12}>
			<RepresentmentBuilderBox>
				<BuilderSectionHeader>{t('refundInformation.label')}</BuilderSectionHeader>
				<Grid container>
					<Grid item xs={12} sm={6} md={2} lg={2}>
						<RepresentmentTextField
							id='maskedCardNumber'
							label={t('refundInformation.maskedCardNumber')}
							defaultValue={data.maskedCardNumber}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={2} lg={2}>
						<RepresentmentTextField
							id='settledOn'
							label={t('refundInformation.settledOn')}
							defaultValue={formatDateForDisplay(data.settledOn)}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={2} lg={2}>
						<RepresentmentTextField
							id='settledAmount'
							label={t('refundInformation.settledAmount')}
							defaultValue={`${data.settledAmount}`}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={6}>
						<RepresentmentTextField
							id='refundReason'
							label={t('refundInformation.refundReason')}
							defaultValue={`${data.refundReason}`}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={3} lg={3}>
						<RepresentmentTextField
							id='merchantTransactionId'
							label={t('refundInformation.merchantTransactionId')}
							defaultValue={data.merchantTransactionId}
							disabled={!data.merchantTransactionId}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={3} lg={3}>
						<RepresentmentTextField
							id='acquirerReferenceNumber'
							minWidth={350}
							label={t('refundInformation.acquirerReferenceNumber')}
							defaultValue={data.acquirerReferenceNumber}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={3} lg={3}>
						<RepresentmentTextField
							id='saleInvoice'
							label={t('refundInformation.saleInvoice')}
							defaultValue={saleData.merchantTransactionId}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={3} lg={3}>
						<RepresentmentTextField
							id='saleArn'
							minWidth={350}
							label={t('refundInformation.saleArn')}
							defaultValue={saleData.acquirerReferenceNumber}
							disabled={!saleData.acquirerReferenceNumber}
						/>
					</Grid>
				</Grid>
			</RepresentmentBuilderBox>
		</Grid>
	);
};

RefundInformation.propTypes = {
	data: PropTypes.object,
	saleData: PropTypes.object,
	t: PropTypes.func
};

export default withTranslation('representments')(RefundInformation);
