import React from 'react';
import { Provider } from 'react-redux';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import Router from './components/Router';
import store from './store';
import Theme from './components/Theme';
import './i18n';
import PermissionProvider from './permissionProvider/PermissionProvider';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

dayjs.extend(utc);

const App = () => {
	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<Provider store={store}>
				<Theme>
					<PermissionProvider>
						<Router />
					</PermissionProvider>
				</Theme>
			</Provider>
		</LocalizationProvider>
	);
};

export default App;
