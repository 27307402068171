import { createTheme } from '@mui/material/styles';
import { creditCardColorCodes } from './colorCodes/creditCards';

const branding = {
	main1: '#51284F',
	main2: '#005776',
	secondary2: '#C1C6C8',
	secondary1: '#63666A'
};

const _brandingMain1Shades = [
	'#30182f', // Dark - 0
	'#381c37',
	'#40203f',
	'#482447',
	'#51284f', // Original - 5
	'#623d60',
	'#735272',
	'#856883',
	'#967e95' // Light - 8
];

const error = '#cf1c24';
const warning = '#f4bb00';
const success = '#007f4b';
const white = '#ffffff';
const black = '#000000';

const my360Branding = '#005677';

const lightTheme = {
	branding: {
		backgroundColor: '#e0e3e5',
		my360: my360Branding
	},
	panel: {
		height: '3.25rem',
		width: '230px'
	},
	sideNavigation: {
		backgroundColor: '#f5f6f7'
	},
	header: {
		height: '3.50rem',
		backgroundColor: '#e0e3e5'
	},
	typography: {
		fontFamily: ['Montserrat', 'Ubuntu', 'Karla'].join(',')
	},
	palette: {
		type: 'light',
		background: {
			default: '#ffffff',
			disabled: '#dedede'
		},
		action: {
			activatedOpacity: '0.2'
		},
		common: {
			black,
			white,
			offWhite: '#f5f6f7'
		},
		currentInventory: {
			cardOverview: {
				cardGradientDark: '#c1c6c8',
				cardGradientLight: '#d3d5d6',
				visibilityToggleActive: '#7f8388',
				visibilityToggleInactive: '#7f8388',
				visibilityBorder: '#ffffff'
			},
			listGroup: {
				border: '#c1c6c8',
				headerTextColor: '#676a6e',
				cellTextColor: '#63666A',
				highlightedCell: branding.main1,
				warningActive: '#B79906',
				sentToProcessorActive: '#74B72E',
				buildIcon: '#676a6e'
			}
		},
		dashboard: {
			dashboardControl: {
				switchLayoutArrow: _brandingMain1Shades[3],
				switchLayoutArrowHover: _brandingMain1Shades[5],
				switchLayoutDisabled: branding.secondary2
			},
			tiles: {
				baseTile: {
					settingsIcon: branding.secondary1
				},
				chargebackKpi: {
					infoIcon: branding.secondary2
				},
				chartColors: {
					color1: '#42546b',
					color2: '#483939',
					color3: '#3c71a2',
					color4: '#795954',
					color5: '#626d83',
					color6: '#547959'
				}
			}
		},
		error: {
			main: error
			// light: '#df7f7f'
		},
		icons: {
			default: branding.secondary1,
			selected: branding.main1,
			disabled: branding.secondary2
		},
		insights: {
			tableHeaderBorder: branding.secondary2,
			triggerCell: '#bf8b85'
		},
		layout: {
			header: {
				background: '#e0e3e5'
			}
		},
		navigation: {
			background: '#E0E2E3',
			color: '#63666a',
			active: _brandingMain1Shades[2],
			shadow: '#a9a9a9'
		},
		navigationActionButton: {
			background: '#c1c6c8'
		},
		notifications: {
			statuses: {
				newBackground: _brandingMain1Shades[2],
				newText: '#ffffff',
				inProgressBackground: branding.main2,
				inProgressText: '#ffffff',
				closedBackground: branding.secondary1,
				closedText: '#ffffff',
				resolvedBackground: '#40826d',
				resolvedText: '#ffffff'
			},
			icons: {
				alert: '#824055',
				assigned: '#75bab8',
				blocked: '#4e2633',
				escalation: '#f7bf46',
				resolved: '#40826d',
				information: '#49a6b9',
				default: '#bdbdbd',
				divider: '#d0d0d0'
			},
			tagFilter: {
				addLabelBorder: '#a6a6a6'
			},
			categoryBackground: _brandingMain1Shades[8],
			categoryText: '#f2f2f2',
			assignedIcon: '#a6a6a6'
		},
		primary: {
			light: _brandingMain1Shades[6],
			main: branding.main1,
			alt: branding.main2,
			dark: _brandingMain1Shades[3]
		},
		secondary: {
			main: branding.secondary1,
			alt: branding.secondary2
		},
		success: {
			main: success
		},
		warning: {
			main: warning
		},
		information: {
			main: '#045eda'
		},
		creditCards: {
			...creditCardColorCodes
		},
		modals: {
			MultiFactorAuthSetupModal: {
				summaryText: _brandingMain1Shades[3],
				secretKeyBackground: _brandingMain1Shades[8],
				secretKey: '#ffffff'
			},
			UserPermissionsModal: {
				sectionHeaderBackground: _brandingMain1Shades[7]
			}
		},
		registration: {
			uploadAvatarButton: {
				text: '#ffffff',
				background: _brandingMain1Shades[8],
				hoverBackground: _brandingMain1Shades[7]
			},
			clearAvatarIcon: {
				base: _brandingMain1Shades[8],
				hover: _brandingMain1Shades[7]
			}
		},
		reports: {
			filters: {
				merchantSelectHighlight: _brandingMain1Shades[8]
			}
		},
		representment: {
			sectionContainer: {
				sectionTitleBackground: '#ffffff',
				sectionText: branding.main2,
				border: '#c6c6c6',
				background: '#ffffff'
			},
			sectionHeader: {
				color: branding.main2
			},
			inputField: {
				background: '#dcdcdc',
				outlineHover: branding.main2
			},
			reversalReasons: {
				addButton: branding.main1,
				addButtonHover: _brandingMain1Shades[6],
				moveIcon: branding.secondary2,
				moveIconHover: branding.main1,
				moveIconDisabled: '#c6c6c6',
				removeIcon: branding.secondary2,
				removeIconHover: '#BF0000'
			}
		},
		representments: {
			page: {
				background: '#e0e2e3'
			},
			statusSelector: {
				tabBorder: branding.secondary2,
				tabBorderSelected: branding.main2,
				backgroundSelected: branding.main2,
				backgroundHover: '#196783',
				color: branding.secondary2,
				activeColor: '#ffffff'
			},
			buttons: {
				color: '#ffffff',
				hover: branding.main1
			},
			header: {
				color: branding.main2,
				border: branding.secondary2,
				background: branding.main1
			},
			text: {
				caseNumber: branding.main2
			},
			rowItem: {
				border: '#f4f0ec'
			},
			icons: {
				inactive: '#f4f0ec',
				active: branding.main1
			}
		},
		ruleManagement: {
			variableText: _brandingMain1Shades[8],
			nonSelectableVariableText: branding.main2
		},
		screenshotter: {
			background:
				'linear-gradient(135deg, #3B444D 25%, transparent 25%), linear-gradient(225deg, #3B444D 25%, transparent 25%), linear-gradient(45deg, #3B444D 25%, transparent 25%), linear-gradient(315deg, #3B444D 25%, #1E252B 25%)',
			buttons: '#4c6175',
			dialogBackground: '#1E2934',
			inputs: '#d2d3d4',
			main: '#425465',
			mainAlt1: '#2D363F',
			mainAlt2: '#1E252B',
			subMenu: '#233342'
		},
		tables: {
			alternateRowColor: '#f5f5f5'
		},
		breakpoints: {
			values: {
				xs: 0,
				sm: 600,
				md: 900,
				lg: 1300,
				xl: 1536
			}
		}
	}
};

export default createTheme(lightTheme);
