import amexCard from '../../assets/cardBrandImages/amex.svg';
import amexLogo from '../../assets/cardBrandImages/amex_noCard.svg';
import discoverCard from '../../assets/cardBrandImages/discover.svg';
import discoverLogo from '../../assets/cardBrandImages/discover_noCard.svg';
import mastercardCard from '../../assets/cardBrandImages/mastercard.svg';
import mastercardLogo from '../../assets/cardBrandImages/mastercard_noCard.svg';
import visaCard from '../../assets/cardBrandImages/visa.svg';
import visaLogo from '../../assets/cardBrandImages/visa_noCard.svg';

import cardBrands from '../../constants/cardBrands';

const _cardBrands = [
	{
		keys: ['A', 'Amex', 'amex', 'uo_amex', 'a'],
		label: 'Amex',
		lowercase: 'amex',
		abbreviation: 'A',
		logo: amexLogo,
		fullCard: amexCard
	},
	{
		keys: ['D', 'Discover', 'discover', 'uo_discover', 'd'],
		label: 'Discover',
		lowercase: 'discover',
		abbreviation: 'D',
		logo: discoverLogo,
		fullCard: discoverCard
	},
	{
		keys: ['M', 'Mastercard', 'mastercard', 'uo_mastercard', 'm'],
		label: 'Mastercard',
		lowercase: 'mastercard',
		abbreviation: 'M',
		logo: mastercardLogo,
		fullCard: mastercardCard
	},
	{
		keys: ['V', 'Visa', 'visa', 'uo_visa', 'v'],
		label: 'Visa',
		lowercase: 'visa',
		abbreviation: 'V',
		logo: visaLogo,
		fullCard: visaCard
	}
];

export const getCardList = () => {
	return cardBrands;
};

export const getLogoByBrandName = (cardBrand) => {
	const cardAssets = _cardBrands.find((card) => {
		return card.keys.includes(cardBrand);
	});

	if (cardAssets) {
		return cardAssets.logo;
	}
};

export const getFullCardByBrandName = (cardBrand) => {
	const cardAssets = _cardBrands.find((card) => {
		return card.keys.includes(cardBrand);
	});

	if (cardAssets) {
		return cardAssets.fullCard;
	}
};

export const getLabel = (cardType) => {
	const cardAssets = _cardBrands.find((card) => {
		return card.keys.includes(cardType);
	});

	if (cardAssets) {
		return cardAssets.label;
	}
};

export const getLowercase = (cardType) => {
	const cardAssets = _cardBrands.find((card) => {
		return card.keys.includes(cardType);
	});

	if (cardAssets) {
		return cardAssets.lowercase;
	}
};

export const getAbbreviation = (cardBrand) => {
	const cardAssets = _cardBrands.find((card) => {
		return card.keys.includes(cardBrand);
	});

	if (cardAssets) {
		return cardAssets.abbreviation;
	}
};
