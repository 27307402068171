import * as actionTypes from './actionTypes';

export const generateToken = (payload) => ({
	type: actionTypes.GENERATE_TOKEN,
	payload
});

export const generateTokenCompleted = (payload) => ({
	type: actionTypes.GENERATE_TOKEN_COMPLETED,
	payload
});

export const generateTokenFailed = (payload) => ({
	type: actionTypes.GENERATE_TOKEN_FAILED,
	payload
});

export const resetToken = (payload) => ({
	type: actionTypes.RESET_TOKEN,
	payload
});
