export const getLocalStorage = (localStorageKey) => {
	// eslint-disable-next-line
			const value = localStorage.getItem(localStorageKey);
	return JSON.parse(value);
};

export const clearLocalStorage = () => {
	// eslint-disable-next-line
	localStorage.clear();
};
