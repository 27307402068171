import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import TwelveMonthChargebackComplianceTable from './Table';
import LoadingTable from './Table/LoadingTable';
import isDataEmpty from '../../../helpers/shared/isDataEmpty';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const ReportContainer = (props) => {
	const { t, data, hasBeenLoaded, isLoading } = props;

	if (!hasBeenLoaded && !isLoading) {
		return (
			<Stack direction='row' mt={2} justifyContent='center' alignItems='center'>
				<Typography variant='body1' sx={{ color: 'secondary.alt' }}>
					{t('shared.submitToLoad')}
				</Typography>
			</Stack>
		);
	}

	if (isLoading) {
		return (
			<Paper>
				<Stack
					direction='column'
					alignItems='center'
					sx={{ width: '100%' }}
					p={1}
					spacing={2}
				>
					<LoadingTable />
				</Stack>
			</Paper>
		);
	}

	if (isDataEmpty(data)) {
		return (
			<Stack direction='row' mt={2} justifyContent='center'>
				<Typography variant='body1'>{t('chargebackSummary.noData')}</Typography>
			</Stack>
		);
	}

	return (
		<Box mb={2}>
			<Stack direction='column' p={1} spacing={2}>
				{data.results.map((cardBrandData, index) => (
					<TwelveMonthChargebackComplianceTable
						key={cardBrandData.cardBrand}
						cardBrand={cardBrandData.cardBrand}
						tableIndex={index}
						data={cardBrandData.results}
					/>
				))}
			</Stack>
			<Stack direction='column' p={2} spacing={1}>
				<Typography variant='caption'>
					{t('twelveMonthChargebackCompliance.informationOne')}
				</Typography>
				<Typography variant='caption'>
					{t('twelveMonthChargebackCompliance.informationTwo')}
				</Typography>
			</Stack>
		</Box>
	);
};

ReportContainer.propTypes = {
	data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	hasBeenLoaded: PropTypes.bool,
	isLoading: PropTypes.bool,
	t: PropTypes.func
};

export default withTranslation('reports')(ReportContainer);
