import * as actionTypes from './actionTypes';

export const getReportDetails = (payload) => ({
	type: actionTypes.GET_REPORT_DETAILS,
	payload
});

export const getReportDetailsCompleted = (payload) => ({
	type: actionTypes.GET_REPORT_DETAILS_COMPLETED,
	payload
});

export const getReportDetailsFailed = (payload) => ({
	type: actionTypes.GET_REPORT_DETAILS_FAILED,
	payload
});

export const executeReport = (payload) => ({
	type: actionTypes.EXECUTE_REPORT,
	payload
});

export const executeReportCompleted = (payload) => ({
	type: actionTypes.EXECUTE_REPORT_COMPLETED,
	payload
});

export const executeReportFailed = (payload) => ({
	type: actionTypes.EXECUTE_REPORT_FAILED,
	payload
});

export const clearReportDetails = (payload) => ({
	type: actionTypes.CLEAR_REPORT_DETAILS,
	payload
});

export const clearReport = (payload) => ({
	type: actionTypes.CLEAR_REPORT,
	payload
});
