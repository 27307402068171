import React from 'react';
import PropTypes from 'prop-types';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const ReportHeader = (props) => {
	const { label, description } = props;

	return (
		<Stack direction='column' justifyContent='flex-start'>
			<Typography variant='h5'>{label}</Typography>
			{description && <Typography variant='caption'>{description}</Typography>}
		</Stack>
	);
};

ReportHeader.propTypes = {
	label: PropTypes.string,
	description: PropTypes.string
};

export default ReportHeader;
