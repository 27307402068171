export const GET_REPORT_DETAILS = 'reporter/GET_REPORT_DETAILS';
export const GET_REPORT_DETAILS_COMPLETED = 'reporter/GET_REPORT_DETAILS_COMPLETED';
export const GET_REPORT_DETAILS_FAILED = 'reporter/GET_REPORT_DETAILS_FAILED';

export const EXECUTE_REPORT = 'reporter/EXECUTE_REPORT';
export const EXECUTE_REPORT_COMPLETED = 'reporter/EXECUTE_REPORT_COMPLETED';
export const EXECUTE_REPORT_FAILED = 'reporter/EXECUTE_REPORT_FAILED';

export const CLEAR_REPORT_DETAILS = 'reporter/CLEAR_REPORT_DETAILS';
export const CLEAR_REPORT = 'reporter/CLEAR_REPORT';
