export default [
	{
		value: 'bank',
		label: 'Bank'
	},
	{
		value: 'creditDebitCode',
		label: 'Debit/Credit'
	}
];

// REQUIRES BACKEND SUPPORT FIRST
/*
{
	value: 'contract',
	label: 'Contract'
}
*/
