import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import Stack from '@mui/material/Stack';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import ErrorIcon from '@mui/icons-material/Error';

const TableErrorRow = (props) => {
	const { columnSpan, errorText, t } = props;

	return (
		<TableRow>
			<TableCell colSpan={columnSpan}>
				<Stack alignItems='center'>
					<ErrorIcon color='error' />
					<Typography color='secondary'>{errorText || t('common:somethingWentWrong')}</Typography>
				</Stack>
			</TableCell>
		</TableRow>
	);
};

TableErrorRow.propTypes = {
	columnSpan: PropTypes.number,
	errorText: PropTypes.string,
	t: PropTypes.func
};

export default withTranslation()(TableErrorRow);
