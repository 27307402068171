import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

import Button from '@mui/material/Button';

const OutlineButton = styled(Button)(({ theme }) => ({
	color: theme.palette.primary.main
}));

const OutlinedButton = ({ children, startIcon, disabled, onClick, size }) => {
	return (
		<OutlineButton
			disabled={disabled}
			onClick={onClick}
			variant='outlined'
			startIcon={startIcon}
			size={size || 'medium'}
			fullWidth
		>
			{children}
		</OutlineButton>
	);
};

OutlinedButton.propTypes = {
	children: PropTypes.node,
	disabled: PropTypes.bool,
	onClick: PropTypes.func,
	startIcon: PropTypes.element,
	size: PropTypes.string
};

export default OutlinedButton;
