import React from 'react';

import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const LoadingTable = () => {
	return (
		<Stack direction='column' sx={{ display: 'flex', width: '100%' }}>
			<Stack direction='row' spacing={1} sx={{ flex: 1 }}>
				<Skeleton width={125} height={50} />
				<Skeleton width={'100%'} height={50} p={3} />
			</Stack>
			<Stack direction='row' spacing={1} sx={{ flex: 1 }}>
				<Skeleton width={125} height={50} />
				<Skeleton width={'100%'} height={50} p={3} />
			</Stack>
		</Stack>
	);
};

export default LoadingTable;
