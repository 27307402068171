import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	builderCategories: {
		data: [],
		isLoading: false,
		hasBeenLoaded: false,
		error: undefined
	},
	builderOptions: {
		data: [],
		isLoading: false,
		hasBeenLoaded: false,
		error: undefined
	},
	ruleConfiguration: {
		data: {},
		isLoading: false,
		hasBeenLoaded: false,
		error: undefined
	}
};

export const ruleBuilderSlice = createSlice({
	name: 'ruleBuilder',
	initialState,
	reducers: {
		getRuleBuilderCategories: (state) => {
			state.builderCategories.isLoading = true;
			state.builderCategories.error = undefined;
		},
		getRuleBuilderCategoriesCompleted: (state, action) => {
			state.builderCategories.isLoading = false;
			state.builderCategories.hasBeenLoaded = true;
			state.builderCategories.data = action.payload;
		},
		getRuleBuilderCategoriesFailed: (state, action) => {
			state.builderCategories.isLoading = true;
			state.builderCategories.error = action.payload;
		},
		getRuleBuilderOptions: (state) => {
			state.builderOptions.isLoading = true;
			state.builderOptions.error = undefined;
		},
		getRuleBuilderOptionsCompleted: (state, action) => {
			state.builderOptions.isLoading = false;
			state.builderOptions.hasBeenLoaded = true;
			state.builderOptions.data = action.payload;
		},
		getRuleBuilderOptionsFailed: (state, action) => {
			state.builderOptions.isLoading = false;
			state.builderOptions.error = action.payload;
		}
	}
});

export const {
	getRuleBuilderCategories,
	getRuleBuilderCategoriesCompleted,
	getRuleBuilderCategoriesFailed,
	getRuleBuilderOptions,
	getRuleBuilderOptionsCompleted,
	getRuleBuilderOptionsFailed
} = ruleBuilderSlice.actions;

export default ruleBuilderSlice.reducer;
