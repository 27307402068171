import axios from '../../utilities/axios';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { refreshToken } from '../../utilities/refreshToken';
import { primaryRestGateway } from '../../utilities/apiEndpointUtility';

import * as actions from './actions';
import * as actionTypes from './actionTypes';

export const getInsightReportEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.GET_INSIGHT_REPORT),
		mergeMap(async (action) => {
			await refreshToken();

			const { reportId, queryParameters } = action.payload;

			const response = await axios.get(
				`${primaryRestGateway()}/api/insights/${reportId}${queryParameters}`
			);

			return response.data;
		}),
		switchMap((res) => [actions.getInsightReportCompleted(res)]),
		catchError((error, source) =>
			merge(of(actions.getInsightReportFailed(error.message)), source)
		)
	);
