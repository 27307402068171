import { numberWithCommas } from '../../utilities/formatNumber';

const zeroValues = [0, '0.00'];
const requiresPercentage = ['chargebackPercent', 'deltaPercent'];
const requiresNegative = [
	'deltaInteger',
	'chargebackUnitsOver',
	'chargebackUnitsOverRounded',
	'transactionsUnderOver',
	'transactionsOverRounded'
];

const handleZero = (value) => {
	if (zeroValues.includes(value)) {
		return '-';
	} else {
		return numberWithCommas(value);
	}
};

const handleNegativeValue = (value) => {
	let parsedValue = parseInt(value);

	if (parsedValue < 0) {
		parsedValue = `(${numberWithCommas(Math.abs(parsedValue))})`;
	} else {
		parsedValue = numberWithCommas(parsedValue);
	}

	return parsedValue;
};

const handlePercentageValue = (value) => {
	if (value == null) {
		return null;
	}

	if (value !== '-') {
		return `${value}%`;
	} else {
		return value;
	}
};

const formatMonthlySummaryByMidCell = (rowKey, cellKey, value) => {
	if (value === null || value === undefined || isNaN(value)) {
		return null;
	}

	let formattedValue = value;

	if (requiresNegative.includes(rowKey) || requiresNegative.includes(cellKey)) {
		formattedValue = handleNegativeValue(value);
	}

	if (
		requiresPercentage.includes(rowKey) ||
		requiresPercentage.includes(cellKey)
	) {
		formattedValue = handlePercentageValue(value);
	}

	if (zeroValues.includes(formattedValue)) {
		formattedValue = '-';
	}

	return numberWithCommas(formattedValue);
};

export {
	handleZero,
	handleNegativeValue,
	handlePercentageValue,
	formatMonthlySummaryByMidCell
};
