import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { StaticReportInputLabel } from '../../../ReportStylings';
import cardBrands from '../constants/cardBrands';

import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';

const CardBrandSelect = (props) => {
	const { onChange, value, t, singleSelect } = props;

	return (
		<FormControl sx={{ flex: 1 }}>
			<StaticReportInputLabel id={'card-brand-select-label'}>
				{t('filterController.cardBrand')}
			</StaticReportInputLabel>
			<Select
				multiple={!singleSelect}
				value={value}
				onChange={(e) => {
					onChange(e.target.value);
				}}
			>
				{cardBrands.map((cardBrand) => {
					return (
						<MenuItem key={cardBrand.value} value={cardBrand.value}>
							{cardBrand.label}
						</MenuItem>
					);
				})}
			</Select>
		</FormControl>
	);
};

CardBrandSelect.propTypes = {
	t: PropTypes.func,
	onChange: PropTypes.func,
	singleSelect: PropTypes.bool,
	value: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
};

export default withTranslation('reports')(CardBrandSelect);
