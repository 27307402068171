import axios from '../../utilities/axios';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { refreshToken } from '../../utilities/refreshToken';

import { getRulesRunnerEndpoint } from '../../utilities/apiEndpointUtility';

import {
	runTrackedUserRule,
	runTrackedRuleCompleted,
	runTrackedRuleFailed
} from './rulesRunnerTrackedSlice';

export const runTrackedUserRuleEpic = (action$) =>
	action$.pipe(
		ofType(runTrackedUserRule),
		mergeMap(async (action) => {
			await refreshToken();

			const { userRuleId, ruleRunToken, ruleRunTrackingId } = action.payload;

			await axios.post(
				`${getRulesRunnerEndpoint()}/run/single/${userRuleId}`, {
					ruleRunTrackingId
				},
				{
					headers: {
						Authorization: `Bearer ${ruleRunToken}`
					}
				}
			);

			return { userRuleId, ruleRunTrackingId };
		}),
		switchMap((res) => [
			runTrackedRuleCompleted(res)
		]),
		catchError((error, source) =>
			merge(of(runTrackedRuleFailed(error)), source)
		)
	);

export default [
	runTrackedUserRuleEpic
];
