import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import OutlinedButton from '../../buttons/OutlinedButton';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

const ActionRow = ({ t, isLoading, actionSuccess }) => {
	const navigate = useNavigate();

	const onClickGoToRules = () => {
		navigate('/rules');
	};

	return (
		<Stack
			direction='row'
			justifyContent='flex-start'
			p={0.5}
			sx={{
				maxWidth: '900px',
				width: '100%',
				alignSelf: 'center'
			}}
		>
			<Box maxWidth={200}>
				<OutlinedButton
					disabled={isLoading}
					onClick={onClickGoToRules}
				>
					{actionSuccess ? t('rules:pageTitle') : t('common:buttons.cancel')}
				</OutlinedButton>
			</Box>
		</Stack>
	);
};

ActionRow.propTypes = {
	actionSuccess: PropTypes.bool,
	isLoading: PropTypes.bool,
	t: PropTypes.func
};

export default withTranslation(['common', 'rules'])(ActionRow);
