import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import download from 'downloadjs';
import LoadingTimeline from './LoadingTimeline';
import DocumentDisplay from './DocumentDisplay';
import { representmentActions } from '../../containers/Representment';
import { servicePdfActions } from '../../containers/ServicePdf';
import { getCurrentTimestamp, formatDateForDisplay } from '../../utilities/dateWrapper';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';

export const RepresentmentViewer = (props) => {
	const {
		t,
		buildRepresentment,
		documentDetails,
		documentDetailsAreLoading,
		documentDetailsHaveBeenLoaded,
		documentError,
		pdfData,
		pdfIsLoading,
		pdfHasBeenLoaded,
		getPdfDocument,
		sentToProcessor,
		clearDocumentDetails,
		clearPdfData,
		markSentToProcessor
	} = props;

	const { chargebackId } = useParams();
	const location = useLocation();
	const navigate = useNavigate();

	const [activePdfData, setActivePdfData] = React.useState();
	const [numPages, setNumPages] = React.useState(null);
	const [documentLoadError, setDocumentLoadError] = React.useState();

	React.useEffect(() => {
		buildRepresentment({
			chargebackId
		});

		return () => {
			setActivePdfData();
			clearDocumentDetails();
			clearPdfData();
		};
	}, [chargebackId]);

	React.useEffect(() => {
		if (documentDetails?.documentUrl) {
			getPdfDocument({
				documentUrl: documentDetails.documentUrl
			});
		}
	}, [documentDetails]);

	React.useEffect(() => {
		if (pdfData !== '' && activePdfData !== pdfData) {
			setActivePdfData(pdfData);
		}
	}, [pdfData]);

	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages);
	}

	function onDocumentLoadError(error) {
		setDocumentLoadError(error);
	};

	const onDownload = () => {
		const fileName = documentDetails.documentUrl.split('/')[2];

		download(pdfData, fileName, 'application/pdf');
	};

	const onSentToProcessor = React.useCallback(() => {
		const representmentUpdate = {
			sentToProcessorOn: getCurrentTimestamp()
		};

		markSentToProcessor({
			chargebackId,
			representmentUpdate
		});
	}, [markSentToProcessor, chargebackId]);

	if (activePdfData) {
		return (
			<Stack direction='row' py={1}>
				<Stack sx={{ width: '200px', position: 'fixed', zIndex: 55 }} spacing={2} justifyContent='flex-start'>
					<Button
						variant='outlined'
						disabled={
							!!(documentDetails.sentToProcessorOn || sentToProcessor)
						}
						onClick={() => {
							navigate(
								`/representments/build/${location.pathname.split('/')[3]}`
							);
						}}
					>
						{t('representments:builder')}
					</Button>
					<LoadingButton
						variant='outlined'
						loading={documentDetailsAreLoading}
						disabled={
							!!(documentDetails.sentToProcessorOn || sentToProcessor)
						}
						onClick={onSentToProcessor}
					>
						{documentDetails.sentToProcessorOn || sentToProcessor
							? `${t('sentToProcessor')} ${formatDateForDisplay(
								documentDetails.sentToProcessorOn || sentToProcessor
							)}` : t('sendToProcessor')}
					</LoadingButton>
					<Button variant='outlined' onClick={onDownload}>
						{t('common:buttons.download')}
					</Button>
				</Stack>
				<DocumentDisplay
					numberOfPages={numPages}
					pdfData={activePdfData}
					onDocumentLoadSuccess={onDocumentLoadSuccess}
					onDocumentLoadError={onDocumentLoadError}
				/>
			</Stack>
		);
	}

	return (
		<LoadingTimeline
			documentError={documentError || documentLoadError}
			documentDetailsHaveBeenLoaded={documentDetailsHaveBeenLoaded}
			documentDetailsAreLoading={documentDetailsAreLoading}
			pdfIsLoading={pdfIsLoading}
			pdfHasBeenLoaded={pdfHasBeenLoaded}
		/>
	);
};

RepresentmentViewer.propTypes = {
	documentDetails: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	documentDetailsAreLoading: PropTypes.bool,
	documentDetailsHaveBeenLoaded: PropTypes.bool,
	documentError: PropTypes.string,
	pdfData: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	pdfIsLoading: PropTypes.bool,
	pdfHasBeenLoaded: PropTypes.bool,
	buildRepresentment: PropTypes.func,
	clearDocumentDetails: PropTypes.func,
	clearPdfData: PropTypes.func,
	getPdfDocument: PropTypes.func,
	markSentToProcessor: PropTypes.func,
	sentToProcessor: PropTypes.string,
	t: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		documentDetails: state.representmentReducer.getIn([
			'representmentDocument',
			'data'
		]),
		documentDetailsAreLoading: state.representmentReducer.getIn([
			'representmentDocument',
			'isLoading'
		]),
		documentDetailsHaveBeenLoaded: state.representmentReducer.getIn([
			'representmentDocument',
			'hasBeenLoaded'
		]),
		documentError: state.representmentReducer.getIn([
			'representmentDocument',
			'error'
		]),
		pdfData: state.servicePdfReducer.get('data'),
		pdfIsLoading: state.servicePdfReducer.get('isLoading'),
		pdfHasBeenLoaded: state.servicePdfReducer.get('hasBeenLoaded'),
		sentToProcessor: state.representmentReducer.get('sentToProcessor')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		buildRepresentment: (payload) => {
			dispatch(representmentActions.buildRepresentment(payload));
		},
		clearDocumentDetails: () => {
			dispatch(representmentActions.clearRepresentment());
		},
		clearPdfData: () => {
			dispatch(servicePdfActions.clearPdf());
		},
		getPdfDocument: (payload) => {
			dispatch(servicePdfActions.getPdf(payload));
		},
		markSentToProcessor: (payload) => {
			dispatch(representmentActions.markSentToProcessor(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation(['representments', 'common'])(RepresentmentViewer));
