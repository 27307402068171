import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	isGroupsExpanded: true,
	isRulesExpanded: true,
	isReportsExpanded: true,
	isRepresentmentsExpanded: true
};

export const sideNavigationSlice = createSlice({
	name: 'sideNavigation',
	initialState,
	reducers: {
		toggleGroupsExpansion: (state) => {
			state.isGroupsExpanded = !state.isGroupsExpanded;
		},
		toggleRulesExpansion: (state) => {
			state.isRulesExpanded = !state.isRulesExpanded;
		},
		toggleReportsExpansion: (state) => {
			state.isReportsExpanded = !state.isReportsExpanded;
		},
		toggleRepresentmentsExpansion: (state) => {
			state.isRepresentmentsExpanded = !state.isRepresentmentsExpanded;
		}
	}
});

export const {
	toggleReportsExpansion,
	toggleRepresentmentsExpansion,
	toggleGroupsExpansion,
	toggleRulesExpansion
} = sideNavigationSlice.actions;

export default sideNavigationSlice.reducer;
