import React from 'react';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import FlagIcon from '@mui/icons-material/Flag';
import InfoIcon from '@mui/icons-material/Info';

const TimelineIcon = (type, color) => {
	switch (type) {
		case 'notification':
			return (
				<FlagIcon
					sx={(theme) => ({ color: theme.palette[color].light })}
				/>
			);
		case 'alert':
			return (
				<ErrorOutlineIcon
					sx={(theme) => ({ color: theme.palette.notifications.icons.alert })}
				/>
			);
		case 'blocked':
			return (
				<NotInterestedIcon
					sx={(theme) => ({ color: theme.palette.notifications.icons.blocked })}
				/>
			);
		case 'escalation':
			return (
				<TrendingUpIcon
					sx={(theme) => ({
						color: theme.palette.notifications.icons.escalation
					})}
				/>
			);
		case 'resolved':
			return (
				<CheckCircleOutlineIcon
					sx={(theme) => ({
						color: theme.palette.notifications.icons.resolved
					})}
				/>
			);
		case 'information':
			return (
				<InfoIcon
					sx={(theme) => ({ color: theme.palette.notifications.icons.information })}
				/>
			);
		default:
			return (
				<TripOriginIcon
					sx={(theme) => ({ color: theme.palette.notifications.icons.default })}
				/>
			);
	}
};

export default TimelineIcon;
