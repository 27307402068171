import axios from '../../utilities/axios';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { refreshToken } from '../../utilities/refreshToken';
import { primaryRestGateway } from '../../utilities/apiEndpointUtility';

import {
	getUsers,
	getUsersCompleted,
	getUsersFailed
} from './usersSlice';

export const getUsersEpic = (action$) =>
	action$.pipe(
		ofType(getUsers),
		mergeMap(async (action) => {
			await refreshToken();

			const response = await axios.get(`${primaryRestGateway()}/api/users`);

			return response?.data;
		}),
		switchMap((res) => [getUsersCompleted(res)]),
		catchError((error, source) =>
			merge(of(getUsersFailed(error.message)), source)
		)
	);

export default [
	getUsersEpic
];
