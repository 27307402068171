import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import deepCopy from '../../../../utilities/deepCopy';
import querier from '../../../../utilities/querier';
import StageAccordion from '../StageAccordion';
import { LabelInput } from '../../shared/inputs';

import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';

export const RulePropertiesSelect = (props) => {
	const {
		t,
		completedLabel,
		isStageCompleted,
		isPreviousStageCompleted,
		ruleConfiguration,
		setRuleConfiguration,
		expandOverride,
		isStageDisabled,
		submitErrors,
		setSubmitErrors
	} = props;

	const [isStageExpanded, setIsStageExpanded] = React.useState(
		expandOverride || true
	);

	React.useEffect(() => {
		if (isPreviousStageCompleted && !isStageCompleted && !isStageExpanded) {
			setIsStageExpanded(true);
		} else if (!isPreviousStageCompleted) {
			setIsStageExpanded(false);
		}
	}, [isPreviousStageCompleted, isStageCompleted]);

	const onUpdateSettingSelections = (property, value) => {
		const updatedConfiguration = deepCopy(ruleConfiguration);

		updatedConfiguration[property] = value;

		if (submitErrors) {
			const updatedSubmitErrors = submitErrors.filter((error) => {
				return error.type !== property;
			});

			setSubmitErrors(updatedSubmitErrors);
		}

		setRuleConfiguration(updatedConfiguration);
	};

	return (
		<Stack direction='row' justifyContent='center'>
			<StageAccordion
				stageLabel={t('ruleProperties.ruleProperties')}
				collapsedSummaryText={completedLabel}
				isAccordionExpanded={true}
				setisAccordionExpanded={setIsStageExpanded}
				isCompletedFlag={isStageCompleted}
				isDisabled={false}
				hasError={querier(submitErrors, '$[type = "label"]')}
			>
				<Stack direction='column' spacing={2} divider={<Divider />}>
					<LabelInput
						onChange={onUpdateSettingSelections}
						translationPath={'ruleProperties.label'}
						variable={'label'}
						value={ruleConfiguration.label}
						maxLength={250}
						isDisabled={isStageDisabled}
					/>
				</Stack>
			</StageAccordion>
		</Stack>
	);
};

RulePropertiesSelect.propTypes = {
	t: PropTypes.func,
	completedLabel: PropTypes.string,
	isStageCompleted: PropTypes.bool,
	isPreviousStageCompleted: PropTypes.bool,
	ruleConfiguration: PropTypes.object,
	setRuleConfiguration: PropTypes.func,
	expandOverride: PropTypes.bool,
	isStageDisabled: PropTypes.bool,
	submitErrors: PropTypes.array,
	setSubmitErrors: PropTypes.func
};

export default withTranslation('rules')(RulePropertiesSelect);
