import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { capitalCase } from 'change-case';
import BuilderSectionHeader from '../BuilderSectionHeader';
import RepresentmentBuilderBox from '../RepresentmentBuilderStack';

import RepresentmentTextField from './components/RepresentmentTextField';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

export const CustomerContact = (props) => {
	const { t, data } = props;

	return (
		<Grid item xs={12} sm={12} md={12} lg={12}>
			<RepresentmentBuilderBox>
				<BuilderSectionHeader>{t('customerContact.label')}</BuilderSectionHeader>
				<Stack direction='row'>
					<RepresentmentTextField
						id='contact'
						label={t('customerContact.contact')}
						defaultValue={capitalCase(data.contact)}
					/>
				</Stack>
				<Stack direction='row'>
					<RepresentmentTextField
						id='cancelRequestedOn'
						label={t('customerContact.cancelRequestedOn')}
						defaultValue={data.cancelRequestedOn}
					/>
					<RepresentmentTextField
						isFlexed={true}
						id='refundRequestedOn'
						label={t('customerContact.refundRequestedOn')}
						defaultValue={data.refundRequestedOn}
					/>
					<RepresentmentTextField
						isFlexed={true}
						id='refundRequestLetterSentOn'
						label={t('customerContact.refundRequestLetterSentOn')}
						defaultValue={data.refundRequestLetterSentOn}
					/>
					<RepresentmentTextField
						isFlexed={true}
						id='welcomeLetterRequestedOn'
						label={t('customerContact.welcomeLetterRequestedOn')}
						defaultValue={data.welcomeLetterRequestedOn}
					/>
				</Stack>
			</RepresentmentBuilderBox>
		</Grid>
	);
};

CustomerContact.propTypes = {
	data: PropTypes.object,
	t: PropTypes.func
};

export default withTranslation('representments')(CustomerContact);
