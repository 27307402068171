import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	representmentReports: {
		data: [],
		isLoading: false,
		hasBeenLoaded: false,
		error: undefined
	}
};

export const representmentReportsSlice = createSlice({
	name: 'representmentReports',
	initialState,
	reducers: {
		getRepresentmentReports: (state) => {
			state.representmentReports.isLoading = true;
			state.representmentReports.error = undefined;
		},
		getRepresentmentReportsCompleted: (state, action) => {
			state.representmentReports.isLoading = false;
			state.representmentReports.hasBeenLoaded = true;
			state.representmentReports.data = action.payload;
		},
		getRepresentmentReportsFailed: (state, action) => {
			state.representmentReports.isLoading = false;
			state.representmentReports.error = action.payload;
		}
	}
});

export const {
	getRepresentmentReports,
	getRepresentmentReportsCompleted,
	getRepresentmentReportsFailed
} = representmentReportsSlice.actions;

export default representmentReportsSlice.reducer;
