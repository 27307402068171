import React from 'react';
import RepresentmentBuilder from '../../components/RepresentmentBuilder';
import PageTitle from '../../components/RepresentmentBuilder/PageTitle';

import Container from '@mui/material/Container';

export const Representment = () => {
	return (
		<Container
			maxWidth={false}
			sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
		>
			<PageTitle />
			<RepresentmentBuilder />
		</Container>
	);
};

export default Representment;
