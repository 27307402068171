import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { getLabel } from '../../../../helpers/shared/cardBrandHelper';
import MidDataTable from './MidDataTable';
import RollupDataTable from './RollupDataTable';
import CollapsedTotals from './CollapsedTotals';

import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const SummaryTableByCardType = (props) => {
	const {
		cardType,
		processorId,
		lookupDate,
		rowData,
		rowSummary,
		rollups,
		t
	} = props;

	const [accordianOpen, setAccordianOpen] = React.useState(true);
	const [midTableOpen, setMidTableOpen] = React.useState(false);

	return (
		<Accordion
			sx={{ mb: '16px' }}
			expanded={accordianOpen}
			onChange={() => setAccordianOpen(!accordianOpen)}
		>
			<AccordionSummary sx={{ display: 'flex', flexDirection: 'row' }} expandIcon={<ExpandMoreIcon />}>
				<Typography variant='h6' sx={{ flexBasis: '10%' }}>
					{getLabel(cardType)}
				</Typography>
				{!accordianOpen && <CollapsedTotals summary={rowSummary} />}
			</AccordionSummary>
			<AccordionDetails>
				<Stack direction='column' spacing={0.5}>
					<Button
						onClick={() => {
							setMidTableOpen(!midTableOpen);
						}}
					>
						{midTableOpen
							? t('chargebackSummary.hideMidList')
							: t('chargebackSummary.displayMidList')}
					</Button>
					<Collapse in={midTableOpen}>
						<MidDataTable
							cardType={cardType}
							processorId={processorId}
							lookupDate={lookupDate}
							rowData={rowData}
							rowSummary={rowSummary}
						/>
					</Collapse>
					<RollupDataTable
						cardType={cardType}
						processorId={processorId}
						lookupDate={lookupDate}
						rowData={rollups}
						midTableOpen={midTableOpen}
					/>
				</Stack>
			</AccordionDetails>
		</Accordion>
	);
};

SummaryTableByCardType.propTypes = {
	cardType: PropTypes.string,
	processorId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	lookupDate: PropTypes.string,
	rowData: PropTypes.array,
	rowSummary: PropTypes.object,
	rollups: PropTypes.array,
	rollupSummary: PropTypes.object,
	t: PropTypes.func
};

export default withTranslation('reports')(SummaryTableByCardType);
