import axios from '../../utilities/axios';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { refreshToken } from '../../utilities/refreshToken';

import { primaryRestGateway } from '../../utilities/apiEndpointUtility';

import {
	getNotificationDetail,
	getNotificationDetailCompleted,
	getNotificationDetailFailed,
	changeAssignedUser,
	changeAssignedUserCompleted,
	changeAssignedUserFailed,
	changeNotificationStatus,
	changeNotificationStatusCompleted,
	changeNotificationStatusFailed,
	addNotificationTimelineEvent,
	addNotificationTimelineEventCompleted,
	addNotificationTimelineEventFailed
} from './notificationDetailSlice';

export const getNotificationDetailEpic = (action$) =>
	action$.pipe(
		ofType(getNotificationDetail),
		mergeMap(async (action) => {
			await refreshToken();
			const { notificationId } = action.payload;

			const response = await axios.get(
				`${primaryRestGateway()}/api/notifications/${notificationId}`
			);

			return response.data;
		}),
		switchMap((res) => [getNotificationDetailCompleted(res)]),
		catchError((error, source) =>
			merge(of(getNotificationDetailFailed(error.message)), source)
		)
	);

export const changeAssignedUserEpic = (action$) =>
	action$.pipe(
		ofType(changeAssignedUser),
		mergeMap(async (action) => {
			await refreshToken();
			const { notificationId, notification } = action.payload;

			const response = await axios.put(
				`${primaryRestGateway()}/api/notifications/${notificationId}/assign`,
				notification
			);

			return response.data;
		}),
		switchMap((response) => [changeAssignedUserCompleted(response)]),
		catchError((error, source) =>
			merge(of(changeAssignedUserFailed(error.message)), source)
		)
	);

export const changeNotificationStatusEpic = (action$) =>
	action$.pipe(
		ofType(changeNotificationStatus),
		mergeMap(async (action) => {
			await refreshToken();
			const { notificationId, notification } = action.payload;

			const response = await axios.put(
				`${primaryRestGateway()}/api/notifications/${notificationId}/status`,
				notification
			);

			return response.data;
		}),
		switchMap((response) => [changeNotificationStatusCompleted(response)]),
		catchError((error, source) =>
			merge(of(changeNotificationStatusFailed(error.message)), source)
		)
	);

export const updateNotificationDetailEpic = (action$) =>
	action$.pipe(
		ofType(addNotificationTimelineEvent),
		mergeMap(async (action) => {
			await refreshToken();

			const { notificationId, notificationDetail } = action.payload;

			const response = await axios.post(
				`${primaryRestGateway()}/api/notifications/${notificationId}/detail`,
				notificationDetail
			);

			return response.data;
		}),
		switchMap((response) => [addNotificationTimelineEventCompleted(response)]),
		catchError((error, source) =>
			merge(of(addNotificationTimelineEventFailed(error.message)), source)
		)
	);

export default [
	getNotificationDetailEpic,
	changeAssignedUserEpic,
	changeNotificationStatusEpic,
	updateNotificationDetailEpic
];
