import * as actionTypes from './actionTypes';

export const toggleTheme = (payload) => ({
	type: actionTypes.TOGGLE_THEME,
	payload
});

// BELEOW ARE OLD REQUIREMENTS BY SCREENSHOTTER

export const sendEmail = (payload) => ({
	type: actionTypes.SEND_EMAIL,
	payload
});

export const sendEmailCompleted = (payload) => ({
	type: actionTypes.SEND_EMAIL_COMPLETED,
	payload
});

export const sendEmailFailed = (payload) => ({
	type: actionTypes.SEND_EMAIL_FAILED,
	payload
});

export const sendText = (payload) => ({
	type: actionTypes.SEND_TEXT,
	payload
});

export const sendTextCompleted = (payload) => ({
	type: actionTypes.SEND_TEXT_COMPLETED,
	payload
});

export const sendTextFailed = (payload) => ({
	type: actionTypes.SEND_TEXT_FAILED,
	payload
});

export const resetEmailValues = () => ({
	type: actionTypes.RESET_EMAIL_VALUES
});

export const resetTextValues = () => ({
	type: actionTypes.RESET_TEXT_VALUES
});
