import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import querier from '../../../../utilities/querier';
import isDataEmpty from '../../../../helpers/shared/isDataEmpty';
import { dataSourceActions } from '../../../../containers/DataSource';
import getDynamicDataFromList from '../../../../helpers/shared/dynamicReducers/getDynamicDataFromList';
import MerchantTooltip from '../../../shared/InputToolTips/MerchantTooltip';

import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import ListItem from '@mui/material/ListItem';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

const MerchantListItem = styled(ListItem)(({ theme }) => ({
	my: theme.spacing(1),
	ml: theme.spacing(1)
}));

const MerchantSelect = (props) => {
	const {
		onChange,
		value,
		t,
		dataSources,
		getDataSource,
		isStageDisabled,
		temporaryListValues,
		setTemporaryListValues
	} = props;

	const [inputValue, setInputValue] = React.useState('');
	const [open, setOpen] = React.useState(false);
	const [options, setOptions] = React.useState([]);
	const loading = options.length === 0 && open;

	const matchedDataSource = getDynamicDataFromList(dataSources, 'merchants');

	React.useEffect(() => {
		getDataSource({
			dataSourceKey: 'merchants'
		});
	}, []);

	React.useEffect(() => {
		if (open && matchedDataSource?.data) {
			setOptions(matchedDataSource.data);
		}
	}, [open, matchedDataSource]);

	React.useEffect(() => {
		if (!open) {
			setOptions([]);
		}
	}, [open]);

	React.useEffect(() => {
		if (temporaryListValues && !isDataEmpty(matchedDataSource?.data)) {
			const mappedValues = temporaryListValues.map((temporaryValue) => {
				return querier(matchedDataSource, `data.$[id = ${temporaryValue}]`);
			});

			onChange(mappedValues);
			setTemporaryListValues();
		}
	}, [temporaryListValues, matchedDataSource]);

	const handleOptionSelect = (newValue) => {
		onChange(newValue);
	};

	if (
		isDataEmpty(matchedDataSource?.data) ||
		!isDataEmpty(temporaryListValues)
	) {
		return (
			<FormControl sx={{ flex: 1 }}>
				<InputLabel sx={{ p: '2px' }} id={'title-label'}>
					{t('rules:selectMerchantIds')}
				</InputLabel>
				<Select
					value={value || ''}
					disabled={true}
					endAdornment={
						<InputAdornment sx={{ mr: 3 }} position='end'>
							<CircularProgress size={20} />
						</InputAdornment>
					}
				></Select>
			</FormControl>
		);
	}

	return (
		<FormControl sx={{ flex: 1 }}>
			<Autocomplete
				multiple
				filterSelectedOptions
				options={options}
				disabled={isStageDisabled}
				open={open}
				onOpen={() => {
					setOpen(true);
				}}
				onClose={() => {
					setOpen(false);
				}}
				loading={loading}
				isOptionEqualToValue={(option, item) => {
					return option.id === item.id;
				}}
				getOptionLabel={(option) => {
					return option.processer
						? `${option.processor} - ${option.label} - ${option.merchantId}`
						: `${option.label} - ${option.merchantId}`;
				}}
				value={value}
				onChange={(event, newValue) => {
					handleOptionSelect(newValue);
				}}
				inputValue={inputValue}
				onInputChange={(event, newInputValue) => {
					setInputValue(newInputValue);
				}}
				renderOption={(props, option) => {
					return (
						<MerchantListItem
							{...props}
							key={option.id}
						>
							<Stack>
								<Typography sx={{ fontWeight: 600 }}>
									{option.label}
								</Typography>
								<Typography variant='caption'>{`${option.processor} - ${option.merchantId}`}</Typography>
							</Stack>
						</MerchantListItem>
					);
				}}
				renderTags={(value) => (
					<Tooltip title={<MerchantTooltip value={value} />}>
						<Chip variant='outlined' label={`${value.length} selected`} />
					</Tooltip>
				)}
				sx={{ flex: 1 }}
				renderInput={(params) => (
					<TextField
						{...params}
						label={t('rules:selectMerchantIds')}
						helperText={t('common:ctrlHelperText')}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<React.Fragment>
									{loading ? (
										<CircularProgress color='inherit' size={20} />
									) : null}
									{params.InputProps.endAdornment}
								</React.Fragment>
							)
						}}
					/>
				)}
			/>
		</FormControl>
	);
};

MerchantSelect.propTypes = {
	onChange: PropTypes.func,
	value: PropTypes.array,
	t: PropTypes.func,
	dataSources: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	getDataSource: PropTypes.func,
	isStageDisabled: PropTypes.bool,
	temporaryListValues: PropTypes.array,
	setTemporaryListValues: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		dataSources: state.dataSourceReducer.get('dataSources')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getDataSource: (payload) => {
			dispatch(dataSourceActions.getDataSource(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation(['common', 'rules'])(MerchantSelect));
