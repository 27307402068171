const requiredSubmitKeys = [
	'category',
	'formula',
	'measurement',
	'paymentMethod',
	'comparator',
	'comparisonUnit',
	'timeFrame',
	'severity',
	'frequency',
	'ignoreNonTriggered'
];

export default requiredSubmitKeys;
