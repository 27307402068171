import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { capitalCase } from 'change-case';
import { notificationStatuses } from '../../../constants/notificationStatuses';

import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';

import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

export const NotificationStatusSelect = ({ t, isDisabled, selectedNotificationStatus, onChangeSelection, excludeAll }) => {
	return (
		<FormControl sx={{ flex: 1 }} fullWidth>
			<InputLabel id='notification-status-select-label'>
				{t('notifications:status')}
			</InputLabel>
			<Select
				labelId='notification-status-select-label'
				label={t('notifications:status')}
				id='notification-status-select'
				variant='outlined'
				value={selectedNotificationStatus}
				disabled={isDisabled}
				onChange={(e) => {
					onChangeSelection(e.target.value);
				}}
				renderValue={(status) => {
					return status === 'all' ? t('common:all') : capitalCase(t(`notifications:${status}`));
				}}
				endAdornment={
					!excludeAll ? (
						<IconButton
							sx={{ display: selectedNotificationStatus ? null : 'none', mr: 1 }}
							onClick={() => onChangeSelection('all')}
						>
							<ClearOutlinedIcon />
						</IconButton>
					) : null
				}
			>
				{ !excludeAll
					? (
						<MenuItem value={'all'}>
							<Typography>{capitalCase(t('common:all'))}</Typography>
						</MenuItem>
					) : null
				}
				{notificationStatuses.map((status) => {
					return (
						<MenuItem key={status.id} value={status.id}>
							<Typography>{capitalCase(t(status.name))}</Typography>
						</MenuItem>
					);
				})}
			</Select>
		</FormControl>
	);
};

NotificationStatusSelect.propTypes = {
	selectedNotificationStatus: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	onChangeSelection: PropTypes.func,
	t: PropTypes.func,
	excludeAll: PropTypes.bool,
	isDisabled: PropTypes.bool
};

export default withTranslation(['notifications', 'common'])(NotificationStatusSelect);
