import React from 'react';
import { styled } from '@mui/material/styles';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import FourteenDayChargebacks from '../../../components/staticReportViews/FourteenDayChargebacks';
import Restricted from '../../../permissionProvider/Restricted';
import RestrictedFallback from '../../../components/shared/RestrictedFallback';

import Container from '@mui/material/Container';

const StyledContainer = styled(Container)({
	margin: 0,
	padding: 0,
	display: 'flex',
	flexFlow: 'column'
});

const TrailingFourteenDayPage = (props) => {
	const { t } = props;

	return (
		<StyledContainer maxWidth={false}>
			<Restricted
				to='reports.trailingFourteenDayChargebackSummary.view'
				Fallback={
					<RestrictedFallback overrideMessage={t('error.notAllowed')} />
				}
			>
				<FourteenDayChargebacks />
			</Restricted>
		</StyledContainer>
	);
};

TrailingFourteenDayPage.propTypes = {
	t: PropTypes.func
};

export default withTranslation('common')(TrailingFourteenDayPage);
