import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import SuggestedActions from './SuggestedActions';
import NotificationTimelineDot from './NotificationTimelineDot';

import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineConnector from '@mui/lab/TimelineConnector';

const StyledPaper = styled(Paper)({
	display: 'flex',
	flexDirection: 'column',
	padding: '20px',
	maxWidth: '900px'
});

const InitialDetail = ({ detail, severity }) => {
	return (
		<TimelineItem>
			<TimelineOppositeContent sx={{ flex: 0.1 }}>
				<Typography variant='caption'>
					{dayjs(detail.createdOn).format('hh:mm A')}
				</Typography>
			</TimelineOppositeContent>
			<TimelineSeparator>
				<NotificationTimelineDot level={severity} iconType={'notification'} />
				<TimelineConnector sx={{ minHeight: '40px' }} />
			</TimelineSeparator>
			<TimelineContent>
				<StyledPaper elevation={1}>
					<Typography variant='body1'>
						{detail.notificationDescription
							? detail.notificationDescription
							: detail.message}
					</Typography>
					{detail.suggestedActions?.length > 0 ? (
						<SuggestedActions
							triggerId={detail.triggerId}
							suggestedActions={detail.suggestedActions}
						/>
					) : null}
				</StyledPaper>
			</TimelineContent>
		</TimelineItem>
	);
};

InitialDetail.propTypes = {
	detail: PropTypes.object,
	severity: PropTypes.string,
	notification: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

export default InitialDetail;
