import React from 'react';
import PropTypes from 'prop-types';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const PageHeader = ({ label, children }) => {
	return (
		<Stack direction='row' alignItems='center' spacing={2} m={2}>
			<Typography variant='h4' sx={{ fontWeight: 700 }}>
				{label}
			</Typography>
			{children}
		</Stack>
	);
};

PageHeader.propTypes = {
	label: PropTypes.string,
	children: PropTypes.element
};

export default PageHeader;
