import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withSize } from 'react-sizeme';
import { styled } from '@mui/material/styles';
import { withTranslation } from 'react-i18next';
import { modalActions } from '../../../containers/Modal';
import { permissionsActions } from '../../../containers/Permissions';
import { userActions } from '../../../containers/User';
import deepCopy from '../../../utilities/deepCopy';
import PermissionList from './PermissionList';
import CompletionPanel from './CompletionPanel';

import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import CloseIcon from '@mui/icons-material/Close';

const BoxBody = styled(Box)(({ theme }) => ({
	backgroundColor: theme.palette.common.white,
	overflow: 'hidden',
	position: 'absolute',
	'@media (min-height: 801px), (min-width: 381px)': {
		left: '50%',
		top: '50%',
		height: '60%',
		width: '60%',
		transform: 'translate(-50%, -50%)'
	}
}));

export const UserPermissionsModal = (props) => {
	const {
		permissionsModalUser,
		availablePermissions,
		availablePermissionsAreLoading,
		availablePermissionsHaveBeenLoaded,
		availablePermissionsError,
		getAvailablePermissions,
		selectedUserPermissions,
		isLoadingSelectedUserPermissions,
		getSelectedUserPermissions,
		isUpdatingUserPermissions,
		successfullyUpdatedPermissions,
		t,
		size
	} = props;

	const [
		modifiedAllowedPermissions,
		setModifiedAllowedPermissions
	] = React.useState();

	React.useEffect(() => {
		if (permissionsModalUser) {
			getAvailablePermissions();
			getSelectedUserPermissions({
				userId: permissionsModalUser.id
			});
		}
	}, [permissionsModalUser]);

	React.useEffect(() => {
		if (selectedUserPermissions?.permissions) {
			setModifiedAllowedPermissions(
				deepCopy(selectedUserPermissions.permissions)
			);
		}
	}, [selectedUserPermissions]);

	const handleOnClose = () => {
		const { setPermissionsModalUser } = props;

		setModifiedAllowedPermissions();
		setPermissionsModalUser();
	};

	const onUpdatePermissions = () => {
		const { updateUserPermissions, permissionsModalUser } = props;

		updateUserPermissions({
			username: permissionsModalUser.username,
			userId: permissionsModalUser.id,
			roles: [],
			permissions: modifiedAllowedPermissions
		});
	};

	const handleSuccessClose = () => {
		const { setPermissionsModalUser, resetUserPermissionProcess } = props;

		resetUserPermissionProcess();
		setPermissionsModalUser();
	};

	return (
		<Modal open={!!permissionsModalUser} onClose={null}>
			{!successfullyUpdatedPermissions ? (
				<BoxBody>
					<Stack
						direction='row'
						pt={2}
						px={2}
						justifyContent='space-between'
						alignItems='center'
						sx={{ height: '60px' }}
					>
						<Typography variant='h4'>
							{t('userPermissionsModal.permissions')}
						</Typography>
						<IconButton onClick={handleOnClose} disabled={successfullyUpdatedPermissions}>
							<CloseIcon />
						</IconButton>
					</Stack>
					<PermissionList
						// AVAILALBE PERMISSIONS
						isLoadingAvailablePermissions={availablePermissionsAreLoading}
						availalbePermissionshasBeenLoaded={
							availablePermissionsHaveBeenLoaded
						}
						modifiedAllowedPermissions={modifiedAllowedPermissions}
						setModifiedAllowedPermissions={setModifiedAllowedPermissions}
						availablePermissions={availablePermissions}
						availablePermissionsError={availablePermissionsError}
						onUpdatePermissions={onUpdatePermissions}
						// SELECTED USER
						permissionsModalUser={permissionsModalUser}
						selectedUserPermissions={selectedUserPermissions}
						isLoadingSelectedUserPermissions={isLoadingSelectedUserPermissions}
						isUpdatingUserPermissions={isUpdatingUserPermissions}
						height={size.height}
						disableChanges={successfullyUpdatedPermissions}
					/>
				</BoxBody>
			) : (
				<CompletionPanel onClose={handleSuccessClose} />
			)}
		</Modal>
	);
};

UserPermissionsModal.propTypes = {
	permissionsModalUser: PropTypes.object,
	availablePermissions: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.array
	]),
	availablePermissionsAreLoading: PropTypes.bool,
	availablePermissionsError: PropTypes.string,
	availablePermissionsHaveBeenLoaded: PropTypes.bool,
	getAvailablePermissions: PropTypes.func,
	selectedUserPermissions: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.array
	]),
	isLoadingSelectedUserPermissions: PropTypes.bool,
	getSelectedUserPermissions: PropTypes.func,
	isUpdatingUserPermissions: PropTypes.bool,
	size: PropTypes.object,
	successfullyUpdatedPermissions: PropTypes.bool,
	setPermissionsModalUser: PropTypes.func,
	updateUserPermissions: PropTypes.func,
	resetUserPermissionProcess: PropTypes.func,
	t: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		user: state.userReducer.get('user'),
		permissionsModalUser: state.modalReducer.get('permissionsModalUser'),
		selectedUserPermissions: state.userReducer.get('selectedUserPermissions'),
		successfullyUpdatedPermissions: state.userReducer.get(
			'successfullyUpdatedPermissions'
		),
		isUpdatingUserPermissions: state.userReducer.get(
			'isUpdatingUserPermissions'
		),
		availablePermissions: state.permissionsReducer.getIn([
			'availablePermissions',
			'data'
		]),
		availablePermissionsAreLoading: state.permissionsReducer.getIn([
			'availablePermissions',
			'isLoading'
		]),
		availablePermissionsHaveBeenLoaded: state.permissionsReducer.getIn([
			'availablePermissions',
			'hasBeenLoaded'
		]),
		availablePermissionsError: state.permissionsReducer.getIn([
			'availablePermissions',
			'error'
		])
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getAvailablePermissions: (payload) => {
			dispatch(permissionsActions.getAvailablePermissions(payload));
		},
		getSelectedUserPermissions: (payload) => {
			dispatch(userActions.getUserPermissions(payload));
		},
		updateUserPermissions: (payload) => {
			dispatch(userActions.updateUserPermissions(payload));
		},
		setPermissionsModalUser: (payload) => {
			dispatch(modalActions.setPermissionsModalUser(payload));
		},
		resetUserPermissionProcess: (payload) => {
			dispatch(userActions.resetUserPermissionProcess(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation('modals')(withSize({ monitorHeight: true })(UserPermissionsModal)));
