import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import ErrorIcon from '@mui/icons-material/Error';

export const PageError = (props) => {
	const { errorText, t } = props;

	return (
		<Stack alignItems='center' justifyContent='center' spacing={2} p={1.2} mt={2.4}>
			<ErrorIcon color='error' sx={{ fontSize: '2.5rem' }} />
			<Typography color='secondary'>
				{errorText || t('common:error.somethingWentWrong')}
			</Typography>
		</Stack>
	);
};

PageError.propTypes = {
	errorText: PropTypes.string,
	t: PropTypes.func
};

export default withTranslation()(PageError);
