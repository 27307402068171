import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { formatDateForDisplay } from '../../../../../utilities/dateWrapper';

import Button from '@mui/material/Button';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import Typography from '@mui/material/Typography';

const StyledButton = styled(Button)(({ theme }) => ({
	textTransform: 'none',
	color: theme.palette.common.black,
	margin: '2px'
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	color: theme.palette.common.black
}));

const TableSummary = (props) => {
	const { summary, lookupDate, processorId, t } = props;

	const navigate = useNavigate();
	const formattedLookupDate = formatDateForDisplay(lookupDate, 'YYYY-MM-DD');

	const handleSummaryRedirect = () => {
		navigate(
			`/reports/f6b37d9d-97e7-4a9c-90a4-2606eaedf227?processorId=${processorId}&merchantIds=all&lookupDate=${formattedLookupDate}&autoApply=true`
		);
	};

	return (
		<TableFooter>
			<TableRow>
				<StyledTableCell>
					<Typography variant='subtitle2' sx={{ fontWeight: 600, fontSize: '1.2rem' }}>
						{t('chargebackSummary.total')}
					</Typography>
				</StyledTableCell>
				<StyledTableCell>
					<Typography variant='subtitle2' sx={{ fontSize: '.8rem' }}>
						{summary.dailyChargebacksSum}
					</Typography>
				</StyledTableCell>
				<StyledTableCell>
					<Typography variant='subtitle2' sx={{ fontSize: '.8rem' }}>
						{summary.monthlyChargebacksSum}
					</Typography>
				</StyledTableCell>
				<TableCell></TableCell>
				<TableCell></TableCell>
				<TableCell colSpan={2} align='center'>
					<StyledButton
						variant='outlined'
						onClick={handleSummaryRedirect}
					>
						{t('chargebackSummary.footer.total')}
					</StyledButton>
				</TableCell>
			</TableRow>
		</TableFooter>
	);
};

TableSummary.propTypes = {
	summary: PropTypes.object,
	data: PropTypes.array,
	lookupDate: PropTypes.string,
	processorId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	t: PropTypes.func
};

export default withTranslation('reports')(TableSummary);
