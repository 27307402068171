import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import CdrnRow from './components/CdrnRow';
import BuilderSectionHeader from '../BuilderSectionHeader';
import RepresentmentBuilderBox from '../RepresentmentBuilderStack';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';

const _cellColumnDefinition = [
	'cdrns.caseId',
	'cdrns.issuerCaseId',
	'cdrns.receivedByMerchant',
	'cdrns.responseDate'
];

export const Cdrns = (props) => {
	const { t, data } = props;

	return (
		<Grid item xs={12} sm={12} md={12} lg={12}>
			<RepresentmentBuilderBox>
				<BuilderSectionHeader>{t('cdrns.label')}</BuilderSectionHeader>
				<TableContainer>
					<Table sx={{ minWidth: 650 }} size='small'>
						<TableHead>
							<TableRow>
								{_cellColumnDefinition.map((label, index) => {
									return <TableCell key={index}>{t(label)}</TableCell>;
								})}
							</TableRow>
						</TableHead>
						<TableBody>
							{data.length === 0 ? (
								<TableRow
									key={'no-data'}
									sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
								>
									<TableCell>{t('cdrns.noData')}</TableCell>
								</TableRow>
							) : (
								data.map((row, index) => {
									return <CdrnRow key={index} row={row} />;
								})
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</RepresentmentBuilderBox>
		</Grid>
	);
};

Cdrns.propTypes = {
	data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	t: PropTypes.func
};

export default withTranslation('representments')(Cdrns);
