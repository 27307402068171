import { fromJS } from 'immutable';
import * as actionTypes from './actionTypes';

export const initialState = fromJS({
	groups: {
		data: [],
		hasBeenLoaded: false,
		isLoading: false,
		error: undefined
	},
	group: {
		data: {},
		isLoading: false,
		hasBeenLoaded: false,
		error: undefined
	},
	isUpdatingGroup: false,
	isDeletingGroup: false,
	isAddingGroup: false
});

export const groupsReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_GROUPS:
			return state.setIn(['groups', 'isLoading'], true);

		case actionTypes.GET_GROUPS_COMPLETED:
			return state
				.setIn(['groups', 'hasBeenLoaded'], true)
				.setIn(['groups', 'isLoading'], false)
				.setIn(['groups', 'data'], action.payload);

		case actionTypes.GET_GROUPS_FAILED:
			return state
				.setIn(['groups', 'isLoading'], false)
				.setIn(['groups', 'error'], action.payload);

		case actionTypes.GET_GROUP:
			return state.setIn(['group', 'isLoading'], true);

		case actionTypes.GET_GROUP_COMPLETED:
			return state
				.setIn(['group', 'hasBeenLoaded'], true)
				.setIn(['group', 'isLoading'], false)
				.setIn(['group', 'data'], action.payload);

		case actionTypes.GET_GROUP_FAILED:
			return state
				.setIn(['group', 'isLoading'], false)
				.setIn(['group', 'error'], action.payload);

		case actionTypes.ADD_GROUP:
			return state
				.set('isAddingGroup', true)
				.setIn(['groups', 'isLoading'], true);

		case actionTypes.ADD_GROUP_COMPLETED:
			return state.set('isAddingGroup', false);

		case actionTypes.ADD_GROUP_FAILED:
			return state.set('isAddingGroup', false);

		case actionTypes.UPDATE_GROUP:
			return state
				.set('isUpdatingGroup', true)
				.setIn(['groups', 'isLoading'], true);

		case actionTypes.UPDATE_GROUP_COMPLETED:
			return state.set('isUpdatingGroup', false);

		case actionTypes.UPDATE_GROUP_FAILED:
			return state.set('isUpdatingGroup', false);

		case actionTypes.DELETE_GROUP:
			return state
				.set('isDeletingGroup', true)
				.setIn(['groups', 'isLoading'], true);

		case actionTypes.DELETE_GROUP_COMPLETED:
			return state.set('isDeletingGroup', false);

		case actionTypes.DELETE_GROUP_FAILED:
			return state.set('isDeletingGroup', false);

		case actionTypes.CLEAR_GROUPS:
			return state
				.setIn(['groups', 'data'], [])
				.setIn(['groups', 'hasBeenLoaded'], false)
				.setIn(['groups', 'isLoading'], false)
				.setIn(['groups', 'error'], undefined);

		default:
			return state;
	}
};
