import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	userRule: {
		data: {},
		isLoading: false,
		hasBeenLoaded: false,
		error: undefined
	},
	updateActiveStatus: {
		userRuleId: undefined,
		isLoading: false,
		hasCompleted: false,
		error: undefined
	},
	deleteUserRule: {
		userRuleId: undefined,
		isLoading: false,
		hasCompleted: false,
		error: undefined
	}
};

export const userRuleSlice = createSlice({
	name: 'userRule',
	initialState,
	reducers: {
		getUserRule: (state) => {
			state.userRule.isLoading = true;
			state.userRule.error = undefined;
		},
		getUserRuleCompleted: (state, action) => {
			state.userRule.isLoading = false;
			state.userRule.hasBeenLoaded = true;
			state.userRule.data = action.payload;
		},
		getUserRuleFailed: (state, action) => {
			state.userRule.isLoading = false;
			state.userRule.error = action.payload;
		},
		updateActiveStatus: (state, action) => {
			state.updateActiveStatus.userRuleId = action.payload.userRuleId;
			state.updateActiveStatus.isLoading = true;
			state.updateActiveStatus.error = undefined;
		},
		updateActiveStatusCompleted: (state) => {
			state.updateActiveStatus.userRuleId = undefined;
			state.updateActiveStatus.isLoading = true;
			state.updateActiveStatus.hasCompleted = true;
		},
		updateActiveStatusFailed: (state) => {
			state.updateActiveStatus.isLoading = true;
			state.updateActiveStatus.error = undefined;
		},
		resetUpdateActiveStatus: (state) => {
			state.updateActiveStatus = initialState.updateActiveStatus;
		},
		deleteUserRule: (state, action) => {
			state.deleteUserRule.userRuleId = action.payload.userRuleId;
			state.deleteUserRule.isLoading = true;
			state.deleteUserRule.error = undefined;
		},
		deleteUserRuleCompleted: (state) => {
			state.deleteUserRule.userRuleId = undefined;
			state.deleteUserRule.isLoading = false;
			state.deleteUserRule.hasCompleted = true;
		},
		deleteUserRuleFailed: (state, action) => {
			state.deleteUserRule.isLoading = false;
			state.deleteUserRule.error = action.payload;
		},
		resetDeleteUserRule: (state) => {
			state.deleteUserRule = initialState.deleteUserRule;
		}
	}
});

export const {
	getUserRule,
	getUserRuleCompleted,
	getUserRuleFailed,
	updateActiveStatus,
	updateActiveStatusCompleted,
	updateActiveStatusFailed,
	resetUpdateActiveStatus,
	deleteUserRule,
	deleteUserRuleCompleted,
	deleteUserRuleFailed,
	resetDeleteUserRule
} = userRuleSlice.actions;

export default userRuleSlice.reducer;
