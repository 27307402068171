import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { capitalCase } from 'change-case';
import { withTranslation } from 'react-i18next';
import { notificationSeverityLevelsNamed } from '../../../../../../constants/notificationSeverityLevels';
import notificationSeverityIcons from '../../../../../icons/notificationSeverityIcons';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const BaseStack = styled(Stack)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	padding: theme.spacing(2),
	margin: theme.spacing(1),
	borderRadius: 5
}));

const UnselectedOptionStack = styled(BaseStack)(({ theme, color }) => ({
	border: `2px solid ${theme.palette.secondary.main}`,
	color: theme.palette.secondary.main,
	cursor: 'pointer',
	'& .icon': {
		color: theme.palette.secondary.main
	},
	'& .label': {
		color: theme.palette.secondary.main
	},
	'&:hover': {
		backgroundColor: theme.palette[color].main,
		borderColor: theme.palette[color].main,
		'& .icon': {
			color: theme.palette.common.white
		},
		'& .label': {
			fontWeight: 600,
			color: theme.palette.common.white
		}
	}
}));

const SelectedOptionStack = styled(BaseStack)(({ theme, color }) => ({
	border: `2px solid ${theme.palette[color].main}`,
	backgroundColor: theme.palette[color].main,
	color: theme.palette[color].contrastText,
	'& .icon': {
		color: theme.palette.common.white
	},
	'& .label': {
		fontWeight: 600,
		color: theme.palette.common.white
	}
}));

const DisabledOptionStack = styled(BaseStack)(({ theme }) => ({
	border: `2px solid ${theme.palette.secondary.light}`,
	opacity: 0.4,
	'& .icon': {
		color: theme.palette.secondary.light
	},
	'& .label': {
		fontWeight: 600,
		color: theme.palette.secondary.light
	}
}));

const SeveritySelectOption = (props) => {
	const { t, option, onSelect, isSelected, isDisabled } = props;

	const optionConfiguration = notificationSeverityLevelsNamed[option.name];
	const OptionIcon = notificationSeverityIcons[option.name];

	if (isDisabled) {
		return (
			<Grid item xs={12} sm={12} md={4}>
				<DisabledOptionStack>
					<OptionIcon className='icon' color={option.colorThemePath} />
					<Typography	className='label'>
						{capitalCase(t(`notifictations:${optionConfiguration.name}`))}
					</Typography>
				</DisabledOptionStack>
			</Grid>
		);
	};

	const StackComponent = isSelected ? SelectedOptionStack : UnselectedOptionStack;

	return (
		<Grid item xs={12} sm={12} md={4} >
			<StackComponent color={optionConfiguration.colorThemePath} onClick={() => onSelect(option)}>
				<OptionIcon className='icon' color={option.colorThemePath} />
				<Typography className='label'>
					{capitalCase(t(`notifictations:${optionConfiguration.name}`))}
				</Typography>
			</StackComponent>
		</Grid>
	);
};

SeveritySelectOption.propTypes = {
	t: PropTypes.func,
	isDisabled: PropTypes.bool,
	option: PropTypes.object,
	onSelect: PropTypes.func,
	isSelected: PropTypes.bool
};

export default withTranslation(['rules', 'notifictations'])(SeveritySelectOption);
