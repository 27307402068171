import React from 'react';
import PropTypes from 'prop-types';
import { sentenceCase } from 'change-case';
import CustomTableRow from './TableRow';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

const NestedRow = (props) => {
	const { columns, row } = props;

	return (
		<React.Fragment>
			<TableRow colSpan={16} align='left'>
				<TableCell>
					<Typography sx={{ fontSize: '.9rem', fontWeight: 600 }} variant='overline'>
						{sentenceCase(row.key)}
					</Typography>
				</TableCell>
			</TableRow>
			{row.nestedRows.map((nestedRow, rowIndex) => {
				const isAlternateRow = rowIndex % 2 === 0;
				return (
					<CustomTableRow
						key={nestedRow.key}
						isAlternateRow={isAlternateRow}
						columns={columns}
						row={nestedRow}
						isNested={true}
					/>
				);
			})}
		</React.Fragment>
	);
};

NestedRow.propTypes = {
	columns: PropTypes.arrayOf(PropTypes.object),
	row: PropTypes.object
};

export default NestedRow;
