import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import querier from '../../../../utilities/querier';
import { dataSourceActions } from '../../../../containers/DataSource';
import getDynamicDataFromList from '../../../../helpers/shared/dynamicReducers/getDynamicDataFromList';
import isDataEmpty from '../../../../helpers/shared/isDataEmpty';
import DisabledSelectInput from './DisabledSelectInput';

import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

// TODO - MOVED TO inputs FOLDER - DELETE FROM HERE AND ENSURE NOTHING BREAKS
const ProcessorSelect = (props) => {
	const {
		onChange,
		value,
		t,
		dataSources,
		getDataSource,
		temporaryListValues,
		setTemporaryListValues,
		isStageDisabled
	} = props;

	const [inputValue, setInputValue] = React.useState('');
	const [open, setOpen] = React.useState(false);
	const [options, setOptions] = React.useState([]);
	const loading = options.length === 0 && open;

	const matchedDataSource = getDynamicDataFromList(dataSources, 'processors');

	React.useEffect(() => {
		getDataSource({
			dataSourceKey: 'processors'
		});
	}, []);

	React.useEffect(() => {
		if (
			matchedDataSource?.data &&
			!matchedDataSource?.isLoading &&
			!matchedDataSource.hasBeenLoaded
		) {
			getDataSource({
				dataSourceKey: 'processors'
			});
		}

		setOptions([]);
	}, []);

	React.useEffect(() => {
		if (open) {
			setOptions(matchedDataSource?.data);
		}
	}, [open, matchedDataSource]);

	React.useEffect(() => {
		if (!open) {
			setOptions([]);
		}
	}, [open]);

	React.useEffect(() => {
		if (temporaryListValues && !isDataEmpty(matchedDataSource?.data)) {
			const matchedProcessor = querier(
				matchedDataSource?.data,
				`$[id = ${temporaryListValues}]`
			);

			onChange(matchedProcessor);
			setTemporaryListValues();
		}
	}, [temporaryListValues, matchedDataSource]);

	if (
		isDataEmpty(matchedDataSource?.data) ||
		!isDataEmpty(temporaryListValues) ||
		isStageDisabled
	) {
		return (
			<DisabledSelectInput
				value={value}
				valueLabelKey={'description'}
				isStageDisabled={isStageDisabled}
				labelTranslationPath={'selectSingleProcessor'}
			/>
		);
	}

	return (
		<FormControl sx={{ flex: 1 }}>
			<Autocomplete
				filterSelectedOptions
				options={options}
				open={open}
				onOpen={() => {
					setOpen(true);
				}}
				onClose={() => {
					setOpen(false);
				}}
				loading={loading}
				isOptionEqualToValue={(option, item) => {
					return option?.id === item?.id;
				}}
				getOptionLabel={(option) => {
					return option ? `${option.description}` : '';
				}}
				value={value || ''}
				onChange={(event, newValue) => {
					onChange(newValue);
				}}
				inputValue={inputValue}
				onInputChange={(event, newInputValue) => {
					setInputValue(newInputValue);
				}}
				sx={{ flex: 1 }}
				renderInput={(params) => (
					<TextField
						{...params}
						label={t('selectSingleProcessor')}
						variant='outlined'
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<React.Fragment>
									{loading ? (
										<CircularProgress color='inherit' size={20} />
									) : null}
									{params.InputProps.endAdornment}
								</React.Fragment>
							)
						}}
					/>
				)}
			/>
		</FormControl>
	);
};

ProcessorSelect.propTypes = {
	onChange: PropTypes.func,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	t: PropTypes.func,
	dataSources: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	getDataSource: PropTypes.func,
	temporaryListValues: PropTypes.array,
	setTemporaryListValues: PropTypes.func,
	isStageDisabled: PropTypes.bool
};

const mapStateToProps = (state) => {
	return {
		dataSources: state.dataSourceReducer.get('dataSources')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getDataSource: (payload) => {
			dispatch(dataSourceActions.getDataSource(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation('rules')(ProcessorSelect));
