import React from 'react';
import { Auth } from 'aws-amplify';
import { styled } from '@mui/material/styles';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { authActions } from '../../../../../containers/Auth';
import { multiFactorAuthActions } from '../../../../../containers/MultiFactorAuth';
import { userActions } from '../../../../../containers/User';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PersonIcon from '@mui/icons-material/Person';
import Logout from '@mui/icons-material/Logout';

const UsernameTypography = styled(Typography)(({ theme }) => ({
	color: theme.palette.primary.main,
	[theme.breakpoints.up('sm')]: {
		fontSize: '0.8rem'
	},
	[theme.breakpoints.down('sm')]: {
		display: 'none'
	}
}));

const Profile = (props) => {
	const { user, userAvatarSrc, t } = props;

	const [anchorEl, setAnchorEl] = React.useState(null);

	const open = Boolean(anchorEl);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleLogOut = async (event) => {
		event.preventDefault();
		const { deAuthUser, mfaLogout, clearUser } = props;
		try {
			Auth.signOut();
			mfaLogout();
			deAuthUser();
			clearUser();
		} catch (error) {
			console.log(error.message);
		}
	};

	return (
		<Stack
			sx={{ height: '100%' }}
			justifyContent='center'
			alignItems='center'
			mr={2}
			p={1}
		>
			<Button
				variant='text'
				endIcon={<ExpandMoreIcon />}
				ref={anchorEl}
				onClick={handleClick}
				disableRipple
			>
				<Box pr={2}>
					<Avatar variant='round' size='small' alt={user.firstName} src={userAvatarSrc} />
				</Box>
				<UsernameTypography>{`${user.firstName.slice(0, 1)}. ${user.lastName}`}</UsernameTypography>
			</Button>
			<Menu
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				onClick={handleClose}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
			>
				<Link to='/account' style={{ textDecoration: 'none' }}>
					<MenuItem>
						<ListItemIcon>
							<PersonIcon fontSize='small' />
						</ListItemIcon>
						{t('navigation:account')}
					</MenuItem>
				</Link>
				<Divider />
				<MenuItem onClick={handleLogOut}>
					<ListItemIcon>
						<Logout fontSize='small' />
					</ListItemIcon>
					{t('navigation:logout')}
				</MenuItem>
			</Menu>
		</Stack>
	);
};

Profile.propTypes = {
	user: PropTypes.object,
	userAvatarSrc: PropTypes.string,
	deAuthUser: PropTypes.func,
	mfaLogout: PropTypes.func,
	clearUser: PropTypes.func,
	t: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		user: state.userReducer.get('user'),
		userAvatarSrc: state.userAvatarSlice.userAvatar.src
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		deAuthUser: () => {
			dispatch(authActions.deAuthUser());
		},
		mfaLogout: () => {
			dispatch(multiFactorAuthActions.logout());
		},
		clearUser: () => {
			dispatch(userActions.logout());
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation('navigation')(Profile));
