import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { withTranslation } from 'react-i18next';
import { sentenceCase, capitalCase } from 'change-case';

import Checkbox from '@mui/material/Checkbox';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const PermissionSwitch = styled(Switch)(({ theme }) => ({
	'& .MuiSwitch-switchBase.Mui-checked': {
		color: theme.palette.common.white
	},
	'& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
		backgroundColor: theme.palette.common.white
	}
}));

export const PermissionArea = (props) => {
	const {
		t,
		areaId,
		areaLabel,
		areaPermissions,
		modifiedAllowedPermissions,
		disableChanges,
		getCheckedPermissionCount,
		handlePermissionAreaChange,
		handlePermissionChange
	} = props;

	const [isExpanded, setIsExpanded] = React.useState(false);

	const toggleCollaspe = () => {
		setIsExpanded(!isExpanded);
	};

	const checkedPermissionCount = React.useMemo(() => {
		return getCheckedPermissionCount(areaPermissions, modifiedAllowedPermissions);
	}, [areaPermissions, modifiedAllowedPermissions]);

	const handleCheckAllPermissions = () => {
		handlePermissionAreaChange(
			checkedPermissionCount === areaPermissions.length,
			areaPermissions,
			modifiedAllowedPermissions
		);
	};

	return (
		<React.Fragment>
			<ListSubheader sx={{ bgcolor: 'primary.light', color: 'common.white', cursor: 'pointer', p: 1.2 }}>
				<Stack direction='row' justifyContent='flex-end' alignItems='center'>
					{ isExpanded
						? <ExpandMoreIcon onClick={toggleCollaspe} />
						: <KeyboardArrowRightIcon onClick={toggleCollaspe} />
					}
					<Box sx={{ flex: 1 }} onClick={toggleCollaspe} pb={0.5}>
						<Typography variant='h6'>
							{capitalCase(sentenceCase(areaLabel))}
						</Typography>
					</Box>
					<Typography variant='caption'>{`${checkedPermissionCount} / ${areaPermissions.length}`}</Typography>
					<Tooltip
						title={
							checkedPermissionCount === areaPermissions.length
								? t('uncheckAll')
								: t('checkAll')
						}
						placement='left'
					>
						<PermissionSwitch
							edge='end'
							disabled={disableChanges}
							onChange={handleCheckAllPermissions}
							checked={checkedPermissionCount === areaPermissions.length}
						/>
					</Tooltip>
				</Stack>
			</ListSubheader>
			<Collapse in={isExpanded}>
				{areaPermissions.map((item) => (
					<ListItem
						key={`item-${areaId}-${item.permissionComponent}-${item.type}`}
					>
						<ListItemText primary={item.description} />
						<Checkbox
							disabled={disableChanges}
							checked={modifiedAllowedPermissions.includes(
								`${item.permissionArea}.${item.permissionComponent}.${item.type}`
							)}
							onChange={() => {
								handlePermissionChange(
									`${item.permissionArea}.${item.permissionComponent}.${item.type}`
								);
							}}
						/>
					</ListItem>
				))}
			</Collapse>
		</React.Fragment>
	);
};

PermissionArea.propTypes = {
	t: PropTypes.func,
	areaId: PropTypes.number,
	areaLabel: PropTypes.string,
	areaPermissions: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.array
	]),
	selectedUserPermissions: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.array
	]),
	modifiedAllowedPermissions: PropTypes.array,
	disableChanges: PropTypes.bool,
	getCheckedPermissionCount: PropTypes.func,
	handlePermissionAreaChange: PropTypes.func,
	handlePermissionChange: PropTypes.func
};

export default withTranslation(['common', 'modals'])(PermissionArea);
