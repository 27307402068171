import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { reporterActions } from '../../../containers/Reporter';
import { tableauActions } from '../../../containers/Tableau';
import { tableauServer } from '../../../utilities/apiEndpointUtility';
import findReportItemInList from '../../../helpers/reporter/findReportItemInList';
import PageError from '../../errors/PageError';
import tableauTimeout from '../../../constants/tableauTimeout';
import isDataEmpty from '../../../helpers/shared/isDataEmpty';
import RestrictedFallback from '../../../components/shared/RestrictedFallback';
import isTokenExpired from '../../../helpers/tableau/isTokenExpired';

import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import ActionRow from './ActionRow';

const TableauWrapper = (props) => {
	const {
		error,
		generateToken,
		reportDetails,
		tokenExpiration,
		siteId,
		token,
		tokenIsLoading,
		getReportDetails,
		user,
		t
	} = props;

	const location = useLocation();

	const tableauRef = React.useRef(null);

	const [
		activeReportViewConfiguration,
		setActiveReportViewConfiguration
	] = React.useState();
	const [isAllowedTo, setIsAllowedTo] = React.useState();

	const reportUuid = location.pathname.split('/')[2];
	const reportViewConfiguration = findReportItemInList(
		reportDetails,
		reportUuid
	);

	React.useEffect(() => {
		if (
			isDataEmpty(reportViewConfiguration) ||
			activeReportViewConfiguration?.reportId !== reportUuid
		) {
			getReportDetails({
				reportUniversalUniqueIdentifier: reportUuid
			});
			setIsAllowedTo();
		}
	}, [location]);

	React.useEffect(() => {
		if (
			(!activeReportViewConfiguration ||
				activeReportViewConfiguration?.reportId !== reportUuid) &&
			reportViewConfiguration?.hasBeenLoaded
		) {
			setActiveReportViewConfiguration(reportViewConfiguration);
			// HACK - UNTIL RESTRICTED WRAPPER WORKS WITH TABLEAU
			setIsAllowedTo(
				user.permissions.includes(
					reportViewConfiguration?.data?.requiredPermission
				)
			);
		}
	}, [reportDetails]);

	React.useEffect(() => {
		if (
			siteId &&
			!isTokenExpired(tokenExpiration) &&
			activeReportViewConfiguration?.data?.reportUrl
		) {
			const viz = document.getElementById('tableau-viz');

			if (viz) {
				viz.src = `${tableauServer()}${siteId}${
					reportViewConfiguration?.data?.reportUrl
				}`;
				viz.token = token;
			}
		}
	}, [activeReportViewConfiguration, token, siteId]);

	React.useEffect(() => {
		if (!token && isTokenExpired(tokenExpiration) && !tokenIsLoading) {
			generateToken();
		}
	}, [location, activeReportViewConfiguration]);

	React.useEffect(() => {
		const runner = () => {
			if (tableauRef.current) {
				generateToken();
			}
			setTimeout(() => {
				runner();
			}, tableauTimeout.timeoutInMilliseconds);
		};

		runner();
	}, [tableauRef]);

	const exportDataHandler = async () => {
		const viz = document.getElementById('tableau-viz');

		await viz.displayDialogAsync('export-cross-tab');
	};

	if (error) {
		return <PageError />;
	}

	if (
		isDataEmpty(activeReportViewConfiguration) ||
		!reportViewConfiguration?.hasBeenLoaded ||
		isAllowedTo === undefined
	) {
		return (
			<Stack
				direction='column'
				justifyContent='center'
				alignItems='center'
				sx={{ width: '100%', height: '100%' }}
			>
				<CircularProgress />
			</Stack>
		);
	}

	// HACK - UNTIL RESTRICTED WRAPPER WORKS WITH TABLEAU
	if (isAllowedTo === false) {
		return <RestrictedFallback overrideMessage={t('error.notAllowed')} />;
	}

	return (
		<Stack
			direction='column'
			sx={{
				width: '100%',
				height: '100%'
			}}
		>
			<tableau-viz
				id='tableau-viz'
				ref={tableauRef}
				style={{
					width: '100%',
					height: '90%',
					position: 'absolute'
				}}
				toolbar='hidden'
			/>
			{activeReportViewConfiguration ? (
				<ActionRow id='download-report' downloadReport={exportDataHandler} />
			) : null}
		</Stack>
	);
};

TableauWrapper.propTypes = {
	error: PropTypes.string,
	generateToken: PropTypes.func,
	reportDetails: PropTypes.object,
	tokenExpiration: PropTypes.object,
	siteId: PropTypes.string,
	token: PropTypes.string,
	tokenIsLoading: PropTypes.bool,
	getReportDetails: PropTypes.func,
	user: PropTypes.object,
	t: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		user: state.userReducer.get('user'),
		error: state.tableauReducer.get('error'),
		tokenHasLoaded: state.tableauReducer.get('hasBeenLoaded'),
		tokenIsLoading: state.tableauReducer.get('isLoading'),
		tokenExpiration: state.tableauReducer.get('expiration'),
		reportDetails: state.reporterReducer.get('reportDetails'),
		siteId: state.tableauReducer.get('siteId'),
		token: state.tableauReducer.get('token')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getReportDetails: (payload) => {
			dispatch(reporterActions.getReportDetails(payload));
		},
		generateToken: (payload) => {
			dispatch(tableauActions.generateToken(payload));
		},
		resetToken: (payload) => {
			dispatch(tableauActions.resetToken(payload));
		},
		clearReportDetails: (payload) => {
			dispatch(reporterActions.clearReportDetails(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation('common')(TableauWrapper));
