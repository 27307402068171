import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import BuilderSectionHeader from '../BuilderSectionHeader';
import RepresentmentBuilderBox from '../RepresentmentBuilderStack';

import RepresentmentTextField from './components/RepresentmentTextField';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

export const CustomerInformation = (props) => {
	const { t, data } = props;

	return (
		<Grid item xs={12} sm={12} md={12} lg={12}>
			<RepresentmentBuilderBox>
				<BuilderSectionHeader>{t('customerInformation.label')}</BuilderSectionHeader>
				<Stack direction='row'>
					<RepresentmentTextField
						id='billingName'
						minWidth={400}
						label={t('customerInformation.billingName')}
						defaultValue={`${data.billingFirstName} ${data.billingLastName}`}
					/>
					<RepresentmentTextField
						id='customerId'
						label={t('customerInformation.customerId')}
						defaultValue={`${data.customerId}`}
					/>
					<RepresentmentTextField
						id='phoneNumber'
						label={t('customerInformation.phoneNumber')}
						defaultValue={data.phoneNumber}
					/>
				</Stack>
				<Stack direction='row'>
					<RepresentmentTextField
						id='address'
						label={t('customerInformation.address')}
						isFlexed={true}
						defaultValue={`${data.billingAddress} ${data.billingCity}, ${data.billingStateProvince} ${data.billingPostalCode}`}
					/>
					<RepresentmentTextField
						id='email'
						label={t('customerInformation.email')}
						isFlexed={true}
						defaultValue={data.email}
					/>
				</Stack>
			</RepresentmentBuilderBox>
		</Grid>
	);
};

CustomerInformation.propTypes = {
	data: PropTypes.object,
	t: PropTypes.func
};

export default withTranslation('representments')(CustomerInformation);
