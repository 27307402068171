import { fromJS } from 'immutable';
import * as actionTypes from './actionTypes';

export const initialState = fromJS({
	isCreateLayoutModalOpen: false,
	isInviteUserModalOpen: false,
	isManageDashboardModalOpen: false,
	isMultiFactorAuthSetupModalOpen: false,
	isRecordingsModalOpen: false,
	isUpdateAvatarModalOpen: false,
	isNewNotificationModalOpen: false,
	isReleaseNotesModalOpen: false,
	attachmentsModalData: undefined,
	deleteUserModal: undefined,
	permissionsModalUser: undefined,
	updateUserStatusModal: undefined
});

export const modalReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.TOGGLE_CREATE_LAYOUT_MODAL:
			return state.set(
				'isCreateLayoutModalOpen',
				!state.get('isCreateLayoutModalOpen')
			);

		case actionTypes.TOGGLE_INVITE_USER_MODAL:
			return state.set(
				'isInviteUserModalOpen',
				!state.get('isInviteUserModalOpen')
			);

		case actionTypes.TOGGLE_MANAGE_DASHBOARD_MODAL:
			return state.set(
				'isManageDashboardModalOpen',
				!state.get('isManageDashboardModalOpen')
			);

		case actionTypes.TOGGLE_MULTI_FACTOR_AUTH_SETUP_MODAL:
			return state.set(
				'isMultiFactorAuthSetupModalOpen',
				!state.get('isMultiFactorAuthSetupModalOpen')
			);

		case actionTypes.TOGGLE_RECORDINGS_MODAL:
			return state.set(
				'isRecordingsModalOpen',
				!state.get('isRecordingsModalOpen')
			);

		case actionTypes.TOGGLE_UPDATE_AVATAR_MODAL:
			return state.set(
				'isUpdateAvatarModalOpen',
				!state.get('isUpdateAvatarModalOpen')
			);

		case actionTypes.TOGGLE_NEW_NOTIFICATION_MODAL:
			return state.set(
				'isNewNotificationModalOpen',
				!state.get('isNewNotificationModalOpen')
			);

		case actionTypes.TOGGLE_RELEASE_NOTES_MODAL:
			return state.set(
				'isReleaseNotesModalOpen',
				!state.get('isReleaseNotesModalOpen')
			);

		case actionTypes.SET_ATTACHMENTS_MODAL_DATA:
			return state.set('attachmentsModalData', action.payload);

		case actionTypes.SET_PERMISSIONS_MODAL_USER:
			return state.set('permissionsModalUser', action.payload);

		case actionTypes.SET_UPDATE_USER_STATUS_MODAL:
			return state.set('updateUserStatusModal', action.payload);

		case actionTypes.SET_DELETE_USER_MODAL:
			return state.set('deleteUserModal', action.payload);

		default:
			return state;
	}
};
