import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { withTranslation } from 'react-i18next';

import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

const SupplementalTypography = styled(Typography)(({ theme }) => ({
	width: 600,
	color: theme.palette.primary.main
}));

const Header = (props) => {
	const { appliedTo, userRuleDescription, name, t } = props;

	return (
		<Stack direction='column' m={1} spacing={1}>
			<Typography variant='h5'>
				{t(name)}
			</Typography>
			<Stack
				direction='row'
				alignItems='center'
				spacing={1}
				divider={<Divider orientation='vertical' />}
			>
				<Stack direction='column' spacing={2} sx={{ width: 'fit-content' }}>
					<Typography variant='overline'>
						{t('appliedTo')}
					</Typography>
					<Typography variant='overline'>
						{t('ruleDescription')}
					</Typography>
				</Stack>
				<Stack direction='column' spacing={2}>
					<SupplementalTypography variant='body1'>
						{appliedTo}
					</SupplementalTypography>
					<SupplementalTypography variant='body1'>
						{userRuleDescription}
					</SupplementalTypography>
				</Stack>
			</Stack>
		</Stack>
	);
};

Header.propTypes = {
	appliedTo: PropTypes.string,
	userRuleDescription: PropTypes.string,
	name: PropTypes.string,
	t: PropTypes.func
};

export default withTranslation('insightReports')(Header);
