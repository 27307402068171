import React from 'react';
import PropTypes from 'prop-types';
import { capitalCase } from 'change-case';

import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';

const ErrorDisplay = (props) => {
	const { submitErrors } = props;

	if (!submitErrors) {
		return null;
	}

	return (
		<Grid
			container
			component={Paper}
			columnSpacing={1}
			rowSpacing={1}
			p={1}
			alignSelf='center'
			sx={{ maxWidth: '900px', border: 1, borderColor: 'error.main' }}
		>
			{submitErrors.map((error) => (
				<Grid item key={error.type}>
					<Chip
						color='error'
						label={capitalCase(error.message)}
					></Chip>
				</Grid>
			))}
		</Grid>
	);
};

ErrorDisplay.propTypes = {
	submitErrors: PropTypes.array
};

export default ErrorDisplay;
