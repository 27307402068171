import * as actionTypes from './actionTypes';

export const getRepresentment = (payload) => ({
	type: actionTypes.GET_REPRESENTMENT,
	payload
});

export const getRepresentmentCompleted = (payload) => ({
	type: actionTypes.GET_REPRESENTMENT_COMPLETED,
	payload
});

export const getRepresentmentFailed = (payload) => ({
	type: actionTypes.GET_REPRESENTMENT_FAILED,
	payload
});

export const updateRepresentment = (payload) => ({
	type: actionTypes.UPDATE_REPRESENTMENT,
	payload
});

export const updateRepresentmentCompleted = (payload) => ({
	type: actionTypes.UPDATE_REPRESENTMENT_COMPLETED,
	payload
});

export const updateRepresentmentFailed = (payload) => ({
	type: actionTypes.UPDATE_REPRESENTMENT_FAILED,
	payload
});

export const markSentToProcessor = (payload) => ({
	type: actionTypes.MARK_SENT_TO_PROCESSOR,
	payload
});

export const markSentToProcessorCompleted = (payload) => ({
	type: actionTypes.MARK_SENT_TO_PROCESSOR_COMPLETED,
	payload
});

export const markSentToProcessorFailed = (payload) => ({
	type: actionTypes.MARK_SENT_TO_PROCESSOR_FAILED,
	payload
});

export const buildRepresentment = (payload) => ({
	type: actionTypes.BUILD_REPRESENTMENT,
	payload
});

export const buildRepresentmentCompleted = (payload) => ({
	type: actionTypes.BUILD_REPRESENTMENT_COMPLETED,
	payload
});

export const buildRepresentmentFailed = (payload) => ({
	type: actionTypes.BUILD_REPRESENTMENT_FAILED,
	payload
});

export const clearRepresentment = (payload) => ({
	type: actionTypes.CLEAR_REPRESENTMENT,
	payload
});
