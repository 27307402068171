import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { withTranslation } from 'react-i18next';
import { capitalCase, sentenceCase, headerCase } from 'change-case';
import { groupedColumns, rowColumns } from './constants/columnDefinitions';

import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import ChargebackActivityRow from './components/ChargebackActivityRow';

const StyledFirstCell = styled(TableCell)({
	width: '150px',
	fontSize: '1.2rem',
	fontWeight: 600
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	borderLeft: `1px solid ${theme.palette.common.black}`,
	fontWeight: 600
}));

const formatColumnGroupKey = (key) => {
	return headerCase(capitalCase(sentenceCase(key)));
};

const DailyChargebackActivityTable = (props) => {
	const { cardBrand, data, dateRangeEnd, t } = props;

	return (
		<TableContainer
			sx={(theme) => ({ borderRadius: '5px', border: `1px solid ${theme.palette.common.black}` })}
		>
			<Table size='small'>
				<TableHead>
					<TableRow>
						<StyledFirstCell>
							{capitalCase(cardBrand)}
						</StyledFirstCell>
						{groupedColumns.map((columnGroup) => (
							<StyledTableCell
								key={columnGroup.key}
								colSpan={columnGroup.columnSpan}
							>
								<Stack direction='row' justifyContent='space-between'>
									<Typography
										sx={{ fontSize: '.8rem', fontWeight: 600 }}
									>
										{columnGroup.translationPath
											? t(columnGroup.translationPath)
											: formatColumnGroupKey(columnGroup.key)}
									</Typography>
									<Typography paragraph={false} sx={{ fontSize: '.8rem' }}>
										{columnGroup.dateGenerator(dateRangeEnd)}
									</Typography>
								</Stack>
							</StyledTableCell>
						))}
					</TableRow>
					<TableRow>
						<StyledFirstCell />
						{rowColumns.map((column, columnIndex) => (
							<TableCell
								align='center'
								key={columnIndex}
								sx={(theme) => ({ borderLeft: column.isFirstColumnInGroup && `1px solid ${theme.palette.common.black}` })}
							>
								{t(column.translationPath)}
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					<ChargebackActivityRow
						rowKey={'chargebacksReceived'}
						rowLabel={t('dailyChargebackReport.received')}
						data={data}
						useRounded={true}
					/>
					<ChargebackActivityRow
						rowKey={'transactions'}
						rowLabel={t('dailyChargebackReport.transactions')}
						data={data}
						useRounded={true}
					/>
					<ChargebackActivityRow
						rowKey={'chargebackPercent'}
						useRounded={false}
						rowLabel={t('dailyChargebackReport.chargebackPercent')}
						data={data}
					/>
					<ChargebackActivityRow
						rowKey={'chargebackUnitsUnderOver'}
						rowLabel={t('dailyChargebackReport.chargebackUnitsOver')}
						data={data}
						useRounded={true}
					/>
					<ChargebackActivityRow
						rowKey={'transactionsUnderOver'}
						rowLabel={t('dailyChargebackReport.transactionsOver')}
						data={data}
						useRounded={true}
					/>
				</TableBody>
			</Table>
		</TableContainer>
	);
};

DailyChargebackActivityTable.propTypes = {
	data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	dateRangeEnd: PropTypes.string,
	cardBrand: PropTypes.string,
	t: PropTypes.func
};

export default withTranslation('reports')(DailyChargebackActivityTable);
