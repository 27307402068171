import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import columnDefinitions from '../../constants/columnDefinitions';
import { AlternatingBackgroundTableRow } from '../../../../ReportStylings';
import { formatDateForDisplay } from '../../../../../utilities/dateWrapper';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

const RollupLabel = styled(Typography)({
	whiteSpace: 'nowrap',
	maxWidth: '10%',
	fontSize: '14px',
	fontWeight: 600
});

const RollupDataTable = (props) => {
	const { processorId, lookupDate, rowData, midTableOpen, t } = props;

	const navigate = useNavigate();

	const handleDailyRedirect = (merchantIdList) => {
		const formattedLookupDate = formatDateForDisplay(lookupDate);

		navigate(
			`/reports/f6b37d9d-97e7-4a9c-90a4-2606eaedf227?processorId=${processorId}&merchantIds=${merchantIdList}&lookupDate=${formattedLookupDate}&autoApply=true`
		);
	};

	return (
		<TableContainer sx={(theme) => ({ borderRadius: '5px', border: `1px solid ${theme.palette.common.offWhite}` })}>
			<Table size='small'>
				<TableHead>
					<TableRow>
						<TableCell sx={{ fontWeight: 700, fontSize: '16px', width: '25%' }}>
							{t('chargebackSummary.columnNames.rollups')}
						</TableCell>
						{columnDefinitions.map((column, index) => {
							return (
								<TableCell
									key={index}
									style={{
										...column.headerStyle,
										visibility: midTableOpen ? 'hidden' : 'visible'
									}}
								>
									{column.titleTranslationPath
										? t(column.titleTranslationPath)
										: ''}
								</TableCell>
							);
						})}
						<TableCell sx={{ width: '7%' }} />
						<TableCell sx={{ width: '7%' }} />
					</TableRow>
				</TableHead>
				<TableBody>
					{rowData.map((row, index) => {
						return (
							<AlternatingBackgroundTableRow key={index}>
								<TableCell>
									<Stack direction='column'>
										<RollupLabel>
											{row.rollupLabel}
										</RollupLabel>
										<Typography variant='caption'>
											{row.rollupAbbreviation}
										</Typography>
									</Stack>
								</TableCell>
								{columnDefinitions.map((column, index) => {
									return (
										<TableCell key={index}>
											{column.formatter
												? column.formatter(row[column.field])
												: row[column.field]}
										</TableCell>
									);
								})}
								<TableCell key={index}>
									<Stack
										direction='column'
										justifyContent='center'
										alignItems='center'
									>
										<Button
											size='small'
											variant='outlined'
											color='primary'
											onClick={() => handleDailyRedirect(row.merchantIdList)}
										>
											{t('chargebackSummary.actions.daily')}
										</Button>
									</Stack>
								</TableCell>
								<TableCell />
							</AlternatingBackgroundTableRow>
						);
					})}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

RollupDataTable.propTypes = {
	midTableOpen: PropTypes.bool,
	cardType: PropTypes.string,
	processorId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	lookupDate: PropTypes.string,
	rowData: PropTypes.array,
	rowSummary: PropTypes.object,
	t: PropTypes.func
};

export default withTranslation('reports')(RollupDataTable);
