import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

import LoadingButton from '@mui/lab/LoadingButton';

const OutlinedButton = styled(LoadingButton)(({ theme }) => ({
	color: theme.palette.primary.main
}));

const OutlinedLoadingButton = (props) => {
	const { children, disabled, endIcon, loading, onClick, useSmall } = props;

	return (
		<OutlinedButton
			disabled={disabled}
			endIcon={endIcon}
			loading={loading}
			onClick={onClick}
			variant='outlined'
			fullWidth
			size={useSmall ? 'small' : 'medium'}
		>
			{children}
		</OutlinedButton>
	);
};

OutlinedLoadingButton.propTypes = {
	children: PropTypes.node,
	color: PropTypes.string,
	disabled: PropTypes.bool,
	endIcon: PropTypes.element,
	loading: PropTypes.bool,
	useSmall: PropTypes.bool,
	onClick: PropTypes.func,
	sx: PropTypes.object
};

export default OutlinedLoadingButton;
