export default function (state, action, key) {
	const uniqueIdentifier = action.payload.dataSourceKey;

	const existingGroup = state.get(key).get(`${uniqueIdentifier}`);

	if (existingGroup === undefined) {
		return state.setIn(`${key}.${uniqueIdentifier}`.split('.'), {
			data: {},
			error: undefined,
			hasBeenLoaded: false,
			isLoading: true
		});
	}

	if (action.payload.error) {
		return state
			.setIn(`${key}.${uniqueIdentifier}.hasBeenLoaded`.split('.'), true)
			.setIn(`${key}.${uniqueIdentifier}.isLoading`.split('.'), false)
			.setIn(
				`${key}.${uniqueIdentifier}.error`.split('.'),
				action.payload.error
			);
	}

	if (action.payload.data) {
		return state
			.setIn(`${key}.${uniqueIdentifier}.data`.split('.'), action.payload.data)
			.setIn(`${key}.${uniqueIdentifier}.hasBeenLoaded`.split('.'), true)
			.setIn(`${key}.${uniqueIdentifier}.isLoading`.split('.'), false)
			.setIn(`${key}.${uniqueIdentifier}.error`.split('.'), undefined);
	}

	if (action.payload.data === undefined) {
		return state.setIn(
			`${key}.${uniqueIdentifier}.isLoading`.split('.'),
			false
		);
	}

	return state;
}
