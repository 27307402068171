import React from 'react';
import PropTypes from 'prop-types';
import querier from '../../../../../utilities/querier';
import { withTranslation } from 'react-i18next';
import deepCopy from '../../../../../utilities/deepCopy';
import StageAccordion from '../../StageAccordion';
import SeveritySelect from './components/SeveritySelect';
import { SingleSelect, UserSelect } from '../../../shared/inputs';
import runFrequencies from '../../../constants/runFrequencies';
import yesNoOptions from '../../../constants/yesNoOptions';

import Stack from '@mui/material/Stack';

export const NotificationSettingsSelect = (props) => {
	const {
		t,
		ruleConfiguration,
		setRuleConfiguration,
		completedLabel,
		isStageCompleted,
		isStageDisabled,
		submitErrors,
		setSubmitErrors
	} = props;

	const onUpdateSettingSelections = (property, value) => {
		const updatedConfiguration = deepCopy(ruleConfiguration);

		updatedConfiguration[property] = value;

		setRuleConfiguration(updatedConfiguration);
	};

	return (
		<Stack direction='row' justifyContent='center'>
			<StageAccordion
				stageLabel={t('notificationSettings')}
				collapsedSummaryText={completedLabel}
				isAccordionExpanded={true}
				isCompletedFlag={isStageCompleted}
				isDisabled={false}
				hasError={querier(submitErrors, '$[type = "severity"]')}
			>
				<Stack direction='column' spacing={2}>
					<SeveritySelect
						ruleConfiguration={ruleConfiguration}
						setRuleConfiguration={setRuleConfiguration}
						isDisabled={isStageDisabled}
						submitErrors={submitErrors}
						setSubmitErrors={setSubmitErrors}
					/>
					<SingleSelect
						onChange={onUpdateSettingSelections}
						translationPath={'rules:frequency'}
						variable={'runFrequency'}
						value={ruleConfiguration.runFrequency}
						options={runFrequencies}
						isDisabled={isStageDisabled}
					/>
					<SingleSelect
						onChange={onUpdateSettingSelections}
						translationPath={'rules:ignoreNonTriggerEvents'}
						helperTextPath={'ignoreHelperText'}
						variable={'ignoreNonTriggered'}
						value={ruleConfiguration.ignoreNonTriggered}
						options={yesNoOptions}
						isDisabled={isStageDisabled}
					/>
					<UserSelect
						onChange={onUpdateSettingSelections}
						translationPath={'rules:autoAssignUser'}
						helperTextPath={'rules:autoAssignHelperText'}
						variable='autoAssignUserId'
						value={ruleConfiguration.autoAssignUserId}
						isDisabled={isStageDisabled}
					/>
				</Stack>
			</StageAccordion>
		</Stack>
	);
};

NotificationSettingsSelect.propTypes = {
	t: PropTypes.func,
	ruleConfiguration: PropTypes.object,
	setRuleConfiguration: PropTypes.func,
	completedLabel: PropTypes.string,
	isStageCompleted: PropTypes.bool,
	isStageDisabled: PropTypes.bool,
	submitErrors: PropTypes.array,
	setSubmitErrors: PropTypes.func
};

export default withTranslation('rules')(NotificationSettingsSelect);
