import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { modalActions } from '../../containers/Modal';
import { usersActions } from '../../containers/Users';
import {
	usersTableColumns,
	pendingUsersTableColumns
} from './shared/constants/columnDefinitions';
import {
	usersTableSearchableColumns,
	pendingInvitesSearchableColumns
} from './shared/constants/searchableColumns';
import DeleteUserModal from './DeleteUserModal';
import EnhancedTableToolbar from './shared/EnhancedTableToolbar';
import UpdateUserStatusModal from './UpdateUserStatusModal';
import UserPermissionsModal from './UserPermissionsModal';
import SortableTable from '../SortableTable';
import UsersTableRow from './UsersTable/UsersTableRow';
import PendingUsersRow from './PendingUsersTable/PendingUsersTableRow';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';

const UsersManager = (props) => {
	const {
		getUsers,
		getPendingUsers,
		hasLoadedPendingUsers,
		isLoadingPendingUsers,
		hasLoadedUsers,
		isLoadingUsers,
		pendingUsers,
		resetUsers,
		t,
		toggleInviteUserModal,
		users
	} = props;

	const [searchTerm, setSearchTerm] = React.useState('');
	const [tabValue, setTabValue] = React.useState('1');

	React.useEffect(() => {
		if (!isLoadingUsers && tabValue === '1') {
			getUsers();
		} else if (!isLoadingPendingUsers && tabValue === '2') {
			getPendingUsers();
		};
	}, [tabValue]);

	React.useEffect(() => {
		return () => {
			resetUsers();
		};
	}, [resetUsers]);

	const handleTabChange = (e, newValue) => {
		setSearchTerm('');
		setTabValue(newValue);
	};

	const handleClearSearchTerm = () => {
		setSearchTerm('');
	};

	const onHandleInviteUserClicked = () => {
		toggleInviteUserModal();
	};

	return (
		<Box sx={{ marginTop: 2, height: 'calc(100% - 80px)', overflow: 'auto' }}>
			<Stack spacing={1} mb={2}>
				<EnhancedTableToolbar
					searchTerm={searchTerm}
					setSearchTerm={setSearchTerm}
					onClearSearchTerm={handleClearSearchTerm}
					inviteUserClicked={onHandleInviteUserClicked}
				/>
				<TabContext value={tabValue}>
					<TabList onChange={handleTabChange} variant='fullWidth'>
						<Tab label={t('usersTable.tabs.active')} value={'1'} />
						<Tab label={t('usersTable.tabs.pending')} value={'2'} />
					</TabList>
					<TabPanel value={'1'}>
						<SortableTable
							data={users}
							isLoading={isLoadingUsers}
							hasBeenLoaded={hasLoadedUsers}
							initialSortColumn={'lastName'}
							TableRowComponent={UsersTableRow}
							columnDefinitions={usersTableColumns}
							translationContext={'users'}
							searchTerm={searchTerm}
							searchableColumns={usersTableSearchableColumns}
						/>
					</TabPanel>
					<TabPanel value={'2'}>
						<SortableTable
							data={pendingUsers}
							isLoading={isLoadingPendingUsers}
							hasBeenLoaded={hasLoadedPendingUsers}
							initialSortColumn={'email'}
							TableRowComponent={PendingUsersRow}
							columnDefinitions={pendingUsersTableColumns}
							translationContext={'users'}
							searchTerm={searchTerm}
							searchableColumns={pendingInvitesSearchableColumns}
						/>
					</TabPanel>
				</TabContext>
			</Stack>
			<UserPermissionsModal />
			<UpdateUserStatusModal />
			<DeleteUserModal />
		</Box>
	);
};

UsersManager.propTypes = {
	getUsers: PropTypes.func,
	getPendingUsers: PropTypes.func,
	hasLoadedUsers: PropTypes.bool,
	isLoadingUsers: PropTypes.bool,
	hasLoadedPendingUsers: PropTypes.bool,
	isLoadingPendingUsers: PropTypes.bool,
	pendingUsers: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	resetUsers: PropTypes.func,
	t: PropTypes.func,
	toggleInviteUserModal: PropTypes.func,
	users: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

const mapStateToProps = (state) => {
	return {
		hasLoadedUsers: state.usersReducer.getIn(['users', 'hasBeenLoaded']),
		isLoadingUsers: state.usersReducer.getIn(['users', 'isLoading']),
		hasLoadedPendingUsers: state.usersReducer.getIn([
			'pendingUsers',
			'hasBeenLoaded'
		]),
		isLoadingPendingUsers: state.usersReducer.getIn([
			'pendingUsers',
			'isLoading'
		]),
		pendingUsers: state.usersReducer.getIn(['pendingUsers', 'data']),
		users: state.usersReducer.getIn(['users', 'data'])
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getUsers: (payload) => {
			dispatch(usersActions.getUsers(payload));
		},
		getPendingUsers: (payload) => {
			dispatch(usersActions.getPendingUsers(payload));
		},
		resetUsers: (payload) => {
			dispatch(usersActions.resetUsers(payload));
		},
		toggleInviteUserModal: () => {
			dispatch(modalActions.toggleInviteUserModal());
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation('users')(UsersManager));
