import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { numberWithCommas } from '../../../../utilities/formatNumber';
import { getUserInputError } from '../validators/validateUserInput';
import OverlineTextError from '../../../errors/OverlineTextError';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';

const PercentageInput = (props) => {
	const {
		t,
		onChange,
		translationPath,
		variable,
		value,
		hiddenLabel,
		isSmall,
		hasError
	} = props;

	const [inputInvalid, setInputInvalid] = React.useState();

	const validatePercentValue = (value) => {
		const error = getUserInputError(value, 'percent', t);

		const numbersOnly = value.toString().replace(/[^0-9.-]/g, '');

		if (error) {
			setInputInvalid(error);
			onChange(variable, numbersOnly);
		} else {
			setInputInvalid();
			onChange(variable, numbersOnly ? numberWithCommas(numbersOnly) : '');
		}
	};

	return (
		<Box pb={1}>
			{hiddenLabel ? null : (
				<InputLabel htmlFor={t(translationPath)} color='secondary' sx={{ my: '3px' }}>
					{t(translationPath)}
				</InputLabel>
			)}
			<TextField
				sx={{ width: '400px' }}
				error={!!(hasError || inputInvalid)}
				size={isSmall ? 'small' : undefined}
				value={value || ''}
				onChange={(e) => {
					validatePercentValue(e.target.value);
				}}
				helperText={
					inputInvalid ? (
						<OverlineTextError>
							{inputInvalid}
						</OverlineTextError>
					) : (
						t('inputs.percentageHelperText')
					)
				}
			/>
		</Box>
	);
};

PercentageInput.propTypes = {
	t: PropTypes.func,
	onChange: PropTypes.func,
	translationPath: PropTypes.string,
	variable: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	hiddenLabel: PropTypes.bool,
	isSmall: PropTypes.bool,
	hasError: PropTypes.bool
};

export default withTranslation('rules')(PercentageInput);
