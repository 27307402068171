import React from 'react';
import PropTypes from 'prop-types';

import Avatar from '@mui/material/Avatar';

export const AvatarDisplay = (props) => {
	const { uploadedAvatar, size } = props;

	return (
		<Avatar
			src={uploadedAvatar}
			variant='circular'
			sx={{
				backgroundColor: (theme) => theme.palette.secondary.alt,
				width: size || 100,
				height: size || 100
			}}
		/>
	);
};

AvatarDisplay.propTypes = {
	uploadedAvatar: PropTypes.string,
	size: PropTypes.number
};

export default AvatarDisplay;
