import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import My360Header from '../../components/my360/Header';
import EditGroup from '../../components/Groups/EditGroup';
import Restricted from '../../permissionProvider/Restricted';
import RestrictedFallback from '../../components/shared/RestrictedFallback';

import Stack from '@mui/material/Stack';

export const EditGroupPage = ({ t }) => {
	return (
		<Stack p={1}>
			<Restricted
				to='groups.groups.edit'
				Fallback={
					<RestrictedFallback overrideMessage={t('common:error.notAllowed')} />
				}
			>
				<My360Header label={t('groups:editGroup')} />
				<EditGroup />
			</Restricted>
		</Stack>
	);
};

EditGroupPage.propTypes = {
	t: PropTypes.func
};

export default withTranslation(['groups', 'common'])(EditGroupPage);
