import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import RepresentmentTextField from './components/RepresentmentTextField';
import BuilderSectionHeader from '../BuilderSectionHeader';
import RepresentmentBuilderBox from '../RepresentmentBuilderStack';
import { formatDateForDisplay } from '../../../utilities/dateWrapper';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

export const ChargebackInformation = (props) => {
	const { t, data } = props;

	return (
		<Grid item xs={12} sm={12} md={6} lg={6}>
			<RepresentmentBuilderBox>
				<BuilderSectionHeader>{t('chargebackInformation.label')}</BuilderSectionHeader>
				<Stack direction='row'>
					<RepresentmentTextField
						id='caseId'
						label={t('chargebackInformation.caseId')}
						defaultValue={data.caseId}
					/>
					<RepresentmentTextField
						id='postedOn'
						label={t('chargebackInformation.postedOn')}
						defaultValue={formatDateForDisplay(data.postedOn, 'YYYY-MM-DD')}
					/>
				</Stack>
				<Stack direction='row'>
					<RepresentmentTextField
						id='code'
						label={t('chargebackInformation.code')}
						defaultValue={data.code}
					/>
					<RepresentmentTextField
						id='amount'
						label={t('chargebackInformation.amount')}
						defaultValue={`${data.amount}`}
					/>
				</Stack>
			</RepresentmentBuilderBox>
		</Grid>
	);
};

ChargebackInformation.propTypes = {
	data: PropTypes.object,
	t: PropTypes.func
};

export default withTranslation('representments')(ChargebackInformation);
