import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { authActions } from '../../containers/Auth';
import BorderedPaper from '../branded/BorderedPaper';
import ContainedLoadingButton from '../buttons/ContainedLoadingButton';
import BoldLargeHeader from '../headers/BoldLargeHeader';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

const ForgotPasswordForm = (props) => {
	const { hasSentCode, isSendingResetCode, t } = props;

	const [username, setUsername] = React.useState('');

	const navigate = useNavigate();

	React.useEffect(() => {
		if (hasSentCode) {
			navigate('/password-reset', { state: { username } });
		}
	}, [hasSentCode]);

	const handleGoBack = () => {
		navigate('/login');
	};

	const onSubmit = (e) => {
		const { sendResetCode } = props;

		sendResetCode({
			username
		});
	};

	return (
		<BorderedPaper>
			<Stack direction='row' justifyContent='flex-start' ml={1} mt={0.5}>
				<IconButton onClick={handleGoBack}>
					<KeyboardArrowLeftIcon sx={{ fontSize: '2rem' }} color='primary' />
				</IconButton>
			</Stack>
			<Box px={4}>
				<Stack direction='row' justifyContent='center' my={2} sx={{ px: 4 }}>
					<BoldLargeHeader>
						{t('passwordReset.title')}
					</BoldLargeHeader>
				</Stack>
				<Stack p={1} mx={4} spacing={1} mt={2}>
					<FormControl>
						<label>{t('passwordReset.input.enterUsername')}</label>
						<TextField
							size='small'
							onChange={(e) => setUsername(e.target.value)}
							type='text'
							InputLabelProps={{
								shrink: true
							}}
							variant='outlined'
							value={username}
						/>
					</FormControl>
				</Stack>
				<Stack direction='row' justifyContent='center' mb={2} mt={2}>
					<ContainedLoadingButton
						bgcolor='primary.alt'
						loading={isSendingResetCode}
						disabled={username.length < 1}
						onClick={onSubmit}
					>
						{t('passwordReset.sendResetCode')}
					</ContainedLoadingButton>
				</Stack>
			</Box>
		</BorderedPaper>
	);
};

ForgotPasswordForm.propTypes = {
	hasSentCode: PropTypes.bool,
	isSendingResetCode: PropTypes.bool,
	sendResetCode: PropTypes.func,
	t: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		hasSentCode: state.authReducer.getIn([
			'passwordReset',
			'hasSentResetEmail'
		]),
		isSendingResetCode: state.authReducer.getIn([
			'passwordReset',
			'isSendingResetCode'
		])
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		sendResetCode: (payload) => {
			dispatch(authActions.sendPasswordResetCode(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation('registration')(ForgotPasswordForm));
