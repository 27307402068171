const newUserDefaultConfiguration = {
	username: '',
	firstName: '',
	lastName: '',
	phone: '',
	department: '',
	avatar: undefined
};

export default newUserDefaultConfiguration;
