import { fromJS } from 'immutable';
import * as actionTypes from './actionTypes';

export const initialState = fromJS({
	token: undefined,
	expiration: undefined,
	siteId: undefined,
	error: undefined,
	hasBeenLoaded: false,
	isLoading: false
});

export const tableauReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GENERATE_TOKEN:
			return state.set('isLoading', true);

		case actionTypes.GENERATE_TOKEN_COMPLETED:
			return state
				.set('token', action.payload.token)
				.set('expiration', action.payload.expiration)
				.set('siteId', action.payload.siteId)
				.set('isLoading', false)
				.set('hasBeenLoaded', true);

		case actionTypes.GENERATE_TOKEN_FAILED:
			return state.set('error', action.payload).set('isLoading', false);

		case actionTypes.RESET_TOKEN:
			return state
				.set('token', undefined)
				.set('expiration', undefined)
				.set('siteId', undefined)
				.set('hasBeenLoaded', false);

		default:
			return state;
	}
};
