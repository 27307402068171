import axios from '../../utilities/axios';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { refreshToken } from '../../utilities/refreshToken';
import { primaryRestGateway } from '../../utilities/apiEndpointUtility';

import {
	getResolved,
	getResolvedCompleted,
	getResolvedFailed
} from './resolvedSlice';

export const getResolvedEpic = (action$) =>
	action$.pipe(
		ofType(getResolved),
		mergeMap(async (action) => {
			await refreshToken();

			const { startDate, endDate, processorId } = action.payload;

			const response = await axios.get(
				`${primaryRestGateway()}/api/representments/inventory/worked`, {
					params: {
						startDate,
						endDate,
						processorId
					}
				}
			);

			return response.data;
		}),
		switchMap((res) => [
			getResolvedCompleted(res)
		]),
		catchError((error, source) =>
			merge(of(getResolvedFailed(error.message)), source)
		)
	);

export default [
	getResolvedEpic
];
