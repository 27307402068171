import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { modalActions } from '../../../containers/Modal';
import { userActions } from '../../../containers/User';
import { usersActions } from '../../../containers/Users';
import ModalBody from './ModalBody';
import CompletionPanel from './CompletionPanel';
import GenericModalPaperWrapper from '../../modals/GenericModalPaperWrapper';

import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

const UpdateUserStatusModal = (props) => {
	const {
		getUsers,
		isUpdatingUserStatus,
		resetUserStatusProcess,
		successfullyUpdatedUserStatus,
		selectUserInfo,
		setUpdateUserStatusModal,
		updateUserStatus
	} = props;

	React.useEffect(() => {
		if (!isUpdatingUserStatus && successfullyUpdatedUserStatus) {
			getUsers();
		}
	}, [isUpdatingUserStatus, successfullyUpdatedUserStatus]);

	const handleOnClose = () => {
		setUpdateUserStatusModal();
	};

	const onUpdateUserStatus = () => {
		updateUserStatus({
			userId: selectUserInfo?.id,
			isEnabled: selectUserInfo?.isEnabled,
			username: selectUserInfo?.username
		});
	};

	const handleSuccessClose = () => {
		resetUserStatusProcess();
		setUpdateUserStatusModal();
	};

	const isEnabled = selectUserInfo?.isEnabled;

	return (
		<GenericModalPaperWrapper open={!!selectUserInfo} isLoading={isUpdatingUserStatus}>
			{isUpdatingUserStatus ? (
				<Stack
					direction='row'
					justifyContent='center'
					alignItems='center'
					sx={{ height: '350px' }}
				>
					<CircularProgress color='primary' />
				</Stack>
			) : null}
			{!successfullyUpdatedUserStatus && !isUpdatingUserStatus ? (
				<ModalBody
					handleOnClose={handleOnClose}
					isEnabled={isEnabled}
					onUpdate={onUpdateUserStatus}
					selectUserInfo={selectUserInfo}
				/>
			) : null}
			{successfullyUpdatedUserStatus ? (
				<CompletionPanel
					onClose={handleSuccessClose}
					isOpen={successfullyUpdatedUserStatus}
				/>
			) : null}
		</GenericModalPaperWrapper>
	);
};

UpdateUserStatusModal.propTypes = {
	getUsers: PropTypes.func,
	isUpdatingUserStatus: PropTypes.bool,
	resetUserStatusProcess: PropTypes.func,
	successfullyUpdatedUserStatus: PropTypes.bool,
	selectUserInfo: PropTypes.object,
	setUpdateUserStatusModal: PropTypes.func,
	updateUserStatus: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		isUpdatingUserStatus: state.userReducer.get('isUpdatingUserStatus'),
		successfullyUpdatedUserStatus: state.userReducer.get(
			'successfullyUpdatedUserStatus'
		),
		selectUserInfo: state.modalReducer.get('updateUserStatusModal')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getUsers: (payload) => {
			dispatch(usersActions.getUsers(payload));
		},
		resetUserStatusProcess: (payload) => {
			dispatch(userActions.resetUserStatusProcess(payload));
		},
		setUpdateUserStatusModal: (payload) => {
			dispatch(modalActions.setUpdateUserStatusModal(payload));
		},
		updateUserStatus: (payload) => {
			dispatch(userActions.updateUserStatus(payload));
		}
	};
};

export default connect(mapStateToProps,	mapDispatchToProps)(UpdateUserStatusModal);
