import axios from '../../utilities/axios';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { refreshToken } from '../../utilities/refreshToken';
import { primaryRestGateway } from '../../utilities/apiEndpointUtility';

import * as actionTypes from './actionTypes';
import * as actions from './actions';

export const sendEmailEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.SEND_EMAIL),
		mergeMap(async (action) => {
			await refreshToken();

			const body = {
				messageDetails: action.payload
			};

			const response = await axios.post(
				`${primaryRestGateway()}/services/email/sendEmail`,
				body
			);

			return response;
		}),
		switchMap((res) => [actions.sendEmailCompleted(res.data)]),
		catchError((error, source) =>
			merge(of(actions.sendEmailFailed(error.message)), source)
		)
	);

export const sendTextEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.SEND_TEXT),
		mergeMap(async (action) => {
			await refreshToken();

			const response = await axios.post(
				`${primaryRestGateway()}/services/mobile`,
				action.payload
			);

			if (!response.data) {
				throw Error('Issue Sending Text Message');
			}

			return response.data;
		}),
		switchMap((res) => [actions.sendTextCompleted(res)]),
		catchError((error, source) =>
			merge(of(actions.sendTextFailed(error.message)), source)
		)
	);
