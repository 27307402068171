import axios from '../../utilities/axios';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { refreshToken } from '../../utilities/refreshToken';
import { primaryRestGateway } from '../../utilities/apiEndpointUtility';

import {
	getReportViews,
	getReportViewsCompleted,
	getReportViewsFailed
} from './reportViewsSlice';

export const getReportViewsEpic = (action$) =>
	action$.pipe(
		ofType(getReportViews),
		mergeMap(async (action) => {
			await refreshToken();

			const response = await axios.get(`${primaryRestGateway()}/api/reports?groupByCategory=true`);

			return response.data;
		}),
		switchMap((res) => [getReportViewsCompleted(res)]),
		catchError((error, source) =>
			merge(of(getReportViewsFailed(error.message)), source)
		)
	);

export default [
	getReportViewsEpic
];
