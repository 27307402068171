import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	affiliates: {
		data: [],
		isLoading: false,
		hasBeenLoaded: false,
		error: undefined
	}
};

export const affiliatesSlice = createSlice({
	name: 'affiliates',
	initialState,
	reducers: {
		getAffiliates: (state) => {
			state.affiliates.isLoading = true;
			state.affiliates.error = undefined;
		},
		getAffiliatesCompleted: (state, action) => {
			state.affiliates.isLoading = false;
			state.affiliates.hasBeenLoaded = true;
			state.affiliates.data = action.payload;
		},
		getAffiliatesFailed: (state, action) => {
			state.affiliates.isLoading = true;
			state.affiliates.error = action.payload;
		},
		clearAffiliates: (state) => {
			state.affiliates = initialState.affiliates;
		}
	}
});

export const {
	getAffiliates,
	getAffiliatesCompleted,
	getAffiliatesFailed,
	clearAffiliates
} = affiliatesSlice.actions;

export default affiliatesSlice.reducer;
