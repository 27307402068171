import React from 'react';
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { authActions } from '../../containers/Auth';
import { multiFactorAuthActions } from '../../containers/MultiFactorAuth';
import { userActions } from '../../containers/User';
import PasswordResetForm from './PasswordResetForm';
import ConfirmationForm from './ConfirmationForm';
import BorderedPaper from '../branded/BorderedPaper';

import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

const PasswordResetManager = (props) => {
	const {
		isResettingPassword,
		hasCompletedPasswordReset,
		resetPassword,
		passwordResetError
	} = props;

	const { state } = useLocation();

	const [temporaryPassword, setTemporaryPassword] = React.useState('');
	const [newUserPassword, setNewUserPassword] = React.useState('');
	const [confirmNewPassword, setConfirmNewPassword] = React.useState('');
	const [doPasswordsMatch, setDoPasswordsMatch] = React.useState(true);

	const navigate = useNavigate();

	const deAuthorizeUser = () => {
		const { deAuthUser, mfaLogout, clearUser } = props;

		Auth.signOut();
		mfaLogout();
		deAuthUser();
		clearUser();

		navigate('/login');
	};

	React.useEffect(() => {
		if (!state?.username) {
			deAuthorizeUser();
		}
	}, [state]);

	const onSubmitPasswordReset = () => {
		if (newUserPassword !== confirmNewPassword) {
			setDoPasswordsMatch(false);
		} else {
			setDoPasswordsMatch(true);

			resetPassword({
				password: newUserPassword,
				code: temporaryPassword,
				username: state.username
			});
		}
	};

	const handleGoBack = () => {
		deAuthorizeUser();
	};

	return (
		<BorderedPaper>
			<Stack direction='row' justifyContent='flex-start' ml={1} mt={0.5}>
				<IconButton onClick={handleGoBack}>
					<KeyboardArrowLeftIcon sx={{ fontSize: '2rem' }} color='primary' />
				</IconButton>
			</Stack>
			{!hasCompletedPasswordReset ? (
				<PasswordResetForm
					doPasswordsMatch={doPasswordsMatch}
					passwordResetError={passwordResetError}
					isResettingPassword={isResettingPassword}
					temporaryPassword={temporaryPassword}
					setTemporaryPassword={setTemporaryPassword}
					newUserPassword={newUserPassword}
					setNewUserPassword={setNewUserPassword}
					confirmNewPassword={confirmNewPassword}
					setConfirmNewPassword={setConfirmNewPassword}
					onSubmitPasswordReset={onSubmitPasswordReset}
				/>
			) : (
				<ConfirmationForm deAuthUser={deAuthorizeUser} />
			)}
		</BorderedPaper>
	);
};

PasswordResetManager.propTypes = {
	isResettingPassword: PropTypes.bool,
	hasCompletedPasswordReset: PropTypes.bool,
	passwordResetError: PropTypes.string,
	resetPassword: PropTypes.func,
	deAuthUser: PropTypes.func,
	mfaLogout: PropTypes.func,
	clearUser: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		isResettingPassword: state.authReducer.getIn([
			'passwordReset',
			'isResettingPassword'
		]),
		hasCompletedPasswordReset: state.authReducer.getIn([
			'passwordReset',
			'hasCompletedPasswordReset'
		]),
		passwordResetError: state.authReducer.getIn([
			'passwordReset',
			'passwordResetError'
		])
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		resetPassword: (payload) => {
			dispatch(authActions.resetPassword(payload));
		},
		deAuthUser: (payload) => {
			dispatch(authActions.deAuthUser(payload));
		},
		mfaLogout: () => {
			dispatch(multiFactorAuthActions.logout());
		},
		clearUser: () => {
			dispatch(userActions.logout());
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PasswordResetManager);
