import axios from '../../utilities/axios';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { refreshToken } from '../../utilities/refreshToken';
import Logger from '../../utilities/logger';
import { primaryRestGateway } from '../../utilities/apiEndpointUtility';

import * as actions from './actions.js';
import * as actionTypes from './actionTypes.js';

export const getAvailablePermissionsEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.GET_AVAILABLE_PERMISSIONS),
		mergeMap(async (action) => {
			await refreshToken();

			const response = await axios.get(
				`${primaryRestGateway()}/api/permissions/available`
			);

			return response.data;
		}),
		switchMap((res) => [actions.getAvailablePermissionsCompleted(res)]),
		catchError((error, source) => {
			Logger.error('Get History By Outcome Failure', error);

			return merge(
				of(actions.getAvailablePermissionsFailed(error.message)),
				source
			);
		})
	);
