import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const SingleSelect = (props) => {
	const {
		t,
		onChange,
		translationPath,
		variable,
		value,
		options,
		isDisabled,
		helperTextPath
	} = props;

	return (
		<FormControl>
			<InputLabel htmlFor={t(translationPath)} sx={{ bgcolor: 'white', px: 1 }}>
				{t(translationPath)}
			</InputLabel>
			<Select
				id={`${variable}-select`}
				value={value}
				disabled={isDisabled}
				onChange={(e) => onChange(variable, e.target.value)}
			>
				{options.map((option) => {
					return (
						<MenuItem
							key={`${option.variable}-${option.optionId || option.id}`}
							value={option.optionId ? option.optionId : option.id}
						>
							<Stack direction='column'>
								<Typography>
									{option.name
										? t(option.name)
										: option.label
									}
								</Typography>
								{option.isRecommended === 1 ? (
									<Typography variant='caption'>
										{t('recommended')}
									</Typography>
								) : null}
							</Stack>
						</MenuItem>
					);
				})}
			</Select>
			<FormHelperText>
				{helperTextPath ? t(helperTextPath) : null}
			</FormHelperText>
		</FormControl>
	);
};

SingleSelect.propTypes = {
	onChange: PropTypes.func,
	translationPath: PropTypes.string,
	variable: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	options: PropTypes.array,
	isDisabled: PropTypes.bool,
	helperTextPath: PropTypes.string,
	t: PropTypes.func
};

export default withTranslation('rules')(SingleSelect);
