export const creditCardColorCodes = {
	masterCard: {
		base: '#16366f',
		primary: '#d9222a'
	},
	discover: {
		base: '#4d4d4d',
		primary: '#f47216'
	},
	visa: {
		base: '#0e4595',
		primary: '#f2ae14'
	},
	amex: {
		base: '#2557d6',
		primary: '#2557d6'
	}
};
