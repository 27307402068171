import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import ContainedLoadingButton from '../buttons/ContainedLoadingButton';
import BoldLargeHeader from '../headers/BoldLargeHeader';
import OverlineTextError from '../errors/OverlineTextError';
import {
	initialValidationErrors,
	validatePassword
} from '../../validation/password';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';

import SecureValidationInput from '../inputs/SecureValidationInput';

const PasswordResetForm = (props) => {
	const {
		t,
		passwordResetError,
		isResettingPassword,
		temporaryPassword,
		setTemporaryPassword,
		newUserPassword,
		setNewUserPassword,
		confirmNewPassword,
		setConfirmNewPassword,
		onSubmitPasswordReset,
		doPasswordsMatch
	} = props;

	const isBlank = (value) => {
		return value.length < 0 || value === undefined || value === '';
	};

	return (
		<Box>
			<Stack direction='row' justifyContent='center' my={2} sx={{ px: 4 }}>
				<BoldLargeHeader>
					{t('passwordReset.title')}
				</BoldLargeHeader>
			</Stack>
			<Stack direction='row' justifyContent='center' my={1}>
				<OverlineTextError>
					{passwordResetError || ' '}
				</OverlineTextError>
			</Stack>
			<Stack direction='row' justifyContent='center'>
				{!doPasswordsMatch ? (
					<OverlineTextError>
						{t('passwordReset.passwordMismatch')}
					</OverlineTextError>
				) : null}
			</Stack>
			<Stack direction='column' p={1} mx={4} spacing={1}>
				<FormControl>
					<label>{t('passwordReset.input.temporaryPassword')}</label>
					<TextField
						size='small'
						value={temporaryPassword}
						onChange={(e) => setTemporaryPassword(e.target.value)}
						type='text'
						variant='outlined'
					/>
				</FormControl>
				<FormControl>
					<label>{t('passwordReset.input.newPassword')}</label>
					<SecureValidationInput
						value={newUserPassword}
						onChange={(value) => setNewUserPassword(value)}
						initialValidationErrors={initialValidationErrors}
						validator={validatePassword}
						t={t}
					/>
				</FormControl>
				<FormControl>
					<label>{t('passwordReset.input.confirm')}</label>
					<SecureValidationInput
						value={confirmNewPassword}
						onChange={(value) => setConfirmNewPassword(value)}
						initialValidationErrors={initialValidationErrors}
						validator={validatePassword}
						t={t}
					/>
				</FormControl>
			</Stack>
			<Stack direction='row' justifyContent='center' mb={3}>
				<ContainedLoadingButton
					bgcolor='primary.alt'
					loading={isResettingPassword}
					disabled={
						isBlank(temporaryPassword) ||
						isBlank(newUserPassword) ||
						isBlank(confirmNewPassword)
					}
					onClick={onSubmitPasswordReset}
				>
					{t('passwordReset.resetPassword')}
				</ContainedLoadingButton>
			</Stack>
		</Box>
	);
};

PasswordResetForm.propTypes = {
	passwordResetError: PropTypes.string,
	isResettingPassword: PropTypes.bool,
	temporaryPassword: PropTypes.string,
	setTemporaryPassword: PropTypes.func,
	newUserPassword: PropTypes.string,
	setNewUserPassword: PropTypes.func,
	confirmNewPassword: PropTypes.string,
	setConfirmNewPassword: PropTypes.func,
	onSubmitPasswordReset: PropTypes.func,
	doPasswordsMatch: PropTypes.bool,
	t: PropTypes.func
};

export default withTranslation('registration')(PasswordResetForm);
