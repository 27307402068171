import axios from '../../utilities/axios';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { refreshToken } from '../../utilities/refreshToken';
import { primaryRestGateway } from '../../utilities/apiEndpointUtility';

import {
	getRepresentmentReports,
	getRepresentmentReportsCompleted,
	getRepresentmentReportsFailed
} from './representmentReportSlice';

export const getRepresentmentReportsEpic = (action$) =>
	action$.pipe(
		ofType(getRepresentmentReports),
		mergeMap(async (action) => {
			await refreshToken();

			const response = await axios.get(
				`${primaryRestGateway()}/api/representments/reports`
			);

			return response.data;
		}),
		switchMap((res) => [getRepresentmentReportsCompleted(res)]),
		catchError((error, source) =>
			merge(of(getRepresentmentReportsFailed(error.message)), source)
		)
	);

export default [
	getRepresentmentReportsEpic
];
