import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const MostRecentDateForData = (props) => {
	const { chargebackDate, settlementDate, t } = props;

	return (
		<Stack direction='row' m={1} justifyContent='space-around' spacing={2}>
			<Typography variant='subtitle2' sx={{ mr: '25px' }}>{`${t(
				'dailyChargebackReport.chargebacksReportedOn'
			)} ${chargebackDate}`}</Typography>
			<Typography variant='subtitle2' sx={{ ml: '25px' }}>{`${t(
				'dailyChargebackReport.settlementsReportedOn'
			)} ${settlementDate}`}</Typography>
		</Stack>
	);
};

MostRecentDateForData.propTypes = {
	chargebackDate: PropTypes.string,
	settlementDate: PropTypes.string,
	t: PropTypes.func
};

export default withTranslation('reports')(MostRecentDateForData);
