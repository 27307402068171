import * as actionTypes from './actionTypes';

export const validateInvitationId = (payload) => ({
	type: actionTypes.VALIDATE_INVITATION_ID,
	payload
});

export const validateInvitationIdCompleted = (payload) => ({
	type: actionTypes.VALIDATE_INVITATION_ID_COMPLETED,
	payload
});

export const validateInvitationIdFailed = (payload) => ({
	type: actionTypes.VALIDATE_INVITATION_ID_FAILED,
	payload
});

export const submitRegistration = (payload) => ({
	type: actionTypes.SUBMIT_REGISTRATION,
	payload
});

export const submitRegistrationCompleted = (payload) => ({
	type: actionTypes.SUBMIT_REGISTRATION_COMPLETED,
	payload
});

export const submitRegistrationFailed = (payload) => ({
	type: actionTypes.SUBMIT_REGISTRATION_FAILED,
	payload
});

export const cancelInvitation = (payload) => ({
	type: actionTypes.CANCEL_INVITATION,
	payload
});

export const cancelInvitationCompleted = (payload) => ({
	type: actionTypes.CANCEL_INVITATION_COMPLETED,
	payload
});

export const cancelInvitationFailed = (payload) => ({
	type: actionTypes.CANCEL_INVITATION_FAILED,
	payload
});
