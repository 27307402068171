import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import isDataEmpty from '../../../../helpers/shared/isDataEmpty';
import querier from '../../../../utilities/querier';
import deepCopy from '../../../../utilities/deepCopy';
import { dataSourceActions } from '../../../../containers/DataSource';
import validateMerchantDataSource from '../../../../helpers/reporter/validateMerchantDatasource';
import getDynamicDataFromList from '../../../../helpers/shared/dynamicReducers/getDynamicDataFromList';
import MerchantTooltip from '../../../shared/InputToolTips/MerchantTooltip';

import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import ListItem from '@mui/material/ListItem';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const StandardListItem = styled(ListItem)(({ theme }) => ({
	marginLeft: '3px',
	marginTop: '4px',
	marginBottom: '4px',
	cursor: 'pointer',
	'&:hover': {
		backgroundColor: theme.palette.reports.filters.merchantSelectHighlight,
		color: theme.palette.common.white
	}
}));

const SpecialListItem = styled(ListItem)(({ theme }) => ({
	marginLeft: '3px',
	marginTop: '4px',
	marginBottom: '4px',
	cursor: 'pointer',
	'&:hover': {
		backgroundColor: theme.palette.reports.filters.merchantSelectHighlight,
		color: theme.palette.common.white
	},
	fontWeight: 600,
	color: theme.palette.primary.alt
}));

const specialSelectOptions = ['all'];

const MerchantSelect = (props) => {
	const {
		onChange,
		translationPath,
		value,
		dataSource,
		t,
		selectedProcessor,
		dataSources,
		getDataSource,
		disabled,
		widthOverride,
		urlMerchants,
		setUrlMerchants,
		allMerchantsSelected,
		setAllMerchantsSelected
	} = props;

	const [inputValue, setInputValue] = React.useState('');
	const [open, setOpen] = React.useState(false);
	const [options, setOptions] = React.useState([]);
	const loading = options.length === 0 && open;

	const matchedDataSource = getDynamicDataFromList(dataSources, dataSource);

	React.useEffect(() => {
		const queryParameters = `?processorId=${selectedProcessor}`;

		if (selectedProcessor) {
			getDataSource({
				dataSourceKey: dataSource,
				queryParameters
			});
			setOptions([]);
			setAllMerchantsSelected(false);

			if (value[0]?.paymentEnablerAcquiringBankId !== selectedProcessor) {
				onChange([]);
			}
		}
	}, [selectedProcessor]);

	React.useEffect(() => {
		if (open && matchedDataSource?.data) {
			let updatedOptions = matchedDataSource.data;

			if (!allMerchantsSelected) {
				updatedOptions = [
					{
						merchantId: 'all',
						label: 'Select All'
					},
					...updatedOptions
				];
			}

			setOptions(updatedOptions);
		}
	}, [open, matchedDataSource]);

	React.useEffect(() => {
		if (!open) {
			setOptions([]);
		}
	}, [open]);

	React.useEffect(() => {
		if (
			urlMerchants.length > 0 &&
			!isDataEmpty(matchedDataSource?.data) &&
			validateMerchantDataSource(matchedDataSource.data, selectedProcessor)
		) {
			let selectedMerchants = [];

			urlMerchants.forEach((merchant) => {
				if (merchant === 'all') {
					selectedMerchants = matchedDataSource?.data;
				} else {
					const match = querier(
						matchedDataSource,
						`data.$[merchantId = "${merchant}"]`
					);

					selectedMerchants.push(match);
				}
			});

			onChange(selectedMerchants);
			setUrlMerchants([]);
		}
	}, [selectedProcessor, urlMerchants, matchedDataSource]);

	const handleSelectAll = () => {
		const allValues = deepCopy(matchedDataSource.data);

		setAllMerchantsSelected(true);
		onChange(allValues);
	};

	const handleOptionSelect = (newValue) => {
		if (querier(newValue, '$[merchantId = "all"]')) {
			handleSelectAll();
		} else if (
			newValue.length === options.length - 1 &&
			!allMerchantsSelected
		) {
			setOptions(matchedDataSource.data);
			setAllMerchantsSelected(true);
			onChange(newValue);
		} else if (newValue.length === 0 && allMerchantsSelected) {
			setOptions([
				{
					merchantId: 'all',
					label: 'Select All'
				},
				...matchedDataSource.data
			]);
			setAllMerchantsSelected(false);
			onChange(newValue);
		} else {
			onChange(newValue);
		}
	};

	if (
		urlMerchants.length > 0 ||
		loading ||
		isDataEmpty(matchedDataSource?.data)
	) {
		return (
			<TextField
				label={t(translationPath)}
				disabled={true}
				variant='outlined'
				sx={{ width: widthOverride || null }}
				helperText={t('groupWizard.multiSelect')}
				InputProps={{
					endAdornment: (
						<React.Fragment>
							<CircularProgress color='inherit' size={20} />
						</React.Fragment>
					)
				}}
			/>
		);
	}

	return (
		<FormControl>
			<Autocomplete
				multiple
				filterSelectedOptions
				options={options}
				disabled={disabled}
				open={open}
				onOpen={() => {
					setOpen(true);
				}}
				onClose={() => {
					setOpen(false);
				}}
				loading={loading}
				isOptionEqualToValue={(option, item) => {
					return (
						`${option.paymentEnablerAcquiringBankId}-${option.merchantId}` ===
						`${item.paymentEnablerAcquiringBankId}-${item.merchantId}`
					);
				}}
				getOptionLabel={(option) => {
					return specialSelectOptions.includes(option.merchantId)
						? option.label
						: `${option.label} - ${option.merchantId}`;
				}}
				value={value}
				onChange={(event, newValue) => {
					handleOptionSelect(newValue);
				}}
				inputValue={inputValue}
				onInputChange={(event, newInputValue) => {
					setInputValue(newInputValue);
				}}
				renderOption={(props, option) => {
					return specialSelectOptions.includes(option.merchantId) ? (
						<SpecialListItem
							key={`${option.paymentEnablerAcquiringBankId}-${option.merchantId}`}
							{...props}
						>
							{option.label}
						</SpecialListItem>
					) : (
						<StandardListItem
							{...props}
							key={`${option.paymentEnablerAcquiringBankId}-${option.merchantId}`}
						>
							<Stack direction='column'>
								<Typography>{option.label}</Typography>
								<Typography variant='caption'>{option.merchantId}</Typography>
							</Stack>
						</StandardListItem>
					);
				}}
				renderTags={(value) => (
					<Tooltip title={<MerchantTooltip value={value} />}>
						<Chip variant='outlined' label={`${value.length} selected`} />
					</Tooltip>
				)}
				sx={{ flex: 1 }}
				renderInput={(params) => (
					<TextField
						{...params}
						label={t(translationPath)}
						variant='outlined'
						sx={{ width: widthOverride || null }}
						helperText={t('groupWizard.multiSelect')}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<React.Fragment>
									{loading ? (
										<CircularProgress color='inherit' size={20} />
									) : null}
									{params.InputProps.endAdornment}
								</React.Fragment>
							)
						}}
					/>
				)}
			/>
		</FormControl>
	);
};

MerchantSelect.propTypes = {
	onChange: PropTypes.func,
	translationPath: PropTypes.string,
	value: PropTypes.array,
	dataSource: PropTypes.string,
	t: PropTypes.func,
	selectedProcessor: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]),
	dataSources: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	getDataSource: PropTypes.func,
	disabled: PropTypes.bool,
	widthOverride: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	urlMerchants: PropTypes.array,
	setUrlMerchants: PropTypes.func,
	allMerchantsSelected: PropTypes.bool,
	setAllMerchantsSelected: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		dataSources: state.dataSourceReducer.get('dataSources')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getDataSource: (payload) => {
			dispatch(dataSourceActions.getDataSource(payload));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('groups')(MerchantSelect));
