import * as actionTypes from './actionTypes';

export const getUsers = (payload) => ({
	type: actionTypes.GET_USERS,
	payload
});

export const getUsersCompleted = (payload) => ({
	type: actionTypes.GET_USERS_COMPLETED,
	payload
});

export const getUsersFailed = (payload) => ({
	type: actionTypes.GET_USERS_FAILED,
	payload
});

export const getPendingUsers = (payload) => ({
	type: actionTypes.GET_PENDING_USERS,
	payload
});

export const getPendingUsersCompleted = (payload) => ({
	type: actionTypes.GET_PENDING_USERS_COMPLETED,
	payload
});

export const getPendingUsersFailed = (payload) => ({
	type: actionTypes.GET_PENDING_USERS_FAILED,
	payload
});

export const inviteUser = (payload) => ({
	type: actionTypes.INVITE_USER,
	payload
});

export const inviteUserCompleted = (payload) => ({
	type: actionTypes.INVITE_USER_COMPLETED,
	payload
});

export const inviteUserFailed = (payload) => ({
	type: actionTypes.INVITE_USER_FAILED,
	payload
});

export const resetInvitation = (payload) => ({
	type: actionTypes.RESET_INVITATION,
	payload
});

export const resetUsers = (payload) => ({
	type: actionTypes.RESET_USERS,
	payload
});
