import * as actionTypes from './actionTypes';

export const getUser = (payload) => ({
	type: actionTypes.GET_USER,
	payload
});

export const getUserCompleted = (payload) => ({
	type: actionTypes.GET_USER_COMPLETED,
	payload
});

export const getUserFailed = (payload) => ({
	type: actionTypes.GET_USER_FAILED,
	payload
});

export const updateUser = (payload) => ({
	type: actionTypes.UPDATE_USER,
	payload
});

export const updateUserCompleted = (payload) => ({
	type: actionTypes.UPDATE_USER_COMPLETED,
	payload
});

export const updateUserFailed = (payload) => ({
	type: actionTypes.UPDATE_USER_FAILED,
	payload
});

export const resetUpdateUser = (payload) => ({
	type: actionTypes.RESET_UPDATE_USER,
	payload
});

export const getUserPermissions = (payload) => ({
	type: actionTypes.GET_USER_PERMISSIONS,
	payload
});

export const getUserPermissionsCompleted = (payload) => ({
	type: actionTypes.GET_USER_PERMISSIONS_COMPLETED,
	payload
});

export const getUserPermissionsFailed = (payload) => ({
	type: actionTypes.GET_USER_PERMISSIONS_FAILED,
	payload
});

export const updateUserPermissions = (payload) => ({
	type: actionTypes.UPDATE_USER_PERMISSIONS,
	payload
});

export const updateUserPermissionsCompleted = (payload) => ({
	type: actionTypes.UPDATE_USER_PERMISSIONS_COMPLETED,
	payload
});

export const updateUserPermissionsFailed = (payload) => ({
	type: actionTypes.UPDATE_USER_PERMISSIONS_FAILED,
	payload
});

export const updateUserStatus = (payload) => ({
	type: actionTypes.UPDATE_USER_STATUS,
	payload
});

export const updateUserStatusCompleted = (payload) => ({
	type: actionTypes.UPDATE_USER_STATUS_COMPLETED,
	payload
});

export const updateUserStatusFailed = (payload) => ({
	type: actionTypes.UPDATE_USER_STATUS_FAILED,
	payload
});

export const deleteUser = (payload) => ({
	type: actionTypes.DELETE_USER,
	payload
});

export const deleteUserCompleted = (payload) => ({
	type: actionTypes.DELETE_USER_COMPLETED,
	payload
});

export const deleteUserFailed = (payload) => ({
	type: actionTypes.DELETE_USER_FAILED,
	payload
});

export const resetUserAvatarUpload = (payload) => ({
	type: actionTypes.RESET_USER_AVATAR_UPLOAD,
	payload
});

export const resetUserPermissionProcess = (payload) => ({
	type: actionTypes.RESET_USER_PERMISSION_PROCESS,
	payload
});

export const resetUserStatusProcess = (payload) => ({
	type: actionTypes.RESET_USER_STATUS_PROCESS,
	payload
});

export const resetDeleteUserProcess = (payload) => ({
	type: actionTypes.RESET_DELETE_USER_PROCESS,
	payload
});

export const logout = (payload) => ({
	type: actionTypes.LOGOUT,
	payload
});
