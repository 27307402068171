import React from 'react';
import { styled } from '@mui/material/styles';
import SuccessPanel from '../../components/Support/SuccessPanel';

import Container from '@mui/material/Container';

const StyledContainer = styled(Container)({
	display: 'flex',
	flexDirection: 'column',
	width: '100%',
	height: 'calc(100% - 80px)',
	overflow: 'auto'
});

const SupportSuccessPage = (props) => {
	return (
		<StyledContainer
			maxWidth={false}
		>
			<SuccessPanel />
		</StyledContainer>
	);
};

export default SupportSuccessPage;
