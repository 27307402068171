export const RESET_CREATE_RULE = 'reules/RESET_CREATE_RULE';
export const RESET_EDIT_RULE = 'rules/RESET_EDIT_RULE';
export const CLEAR_USER_RULE = 'rules/CLEAR_USER_RULE';

export const CREATE_RULE = 'rules/CREATE_RULE';
export const CREATE_RULE_COMPLETED = 'rules/CREATE_RULE_COMPLETED';
export const CREATE_RULE_FAILED = 'rules/CREATE_RULE_FAILED';

export const UPDATE_RULE = 'rules/UPDATE_RULE';
export const UPDATE_RULE_COMPLETED = 'rules/UPDATE_RULE_COMPLETED';
export const UPDATE_RULE_FAILED = 'rules/UPDATE_RULE_FAILED';

export const GET_ACTIVE_RULES_BY_GROUP = 'rules/GET_ACTIVE_RULES_BY_GROUP';
export const GET_ACTIVE_RULES_BY_GROUP_COMPLETED =
	'rules/GET_ACTIVE_RULES_BY_GROUP_COMPLETED';
export const GET_ACTIVE_RULES_BY_GROUP_FAILED = 'rules/GET_ACTIVE_RULES_BY_GROUP_FAILED';

export const DELETE_ACTIVE_RULE = 'rules/DELETE_ACTIVE_RULE';
export const DELETE_ACTIVE_RULE_COMPLETED =
	'rules/DELETE_ACTIVE_RULE_COMPLETED';
export const DELETE_ACTIVE_RULE_FAILED = 'rules/DELETE_ACTIVE_RULE_FAILED';
