import React from 'react';
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';

import { withTranslation } from 'react-i18next';

import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';

import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Restricted from '../../../../permissionProvider/Restricted';

const CurrentInventoryLoadingButton = styled(LoadingButton)(({ theme }) => ({
	borderColor: theme.palette.primary.alt,
	backgroundColor: theme.palette.common.white,
	color: theme.palette.primary.alt,
	'&:hover': {
		borderColor: theme.palette.common.white,
		backgroundColor: theme.palette.common.white,
		color: theme.palette.primary.alt
	}
}));

const EnhancedTableToolbar = (props) => {
	const {
		t,
		numSelected,
		handleMarkedNotChallenged,
		downloadToCsv,
		searchTerm,
		setSearchTerm,
		onClearSearchTerm
	} = props;

	return (
		<Toolbar
			sx={{
				flex: 1,
				...(numSelected > 0 && {
					bgcolor: (theme) =>
						alpha(
							theme.palette.primary.main,
							theme.palette.action.activatedOpacity
						)
				})
			}}
		>
			{ numSelected > 0
				? <Typography>{numSelected} selected</Typography>
				: null
			}
			<Stack
				direction='row'
				p={1}
				spacing={2}
				sx={{ flex: 1 }}
				alignItems='flex-end'
				justifyContent='flex-end'
			>
				<Restricted to='representments.notChallenged.edit'>
					{numSelected > 0 && (
						<CurrentInventoryLoadingButton
							variant='outlined'
							onClick={handleMarkedNotChallenged}
						>
							<Typography sx={{ whitespace: 'nowrap', fontSize: '.8rem', minWidth: 'max-content' }}>
								{t('representments:representmentsTable.markNotChallenged')}
							</Typography>
						</CurrentInventoryLoadingButton>
					)}
				</Restricted>
				<TextField
					label={t('common:search')}
					value={searchTerm}
					onChange={(e) => {
						setSearchTerm(e.target.value);
					}}
					variant='standard'
					InputProps={{
						startAdornment: <SearchIcon />,
						endAdornment: (
							<ClearIcon
								onClick={onClearSearchTerm}
								sx={{ cursor: 'pointer' }}
							/>
						)
					}}
				/>
				<Tooltip
					title={t(
						numSelected > 0
							? 'representments:representmentsTable.downloadSelected'
							: 'representments:representmentsTable.downloadAll'
					)}
				>
					<FileDownloadIcon onClick={downloadToCsv} sx={{ color: 'primary.alt', cursor: 'pointer' }} />
				</Tooltip>
			</Stack>
		</Toolbar>
	);
};

EnhancedTableToolbar.propTypes = {
	t: PropTypes.func,
	numSelected: PropTypes.number,
	handleMarkedNotChallenged: PropTypes.func,
	downloadToCsv: PropTypes.func,
	searchTerm: PropTypes.string,
	setSearchTerm: PropTypes.func,
	onClearSearchTerm: PropTypes.func,
	markNotChallengedError: PropTypes.string
};

export default withTranslation(['common', 'representments'])(EnhancedTableToolbar);
