import * as RegistrationEpics from './epics';
import * as registrationActions from './actions';
import * as registrationActionTypes from './actionTypes';
import { registrationReducer } from './reducer';

const registrationEpics = Object.values({ ...RegistrationEpics });

export {
	registrationActions,
	registrationActionTypes,
	registrationReducer,
	registrationEpics
};
