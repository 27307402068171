import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import isDataEmpty from '../../../../helpers/shared/isDataEmpty';
import querier from '../../../../utilities/querier';
import { usersActions } from '../../../../containers/Users';

import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import CircularProgress from '@mui/material/CircularProgress';

const UserSelect = (props) => {
	const {
		t,
		onChange,
		translationPath,
		variable,
		value,
		helperTextPath,
		users,
		usersAreLoading,
		usersHaveBeenLoaded,
		usersError,
		getUsers,
		isDisabled
	} = props;

	const [inputValue, setInputValue] = React.useState('');
	const [open, setOpen] = React.useState(false);
	const [options, setOptions] = React.useState([]);
	const loading = open && options.length === 0;

	React.useEffect(() => {
		if (!usersAreLoading && !usersHaveBeenLoaded && !usersError) {
			getUsers();
		}
	}, []);

	React.useEffect(() => {
		if (open && usersHaveBeenLoaded && options.length === 0) {
			setOptions(users);
		}
	}, [open, users]);

	React.useEffect(() => {
		if (!open) {
			setOptions([]);
		}
	}, [open]);

	React.useEffect(() => {
		if (typeof value !== 'object' && value !== null && !isDataEmpty(users)) {
			const matchedUser = querier(users, `$[id = ${value}]`);

			if (matchedUser) {
				onChange(variable, matchedUser);
			}
		} else if (value === undefined) {
			onChange(variable, null);
		}
	}, [value, users]);

	if (
		isDataEmpty(users) ||
		(typeof value !== 'object' && (value !== null || value !== undefined))
	) {
		return (
			<FormControl sx={{ flex: 1 }}>
				<InputLabel sx={{ padding: '2px', bgcolor: 'common.white' }} id={'title-label'}>
					{t(translationPath)}
				</InputLabel>
				<Select
					value={''}
					disabled={true}
					endAdornment={
						<InputAdornment sx={{ mr: 3 }} position='end'>
							<CircularProgress size={20} />
						</InputAdornment>
					}
				></Select>
				<FormHelperText>
					{helperTextPath ? t(helperTextPath) : null}
				</FormHelperText>
			</FormControl>
		);
	}

	return (
		<FormControl>
			<Autocomplete
				filterSelectedOptions
				disabled={isDisabled}
				options={options}
				open={open}
				onOpen={() => {
					setOpen(true);
				}}
				onClose={() => {
					setOpen(false);
				}}
				loading={loading}
				isOptionEqualToValue={(option, item) => {
					return option.id === item.id;
				}}
				getOptionLabel={(option) => {
					return option ? `${option.firstName} ${option.lastName}` : '';
				}}
				value={value}
				onChange={(event, newValue) => {
					onChange(variable, newValue);
				}}
				inputValue={inputValue}
				onInputChange={(event, newInputValue) => {
					setInputValue(newInputValue);
				}}
				sx={{ flex: 1 }}
				renderInput={(params) => (
					<TextField
						{...params}
						label={t(translationPath)}
						variant='outlined'
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<React.Fragment>
									{loading ? (
										<CircularProgress color='inherit' size={20} />
									) : null}
									{params.InputProps.endAdornment}
								</React.Fragment>
							)
						}}
					/>
				)}
			/>
			<FormHelperText>
				{helperTextPath ? t(helperTextPath) : null}
			</FormHelperText>
		</FormControl>
	);
};

UserSelect.propTypes = {
	t: PropTypes.func,
	onChange: PropTypes.func,
	translationPath: PropTypes.string,
	variable: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
	users: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	usersHaveBeenLoaded: PropTypes.bool,
	usersAreLoading: PropTypes.bool,
	usersError: PropTypes.string,
	getUsers: PropTypes.func,
	helperTextPath: PropTypes.string,
	isDisabled: PropTypes.bool
};

const mapStateToProps = (state) => {
	return {
		users: state.usersReducer.getIn(['users', 'data']),
		usersHaveBeenLoaded: state.usersReducer.getIn(['users', 'hasBeenLoaded']),
		usersAreLoading: state.usersReducer.getIn(['users', 'isLoading']),
		usersError: state.usersReducer.getIn(['users', 'error'])
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getUsers: () => {
			dispatch(usersActions.getUsers());
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation('rules')(UserSelect));
