import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

const LabelInput = (props) => {
	const {
		t,
		onChange,
		translationPath,
		variable,
		value,
		maxLength,
		isDisabled
	} = props;

	return (
		<FormControl>
			<TextField
				label={t(translationPath)}
				value={value}
				disabled={isDisabled}
				maxLength={maxLength}
				sx={{ width: '100%' }}
				onChange={(e) => onChange(variable, e.target.value)}
			/>
		</FormControl>
	);
};

LabelInput.propTypes = {
	t: PropTypes.func,
	onChange: PropTypes.func,
	translationPath: PropTypes.string,
	variable: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	maxLength: PropTypes.number,
	isDisabled: PropTypes.bool
};

export default withTranslation('rules')(LabelInput);
