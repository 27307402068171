export const formatPhoneNumberForDisplay = (input) => {
	let formatted = input;

	if (input) {
		formatted = input.replace(/\D/g, '');

		const size = formatted.length;

		if (size > 0) {
			formatted = '(' + formatted;
		}
		if (size > 3) {
			formatted = formatted.slice(0, 4) + ') ' + formatted.slice(4, 11);
		}
		if (size > 6) {
			formatted = formatted.slice(0, 9) + '-' + formatted.slice(9);
		}
	}

	return formatted;
};

export const formatPhoneNumberDigitsOnly = (phoneNumber) => {
	if (phoneNumber === null || !phoneNumber) {
		return;
	}

	return ('' + phoneNumber).replace(/\D/g, '');
};
