export default [
	{
		id: 'alert',
		name: 'alert'
	},
	{
		id: 'information',
		name: 'information'
	}
];
