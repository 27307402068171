import { sentenceCase } from 'change-case';

export default [
	{
		id: 'runOn',
		name: 'createdOn',
		align: 'center',
		sortable: false,
		width: 200
	},
	{
		id: 'severity',
		name: 'severity',
		align: 'left',
		sortable: false,
		width: 100,
		formatter: sentenceCase
	},
	{
		id: 'category',
		name: 'category',
		align: 'left',
		width: 100,
		sortable: false
	},
	{
		id: 'triggerRuleDescription',
		name: 'triggerRuleDescription',
		align: 'left',
		sortable: false
	},
	{
		id: 'alertTriggered',
		name: 'notificationGenerated',
		align: 'center',
		sortable: false,
		width: 300
	}
];
