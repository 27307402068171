import React from 'react';
import { styled } from '@mui/material/styles';
import { getAssetUri } from '../../utilities/assetEndpointUtility';
import PostLoginManager from '../../components/PostLogin/PostLoginManager';

import Container from '@mui/material/Container';

const StyledContainer = styled(Container)({
	height: '100vh',
	width: '100%',
	backgroundImage: `url(${getAssetUri()}/public/slyce360LoginFlowBackground.png)`,
	backgroundRepeat: 'no-repeat',
	backgroundSize: 'contain'
});

const PostLogin = () => {
	return (
		<StyledContainer maxWidth={false}>
			<PostLoginManager />
		</StyledContainer>
	);
};

export default PostLogin;
