import { fromJS } from 'immutable';
import * as actionTypes from './actionTypes';

export const initialState = fromJS({
	isActivating: false,
	isDisabling: false,
	preferredMFA: 'NOMFA',
	isVerifying: false,
	codeVerified: false,
	error: undefined
});

export const multiFactorAuthReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.ACTIVATE_MULTI_FACTOR_AUTH:
			return state.set('isActivating', true);

		case actionTypes.ACTIVATE_MULTI_FACTOR_AUTH_COMPLETED:
			return state
				.set('isActivating', false)
				.set('preferredMFA', action.payload);

		case actionTypes.ACTIVATE_MULTI_FACTOR_AUTH_FAILED:
			return state
				.set('isActivating', false)
				.set('error', action.payload);

		case actionTypes.VERIFY_MULTI_FACTOR_AUTH_CODE:
			return state.set('isVerifying', true);

		case actionTypes.VERIFY_MULTI_FACTOR_AUTH_CODE_COMPLETED:
			return state
				.set('isVerifying', false)
				.set('codeVerified', action.payload);

		case actionTypes.VERIFY_MULTI_FACTOR_AUTH_CODE_FAILED:
			return state
				.set('isVerifying', false)
				.set('error', action.payload);

		case actionTypes.SET_PREFERRED_MULTI_FACTOR_AUTH:
			return state.set('preferredMFA', action.payload);

		case actionTypes.DISABLE_MULTI_FACTOR_AUTH:
			return state.set('isDisabling', true);

		case actionTypes.DISABLE_MULTI_FACTOR_AUTH_COMPLETED:
			return state
				.set('isDisabling', false)
				.set('preferredMFA', action.payload);

		case actionTypes.DISABLE_MULTI_FACTOR_AUTH_FAILED:
			return state
				.set('isDisabling', false)
				.set('error', action.payload);

		case actionTypes.LOGOUT:
			return initialState;

		default:
			return state;
	}
};
