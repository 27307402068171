import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { dataSourceActions } from '../../../../containers/DataSource';
import getDynamicDataFromList from '../../../../helpers/shared/dynamicReducers/getDynamicDataFromList';

import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';

const DataSourceInput = (props) => {
	const {
		onChange,
		translationPath,
		variable,
		value,
		dataSource,
		t,
		dataSources,
		getDataSource
	} = props;

	const [inputValue, setInputValue] = React.useState('');
	const [open, setOpen] = React.useState(false);
	const [options, setOptions] = React.useState([]);
	const loading = open && options.length === 0;

	const matchedDataSource = getDynamicDataFromList(dataSources, dataSource);

	React.useEffect(() => {
		if (open && !matchedDataSource.hasBeenLoaded) {
			getDataSource({
				dataSourceKey: dataSource
			});
		}
	}, [open, dataSource]);

	React.useEffect(() => {
		if (open && matchedDataSource?.hasBeenLoaded && options.length === 0) {
			setOptions(matchedDataSource?.data);
		}
	}, [open, matchedDataSource]);

	React.useEffect(() => {
		if (!open) {
			setOptions([]);
		}
	}, [open]);

	const handleOnChangeSelection = (filterKey, newValue) => {
		const formattedNewValue = newValue.map((value) => {
			return value.value ? value.value : value;
		});

		onChange(formattedNewValue, filterKey);
	};

	return (
		<FormControl>
			<Autocomplete
				multiple
				filterSelectedOptions
				options={options}
				open={open}
				onOpen={() => {
					setOpen(true);
				}}
				onClose={() => {
					setOpen(false);
				}}
				loading={loading}
				isOptionEqualToValue={(option, item) => {
					return (
						(option.value ? option.value : option) ===
						(item.value ? item.value : item)
					);
				}}
				getOptionLabel={(option) => {
					return option.label || option.value || option;
				}}
				value={value || []}
				onChange={(event, newValue) => {
					handleOnChangeSelection(variable, newValue);
				}}
				inputValue={inputValue}
				onInputChange={(event, newInputValue) => {
					setInputValue(newInputValue);
				}}
				sx={{ ml: 1, flex: 1 }}
				renderInput={(params) => (
					<TextField
						{...params}
						label={t(translationPath)}
						variant='outlined'
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<React.Fragment>
									{loading ? (
										<CircularProgress color='inherit' size={20} />
									) : null}
									{params.InputProps.endAdornment}
								</React.Fragment>
							)
						}}
					/>
				)}
			/>
		</FormControl>
	);
};

DataSourceInput.propTypes = {
	t: PropTypes.func,
	onChange: PropTypes.func,
	translationPath: PropTypes.string,
	variable: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	dataSource: PropTypes.string,
	dataSources: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	getDataSource: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		dataSources: state.dataSourceReducer.get('dataSources')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getDataSource: (payload) => {
			dispatch(dataSourceActions.getDataSource(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation('rules')(DataSourceInput));
