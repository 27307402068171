import React from 'react';
import PropTypes from 'prop-types';
import { formatDateForDisplay } from '../../../../utilities/dateWrapper';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

export const TransactionListRow = (props) => {
	const { row } = props;

	return (
		<TableRow
			key={row.merchantTransactionId}
			sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
		>
			<TableCell component='th' scope='row'>
				{formatDateForDisplay(row.settledOn)}
			</TableCell>
			<TableCell >{row.settledAmount}</TableCell>
			<TableCell >{row.maskedCardNumber}</TableCell>
			<TableCell >{row.authorizationCode}</TableCell>
			<TableCell >{row.acquirerReferenceNumber}</TableCell>
		</TableRow>
	);
};

TransactionListRow.propTypes = {
	row: PropTypes.object
};

export default TransactionListRow;
