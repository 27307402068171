import React from 'react';
import PropTypes from 'prop-types';

const TableauFilters = (props) => {
	const { filters, parameters } = props;

	return (
		<React.Fragment>
			{
				filters.map((filter) => (
					<viz-filter key={filter.key} field={filter.key} value={filter.value} />
				))
			}
			{
				parameters.map((parameter) => (
					<viz-parameter key={parameter.key} name={parameter.key} value={parameter.value} />
				))
			}
		</React.Fragment>
	);
};

TableauFilters.propTypes = {
	filters: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	parameters: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

export default TableauFilters;
