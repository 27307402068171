import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { registrationActions } from '../../../containers/Registration';
import { usersActions } from '../../../containers/Users';
import OutlinedLoadingButton from '../../buttons/OutlinedLoadingButton';

const PendingUsersActionList = (props) => {
	const {
		cancelInvitation,
		getPendingUsers,
		hasBeenCancelled,
		isCancelling,
		invitationId,
		t
	} = props;

	const [selectedInvitationId, setSelectedInvitationId] = React.useState();

	React.useEffect(() => {
		if (hasBeenCancelled && !isCancelling) {
			getPendingUsers();
		}
	}, [hasBeenCancelled, isCancelling]);

	const handleCancelInvitation = () => {
		setSelectedInvitationId(invitationId);

		cancelInvitation({
			status: 'CANCELLED',
			invitationId
		});
	};

	return (
		<OutlinedLoadingButton
			onClick={handleCancelInvitation}
			loading={selectedInvitationId === invitationId && isCancelling}
		>
			<span>{t('buttons.cancel')}</span>
		</OutlinedLoadingButton>
	);
};

PendingUsersActionList.propTypes = {
	cancelInvitation: PropTypes.func,
	getPendingUsers: PropTypes.func,
	hasBeenCancelled: PropTypes.bool,
	isCancelling: PropTypes.bool,
	invitationId: PropTypes.string,
	t: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		isCancelling: state.registrationReducer.get('isCancellingInvitation'),
		hasBeenCancelled: state.registrationReducer.get('hasCancelledInvitation')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		cancelInvitation: (payload) => {
			dispatch(registrationActions.cancelInvitation(payload));
		},
		getPendingUsers: (payload) => {
			dispatch(usersActions.getPendingUsers(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation('common')(PendingUsersActionList));
