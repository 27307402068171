import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import PageHeader from '../../components/headers/PageHeader';
import NotificationListFilters from '../../components/notifications/NotificationListFilters';
import NotificationsList from '../../components/notifications/NotificationList';
import Restricted from '../../permissionProvider/Restricted';
import RestrictedFallback from '../../components/shared/RestrictedFallback';

import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';

const Notifications = ({ t }) => {
	return (
		<Container maxWidth={false}>
			<Restricted
				to='notifications.notificationsList.view'
				Fallback={
					<RestrictedFallback overrideMessage={t('common:error.notAllowed')} />
				}
			>
				<Stack spacing={2} p={2}>
					<PageHeader label={t('notifications:notifications')} />
					<NotificationListFilters />
					<NotificationsList />
				</Stack>
			</Restricted>
		</Container>
	);
};

Notifications.propTypes = {
	t: PropTypes.func
};

export default (withTranslation(['notifications', 'common'])(Notifications));
