export const VALIDATE_INVITATION_ID = 'registration/VALIDATE_INVITATION_ID';
export const VALIDATE_INVITATION_ID_COMPLETED =
	'registration/VALIDATE_INVITATION_ID_COMPLETED';
export const VALIDATE_INVITATION_ID_FAILED =
	'registration/VALIDATE_INVITATION_ID_FAILED';

export const SUBMIT_REGISTRATION = 'registration/SUBMIT_REGISTRATION';
export const SUBMIT_REGISTRATION_COMPLETED =
	'registration/SUBMIT_REGISTRATION_COMPLETED';
export const SUBMIT_REGISTRATION_FAILED =
	'registration/SUBMIT_REGISTRATION_FAILED';

export const CANCEL_INVITATION = 'registration/CANCEL_INVITATION';
export const CANCEL_INVITATION_COMPLETED =
	'registration/CANCEL_INVITATION_COMPLETED';
export const CANCEL_INVITATION_FAILED = 'registration/CANCEL_INVITATION_FAILED';
