import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import querier from '../../../utilities/querier';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';

import { reversalReasons } from './constants/reversalReasons';

export const AddChargebackReason = (props) => {
	const { onAddChargebackReason, t } = props;

	const handleReasonChange = (e) => {
		if (e.target.value) {
			const reasonResult = querier(
				reversalReasons,
				`$[label = "${e.target.value}"]`
			);
			onAddChargebackReason(reasonResult.label, reasonResult.value);
		}
	};

	return (
		<Stack direction='row' px={2.2} py={0.9}>
			<TextField
				sx={{ flex: 1 }}
				id='select-reason'
				select
				label='Select'
				size='small'
				value={reversalReasons[0].label}
				onChange={handleReasonChange}
				helperText={t('chargebackReversalReasons.selectReasonHelperText')}
				variant='filled'
			>
				{reversalReasons.map((option, index) => (
					<MenuItem key={index} value={option.label}>
						{option.label}
					</MenuItem>
				))}
			</TextField>
		</Stack>
	);
};

AddChargebackReason.propTypes = {
	onAddChargebackReason: PropTypes.func,
	t: PropTypes.func
};

export default withTranslation('representments')(AddChargebackReason);
