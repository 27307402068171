import React from 'react';
import PropTypes from 'prop-types';
import querier from '../../utilities/querier';
import Overview from './displaySections/Overview';
import ChargebackInformation from './displaySections/ChargebackInformation';
import CustomerInformation from './displaySections/CustomerInformation';
import TransactionDetails from './displaySections/TransactionDetails';
import TransactionList from './displaySections/TransactionList';
import RefundInformation from './displaySections/RefundInformation';
import ProofOfDelivery from './displaySections/ProofOfDelivery';
import CustomerContact from './displaySections/CustomerContact';
import EthocaAlerts from './displaySections/EthocaAlerts';
import Cdrns from './displaySections/Cdrns';

import Grid from '@mui/material/Grid';

export const DetailDisplay = (props) => {
	const { representmentData, displayedSections } = props;

	const refundTransaction = querier(
		representmentData,
		`creditTransactions[merchantTransactionId = "${representmentData.debitTransaction.merchantTransactionId}"]`
	);

	return (
		<Grid container m={1} sx={{ maxWidth: '1200px' }} rowGap={2}>
			{displayedSections.includes('overview') && (
				<Overview data={representmentData.overview} />
			)}
			{displayedSections.includes('chargebackInformation') && (
				<ChargebackInformation data={representmentData.chargebackInformation} />
			)}
			{displayedSections.includes('customerInformation') && (
				<CustomerInformation data={representmentData.customerInformation} />
			)}
			{displayedSections.includes('debitTransaction') && (
				<TransactionDetails data={representmentData.debitTransaction} />
			)}
			{displayedSections.includes('customerContact') && (
				<CustomerContact data={representmentData.customerContact} />
			)}
			{displayedSections.includes('refundInformation') && refundTransaction && (
				<RefundInformation
					data={refundTransaction}
					saleData={representmentData.debitTransaction}
				/>
			)}
			{displayedSections.includes('ethocaAlerts') && (
				<EthocaAlerts data={representmentData.ethocaAlerts} />
			)}
			{displayedSections.includes('cdrns') && (
				<Cdrns data={representmentData.cdrns} />
			)}
			{displayedSections.includes('previouslyBilledUndisputedTransactions') && (
				<TransactionList
					data={representmentData.previouslyBilledUndisputedTransactions}
				/>
			)}
			{displayedSections.includes('proofOfDelivery') && (
				<ProofOfDelivery data={representmentData.proofOfDelivery} />
			)}
		</Grid>
	);
};

DetailDisplay.propTypes = {
	representmentData: PropTypes.object,
	displayedSections: PropTypes.array
};

export default DetailDisplay;
