import React from 'react';
import PropTypes from 'prop-types';
import querier from '../../../../../utilities/querier';
import GenericDatasourceSelect from '../shared/GenericDatasourceSelect';

const MerchantCategoryCodeSelect = (props) => {
	const {
		onChange,
		translationPath,
		value,
		dataSource,
		temporaryGroupValues,
		setTemporaryGroupValues,
		listItemsIndividually
	} = props;

	const findTemporaryValueMatches = (temporaryGroupValues, matchedDataSource) => {
		const mappedValues = temporaryGroupValues.map((temporaryValue) => {
			const foundValue = querier(
				matchedDataSource,
				`data.$[value = "${temporaryValue.value}"]`
			);
			foundValue.itemRowId = temporaryValue.id;

			return foundValue;
		});

		return mappedValues;
	};

	return (
		<GenericDatasourceSelect
			onChange={onChange}
			translationPath={translationPath}
			value={value}
			dataSource={dataSource}
			temporaryGroupValues={temporaryGroupValues}
			setTemporaryGroupValues={setTemporaryGroupValues}
			listItemsIndividually={listItemsIndividually}
			findTemporaryValueMatches={findTemporaryValueMatches}
		/>
	);
};

MerchantCategoryCodeSelect.propTypes = {
	onChange: PropTypes.func,
	translationPath: PropTypes.string,
	value: PropTypes.array,
	dataSource: PropTypes.string,
	temporaryGroupValues: PropTypes.array,
	setTemporaryGroupValues: PropTypes.func,
	listItemsIndividually: PropTypes.bool
};

export default MerchantCategoryCodeSelect;
