const yesNoOptions = [
	{
		id: 1,
		name: 'ignoreNonTriggeredEvents'
	},
	{
		id: 0,
		name: 'includeNonTriggeredEvents'
	}
];

export default yesNoOptions;
