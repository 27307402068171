import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { numberWithCommas } from '../../../../utilities/formatNumber';
import { getUserInputError } from '../validators/validateUserInput';
import OverlineTextError from '../../../errors/OverlineTextError';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';

const IntegerInput = (props) => {
	const {
		t,
		onChange,
		translationPath,
		variable,
		value,
		isDisabled,
		hiddenLabel,
		isSmall,
		hasError
	} = props;

	const [inputInvalid, setInputInvalid] = React.useState();

	const validateNumberValue = (value) => {
		const error = getUserInputError(value, 'number', t);

		const numbersOnly = value.toString().replace(/\D/g, '');

		if (error) {
			setInputInvalid(error);
			onChange(variable, value);
		} else {
			setInputInvalid();
			onChange(variable, numbersOnly ? numberWithCommas(numbersOnly) : '');
		}
	};

	return (
		<Box height={60}>
			{hiddenLabel ? null : (
				<InputLabel htmlFor={t(translationPath)} color='secondary' sx={{ my: '3px' }}>
					{t(translationPath)}
				</InputLabel>
			)}
			<TextField
				sx={{ width: '400px' }}
				error={!!(hasError || inputInvalid)}
				size={isSmall ? 'small' : undefined}
				disabled={isDisabled}
				value={value || ''}
				helperText={
					inputInvalid ? (
						<OverlineTextError>
							{inputInvalid}
						</OverlineTextError>
					) : (
						t('inputs.integerHelperText')
					)
				}
				onChange={(e) => {
					validateNumberValue(e.target.value);
				}}
			/>
		</Box>
	);
};

IntegerInput.propTypes = {
	t: PropTypes.func,
	onChange: PropTypes.func,
	translationPath: PropTypes.string,
	variable: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	isDisabled: PropTypes.bool,
	hiddenLabel: PropTypes.bool,
	isSmall: PropTypes.bool,
	hasError: PropTypes.bool
};

export default withTranslation('rules')(IntegerInput);
