import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { getFullCardByBrandName } from '../../../../helpers/shared/cardBrandHelper';
import { formatDateForDisplay } from '../../../../utilities/dateWrapper';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import ArticleIcon from '@mui/icons-material/Article';
import Restricted from '../../../../permissionProvider/Restricted';

export const WorkedCasesTableRow = (props) => {
	const { t, row, labelId } = props;

	return (
		<TableRow hover key={row.caseId} sx={{ pl: 1 }}>
			<TableCell padding='none'>
				{row.paymentEnablerAcquiringBankLabel}
			</TableCell>
			<TableCell component='th' id={labelId} scope='row' padding='none'>
				{row.caseId}
			</TableCell>
			<TableCell align='left'>
				{row.billingLastName ? row.billingLastName : '-'}
			</TableCell>
			<TableCell align='right'>
				{row.amount ? row.amount.toFixed(2) : '-'}
			</TableCell>
			<TableCell align='center'>
				<Stack direction='column' alignItems='center'>
					<Box
						component='img'
						src={getFullCardByBrandName(row.paymentCardBrand)}
						height={25}
						width={45}
					/>
					{row.paymentCardLast4 ? (
						<Tooltip
							placement='right'
							title={`${row.paymentCardBankIdentificationNumber}****${row.paymentCardLast4}`}
						>
							<Typography>{`****${row.paymentCardLast4}`}</Typography>
						</Tooltip>
					) : (
						'-'
					)}
				</Stack>
			</TableCell>
			<TableCell align='center'>
				{formatDateForDisplay(row.receivedByMerchantOn)}
			</TableCell>
			<TableCell align='center'>
				{formatDateForDisplay(row.sentToProcessorOn)}
			</TableCell>
			<Restricted to='representments.pdfGenerator.view'>
				<TableCell align='center'>
					<Link to={`/representments/view/${row.chargebackId}`}>
						<Tooltip title={t('representmentsTable.generate')}>
							<ArticleIcon sx={{ color: 'primary.alt' }} />
						</Tooltip>
					</Link>
				</TableCell>
			</Restricted>
		</TableRow>
	);
};

WorkedCasesTableRow.propTypes = {
	row: PropTypes.object,
	labelId: PropTypes.string,
	t: PropTypes.func
};

export default withTranslation('representments')(WorkedCasesTableRow);
