import React from 'react';
import PropTypes from 'prop-types';
import { noCase } from 'change-case';
import { withTranslation } from 'react-i18next';
import notificationSeverityIcons from '../icons/notificationSeverityIcons';

import Tooltip from '@mui/material/Tooltip';

export const NotificationSeverityIcon = (props) => {
	const { t, severity, fontSizeOverride } = props;

	if (!severity) {
		return null;
	}

	if (noCase(severity) === 'severe') {
		return (
			<Tooltip title={t('severe')}>
				<notificationSeverityIcons.severe color='error' sx={{ fontSize: fontSizeOverride || '2rem' }} />
			</Tooltip>
		);
	}

	if (noCase(severity) === 'warning') {
		return (
			<Tooltip title={t('warning')}>
				<notificationSeverityIcons.warning color='warning' sx={{ fontSize: fontSizeOverride || '2rem' }} />
			</Tooltip>
		);
	}

	if (noCase(severity) === 'information') {
		return (
			<Tooltip title={t('information')}>
				<notificationSeverityIcons.information color='information' sx={{ fontSize: fontSizeOverride || '2rem' }} />
			</Tooltip>
		);
	}
};

NotificationSeverityIcon.propTypes = {
	severity: PropTypes.string,
	fontSizeOverride: PropTypes.string,
	t: PropTypes.func
};

export default withTranslation('notifications')(NotificationSeverityIcon);
