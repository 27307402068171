import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { withTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';

import { ruleBuilderCategoryIcons } from '../../../../../../icons';

const BaseRuleCategoryAvatar = styled(Avatar)(({ theme }) => ({
	[theme.breakpoints.up('md')]: {
		height: '80px',
		width: '80px'
	},
	[theme.breakpoints.down('md')]: {
		height: '70px',
		width: '70px'
	},
	'& .icon': {
		width: '40%',
		height: '40%'
	},
	border: `2px solid ${theme.palette.secondary.main}`,
	backgroundColor: theme.palette.common.white
}));

const UnselectedRuleCategoryAvatar = styled(BaseRuleCategoryAvatar)(({ theme }) => ({
	cursor: 'pointer',
	'& .icon': {
		color: theme.palette.secondary.main
	},
	'&:hover': {
		border: `2px solid ${theme.palette.primary.light}`,
		backgroundColor: theme.palette.primary.light,
		'& .icon': {
			color: theme.palette.primary.contrastText
		}
	}
}));

const SelectedRuleCategoryAvatar = styled(BaseRuleCategoryAvatar)(({ theme }) => ({
	border: `2px solid ${theme.palette.primary.light}`,
	backgroundColor: theme.palette.primary.light,
	'& .icon': {
		color: theme.palette.primary.contrastText
	}
}));

const CategorySelectOption = (props) => {
	const { t, option, onSelect, isSelected } = props;

	const onSelectCategory = (value) => {
		onSelect('category', value);
	};

	const CategoryIcon = ruleBuilderCategoryIcons[option.name];
	const AvatarComponent = isSelected ? SelectedRuleCategoryAvatar : UnselectedRuleCategoryAvatar;

	return (
		<Grid item sx={{ flex: 1 }}>
			<Stack
				justifyContent='center'
				alignItems='center'
				p={2}
				spacing={2}
			>
				<AvatarComponent onClick={() => onSelectCategory(option)}>
					<CategoryIcon className='icon'/>
				</AvatarComponent>
				<Typography
					color='secondary'
					sx={{ fontWeight: 600, fontSize: '.8rem' }}
					variant='overline'
				>
					{t(`rules:${option.name}`)}
				</Typography>
			</Stack>
		</Grid>
	);
};

CategorySelectOption.propTypes = {
	t: PropTypes.func,
	option: PropTypes.object,
	onSelect: PropTypes.func,
	isSelected: PropTypes.bool
};

export default withTranslation('rules')(CategorySelectOption);
