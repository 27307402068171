import { fromJS } from 'immutable';
import * as actionTypes from './actionTypes';

export const initialState = fromJS({
	hasBeenInvited: false,
	isInviting: false,
	invitationError: undefined,
	hasChangedUserRole: false,
	isChangingUserRole: false,
	hasLoadedPendingUsers: false,
	isLoadingPendingUsers: false,
	pendingUsers: {
		data: [],
		isLoading: false,
		hasBeenLoaded: false,
		error: undefined
	},
	users: {
		data: [],
		hasBeenLoaded: false,
		isLoading: false,
		error: undefined
	}
});

export const usersReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_USERS:
			return state.setIn(['users', 'isLoading'], true);

		case actionTypes.GET_USERS_COMPLETED:
			return state
				.setIn(['users', 'data'], action.payload)
				.setIn(['users', 'hasBeenLoaded'], true)
				.setIn(['users', 'isLoading'], false)
				.setIn(['users', 'error'], undefined);

		case actionTypes.GET_USERS_FAILED:
			return state
				.setIn(['users', 'data'], [])
				.setIn(['users', 'hasBeenLoaded'], false)
				.setIn(['users', 'isLoading'], false)
				.setIn(['users', 'error'], action.payload);

		case actionTypes.GET_PENDING_USERS:
			return state.setIn(['pendingUsers', 'isLoading'], true);

		case actionTypes.GET_PENDING_USERS_COMPLETED:
			return state
				.setIn(['pendingUsers', 'isLoading'], false)
				.setIn(['pendingUsers', 'data'], action.payload)
				.setIn(['pendingUsers', 'hasBeenLoaded'], true);

		case actionTypes.GET_PENDING_USERS_FAILED:
			return state
				.setIn(['pendingUsers', 'isLoading'], false)
				.setIn(['pendingUsers', 'data'], [])
				.setIn(['pendingUsers', 'error'], action.payload);

		case actionTypes.INVITE_USER:
			return state.set('isInviting', true);

		case actionTypes.INVITE_USER_COMPLETED:
			return state.set('isInviting', false).set('hasBeenInvited', true);

		case actionTypes.INVITE_USER_FAILED:
			return state
				.set('isInviting', false)
				.set('invitationError', action.payload);

		case actionTypes.RESET_INVITATION:
			return state.set('hasBeenInvited', false);

		case actionTypes.RESET_USERS:
			return state.setIn(['users', 'data'], []).setIn(['pendingUsers', 'data'], []);

		default:
			return state;
	}
};
