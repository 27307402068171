import * as RepresentmentsEpics from './epics';
import * as representmentsActions from './actions';
import * as representmentsActionTypes from './actionTypes';
import { representmentsReducer } from './reducer';

const representmentsEpics = Object.values({ ...RepresentmentsEpics });

export {
	representmentsActions,
	representmentsActionTypes,
	representmentsReducer,
	representmentsEpics
};
