import querier from '../../utilities/querier';
import { formatDateForDisplay } from '../../utilities/dateWrapper';

const _dynamicReplacementKeys = {
	'{$trial_start_date}': {
		query: 'chargebackReversalSupplementalData.membershipStartDate',
		formatter: formatDateForDisplay
	},
	'{$cs_phone}': {
		query: 'overview.customerServicePhone'
	},
	'{$cancel_request_date}': {
		query: 'chargebackReversalSupplementalData.cancelRequestedOn',
		formatter: formatDateForDisplay
	},
	'{$refund_letter_sent_date}': {
		query: 'chargebackReversalSupplementalData.refundRequestLetterSentOn',
		formatter: formatDateForDisplay
	},
	'{$billing_descriptor}': {
		query: 'overview.billingDescriptor'
	},
	'{$refund_date}': {
		query: '[$.creditTransactions.settledOn][0]',
		formatter: formatDateForDisplay
	},
	'{$kit_mail_date}': {
		query: 'chargebackReversalSupplementalData.welcomeLetterMailedOn',
		formatter: formatDateForDisplay
	},
	'{$trial_billing_amount}': {
		query: 'chargebackReversalSupplementalData.membershipFee'
	}
};

// HACK FOR NOW - STORE THESE SOMEWHERE THATS NOT HERE
const _staticReplacementKeys = {
	'{{phoneNumber}}': '800-925-8674',
	'{{websiteUrl}}': 'www.programstop.com'
};

export default function (reasonParagraph, chargebackData, label) {
	let populatedReasonStatement = reasonParagraph;

	Object.entries(_dynamicReplacementKeys).forEach(([key, value]) => {
		if (populatedReasonStatement.indexOf(key) > -1) {
			let replacementValue = querier(chargebackData, value.query);

			if (value.formatter) {
				replacementValue = value.formatter(replacementValue);
			}

			populatedReasonStatement = populatedReasonStatement.replace(
				key,
				replacementValue
			);
		}
	});

	Object.entries(_staticReplacementKeys).forEach(([key, value]) => {
		if (populatedReasonStatement.indexOf(key) > -1) {
			populatedReasonStatement = populatedReasonStatement.replace(key, value);
		}
	});

	if (label === 'Previously billed and undisputed transactions') {
		populatedReasonStatement =
			'Previously Billed Undisputed Transactions\n\n' +
			populatedReasonStatement;
		populatedReasonStatement =
			populatedReasonStatement +
			'\n\nBilling Date       Amount     Masked Card #            Auth Code     ARN \n';

		chargebackData.previouslyBilledUndisputedTransactions.forEach(
			(transaction) => {
				const currentRow = `${transaction.settledOn}       $${transaction.settledAmount}       ${transaction.maskedCardNumber}       ${transaction.authorizationCode}          ${transaction.acquirerReferenceNumber}\n`;

				populatedReasonStatement = populatedReasonStatement + currentRow;
			}
		);
	}

	return populatedReasonStatement;
}
