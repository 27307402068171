import ReceiptIcon from '@mui/icons-material/Receipt';
import StorefrontIcon from '@mui/icons-material/Storefront';
import TollIcon from '@mui/icons-material/Toll';
import AnimationIcon from '@mui/icons-material/Animation';

export const groupBuildTypesList = [
	{
		id: 'merchantId',
		labelTranslationPath: 'groupWizard.buildStyleSelect.merchantId',
		icon: StorefrontIcon
	},
	{
		id: 'paymentEnablerAcquiringBank',
		labelTranslationPath: 'groupWizard.buildStyleSelect.processor',
		icon: TollIcon
	},
	{
		id: 'contract',
		labelTranslationPath: 'groupWizard.buildStyleSelect.contract',
		icon: ReceiptIcon
	},
	{
		id: 'merchantCategory',
		labelTranslationPath: 'groupWizard.buildStyleSelect.merchantCategories',
		icon: AnimationIcon
	}
];

export const groupBuildTypes = {
	merchantId: groupBuildTypesList[0],
	paymentEnablerAcquiringBank: groupBuildTypesList[1],
	contract: groupBuildTypesList[2],
	merchantCategory: groupBuildTypesList[3]
};
