import React from 'react';
import PropTypes from 'prop-types';
import { formatMonthlySummaryByMidCell } from '../../../../../helpers/shared/reportDataFormatter';
import { AlternatingBackgroundTableRow, TwelveMonthReportCell } from '../../../../ReportStylings';

import TableCell from '@mui/material/TableCell';

const ComplianceTableRow = (props) => {
	const { data, rowKey, rowLabel } = props;

	return (
		<AlternatingBackgroundTableRow>
			<TableCell width={200}>{rowLabel}</TableCell>
			{data.map((column, columnIndex) => {
				return (
					<TwelveMonthReportCell
						align='center'
						width={100}
						key={`${rowKey}-${columnIndex}`}
						columnIndex={columnIndex}
					>
						{formatMonthlySummaryByMidCell(
							rowKey,
							column.month,
							column[rowKey]
						)}
					</TwelveMonthReportCell>
				);
			})}
		</AlternatingBackgroundTableRow>
	);
};

ComplianceTableRow.propTypes = {
	rowKey: PropTypes.string,
	rowLabel: PropTypes.string,
	data: PropTypes.array,
	t: PropTypes.func
};

export default ComplianceTableRow;
