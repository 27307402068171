import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Slyce360LogoOriginal from '../../../../../assets/logos/Slyce360Logo_Original';
import Slyce360LogoDark from '../../../../../assets/logos/Slyce360Logo_Dark';

import Box from '@mui/material/Box';

const Branding = (props) => {
	const { theme } = props;

	return (
		<Box sx={{
			maxWidth: '300px',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			'& svg': { width: '80%', ml: 3 }
		}}>
			<Link to='dashboard'>
				{theme === 'light' ? (
					<Slyce360LogoOriginal />
				) : (
					<Slyce360LogoDark />
				)}
			</Link>
		</Box>
	);
};

Branding.propTypes = {
	theme: PropTypes.string
};

const mapStateToProps = (state) => {
	return {
		theme: state.systemReducer.get('theme')
	};
};

export default connect(mapStateToProps, null)(Branding);
