import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';

// DONT USE ANYMORE. USE ERROR INDICATOR
// ELIMINATING THE NEED FOR ERROR CHECKS IN COMPONENT
// TODO - MIGRATE ANYTHING USING THIS
const OverlineTextError = (props) => {
	const { children } = props;

	return (
		<Typography color='error' variant='overline' sx={{ fontSize: '0.8rem' }}>
			{children}
		</Typography>
	);
};

OverlineTextError.propTypes = {
	children: PropTypes.node
};

export default OverlineTextError;
