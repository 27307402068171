import React from 'react';
import PropTypes from 'prop-types';

import TextField from '@mui/material/TextField';

export const RepresentmentTextField = (props) => {
	const {
		id,
		label,
		defaultValue,
		isMultiline,
		isFlexed,
		shouldFitContent,
		isDisabled,
		minWidth
	} = props;

	return (
		<TextField
			id={id}
			label={label}
			defaultValue={defaultValue}
			variant='standard'
			size='small'
			multiline={isMultiline}
			disabled={isDisabled}
			InputProps={{
				readOnly: true,
				disableUnderline: true
			}}
			sx={{
				margin: '5px 5px 3px 5px',
				fontSize: '14px',
				overflowWrap: 'break-word',
				flex: isFlexed && 1,
				minWidth: minWidth || null,
				width: (id === 'programDescription' && '100%') || (shouldFitContent && 'fit-content')
			}}
		/>
	);
};

RepresentmentTextField.propTypes = {
	id: PropTypes.string,
	label: PropTypes.string,
	defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	isMultiline: PropTypes.bool,
	isFlexed: PropTypes.bool,
	isDisabled: PropTypes.bool,
	shouldFitContent: PropTypes.bool,
	minWidth: PropTypes.number
};

export default RepresentmentTextField;
