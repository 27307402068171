import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import querier from '../../../../utilities/querier';
import {
	AlternatingBackgroundTableRow,
	FourteenDayChargebacksTotalTableCell
} from '../../../ReportStylings';

import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';

const StandardTableCell = styled(TableCell)(({ theme }) => ({
	borderRight: `1px solid ${theme.palette.secondary.alt}`,
	boxShadow: `5px 0px -5px 3px ${theme.palette.secondary.alt}`
}));

const CustomTableRow = (props) => {
	const { columns, row, rowIndex, isNested } = props;

	return (
		<AlternatingBackgroundTableRow>
			<StandardTableCell>
				{isNested ? <Box sx={{ pl: 2 }}>{row.key}</Box> : row.key}
			</StandardTableCell>
			{columns.map((column, columnIndex) => {
				const value = querier(
					row,
					`rowData[key = "${column.key}"].chargebacks`
				);
				return (
					<TableCell key={`${rowIndex}-${columnIndex}`} align='center'>
						{value === 0 ? '-' : value}
					</TableCell>
				);
			})}
			<FourteenDayChargebacksTotalTableCell align='center'>
				<Typography>{querier(row, 'total')}</Typography>
			</FourteenDayChargebacksTotalTableCell>
		</AlternatingBackgroundTableRow>
	);
};

CustomTableRow.propTypes = {
	columns: PropTypes.array,
	row: PropTypes.object,
	rowIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	isNested: PropTypes.bool
};

export default CustomTableRow;
