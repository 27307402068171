import React from 'react';
import PropTypes from 'prop-types';
import PasswordValidationToolTip from '../shared/ValidationToolTips/PasswordValidationToolTip';

import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

const SecureValidationInput = (props) => {
	const {
		label,
		onChange,
		showTooltip,
		value,
		onEnterListener,
		validator,
		initialValidationErrors
	} = props;

	const [validationErrors, setValidationErrors] = React.useState(
		initialValidationErrors || []
	);
	const [toolTipIsOpen, setToolTipIsOpen] = React.useState(false);
	const [showSecureInput, setShowSecureInput] = React.useState(false);

	const handlePasswordValidation = (value) => {
		const passwordValidationErrors = validator ? validator(value) : [];

		if (passwordValidationErrors.length) {
			setValidationErrors(passwordValidationErrors);
		} else {
			setValidationErrors([]);
		}

		onChange(value);
	};

	const toggleShowSecureInput = () => {
		setShowSecureInput(!showSecureInput);
	};

	return (
		<TextField
			error={value === '' || !value ? null : validationErrors.length !== 0}
			onChange={(e) => handlePasswordValidation(e.target.value)}
			sx={{ width: '30ch', mb: '10px', textDecoration: 'none!important' }}
			variant='outlined'
			size='small'
			label={label}
			type={showSecureInput ? 'text' : 'password'}
			value={value}
			onFocus={(e) => setToolTipIsOpen(showTooltip)}
			onBlur={(e) => setToolTipIsOpen(false)}
			onKeyDown={(event) => {
				if (event.key === 'Enter' && onEnterListener) {
					onEnterListener();
				}
			}}
			InputProps={{
				endAdornment: (
					<InputAdornment position='end'>
						<PasswordValidationToolTip
							showSecureInput={showSecureInput}
							toolTipIsOpen={toolTipIsOpen}
							toggleShowSecureInput={toggleShowSecureInput}
							passwordValidationErrors={validationErrors}
							isTextEmpty={!value || value === ''}
						/>
					</InputAdornment>
				)
			}}
		/>
	);
};

SecureValidationInput.propTypes = {
	label: PropTypes.string,
	onChange: PropTypes.func,
	showTooltip: PropTypes.bool,
	value: PropTypes.string,
	onEnterListener: PropTypes.func,
	validator: PropTypes.func,
	initialValidationErrors: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.array
	])
};

SecureValidationInput.defaultProps = {
	showTooltip: true
};

export default SecureValidationInput;
