import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { withTranslation } from 'react-i18next';

import Accordion from '@mui/material/Accordion';
import Stack from '@mui/material/Stack';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const StyledAccordion = styled(Accordion)({
	display: 'flex',
	flexDirection: 'column',
	width: '900px'
});

const StyledAccordionSummary = styled(AccordionSummary)({
	display: 'flex',
	flexDirection: 'row'
});

const StyledCheckCircleIcon = styled(CheckCircleIcon)(({ theme }) => ({
	marginRight: '3px',
	alignSelf: 'center',
	fontSize: '1.2rem',
	color: theme.palette.success.main
}));

export const StageAccordion = (props) => {
	const {
		t,
		stageLabel,
		collapsedSummaryText,
		isAccordionExpanded,
		setisAccordionExpanded,
		isCompletedFlag,
		isDisabled,
		children
	} = props;

	return (
		<StyledAccordion
			expanded={isAccordionExpanded}
			disabled={isDisabled}
			onChange={() => setisAccordionExpanded(!isAccordionExpanded)}
		>
			<StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
				<Stack direction='row' justifyContent='space-between' sx={{ flex: 1 }}>
					<Stack direction='row' spacing={0.5} sx={{ flex: 1 }}>
						<StyledCheckCircleIcon	sx={{ display: isCompletedFlag ? 'block' : 'none' }}
						/>
						<Typography variant='overline'>{stageLabel}</Typography>
					</Stack>
					<Stack direction='row' justifyContent='center' alignItems='center'>
						{isCompletedFlag && (
							<Typography variant='body2'>{collapsedSummaryText}</Typography>
						)}
						{isDisabled && !isCompletedFlag ? (
							<div>
								<Typography variant='body2'>
									{t('groupWizard.completePreviousStage')}
								</Typography>
							</div>
						) : null}
					</Stack>
				</Stack>
			</StyledAccordionSummary>
			<AccordionDetails>{children}</AccordionDetails>
		</StyledAccordion>
	);
};

StageAccordion.propTypes = {
	t: PropTypes.func,
	stageLabel: PropTypes.string,
	collapsedSummaryText: PropTypes.string,
	isAccordionExpanded: PropTypes.bool,
	setisAccordionExpanded: PropTypes.func,
	isCompletedFlag: PropTypes.bool,
	isDisabled: PropTypes.bool,
	children: PropTypes.oneOfType([PropTypes.array, PropTypes.element])
};

export default withTranslation('groups')(StageAccordion);
