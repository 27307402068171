import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
	initialValidationErrors,
	validatePassword
} from '../../validation/password';
import ContainedLoadingButton from '../buttons/ContainedLoadingButton';
import BoldLargeHeader from '../headers/BoldLargeHeader';
import OverlineTextError from '../errors/OverlineTextError';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';

import SecureValidationInput from '../inputs/SecureValidationInput';

const PasswordSetupForm = (props) => {
	const {
		t,
		doPasswordsMatch,
		isSettingUpPassword,
		newUserPassword,
		setNewUserPassword,
		confirmNewPassword,
		setConfirmNewPassword,
		onSubmitPasswordSetup
	} = props;

	const isBlank = (value) => {
		return value.length < 0 || value === undefined || value === '';
	};

	return (
		<Box>
			<Stack direction='row' justifyContent='center' my={2} sx={{ px: 4 }}>
				<BoldLargeHeader>
					{t('registration:passwordSetup.pageTitle')}
				</BoldLargeHeader>
			</Stack>
			<Stack direction='row' justifyContent='center'>
				{!doPasswordsMatch ? (
					<OverlineTextError>
						{t('registration:passwordSetup.passwordMismatch')}
					</OverlineTextError>
				) : null}
			</Stack>
			<Stack p={1} mx={4} spacing={1}>
				<FormControl>
					<label>{t('registration:passwordSetup.input.newPassword')}</label>
					<SecureValidationInput
						value={newUserPassword}
						onChange={(value) => setNewUserPassword(value)}
						initialValidationErrors={initialValidationErrors}
						validator={validatePassword}
						t={t}
					/>
				</FormControl>
				<FormControl>
					<label>{t('registration:passwordSetup.input.confirm')}</label>
					<SecureValidationInput
						value={confirmNewPassword}
						onChange={(value) => setConfirmNewPassword(value)}
						initialValidationErrors={initialValidationErrors}
						validator={validatePassword}
						t={t}
					/>
				</FormControl>
			</Stack>
			<Stack direction='row' justifyContent='center' mb={3}>
				<ContainedLoadingButton
					bgcolor='primary.alt'
					loading={isSettingUpPassword}
					disabled={isBlank(newUserPassword) || isBlank(confirmNewPassword)}
					onClick={onSubmitPasswordSetup}
				>
					{t('common:buttons.submit')}
				</ContainedLoadingButton>
			</Stack>
		</Box>
	);
};

PasswordSetupForm.propTypes = {
	doPasswordsMatch: PropTypes.bool,
	isSettingUpPassword: PropTypes.bool,
	newUserPassword: PropTypes.string,
	setNewUserPassword: PropTypes.func,
	confirmNewPassword: PropTypes.string,
	setConfirmNewPassword: PropTypes.func,
	onSubmitPasswordSetup: PropTypes.func,
	t: PropTypes.func
};

export default withTranslation(['common', 'registration'])(PasswordSetupForm);
