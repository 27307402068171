import { fromJS } from 'immutable';
import * as actionTypes from './actionTypes';

export const initialState = fromJS({
	rules: {
		data: [],
		pageCount: 0,
		hasBeenLoaded: false,
		isLoading: false,
		error: undefined
	},
	createRule: {
		isCreatingRule: false,
		success: false,
		error: undefined
	},
	updateRule: {
		isUpdatingRule: false,
		success: false,
		error: undefined
	},
	groupActiveRules: {
		data: [],
		hasBeenLoaded: false,
		isLoading: false,
		error: undefined
	}
});

export const rulesReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.RESET_CREATE_RULE:
			return state
				.setIn(['createRule', 'isCreatingRule'], false)
				.setIn(['createRule', 'success'], false)
				.setIn(['createRule', 'error'], undefined);

		case actionTypes.RESET_EDIT_RULE:
			return state
				.setIn(['userRule', 'data'], {})
				.setIn(['userRule', 'isLoading'], false)
				.setIn(['userRule', 'hasBeenLoaded'], false)
				.setIn(['userRule', 'error'], undefined)
				.setIn(['updateRule', 'isUpdatingRule'], false)
				.setIn(['updateRule', 'success'], false)
				.setIn(['updateRule', 'error'], undefined);

		case actionTypes.CREATE_RULE:
			return state.setIn(['createRule', 'isCreatingRule'], true)
				.setIn(['createRule', 'error'], undefined);

		case actionTypes.CREATE_RULE_COMPLETED:
			return state.setIn(['createRule', 'isCreatingRule'], false)
				.setIn(['createRule', 'success'], true);

		case actionTypes.CREATE_RULE_FAILED:
			return state.setIn(['createRule', 'isCreatingRule'], false)
				.setIn(['createRule', 'error'], action.payload);

		case actionTypes.UPDATE_RULE:
			return state.setIn(['updateRule', 'isUpdatingRule'], true)
				.setIn(['updateRule', 'error'], undefined);

		case actionTypes.UPDATE_RULE_COMPLETED:
			return state.setIn(['updateRule', 'isUpdatingRule'], false)
				.setIn(['updateRule', 'success'], true);

		case actionTypes.UPDATE_RULE_FAILED:
			return state.setIn(['updateRule', 'isUpdatingRule'], false)
				.setIn(['updateRule', 'error'], action.payload);

		case actionTypes.GET_ACTIVE_RULES_BY_GROUP:
		case actionTypes.DELETE_ACTIVE_RULE:
			return state.setIn(['groupActiveRules', 'isLoading'], true);

		case actionTypes.GET_ACTIVE_RULES_BY_GROUP_COMPLETED:
			return state
				.setIn(['groupActiveRules', 'data'], action.payload)
				.setIn(['groupActiveRules', 'isLoading'], false)
				.setIn(['groupActiveRules', 'hasBeenLoaded'], true);

		case actionTypes.GET_ACTIVE_RULES_BY_GROUP_FAILED:
		case actionTypes.DELETE_ACTIVE_RULE_FAILED:
			return state
				.setIn(['groupActiveRules', 'isLoading'], false)
				.setIn(['groupActiveRules', 'error'], action.payload);

		default:
			return state;
	}
};
