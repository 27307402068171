export const ACTIVATE_MULTI_FACTOR_AUTH = 'mutliFactorAuth/ACTIVATE_MULTI_FACTOR_AUTH';
export const ACTIVATE_MULTI_FACTOR_AUTH_COMPLETED = 'mutliFactorAuth/ACTIVATE_MULTI_FACTOR_AUTH_COMPLETED';
export const ACTIVATE_MULTI_FACTOR_AUTH_FAILED = 'mutliFactorAuth/ACTIVATE_MULTI_FACTOR_AUTH_FAILED';

export const VERIFY_MULTI_FACTOR_AUTH_CODE = 'mutliFactorAuth/VERIFY_MULTI_FACTOR_AUTH_CODE';
export const VERIFY_MULTI_FACTOR_AUTH_CODE_COMPLETED = 'mutliFactorAuth/VERIFY_MULTI_FACTOR_AUTH_CODE_COMPLETED';
export const VERIFY_MULTI_FACTOR_AUTH_CODE_FAILED = 'mutliFactorAuth/VERIFY_MULTI_FACTOR_AUTH_CODE_FAILED';

export const SET_PREFERRED_MULTI_FACTOR_AUTH = 'mutliFactorAuth/SET_PREFERRED_MULTI_FACTOR_AUTH';

export const DISABLE_MULTI_FACTOR_AUTH = 'mutliFactorAuth/DISABLE_MULTI_FACTOR_AUTH';
export const DISABLE_MULTI_FACTOR_AUTH_COMPLETED = 'mutliFactorAuth/DISABLE_MULTI_FACTOR_AUTH_COMPLETED';
export const DISABLE_MULTI_FACTOR_AUTH_FAILED = 'mutliFactorAuth/DISABLE_MULTI_FACTOR_AUTH_FAILED';

export const LOGOUT = 'mutliFactorAuth/LOGOUT';
