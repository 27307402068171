import { fromJS } from 'immutable';
import * as actionTypes from './actionTypes';

export const initialState = fromJS({
	workedCases: {
		data: [],
		isLoading: false,
		hasBeenLoaded: false,
		error: undefined
	}
});

export const representmentsReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_WORKED_CASES:
			return state.setIn(['workedCases', 'isLoading'], true)
				.setIn(['workedCases', 'error'], undefined);

		case actionTypes.GET_WORKED_CASES_COMPLETED:
			return state
				.setIn(['workedCases', 'isLoading'], false)
				.setIn(['workedCases', 'hasBeenLoaded'], true)
				.setIn(['workedCases', 'data'], action.payload);

		case actionTypes.GET_WORKED_CASES_FAILED:
			return state
				.setIn(['workedCases', 'isLoading'], false)
				.setIn(['workedCases', 'error'], action.payload);

		default:
			return state;
	}
};
