import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import CategorySelectOption from './CategorySelectOption';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

const CategorySelect = (props) => {
	const {
		t,
		ruleConfiguration,
		updateConfiguration,
		categoryOptions,
		categoryOptionsError,
		categoryOptionsAreLoading,
		categoryOptionsHaveBeenLoaded,
		builderOptionsAreLoading,
		builderOptionsHaveBeenLoaded,
		isStageDisabled
	} = props;

	const onSelectCategory = (value) => {
		updateConfiguration('category', value);
	};

	if (categoryOptionsError) {
		return (
			<Stack direction='row' py={1} justifyContent='center'>
				<Typography color='primary' sx={{ fontWeight: 600 }}>
					{t('rules:failedToLoadCategories')}
				</Typography>
			</Stack>
		);
	}

	if (
		builderOptionsAreLoading ||
		!builderOptionsHaveBeenLoaded ||
		categoryOptionsAreLoading ||
		!categoryOptionsHaveBeenLoaded
	) {
		return (
			<Stack direction='row' justifyContent='center'>
				<CircularProgress />
			</Stack>
		);
	}

	return (
		<Stack>
			<Stack direction='row' py={1} justifyContent='center'>
				<Typography color='primary' sx={{ fontWeight: 600 }}>
					{t('rules:selectCategory')}
				</Typography>
			</Stack>
			<Grid
				container
				sx={{ width: '100%' }}
				columnSpacing={1}
				justifyContent='space-around'
			>
				{!isStageDisabled
					? categoryOptions.map((option) => (
						<CategorySelectOption
							key={option.id}
							option={option}
							onSelect={() => {
								onSelectCategory(option);
							}}
							isSelected={ruleConfiguration.category?.id === option.id}
						/>
					)) : null}
			</Grid>
		</Stack>
	);
};

CategorySelect.propTypes = {
	t: PropTypes.func,
	ruleConfiguration: PropTypes.object,
	updateConfiguration: PropTypes.func,
	categoryOptions: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	categoryOptionsError: PropTypes.string,
	categoryOptionsAreLoading: PropTypes.bool,
	categoryOptionsHaveBeenLoaded: PropTypes.bool,
	builderOptionsAreLoading: PropTypes.bool,
	builderOptionsHaveBeenLoaded: PropTypes.bool,
	isStageDisabled: PropTypes.bool
};

export default withTranslation('rules')(CategorySelect);
