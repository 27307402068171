import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import querier from '../../../../../../utilities/querier';
import PartSelect from './PartSelect';
import UserInputPartSelect from './UserInputPartSelect';

import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';

const PartSelectGrid = (props) => {
	const {
		t,
		ruleConfiguration,
		builderOptions,
		userInput,
		updateConfiguration,
		submitErrors
	} = props;

	return (
		<Stack justifyContent='flex-start' spacing={3} pt={2}>
			<PartSelect
				label={t('rules:formula')}
				configurationKey={'formula'}
				options={builderOptions.formula?.options}
				selectedOption={ruleConfiguration.formula}
				updateOption={updateConfiguration}
				ruleConfiguration={ruleConfiguration}
				hasError={querier(submitErrors, '$[type = "formula"]')}
			/>
			<Divider flexItem orientation='horizontal' />
			<PartSelect
				label={t('rules:measurement')}
				configurationKey={'measurement'}
				options={builderOptions.measurement?.options}
				selectedOption={ruleConfiguration.measurement}
				updateOption={updateConfiguration}
				ruleConfiguration={ruleConfiguration}
				hasError={querier(submitErrors, '$[type = "measurement"]')}
			/>
			<Divider flexItem orientation='horizontal' />
			<PartSelect
				label={t('rules:paymentMethod')}
				configurationKey={'paymentMethod'}
				options={builderOptions.paymentMethod?.options}
				selectedOption={ruleConfiguration.paymentMethod}
				updateOption={updateConfiguration}
				ruleConfiguration={ruleConfiguration}
				hasError={querier(submitErrors, '$[type = "paymentMethod"]')}
			/>
			<Divider flexItem orientation='horizontal' />
			<PartSelect
				label={t('rules:comparator')}
				configurationKey={'comparator'}
				options={builderOptions.comparator?.options}
				selectedOption={ruleConfiguration.comparator}
				updateOption={updateConfiguration}
				ruleConfiguration={ruleConfiguration}
				hasError={querier(submitErrors, '$[type = "comparator"]')}
			/>
			<Divider flexItem orientation='horizontal' />
			<PartSelect
				label={t('rules:comparisonUnit')}
				configurationKey={'comparisonUnit'}
				options={builderOptions.comparisonUnit?.options}
				selectedOption={ruleConfiguration.comparisonUnit}
				updateOption={updateConfiguration}
				ruleConfiguration={ruleConfiguration}
				extraPadding={!!ruleConfiguration?.comparisonUnit}
				hasError={querier(submitErrors, '$[type = "comparisonUnit"]')}
			>
				<UserInputPartSelect
					configurationKey={'userInput'}
					userInput={userInput}
					selectedComparisonUnit={ruleConfiguration.comparisonUnit}
					selectedComparator={ruleConfiguration.comparator}
					selectedUnits={ruleConfiguration.measurement}
					ruleConfiguration={ruleConfiguration}
					updateOption={updateConfiguration}
					hasError={querier(submitErrors, '$[type = "userInput"]')}
				/>
			</PartSelect>
			{ruleConfiguration.comparator ? (
				<Divider flexItem orientation='horizontal' />
			) : null}
			<PartSelect
				label={t('rules:timeFrame')}
				configurationKey={'timeFrame'}
				options={builderOptions.timeFrame?.options}
				selectedOption={ruleConfiguration.timeFrame}
				updateOption={updateConfiguration}
				ruleConfiguration={ruleConfiguration}
				hideUntilDependentSelected={true}
				dependentSelection={!!ruleConfiguration.comparator}
				hasError={querier(submitErrors, '$[type = "timeFrame"]')}
			/>
		</Stack>
	);
};

PartSelectGrid.propTypes = {
	t: PropTypes.func,
	ruleConfiguration: PropTypes.object,
	builderOptions: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	userInput: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	updateConfiguration: PropTypes.func,
	submitErrors: PropTypes.array
};

export default withTranslation('rules')(PartSelectGrid);
