import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import RuleDisplay from './RuleDisplay';
import PartSelectGrid from './PartSelectGrid';
import isObject from '../../../../../../helpers/shared/isObject';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const Builder = (props) => {
	const {
		ruleConfiguration,
		builderOptions,
		updateConfiguration,
		submitErrors,
		t
	} = props;

	if (ruleConfiguration?.category === null || !isObject(ruleConfiguration?.category)) {
		return null;
	}

	return (
		<Stack spacing={2} >
			<Stack direction='row' py={1} justifyContent='center'>
				<Typography color='primary' sx={{ fontWeight: 600 }}>{t('rules:buildRule')}</Typography>
			</Stack>
			<RuleDisplay
				selectedFormula={ruleConfiguration.formula}
				selectedMeasurement={ruleConfiguration.measurement}
				selectedPaymentMethod={ruleConfiguration.paymentMethod}
				selectedComparator={ruleConfiguration.comparator}
				selectedComparisonUnit={ruleConfiguration.comparisonUnit}
				selectedTimeFrame={ruleConfiguration.timeFrame}
				updateConfiguration={updateConfiguration}
				userInput={ruleConfiguration.userInput}
			/>
			<PartSelectGrid
				ruleConfiguration={ruleConfiguration}
				builderOptions={builderOptions}
				updateConfiguration={updateConfiguration}
				userInput={ruleConfiguration.userInput}
				submitErrors={submitErrors}
			/>
		</Stack>
	);
};

Builder.propTypes = {
	t: PropTypes.func,
	ruleConfiguration: PropTypes.object,
	builderOptions: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	updateConfiguration: PropTypes.func,
	submitErrors: PropTypes.array
};

export default withTranslation('rules')(Builder);
