import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { rulesActions } from '../../../containers/Rules';
import { getUserRule } from '../../../containers/Rules/userRuleSlice';
import deepCopy from '../../../utilities/deepCopy';
import UpdateRow from './components/UpdateRow';
import ErrorDisplay from '../shared/ErrorDisplay';
import newRuleConfiguration from '../constants/newRuleConfiguration';
import mapConfigurationForSubmit from '../shared/mappers/mapConfigurationForSubmit';

// Stages
import RuleBuilder from '../shared/stages/RuleBuilder';
import BuildListSelect from '../shared/stages/BuildListSelect';
import NotificationSettingsSelect from '../shared/stages/NotificationSettings/NotificationSettingsSelect';
import RulePropertiesSelect from '../shared/stages/RulePropertiesSelect';

import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

const EditRule = (props) => {
	const {
		t,
		getUserRule,
		isUpdatingRule,
		updateSuccess,
		updateError,
		updateRule,
		categoryOptionsError,
		builderOptionsError,
		userRule,
		userRuleIsLoading,
		userRuleHasBeenLoaded,
		userRuleError,
		categoryOptionsAreLoading,
		builderOptionsAreLoading,
		resetRuleEdit,
		user
	} = props;

	const [ruleConfiguration, setRuleConfiguration] = React.useState(
		deepCopy(newRuleConfiguration)
	);
	const [submitErrors, setSubmitErrors] = React.useState();

	const location = useLocation();
	const navigate = useNavigate();

	React.useEffect(() => {
		if (submitErrors?.length === 0) {
			setSubmitErrors();
		}
	}, [submitErrors]);

	React.useEffect(() => {
		const userRuleId = location.pathname.split('/')[3];

		if (userRuleId) {
			getUserRule({ userRuleId });
		}
	}, [location]);

	React.useEffect(() => {
		const userRuleId = location.pathname.split('/')[3];

		if (
			userRuleHasBeenLoaded &&
			userRule?.userRuleId === parseInt(userRuleId)
		) {
			const updatedRuleConfiguration = deepCopy(userRule);

			setRuleConfiguration(updatedRuleConfiguration);
		}
	}, [userRule]);

	React.useEffect(() => {
		return () => {
			onResetEdit();
		};
	}, []);

	React.useEffect(() => {
		if (updateSuccess) {
			onResetEdit();
			navigate('/rules');
		}
	}, [updateSuccess]);

	const onClickSubmit = () => {
		const userRuleId = location.pathname.split('/')[3];

		const updatedRuleConfiguration = mapConfigurationForSubmit(
			user,
			ruleConfiguration
		);

		updateRule({
			updatedRuleConfiguration,
			userRuleId
		});
	};

	const onResetEdit = () => {
		resetRuleEdit();
	};

	const disabledBuilder = userRuleIsLoading || !userRuleHasBeenLoaded || userRuleError ||
		categoryOptionsError !== undefined || builderOptionsError !== undefined;

	return (
		<Stack p={3} spacing={3} sx={{ height: 'calc(100% - 60px)', width: '100%', overflow: 'auto' }}>
			{ruleConfiguration?.userRuleId === undefined && !userRuleError ? (
				<Stack direction='row' alignItems='center' justifyContent='center'>
					<LinearProgress sx={{ width: '100%', maxWidth: '900px' }} />
				</Stack>
			) : null}
			{userRuleError ? (
				<Stack direction='row' alignItems='center' justifyContent='center'>
					<Typography>{t('rules:editRuleError')}</Typography>
				</Stack>
			) : null}
			<ErrorDisplay submitErrors={submitErrors} />
			<RuleBuilder
				ruleConfiguration={ruleConfiguration}
				setRuleConfiguration={setRuleConfiguration}
				isStageDisabled={userRuleIsLoading || !userRuleHasBeenLoaded}
				submitErrors={submitErrors}
				setSubmitErrors={setSubmitErrors}
			/>
			<BuildListSelect
				ruleConfiguration={ruleConfiguration}
				setRuleConfiguration={setRuleConfiguration}
				isStageDisabled={disabledBuilder}
				submitErrors={submitErrors}
				setSubmitErrors={setSubmitErrors}
			/>
			<NotificationSettingsSelect
				ruleConfiguration={ruleConfiguration}
				setRuleConfiguration={setRuleConfiguration}
				isStageDisabled={disabledBuilder}
				submitErrors={submitErrors}
				setSubmitErrors={setSubmitErrors}
			/>
			<RulePropertiesSelect
				ruleConfiguration={ruleConfiguration}
				setRuleConfiguration={setRuleConfiguration}
				isStageDisabled={disabledBuilder}
				submitErrors={submitErrors}
				setSubmitErrors={setSubmitErrors}
			/>
			<UpdateRow
				ruleConfiguration={ruleConfiguration}
				onClickSubmit={onClickSubmit}
				isUpdatingRule={isUpdatingRule}
				updateSuccess={updateSuccess}
				updateError={updateError}
				submitErrors={submitErrors}
				setSubmitErrors={setSubmitErrors}
				isLoading={
					categoryOptionsAreLoading ||
					builderOptionsAreLoading ||
					disabledBuilder
				}
			/>
		</Stack>
	);
};

const mapStateToProps = (state) => {
	return {
		user: state.userReducer.get('user'),
		userRule: state.userRuleSlice.userRule.data,
		userRuleIsLoading: state.userRuleSlice.userRule.userRuleIsLoading,
		userRuleHasBeenLoaded: state.userRuleSlice.userRule.hasBeenLoaded,
		userRuleError: state.userRuleSlice.userRule.error,
		isUpdatingRule: state.rulesReducer.getIn(['updateRule', 'isUpdatingRule']),
		updateSuccess: state.rulesReducer.getIn(['updateRule', 'success']),
		updateError: state.rulesReducer.getIn(['updateRule', 'error']),
		categoryOptionsAreLoading:
			state.ruleBuilderSlice.builderCategories.isLoading,
		categoryOptionsError: state.ruleBuilderSlice.builderCategories.error,
		builderOptionsAreLoading: state.ruleBuilderSlice.builderOptions.isLoading,
		builderOptionsError: state.ruleBuilderSlice.builderOptions.error
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getUserRule: (payload) => {
			dispatch(getUserRule(payload));
		},
		updateRule: (payload) => {
			dispatch(rulesActions.updateRule(payload));
		},
		resetRuleEdit: () => {
			dispatch(rulesActions.resetRuleEdit());
		}
	};
};

EditRule.propTypes = {
	t: PropTypes.func,
	user: PropTypes.object,
	getUserRule: PropTypes.func,
	userRule: PropTypes.object,
	userRuleIsLoading: PropTypes.bool,
	userRuleHasBeenLoaded: PropTypes.bool,
	userRuleError: PropTypes.string,
	isUpdatingRule: PropTypes.bool,
	updateSuccess: PropTypes.bool,
	updateError: PropTypes.string,
	categoryOptionsError: PropTypes.string,
	builderOptionsError: PropTypes.string,
	categoryOptionsAreLoading: PropTypes.bool,
	builderOptionsAreLoading: PropTypes.bool,
	updateRule: PropTypes.func,
	resetRuleEdit: PropTypes.func
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation('rules')(EditRule));
