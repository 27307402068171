import * as actionTypes from './actionTypes';

export const getInsightReport = (payload) => ({
	type: actionTypes.GET_INSIGHT_REPORT,
	payload
});

export const getInsightReportCompleted = (payload) => ({
	type: actionTypes.GET_INSIGHT_REPORT_COMPLETED,
	payload
});

export const getInsightReportFailed = (payload) => ({
	type: actionTypes.GET_INSIGHT_REPORT_FAILED,
	payload
});

export const resetInsightReport = (payload) => ({
	type: actionTypes.RESET_INSIGHT_REPORT,
	payload
});
