import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { getFullCardByBrandName } from '../../../../helpers/shared/cardBrandHelper';
import Restricted from '../../../../permissionProvider/Restricted';
import { formatDateForDisplay } from '../../../../utilities/dateWrapper';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import InfoIcon from '@mui/icons-material/Info';

export const NotChallengedTableRow = (props) => {
	const {
		row,
		labelId,
		t,
		onRevertToOpportunities,
		onViewRepresentment,
		revertIdList
	} = props;

	const isReverting = revertIdList?.includes(row.chargebackId);

	return (
		<TableRow hover key={row.caseId} sx={{ pl: 1, opacity: isReverting ? 0.4 : null }}>
			<TableCell component='th' scope='row' padding='none'>
				{row.paymentEnablerAcquiringBankLabel}
			</TableCell>
			<TableCell component='th' id={labelId} scope='row' padding='none'>
				{row.caseId}
			</TableCell>
			<TableCell align='left'>
				{row.billingLastName ? row.billingLastName : '-'}
			</TableCell>
			<TableCell align='center'>
				{row.amount ? row.amount.toFixed(2) : '-'}
			</TableCell>
			<TableCell align='center'>
				<Stack direction='column' alignItems='flex-end'>
					<Box
						component='img'
						src={getFullCardByBrandName(row.paymentCardBrand)}
						height={25}
						width={45}
					/>
					{row.paymentCardLast4 ? (
						<Tooltip
							placement='right'
							title={`${row.paymentCardBankIdentificationNumber}****${row.paymentCardLast4}`}
						>
							<Typography>{`****${row.paymentCardLast4}`}</Typography>
						</Tooltip>
					) : (
						'-'
					)}

				</Stack>
			</TableCell>
			<TableCell align='center'>
				{formatDateForDisplay(row.receivedByMerchantOn)}
			</TableCell>
			<TableCell align='center'>
				{formatDateForDisplay(row.notChallengedOn)}
			</TableCell>
			<TableCell align='center'>
				<Tooltip title={t('representmentsTable.viewRepresentment')}>
					<InfoIcon
						sx={{ color: 'primary.alt', cursor: 'pointer' }}
						onClick={() => {
							onViewRepresentment(row.chargebackId);
						}}
					/>
				</Tooltip>
			</TableCell>
			<Restricted to='representments.notChallenged.edit'>
				<TableCell align='center'>
					<Tooltip title={t('representmentsTable.revertToOpportunities')}>
						<SettingsBackupRestoreIcon
							sx={{ color: 'primary.alt', cursor: 'pointer' }}
							onClick={() => {
								onRevertToOpportunities(row.chargebackId);
							}}
						/>
					</Tooltip>
				</TableCell>
			</Restricted>
		</TableRow>
	);
};

NotChallengedTableRow.propTypes = {
	row: PropTypes.object,
	labelId: PropTypes.string,
	t: PropTypes.func,
	onRevertToOpportunities: PropTypes.func,
	onViewRepresentment: PropTypes.func,
	revertIdList: PropTypes.array
};

export default withTranslation('representments')(NotChallengedTableRow);
