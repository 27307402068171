import React from 'react';
import PropTypes from 'prop-types';

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const ErrorIndicator = ({ error, usePaper }) => {
	if (!error) {
		return null;
	}

	return (
		<Stack component={usePaper ? Paper : 'div'}
			direction='row' justifyContent='center' spacing={2} alignItems='center' p={1}
		>
			<ErrorOutlineIcon color='error' />
			<Typography color='error' variant='overline' sx={{ fontSize: '0.8rem' }}>
				{error}
			</Typography>
		</Stack>
	);
};

ErrorIndicator.propTypes = {
	error: PropTypes.string,
	usePaper: PropTypes.bool
};

export default ErrorIndicator;
