import axios from '../../utilities/axios';
import { Auth } from 'aws-amplify';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { refreshToken } from '../../utilities/refreshToken';

import { getRulesRunnerEndpoint } from '../../utilities/apiEndpointUtility';

import {
	getRuleRunToken,
	getRuleRunTokenCompleted,
	getRuleRunTokenFailed
} from './rulesRunnerTokenSlice';

export const getSocketGetwayTokenEpic = (action$) =>
	action$.pipe(
		ofType(getRuleRunToken),
		mergeMap(async (action) => {
			await refreshToken();

			const session = await Auth.currentSession();

			const jwtToken = session.getAccessToken().getJwtToken();

			const response = await axios.get(
				`${getRulesRunnerEndpoint()}/token`, 		{
					headers: {
						token: jwtToken
					}
				});

			return response.data;
		}),
		switchMap((res) => [
			getRuleRunTokenCompleted(res)
		]),
		catchError((error, source) =>
			merge(of(
				getRuleRunTokenFailed(error.message)
			), source)
		)
	);

export default [
	getSocketGetwayTokenEpic
];
