import axios from '../../utilities/axios';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { refreshToken } from '../../utilities/refreshToken';
import { pdfEndpoint } from '../../utilities/apiEndpointUtility';

import * as actions from './actions';
import * as actionTypes from './actionTypes';

export const getPdfEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.GET_PDF),
		mergeMap(async (action) => {
			await refreshToken();

			const documentUrl = action.payload.documentUrl;

			const response = await axios.get(`${pdfEndpoint()}${documentUrl}`, {
				responseType: 'arraybuffer',
				responseEncoding: 'binary',
				headers: {
					'Content-Type': 'application/json'
				}
			});

			if (!response.data) {
				throw Error('Issue Generating Pdf');
			}

			return response.data;
		}),
		switchMap((res) => [actions.getPdfCompleted(res)]),
		catchError((error, source) =>
			merge(of(actions.getPdfFailed(error.message)), source)
		)
	);
