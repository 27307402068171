import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const SecureInput = (props) => {
	const { label, onChange, value, onEnterListener } = props;

	const [showSecureInput, setShowSecureInput] = React.useState(false);

	const toggleShowSecureInput = () => {
		setShowSecureInput(!showSecureInput);
	};

	return (
		<TextField
			onChange={(e) => onChange(e.target.value)}
			sx={{ width: '30ch', mb: '10px', textDecoration: 'none!important' }}
			variant='outlined'
			size='small'
			label={label}
			type={showSecureInput ? 'text' : 'password'}
			value={value}
			onKeyDown={(event) => {
				if (event.key === 'Enter' && onEnterListener) {
					onEnterListener();
				}
			}}
			InputProps={{
				endAdornment: (
					<InputAdornment position='end'>
						<IconButton sx={{ color: 'secondary.alt' }} onClick={toggleShowSecureInput}>
							{showSecureInput ? (
								<VisibilityIcon	/>
							) : (
								<VisibilityOffIcon />
							)}
						</IconButton>
					</InputAdornment>
				)
			}}
		/>
	);
};

SecureInput.propTypes = {
	label: PropTypes.string,
	onChange: PropTypes.func,
	onEnterListener: PropTypes.func,
	value: PropTypes.string
};

export default withTranslation()(SecureInput);
