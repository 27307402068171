import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import querier from '../../../utilities/querier';
import isDataEmpty from '../../../helpers/shared/isDataEmpty';
import { getProcessors } from '../../../containers/Processors/processorsSlice';

import FormControl from '@mui/material/FormControl';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const ProcessorSelect = (props) => {
	const {
		onChange,
		value,
		t,
		temporaryValue,
		setTemporaryValue
	} = props;
	const dispatch = useDispatch();

	const [inputValue, setInputValue] = React.useState('');
	const [open, setOpen] = React.useState(false);
	const [options, setOptions] = React.useState([]);
	const loading = options.length === 0 && open;

	const {
		data: processors,
		isLoading: isLoadingProcessors,
		hasBeenLoaded: processorsHaveBeenLoaded,
		error: processorsError
	} = useSelector((state) => state.processorsSlice.processors);

	React.useEffect(() => {
		if (
			open && isDataEmpty(processors) && !processorsError
		) {
			dispatch(getProcessors());
		} else if (temporaryValue && !isLoadingProcessors && !processorsHaveBeenLoaded) {
			dispatch(getProcessors());
		}
	}, [open, processors, processorsHaveBeenLoaded, processorsError, isLoadingProcessors, temporaryValue]);

	React.useEffect(() => {
		if (open && isDataEmpty(options) && !isDataEmpty(processors)) {
			setOptions(processors);
		}
	}, [open, options, processors]);

	React.useEffect(() => {
		if (!open) {
			setOptions([]);
		}
	}, [open]);

	React.useEffect(() => {
		if (temporaryValue && !isDataEmpty(processors)) {
			const matchedProcessor = querier(
				processors,
				`$[id = ${temporaryValue}]`
			);

			onChange(matchedProcessor);
			setTemporaryValue();
		}
	}, [temporaryValue, processors]);

	const isDisabled = (isDataEmpty(processors) ||
	!isDataEmpty(temporaryValue));

	return (
		<FormControl sx={{ flex: 1, display: 'flex' }}>
			<Autocomplete
				filterSelectedOptions
				freeSolo
				options={options}
				open={open}
				onOpen={() => {
					setOpen(true);
				}}
				onClose={() => {
					setOpen(false);
				}}
				loading={loading}
				isOptionEqualToValue={(option, item) => {
					return isDataEmpty(item) ? '' : option?.id === item?.id;
				}}
				getOptionLabel={(option) => {
					return isDataEmpty(option) ? '' : option?.description;
				}}
				value={value || null}
				onChange={(event, newValue) => {
					onChange(newValue);
				}}
				inputValue={inputValue}
				onInputChange={(event, value) => {
					setInputValue(value);
				}}
				renderInput={(params) => (
					<TextField
						{...params}
						sx={{ flex: 1 }}
						label={t('selectSingleProcessor')}
						variant='outlined'
						disabled={isDisabled || isLoadingProcessors}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<React.Fragment>
									{isLoadingProcessors ? (
										<CircularProgress color='inherit' size={20} />
									) : null}
									{params.InputProps.endAdornment}
								</React.Fragment>
							)
						}}
					/>
				)}
			/>
		</FormControl>
	);
};

ProcessorSelect.propTypes = {
	onChange: PropTypes.func,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	t: PropTypes.func,
	temporaryValue: PropTypes.string,
	setTemporaryValue: PropTypes.func,
	isStageDisabled: PropTypes.bool
};

export default withTranslation('rules')(ProcessorSelect);
