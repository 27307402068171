import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { modalActions } from '../../../containers/Modal';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import MoreVertIcon from '@mui/icons-material/MoreVert';

const UserActionList = (props) => {
	const {
		setDeleteUserModal,
		setUpdateUserStatusModal,
		setPermissionsModalUser,
		t,
		userData
	} = props;

	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (e) => setAnchorEl(e.currentTarget);

	const handleClose = () => setAnchorEl(null);

	return (
		<Box sx={{ m: 0.5, display: 'flex', justifyContent: 'flex-end' }}>
			<IconButton size='small' onClick={handleClick}>
				<MoreVertIcon />
			</IconButton>
			<Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
				<MenuItem
					onClick={() => {
						setPermissionsModalUser(userData);
						handleClose();
					}}
				>
					{t('usersTable.actions.permissions')}
				</MenuItem>
				<MenuItem
					onClick={() => {
						setUpdateUserStatusModal(userData);
						handleClose();
					}}
				>
					{t(
						`usersTable.actions.${userData?.isEnabled ? 'disable' : 'enable'}`
					)}
				</MenuItem>
				<MenuItem
					onClick={() => {
						setDeleteUserModal(userData);
						handleClose();
					}}
				>
					{t('usersTable.actions.delete')}
				</MenuItem>
			</Menu>
		</Box>
	);
};

UserActionList.propTypes = {
	setDeleteUserModal: PropTypes.func,
	setDisableUserModal: PropTypes.func,
	setPermissionsModalUser: PropTypes.func,
	setUpdateUserStatusModal: PropTypes.func,
	t: PropTypes.func,
	userData: PropTypes.object
};

const mapDispatchToProps = (dispatch) => {
	return {
		setDeleteUserModal: (payload) => {
			dispatch(modalActions.setDeleteUserModal(payload));
		},
		setUpdateUserStatusModal: (payload) => {
			dispatch(modalActions.setUpdateUserStatusModal(payload));
		},
		setPermissionsModalUser: (payload) => {
			dispatch(modalActions.setPermissionsModalUser(payload));
		}
	};
};

export default connect(
	null,
	mapDispatchToProps
)(withTranslation('users')(UserActionList));
