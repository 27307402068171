import { capitalCase } from 'change-case';
import ruleListTypes from '../../constants/ruleListTypes';
import requiredSubmitKeys from '../../constants/requiredSubmitKeys';
import { getUserInputError } from './validateUserInput';

export default function (ruleConfiguration, t) {
	const errors = [];

	Object.keys(ruleConfiguration).forEach((key) => {
		if (
			requiredSubmitKeys.includes(key) &&
			(ruleConfiguration[key] === undefined || ruleConfiguration[key] === null)
		) {
			errors.push({
				type: key,
				message: `Missing selection for ${capitalCase(key)}`
			});
		}
	});

	if (!ruleConfiguration.ruleListType?.id) {
		errors.push({
			type: 'ruleListType',
			message: 'Missing Apply To Selection'
		});
	} else if (
		ruleConfiguration.ruleListType?.id === ruleListTypes[0].id &&
		ruleConfiguration[ruleListTypes[0].name] === null
	) {
		errors.push({
			type: 'group',
			message: t('rules:errorDisplay.missingGroup')
		});
	} else if (
		ruleConfiguration.ruleListType?.id === ruleListTypes[1].id &&
		ruleConfiguration[ruleListTypes[1].name] === null
	) {
		errors.push({
			type: 'processor',
			message: t('rules:errorDisplay.missingProcessor')
		});
	} else if (
		ruleConfiguration.ruleListType?.id === ruleListTypes[2].id &&
		ruleConfiguration[ruleListTypes[2].name].length === 0
	) {
		errors.push({
			type: 'merchantIds',
			message: t('rules:errorDisplay.missingMerchant')
		});
	}

	if (!ruleConfiguration.userInput || ruleConfiguration.userInput === '') {
		errors.push({
			type: 'userInput',
			message: 'Missing user input'
		});
	}

	if (ruleConfiguration.label === '') {
		errors.push({
			type: 'label',
			message: 'Missing Label'
		});
	}

	const userInputError = getUserInputError(
		ruleConfiguration.userInput,
		ruleConfiguration.comparisonUnit?.optionName,
		t
	);

	if (userInputError) {
		errors.push({
			type: 'userInput',
			message: userInputError
		});
	}

	return errors;
}
