import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import ContainedButton from '../../buttons/ContainedButton';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export const CompletionPanel = (props) => {
	const { t, onClose } = props;

	return (
		<Stack spacing={2} p={2} justifyContent='space-around' alignItems='center' sx={{ height: '300px' }}>
			<Typography variant='h6'>
				{t('modals:inviteUser.success')}
			</Typography>
			<ContainedButton onClick={onClose}>
				{t('common:buttons.close')}
			</ContainedButton>
		</Stack>
	);
};

CompletionPanel.propTypes = {
	onClose: PropTypes.func,
	t: PropTypes.func
};

export default withTranslation(['common', 'modals'])(CompletionPanel);
