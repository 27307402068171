import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
	getRuleBuilderCategories,
	getRuleBuilderOptions
} from '../../../../../containers/Rules/ruleBuilderSlice';
import deepCopy from '../../../../../utilities/deepCopy';
import querier from '../../../../../utilities/querier';
import Builder from './components/Builder';
import CategorySelect from './components/CategorySelect';
import StageAccordion from '../../StageAccordion';

import Stack from '@mui/material/Stack';

const RuleBuilder = (props) => {
	const {
		t,
		ruleConfiguration,
		categoryOptions,
		categoryOptionsAreLoading,
		categoryOptionsHaveBeenLoaded,
		categoryOptionsError,
		getRuleCategoryOptions,
		builderOptions,
		builderOptionsAreLoading,
		builderOptionsHaveBeenLoaded,
		builderOptionsError,
		getRuleBuilderOptions,
		setRuleConfiguration,
		isStageDisabled,
		submitErrors,
		setSubmitErrors
	} = props;

	React.useEffect(() => {
		getRuleBuilderOptions();
		getRuleCategoryOptions();
	}, []);

	const updateConfiguration = (property, value) => {
		const updatedConfiguration = deepCopy(ruleConfiguration);

		updatedConfiguration[property] = value;

		if (property === 'category') {
			setRuleConfiguration((previousConfiguration) => ({
				...previousConfiguration,
				[property]: value,
				formula: null,
				measurement: null,
				paymentMethod: null,
				comparator: null,
				comparisonUnit: null,
				timeFrame: null,
				userInput: null
			}));
		}

		if (property === 'comparator') {
			setRuleConfiguration((previousConfiguration) => ({
				...previousConfiguration,
				[property]: value,
				timeFrame: null
			}));
		}

		if (submitErrors) {
			const updatedSubmitErrors = submitErrors.filter((error) => {
				return error.type !== property;
			});

			setSubmitErrors(updatedSubmitErrors);
		}

		setRuleConfiguration((previousConfiguration) => ({
			...previousConfiguration,
			[property]: value
		}));
	};

	return (
		<Stack direction='row' justifyContent='center'>
			<StageAccordion
				stageLabel={t('rules:categorySelect')}
				isAccordionExpanded={true}
				setisAccordionExpanded={() => {}}
				isCompletedFlag={false}
				isDisabled={isStageDisabled}
				hasError={querier(submitErrors, '$[type = "category"]')}
			>
				<Stack p={3} spacing={3} sx={{ width: '100%' }}>
					<CategorySelect
						ruleConfiguration={ruleConfiguration}
						categoryOptions={categoryOptions}
						categoryOptionsAreLoading={categoryOptionsAreLoading}
						categoryOptionsHaveBeenLoaded={categoryOptionsHaveBeenLoaded}
						builderOptionsAreLoading={builderOptionsAreLoading}
						builderOptionsHaveBeenLoaded={builderOptionsHaveBeenLoaded}
						categoryOptionsError={categoryOptionsError}
						updateConfiguration={updateConfiguration}
						isStageDisabled={isStageDisabled}
					/>
					<Builder
						ruleConfiguration={ruleConfiguration}
						builderOptionsError={builderOptionsError}
						builderOptions={builderOptions}
						updateConfiguration={updateConfiguration}
						submitErrors={submitErrors}
					/>
				</Stack>
			</StageAccordion>
		</Stack>
	);
};

const mapStateToProps = (state) => {
	return {
		categoryOptions: state.ruleBuilderSlice.builderCategories.data,
		categoryOptionsAreLoading:
			state.ruleBuilderSlice.builderCategories.isLoading,
		categoryOptionsHaveBeenLoaded:
			state.ruleBuilderSlice.builderCategories.hasBeenLoaded,
		categoryOptionsError: state.ruleBuilderSlice.builderCategories.error,
		builderOptions: state.ruleBuilderSlice.builderOptions.data,
		builderOptionsAreLoading: state.ruleBuilderSlice.builderOptions.isLoading,
		builderOptionsHaveBeenLoaded:
			state.ruleBuilderSlice.builderOptions.hasBeenLoaded,
		builderOptionsError: state.ruleBuilderSlice.builderOptions.error
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getRuleCategoryOptions: (payload) => {
			dispatch(getRuleBuilderCategories(payload));
		},
		getRuleBuilderOptions: (payload) => {
			dispatch(getRuleBuilderOptions(payload));
		}
	};
};

RuleBuilder.propTypes = {
	t: PropTypes.func,
	ruleConfiguration: PropTypes.object,
	categoryOptions: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	categoryOptionsAreLoading: PropTypes.bool,
	categoryOptionsHaveBeenLoaded: PropTypes.bool,
	categoryOptionsError: PropTypes.string,
	getRuleCategoryOptions: PropTypes.func,
	builderOptions: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	builderOptionsAreLoading: PropTypes.bool,
	builderOptionsHaveBeenLoaded: PropTypes.bool,
	builderOptionsError: PropTypes.string,
	getRuleBuilderOptions: PropTypes.func,
	setRuleConfiguration: PropTypes.func,
	isStageDisabled: PropTypes.bool,
	submitErrors: PropTypes.func,
	setSubmitErrors: PropTypes.func
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation('rules')(RuleBuilder));
