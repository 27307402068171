import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Restricted from '../../../permissionProvider/Restricted';
import OutlinedButton from '../../buttons/OutlinedButton';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

const EnhancedTableToolbar = (props) => {
	const {
		t,
		searchTerm,
		setSearchTerm,
		onClearSearchTerm,
		inviteUserClicked
	} = props;

	return (
		<Toolbar
			sx={{
				flex: 1,
				pl: { sm: 2 },
				pr: { xs: 1, sm: 1 },
				pb: 2
			}}
		>
			<Stack
				direction='row'
				p={1}
				spacing={2}
				sx={{ flex: 1 }}
				alignItems='flex-end'
				justifyContent='flex-end'
			>
				<TextField
					value={searchTerm}
					onChange={(e) => setSearchTerm(e.target.value)}
					variant='standard'
					placeholder={t('common:search')}
					InputProps={{
						startAdornment: <SearchIcon />,
						endAdornment: (
							<IconButton
								sx={{ visibility: searchTerm.length === 0 && 'hidden' }}
								onClick={onClearSearchTerm}
							>
								<ClearIcon />
							</IconButton>
						)
					}}
				/>
				<Restricted to='users.usersList.add'>
					<Box maxWidth={300}>
						<OutlinedButton onClick={inviteUserClicked}>
							{t('users:usersTable.inviteUser')}
						</OutlinedButton>
					</Box>
				</Restricted>
			</Stack>
		</Toolbar>
	);
};

EnhancedTableToolbar.propTypes = {
	t: PropTypes.func,
	searchTerm: PropTypes.string,
	setSearchTerm: PropTypes.func,
	onClearSearchTerm: PropTypes.func,
	onCreateList: PropTypes.func,
	inviteUserClicked: PropTypes.func
};

export default withTranslation(['common', 'users'])(EnhancedTableToolbar);
