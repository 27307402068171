import React from 'react';
import PropTypes from 'prop-types';

import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const ConfirmSelection = ({ shouldDisplay, onConfirm, onCancel }) => {
	if (!shouldDisplay) {
		return null;
	}

	return (
		<Stack direction='row' justifyContent='flex-end'>
			<IconButton onClick={onConfirm}>
				<CheckIcon color='success' />
			</IconButton>
			<IconButton onClick={onCancel}>
				<CloseIcon color='error' />
			</IconButton>
		</Stack>
	);
};

ConfirmSelection.propTypes = {
	t: PropTypes.func,
	shouldDisplay: PropTypes.bool,
	onConfirm: PropTypes.func,
	onCancel: PropTypes.func
};

export default ConfirmSelection;
