import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	notifications: {
		data: [],
		currentPage: 1,
		availablePageCount: 0,
		isLoading: false,
		hasBeenLoaded: false,
		error: undefined
	}
};

export const notificationsSlice = createSlice({
	name: 'notifications',
	initialState,
	reducers: {
		getNotifications: (state) => {
			state.notifications.isLoading = true;
			state.notifications.error = undefined;
		},
		getNotificationsCompleted: (state, action) => {
			state.notifications.isLoading = false;
			state.notifications.hasBeenLoaded = true;
			state.notifications.data = action.payload.results;
			state.notifications.availablePageCount = action.payload.availablePageCount;
			state.notifications.currentPage = action.payload.page;
		},
		getNotificationsFailed: (state, action) => {
			state.notifications.isLoading = false;
			state.notifications.error = action.payload;
		},
		clearNotifications: (state) => {
			state.notifications = initialState.notifications;
		}
	}
});

export const {
	getNotifications,
	getNotificationsCompleted,
	getNotificationsFailed,
	clearNotifications
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
