import * as actionTypes from './actionTypes';

export const getDefaultLayout = (payload) => ({
	type: actionTypes.GET_DEFAULT_LAYOUT,
	payload
});

export const getDefaultLayoutCompleted = (payload) => ({
	type: actionTypes.GET_DEFAULT_LAYOUT_COMPLETED,
	payload
});

export const getDefaultLayoutFailed = (payload) => ({
	type: actionTypes.GET_DEFAULT_LAYOUT_FAILED,
	payload
});
