import React from 'react';
import PermissionContext from './PermissionContext';

const usePermission = (permission) => {
	const [loading, setLoading] = React.useState(true);
	const [allowed, setAllowed] = React.useState();

	const { isAllowedTo } = React.useContext(PermissionContext);

	React.useEffect(() => {
		let mounted = true;

		if (mounted) {
			const allowed = isAllowedTo(permission);

			setLoading(false);
			setAllowed(allowed);
		}

		return () => (mounted = false);
	}, []);

	return [loading, allowed];
};

export default usePermission;
