export const GET_WORKED_CASES = 'representments/GET_WORKED_CASES';
export const GET_WORKED_CASES_COMPLETED =
	'representments/GET_WORKED_CASES_COMPLETED';
export const GET_WORKED_CASES_FAILED = 'representments/GET_WORKED_CASES_FAILED';

export const REMOVE_NOT_CHALLENGED_DATE =
	'representments/REMOVE_NOT_CHALLENGED_DATE';
export const REMOVE_NOT_CHALLENGED_DATE_COMPLETED =
	'representments/REMOVE_NOT_CHALLENGED_DATE_COMPLETED';
export const REMOVE_NOT_CHALLENGED_DATE_FAILED =
	'representments/REMOVE_NOT_CHALLENGED_DATE_FAILED';
