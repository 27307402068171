import * as actionTypes from '../actionTypes';

export const handleDynamicReport = (state, action, key) => {
	const reportId = action.payload.reportUniversalUniqueIdentifier;
	const existingReport = state.get(key).get(reportId);

	if (action.type === actionTypes.CLEAR_REPORT) {
		return state.deleteIn([`${key}`, `${reportId}`]);
	}

	if (existingReport === undefined) {
		return state.setIn(`${key}.${reportId}`.split('.'), {
			reportId,
			isLoading: true,
			hasBeenLoaded: false,
			error: undefined,
			data: {}
		});
	}

	if (action.type === actionTypes.EXECUTE_REPORT) {
		return state
			.setIn(`${key}.${reportId}.error`.split('.'), undefined)
			.setIn(`${key}.${reportId}.isLoading`.split('.'), true)
			.setIn(
				`${key}.${reportId}.hasBeenLoaded`.split('.'),
				state.getIn(`${key}.${reportId}.hasBeenLoaded`.split('.'))
			)
			.setIn(
				`${key}.${reportId}.data`.split('.'),
				state.getIn(`${key}.${reportId}.data`.split('.'))
			);
	}

	if (action.payload.error) {
		return state
			.setIn(`${key}.${reportId}.isLoading`.split('.'), false)
			.setIn(`${key}.${reportId}.error`.split('.'), action.payload.error);
	}

	if (action.payload.data) {
		return state
			.setIn(`${key}.${reportId}.isLoading`.split('.'), false)
			.setIn(`${key}.${reportId}.hasBeenLoaded`.split('.'), true)
			.setIn(`${key}.${reportId}.error`.split('.'), undefined)
			.setIn(`${key}.${reportId}.data`.split('.'), action.payload.data);
	}

	if (action.payload.data === undefined) {
		return state.setIn(`${key}.${reportId}.isLoading`.split('.'), true);
	}

	return state;
};
