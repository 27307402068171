import config from '../config.json';

export function serviceApiEndpoint() {
	return config[process.env.REACT_APP_STAGE].api.servicesApi;
}

export function primaryRestGateway() {
	return config[process.env.REACT_APP_STAGE].api.primaryRestGateway;
}

export function secondaryRestGateway() {
	return config[process.env.REACT_APP_STAGE].api.secondaryRestGateway;
}

export function pdfEndpoint() {
	return config[process.env.REACT_APP_STAGE].pdfURI;
}

export function landingPageURI() {
	return config[process.env.REACT_APP_STAGE].landingPageURI;
}

export function tableauServer() {
	return config[process.env.REACT_APP_STAGE].tableauServer;
}

export function getRulesRunnerEndpoint() {
	return config[process.env.REACT_APP_STAGE].rulesRunnerApi;
}

export function getWebsocketGatewayEndpoint() {
	return config[process.env.REACT_APP_STAGE].websocketGateway;
}
