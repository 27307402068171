export const notificationSeverityLevels = [
	{
		id: 1,
		name: 'severe',
		colorThemePath: 'error'
	},
	{
		id: 2,
		name: 'warning',
		colorThemePath: 'warning'
	},
	{
		id: 3,
		name: 'information',
		colorThemePath: 'information'
	}
];

export const notificationSeverityLevelsNamed = {
	severe: notificationSeverityLevels[0],
	warning: notificationSeverityLevels[1],
	information: notificationSeverityLevels[2]
};
