import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import EnhancedTableHead from '../components/EnhancedTableHead';
import NoDataRow from '../components/NoDataRow';
import TableErrorRow from '../../errors/TableErrorRow';

import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

export const PaginatedTable = (props) => {
	const {
		t,
		data,
		isLoading,
		hasBeenLoaded,
		reportName,
		columnDefinitions,
		currentPage,
		handleChangePage,
		availablePageCount,
		rowGenerator,
		error
	} = props;

	const onPageChangeClicked = (e, newPage) => {
		handleChangePage(newPage);
	};

	return (
		<Box sx={{ width: '100%', mb: 2 }}>
			<Stack direction='row' justifyContent='flex-end' my={1}>
				<Pagination count={availablePageCount} page={currentPage} onChange={onPageChangeClicked} color='primary' />
			</Stack>
			<TableContainer sx={{ borderTopLeftRadius: 10, borderTopRightRadius: 10 }}>
				<Table
					aria-labelledby={reportName}
					size={'small'}
					stickyHeader
				>
					<EnhancedTableHead
						reportName={reportName}
						columnDefinitions={columnDefinitions}
					/>
					<TableBody>
						{isLoading ? (
							<TableRow>
								<TableCell colSpan={columnDefinitions.length}>
									<LinearProgress width={'100%'} />
								</TableCell>
							</TableRow>
						) : null}
						{ error
							? <TableErrorRow errorText={t('error.tableError')} columnSpan={columnDefinitions.length} />
							: null
						}
						{ hasBeenLoaded && !error
							? rowGenerator()
							: null
						}
						{hasBeenLoaded && !isLoading && data.length === 0 && (
							<NoDataRow columnSpan={columnDefinitions.length} />
						)}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	);
};

PaginatedTable.propTypes = {
	t: PropTypes.func,
	data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	isLoading: PropTypes.bool,
	hasBeenLoaded: PropTypes.bool,
	initialSortColumn: PropTypes.string,
	reportName: PropTypes.string,
	columnDefinitions: PropTypes.array,
	currentPage: PropTypes.number,
	handleChangePage: PropTypes.func,
	availablePageCount: PropTypes.number,
	rowGenerator: PropTypes.func,
	error: PropTypes.string
};

export default withTranslation('common')(PaginatedTable);
