import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { getAssetUri } from '../../../../utilities/assetEndpointUtility';
import { getAuth } from '../../../../utilities/axios';

import Tooltip from '@mui/material/Tooltip';

const StyledImage = styled('img')({
	cursor: 'pointer',
	margin: '10px 5px 5px 5px',
	borderRadius: '3px',
	boxShadow: '0 0 0 1px rgba(0,0,0,.1)'
});

const ImageAttachment = (props) => {
	const { attachment, onOpenAttachmentViewerModal } = props;

	const [img, setImg] = React.useState();

	const fetchImage = async () => {
		const res = await fetch(`${getAssetUri()}${attachment.assetData}`, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${getAuth()}`
			}
		});
		const imageBlob = await res.blob();
		const imageObjectURL = URL.createObjectURL(imageBlob);
		setImg(imageObjectURL);
	};

	React.useEffect(() => {
		fetchImage();
	}, []);

	return (
		<Tooltip title={attachment.label}>
			<StyledImage
				src={img}
				height={90}
				width={150}
				onClick={() => {
					onOpenAttachmentViewerModal(attachment);
				}}
			/>
		</Tooltip>
	);
};

ImageAttachment.propTypes = {
	attachment: PropTypes.object,
	onOpenAttachmentViewerModal: PropTypes.func,
	t: PropTypes.func
};

export default ImageAttachment;
