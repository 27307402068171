import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { dataSourceActions } from '../../../../../containers/DataSource';
import getDynamicDataFromList from '../../../../../helpers/shared/dynamicReducers/getDynamicDataFromList';
import querier from '../../../../../utilities/querier';
import isDataEmpty from '../../../../../helpers/shared/isDataEmpty';
import MerchantTooltip from '../../../../shared/InputToolTips/MerchantTooltip';
import keyCodes from '../../../../../constants/keyCodes';

import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

const StyledListItem = styled(ListItem)(({ theme }) => ({
	marginLeft: '3px',
	marginTop: '4px',
	marginBottom: '4px',
	cursor: 'pointer',
	'&:hover': {
		backgroundColor: theme.palette.reports.filters.merchantSelectHightlight,
		color: theme.palette.primary.main
	}
}));

const MerchantSelect = (props) => {
	const {
		onChange,
		translationPath,
		value,
		dataSource,
		t,
		dataSources,
		getDataSource,
		temporaryGroupValues,
		setTemporaryGroupValues,
		listItemsIndividually
	} = props;

	const [inputValue, setInputValue] = React.useState('');
	const [open, setOpen] = React.useState(false);
	const [options, setOptions] = React.useState([]);

	const matchedDataSource = getDynamicDataFromList(dataSources, dataSource);

	React.useEffect(() => {
		getDataSource({
			dataSourceKey: dataSource
		});
	}, []);

	React.useEffect(() => {
		if (open) {
			setOptions(matchedDataSource?.data);
		}
	}, [open, matchedDataSource]);

	React.useEffect(() => {
		if (!open) {
			setOptions([]);
		}
	}, [open]);

	React.useEffect(() => {
		if (temporaryGroupValues && !isDataEmpty(matchedDataSource?.data)) {
			const mappedValues = temporaryGroupValues.map((temporaryValue) => {
				const foundValue = querier(
					matchedDataSource,
					`data.$[id = ${temporaryValue.merchantRowId}]`
				);
				foundValue.itemRowId = temporaryValue.id;

				return foundValue;
			});

			onChange(mappedValues);
			setTemporaryGroupValues();
		}
	}, [temporaryGroupValues, matchedDataSource]);

	const loading =
		(options.length === 0 && open) ||
		!matchedDataSource ||
		!matchedDataSource?.hasBeenLoaded;

	return (
		<FormControl sx={{ flex: 1 }}>
			<Autocomplete
				multiple
				filterSelectedOptions
				options={options}
				open={open}
				onOpen={() => {
					setOpen(true);
				}}
				onClose={() => {
					setOpen(false);
				}}
				loading={loading}
				disabled={!matchedDataSource || !matchedDataSource?.hasBeenLoaded}
				isOptionEqualToValue={(option, item) => {
					return option.value === item.value;
				}}
				getOptionLabel={(option) => option?.label}
				renderTags={
					!listItemsIndividually
						? (value) => (
							<Tooltip title={<MerchantTooltip value={value} />}>
								<Chip variant='outlined' label={`${value.length} selected`} />
							</Tooltip>
						)	: undefined
				}
				renderOption={(props, option) => {
					return (
						<StyledListItem {...props} key={option.id}>
							<Stack direction='column'>
								<Typography sx={{ fontWeight: 600 }}>
									{option.label}
								</Typography>
								<Typography variant='caption'>{`${option.processor} - ${option.merchantId}`}</Typography>
							</Stack>
						</StyledListItem>
					);
				}}
				value={value}
				onChange={(event, newValue, reason) => {
					if (
						reason !== 'removeOption' ||
						event.keyCode !== keyCodes.backspace
					) {
						onChange(newValue);
					}
				}}
				inputValue={inputValue}
				onInputChange={(event, newInputValue) => {
					setInputValue(newInputValue);
				}}
				sx={{ ml: 1, flex: 1 }}
				renderInput={(params) => (
					<TextField
						{...params}
						label={t(translationPath)}
						variant='outlined'
						helperText={t('groupWizard.multiSelect')}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<React.Fragment>
									{loading ? (
										<CircularProgress color='inherit' size={20} />
									) : null}
									{params.InputProps.endAdornment}
								</React.Fragment>
							)
						}}
					/>
				)}
			/>
		</FormControl>
	);
};

MerchantSelect.propTypes = {
	onChange: PropTypes.func,
	translationPath: PropTypes.string,
	value: PropTypes.array,
	dataSource: PropTypes.string,
	t: PropTypes.func,
	dataSources: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	getDataSource: PropTypes.func,
	temporaryGroupValues: PropTypes.array,
	setTemporaryGroupValues: PropTypes.func,
	listItemsIndividually: PropTypes.bool
};

const mapStateToProps = (state) => {
	return {
		dataSources: state.dataSourceReducer.get('dataSources')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getDataSource: (payload) => {
			dispatch(dataSourceActions.getDataSource(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation('groups')(MerchantSelect));
