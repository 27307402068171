import { fromJS } from 'immutable';
import * as actionTypes from './actionTypes';

export const initialState = fromJS({
	representment: {
		data: {},
		isLoading: false,
		hasBeenLoaded: false,
		error: undefined
	},
	representmentDocument: {
		data: {},
		isLoading: false,
		hasBeenLoaded: false,
		error: undefined
	},
	sentToProcessor: undefined
});

export const representmentReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_REPRESENTMENT:
		case actionTypes.UPDATE_REPRESENTMENT:
			return state.setIn(['representment', 'isLoading'], true);

		case actionTypes.GET_REPRESENTMENT_COMPLETED:
			return state
				.setIn(['representment', 'data'], action.payload)
				.setIn(['representment', 'isLoading'], false)
				.setIn(['representment', 'hasBeenLoaded'], true);

		case actionTypes.GET_REPRESENTMENT_FAILED:
		case actionTypes.UPDATE_REPRESENTMENT_FAILED:
			return state
				.setIn(['representment', 'error'], action.payload)
				.setIn(['representment', 'isLoading'], false);

		case actionTypes.BUILD_REPRESENTMENT:
			return state
				.setIn(['representmentDocument', 'isLoading'], true)
				.set('sentToProcessor', undefined);

		case actionTypes.BUILD_REPRESENTMENT_COMPLETED:
			return state
				.setIn(['representmentDocument', 'data'], action.payload)
				.setIn(['representmentDocument', 'isLoading'], false)
				.setIn(['representmentDocument', 'hasBeenLoaded'], true);

		case actionTypes.BUILD_REPRESENTMENT_FAILED:
			return state
				.setIn(['representmentDocument', 'error'], action.payload)
				.setIn(['representmentDocument', 'isLoading'], false);

		case actionTypes.MARK_SENT_TO_PROCESSOR:
			return state
				.setIn(['representmentDocument', 'isLoading'], true)
				.set('sentToProcessor', undefined);

		case actionTypes.MARK_SENT_TO_PROCESSOR_COMPLETED:
			return state
				.setIn(['representmentDocument', 'isLoading'], false)
				.setIn(['representmentDocument', 'hasBeenLoaded'], true)
				.set('sentToProcessor', action.payload);

		case actionTypes.MARK_SENT_TO_PROCESSOR_FAILED:
			return state
				.setIn(['representmentDocument', 'error'], action.payload)
				.setIn(['representmentDocument', 'isLoading'], false);

		case actionTypes.CLEAR_REPRESENTMENT:
			return state
				.setIn(['representmentDocument', 'data'], undefined)
				.setIn(['representmentDocument', 'isLoading'], false)
				.setIn(['representmentDocument', 'hasBeenLoaded'], false)
				.setIn(['representmentDocument', 'error'], undefined)
				.set('sentToProcessor', undefined);

		default:
			return state;
	}
};
