import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import deepCopy from '../../../utilities/deepCopy';
import PageError from '../../errors/PageError';
import PermissionArea from './PermissionArea';
import OutlinedLoadingButton from '../../buttons/OutlinedLoadingButton';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import ListSubheader from '@mui/material/ListSubheader';

export const PermissionList = (props) => {
	const {
		t,
		isLoadingAvailablePermissions,
		availalbePermissionshasBeenLoaded,
		availablePermissions,
		availablePermissionsError,
		onUpdatePermissions,
		selectedUserPermissions,
		isLoadingSelectedUserPermissions,
		isUpdatingUserPermissions,
		modifiedAllowedPermissions,
		setModifiedAllowedPermissions,
		permissionsModalUser,
		disableChanges
	} = props;

	if (availablePermissionsError) {
		return <PageError />;
	}

	if (
		isLoadingAvailablePermissions ||
		!availalbePermissionshasBeenLoaded ||
		!selectedUserPermissions ||
		!modifiedAllowedPermissions ||
		isLoadingSelectedUserPermissions
	) {
		return (
			<Box
				height={'100%'}
				width={'100%'}
				sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
			>
				<CircularProgress />
			</Box>
		);
	}

	const handlePermissionChange = (permissionString) => {
		if (modifiedAllowedPermissions.includes(permissionString)) {
			const index = modifiedAllowedPermissions.indexOf(permissionString);

			const updatedAllowedPermissions = deepCopy(modifiedAllowedPermissions);

			updatedAllowedPermissions.splice(index, 1);

			setModifiedAllowedPermissions(updatedAllowedPermissions);
		} else {
			const updatedAllowedPermissions = deepCopy(modifiedAllowedPermissions);

			updatedAllowedPermissions.push(permissionString);

			setModifiedAllowedPermissions(updatedAllowedPermissions);
		}
	};

	const getCheckedPermissionCount = React.useCallback((areaPermissions, modifiedAllowedPermissions) => {
		let count = 0;

		areaPermissions.forEach((areaPermission) => {
			const stringPermission = `${areaPermission.permissionArea}.${areaPermission.permissionComponent}.${areaPermission.type}`;

			if (modifiedAllowedPermissions.includes(stringPermission)) {
				count++;
			};
		});

		return count;
	}, [availablePermissions]);

	const handlePermissionAreaChange = (areAllChecked, areaPermissions, modifiedAllowedPermissions) => {
		let updatedAllowedPermissions = deepCopy(modifiedAllowedPermissions);

		if (areAllChecked) {
			areaPermissions.forEach((areaPermission) => {
				const stringPermission = `${areaPermission.permissionArea}.${areaPermission.permissionComponent}.${areaPermission.type}`;

				updatedAllowedPermissions = updatedAllowedPermissions.filter((allowedPermission) => {
					return allowedPermission !== stringPermission;
				});
			});
		} else {
			areaPermissions.forEach((areaPermission) => {
				const stringPermission = `${areaPermission.permissionArea}.${areaPermission.permissionComponent}.${areaPermission.type}`;

				if (!updatedAllowedPermissions.includes(stringPermission)) {
					updatedAllowedPermissions.push(stringPermission);
				}
			});
		}

		setModifiedAllowedPermissions(updatedAllowedPermissions);
	};

	const handleUpdate = () => {
		onUpdatePermissions();
	};

	return (
		<Stack sx={{ height: 'calc(100% - 60px)' }}>
			<Stack
				direction='row'
				justifyContent='space-between'
				alignItems='center'
				py={1}
				px={2}
				sx={{ height: '60px' }}
			>
				<Box>{`${t('modals:userPermissionsModal.user')} - ${
					permissionsModalUser.firstName
				} ${permissionsModalUser.lastName}`}</Box>
				<Box maxWidth={350}>
					<OutlinedLoadingButton
						loading={isUpdatingUserPermissions}
						disabled={disableChanges}
						onClick={handleUpdate}
					>
						{t('common:buttons.update')}
					</OutlinedLoadingButton>
				</Box>
			</Stack>
			<List
				sx={{
					width: '100%',
					overflow: 'auto',
					height: '100%',
					'& li': { marginBottom: 1 }
				}}
				subheader={<ListSubheader />}
			>
				{Object.keys(availablePermissions).map((sectionId) => (
					<PermissionArea
						key={`section-${sectionId}`}
						areaPermissions={availablePermissions[sectionId].permissionList}
						areaLabel={availablePermissions[sectionId].permissionAreaLabel}
						areaId={availablePermissions[sectionId].permissionAreaId}
						getCheckedPermissionCount={getCheckedPermissionCount}
						modifiedAllowedPermissions={modifiedAllowedPermissions}
						handlePermissionAreaChange={handlePermissionAreaChange}
						handlePermissionChange={handlePermissionChange}
						disableChanges={disableChanges}
					/>
				))}
			</List>
		</Stack>
	);
};

PermissionList.propTypes = {
	t: PropTypes.func,
	isLoadingAvailablePermissions: PropTypes.bool,
	availalbePermissionshasBeenLoaded: PropTypes.bool,
	availablePermissions: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.array
	]),
	availablePermissionsError: PropTypes.string,
	onUpdatePermissions: PropTypes.func,
	selectedUserPermissions: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.array
	]),
	isLoadingSelectedUserPermissions: PropTypes.bool,
	isUpdatingUserPermissions: PropTypes.bool,
	modifiedAllowedPermissions: PropTypes.array,
	setModifiedAllowedPermissions: PropTypes.func,
	permissionsModalUser: PropTypes.object,
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	disableChanges: PropTypes.bool
};

export default (
	withTranslation(['common', 'modals'])(PermissionList)
);
