import React from 'react';
import PropTypes from 'prop-types';
import querier from '../../../../../utilities/querier';
import { rowColumns } from '../constants/columnDefinitions';
import { AlternatingBackgroundTableRow } from '../../../../ReportStylings';

import TableCell from '@mui/material/TableCell';

const ChargebackActivityRow = (props) => {
	const { data, rowKey, rowLabel, useRounded } = props;

	return (
		<AlternatingBackgroundTableRow>
			<TableCell width={250}>{rowLabel}</TableCell>
			{rowColumns.map((column, columnIndex) => {
				const value = querier(
					data,
					`$[timeFrameKey = "${column.basePath}"].${rowKey}.${
						useRounded ? column.valuePathRounded : column.valuePath
					}`
				);
				return (
					<TableCell
						align='center'
						key={`${rowKey}-${columnIndex}`}
						sx={(theme) =>
							({ borderLeft: column.isFirstColumnInGroup && `1px solid ${theme.palette.common.black}` })}
					>
						{column.formatter
							? column.formatter(rowKey, column.valuePath, value)
							: value}
					</TableCell>
				);
			})}
		</AlternatingBackgroundTableRow>
	);
};

ChargebackActivityRow.propTypes = {
	rowKey: PropTypes.string,
	rowLabel: PropTypes.string,
	data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	useRounded: PropTypes.bool
};

export default ChargebackActivityRow;
