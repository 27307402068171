import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { noCase, capitalCase } from 'change-case';
import NotificationSeverityIcon from '../../../shared/NotificationSeverityIcon';
import NotificationSeverityIndicatorBox from '../../../shared/NotificationSeverityIndicatorBox';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import CheckIcon from '@mui/icons-material/Check';
import CircleIcon from '@mui/icons-material/Circle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SendIcon from '@mui/icons-material/Send';

import { ruleBuilderCategoryIcons } from '../../../../icons';

const RuleStack = styled(Stack)(({ theme }) => ({
	width: '100%',
	borderTop: '2px solid rgba(0,0,0,0.1)',
	borderBottom: '2px solid rgba(0,0,0,0.1)',
	borderRight: '2px solid rgba(0,0,0,0.1)',
	borderTopRightRadius: 5,
	borderBottomRightRadius: 5,
	minWidth: 500
}));

const NotificationIconBox = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	height: '100%',
	backgroundColor: 'rgba(0,0,0,0.03)',
	paddingTop: theme.spacing(2),
	paddingBottom: theme.spacing(2),
	marginRight: theme.spacing(2),
	maxWidth: 75,
	minWidth: 75,
	'& .icon': {
		fontSize: '2rem',
		color: theme.palette.secondary.main
	}
}));

const StatusCircle = styled(CircleIcon)(({ color, theme }) => ({
	color: theme.palette[color].light,
	opacity: color === 'secondary' ? 0.5 : 1,
	fontSize: '0.8rem'
}));

const UserChip = styled(Chip)(({ theme }) => ({
	color: theme.palette.secondary.light
}));

const RuleRow = (props) => {
	const {
		t,
		rule,
		isMarkedForDeletion,
		onEditRule,
		onUpdateRuleActiveStatus,
		runningUserRuleId,
		runningUserRuleCompleted,
		runningUserRuleError,
		onRunUntrackedUserRule,
		onDeleteRule
	} = props;

	const navigate = useNavigate();

	const [ruleActiveStatus, setRuleActiveStatus] = React.useState(rule.isActive);
	const [resetRuleCountdown, setResetRuleCountdown] = React.useState(100);
	const [anchorEl, setAnchorEl] = React.useState(null);

	React.useEffect(() => {
		if (ruleActiveStatus !== rule.isActive) {
			onUpdateRuleActiveStatus(rule.userRuleId);
		}
	}, [ruleActiveStatus]);

	React.useEffect(() => {
		let timer;

		if (runningUserRuleId === rule.userRuleId && runningUserRuleCompleted) {
			timer = setInterval(() => {
				setResetRuleCountdown((prevProgress) => (prevProgress === 0 ? 0 : prevProgress - 5));
			}, 225);
		}

		return () => {
			if (timer) {
				clearInterval(timer);
				setResetRuleCountdown(100);
			}
		};
	}, [runningUserRuleId, runningUserRuleCompleted]);

	const onUpdateActiveStatusClicked = () => {
		setRuleActiveStatus(!rule.isActive);
	};

	const onViewNotificationRuleReport = () => {
		const currentDate = dayjs().format('YYYY-MM-DD');

		navigate(`/reports/1815860b-108d-4167-9b61-a552f001d6c1?startDate=${currentDate}&endDate=${currentDate}&status=active&alertTriggered=all&severityLevel=all&autoApply=true`);
	};

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const CategoryIcon = ruleBuilderCategoryIcons[noCase(rule.categoryName)];
	const activeStatusColor = ruleActiveStatus ? 'success' : 'secondary';

	return (
		<Stack
			direction='row'
			elevation={0}
			minWidth={800}
			sx={{ opacity: isMarkedForDeletion ? 0.5 : 1 }}
		>
			<NotificationSeverityIndicatorBox severity={rule.severity} />
			<RuleStack direction='row'>
				<NotificationIconBox>
					<NotificationSeverityIcon severity={rule.severity} />
				</NotificationIconBox>
				<Box width={75} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
					<Tooltip title={capitalCase(t(rule.categoryName))}>
						<CategoryIcon className='icon' color='secondary' sx={{ fontSize: '2rem', opacity: 0.7 }} />
					</Tooltip>
				</Box>
				<Stack direction={{ xs: 'column', xl: 'row' }} sx={{ width: '100%' }}>
					<Stack spacing={1} sx={{ flex: 1 }} justifyContent='center'>
						<Typography color='secondary' sx={{ fontSize: '1rem', fontWeight: 550 }}>
							{rule.label}
						</Typography>
						<Typography color='secondary' sx={{ fontSize: '0.8rem' }}>
							{rule.ruleText}
						</Typography>
					</Stack>
					<Stack
						direction='row'
						alignItems='center'
						justifyContent='flex-start'
						spacing={3}
					>
						<Stack direction='row' alignItems='center' width={100}>
							<Box width={20}>
								<StatusCircle color={activeStatusColor} className='status-circle' />
							</Box>
							<Box width={80}>
								{ ruleActiveStatus
									? <Typography variant='body1'>{t('rules:active')}</Typography>
									: <Typography variant='body1'>{t('rules:paused')}</Typography>
								}
							</Box>
						</Stack>
						<Stack alignItems='center' justifyContent='center' width={200}>
							{ rule.autoAssignUserId
								? (
									<UserChip
										avatar={
											<Avatar alt={rule.autoAssignUserFirstName} src={null} >
												{rule.autoAssignUser?.firstName.split('')[0]}
											</Avatar>
										}
										label={`${rule.autoAssignUserFirstName} ${rule.autoAssignUserLastName}`}
									/>
								) : <Chip label={t('rules:notAssigned')} />
							}
						</Stack>
						<Stack alignItems='center' justifyContent='center' width={100}>
							<Typography variant='caption' color='secondary'>{t('rules:appliedTo')}</Typography>
							<Typography variant='body1' color='secondary' sx={{ fontWeight: 550 }}>{t(`rules:${rule.ruleListType}`)}</Typography>
						</Stack>
						<Stack alignItems='center' justifyContent='center' width={100}>
							<Typography variant='caption' color='secondary'>{t('rules:lastUpdated')}</Typography>
							<Typography variant='body1' color='secondary' sx={{ fontWeight: 550 }}>{dayjs(rule.updatedOn).format('M.D.YY')}</Typography>
						</Stack>
					</Stack>
				</Stack>
				{ (runningUserRuleId === rule.userRuleId && !runningUserRuleError) ? (
					<Stack direction={{ xs: 'column' }} width={{ xs: 150, md: 200 }} alignItems='center' justifyContent={{ xs: 'space-evenly' }} spacing={1} p={1}>
						{ !runningUserRuleCompleted
							? (
								<CircularProgress size='1rem' />
							) : (
								<React.Fragment>
									<Stack direction='row' spacing={1}>
										<CheckIcon color='success' />
										<Typography>{t('rules:successfullyRanRule')}</Typography>
									</Stack>
									<Stack spacing={1}>
										<Button variant='text' size='small' onClick={onViewNotificationRuleReport}>{t('rules:viewResults')}</Button>
										<LinearProgress width={'100%'} variant='determinate' value={resetRuleCountdown} />
									</Stack>
								</React.Fragment>
							)
						}
					</Stack>
				) : (
					<Stack direction='row' width={{ xs: 150, md: 200 }} alignItems='center' justifyContent={{ xs: 'space-evenly' }} spacing={1} p={1}>
						<Button
							variant='outlined'
							size='small'
							startIcon={<SendIcon />}
							fullWidth
							disabled={
								!onRunUntrackedUserRule ||
								(runningUserRuleId && runningUserRuleId !== rule.userRuleId) ||
								!ruleActiveStatus ||
								isMarkedForDeletion
							}
							onClick={() => onRunUntrackedUserRule(rule.userRuleId)}
						>
							{t('rules:runRule')}
						</Button>
						<IconButton size='small' onClick={handleClick}>
							<MoreVertIcon disabled={isMarkedForDeletion} />
						</IconButton>
						<Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
							<MenuItem
								onClick={() => {
									onEditRule(rule.userRuleId);
									handleClose();
								}}
							>
								{t('common:buttons.edit')}
							</MenuItem>
							<MenuItem
								onClick={() => {
									onUpdateActiveStatusClicked(rule.userRuleId);
									handleClose();
								}}
							>
								{ruleActiveStatus ? t('rules:pause') : t('rules:activate')}
							</MenuItem>
							<MenuItem
								onClick={() => {
									onDeleteRule(rule.userRuleId);
									handleClose();
								}}
							>
								{t('common:buttons.delete')}
							</MenuItem>
						</Menu>
					</Stack>
				)
				}
			</RuleStack>
		</Stack>
	);
};

RuleRow.propTypes = {
	t: PropTypes.func,
	isConnected: PropTypes.bool,
	isUpdatingRule: PropTypes.bool,
	isMarkedForDeletion: PropTypes.bool,
	onTriggerRuleRun: PropTypes.func,
	rule: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	onEditRule: PropTypes.func,
	ruleRunError: PropTypes.string,
	setMarkedForDeletionId: PropTypes.func,
	onUpdateRuleActiveStatus: PropTypes.func,
	runningUserRuleId: PropTypes.number,
	runningUserRuleCompleted: PropTypes.bool,
	runningUserRuleError: PropTypes.string,
	onRunUntrackedUserRule: PropTypes.func,
	onDeleteRule: PropTypes.func
};

export default withTranslation('rules')(RuleRow);
