import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { multiFactorAuthActions } from '../../containers/MultiFactorAuth';
import BorderedPaper from '../branded/BorderedPaper';
import ContainedButton from '../buttons/ContainedButton';
import TextButton from '../buttons/TextButton';

import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

const MultiFactorAuthChallenge = (props) => {
	const {
		t,
		authUser,
		codeVerified,
		userHasBeenLoaded,
		isVerifying,
		isLoadingUser
	} = props;

	const [authenticationCode, setAuthenticationCode] = React.useState();

	const { state } = useLocation();
	const navigate = useNavigate();

	const onVerifyCode = (authUser, challengeAnswer) => {
		const { verifyMultiFactorAuthCode } = props;

		verifyMultiFactorAuthCode({
			user: authUser,
			challengeAnswer
		});
	};

	React.useEffect(() => {
		if (codeVerified && userHasBeenLoaded) {
			if (state && state.pathname) {
				navigate(state.pathname);
			} else {
				navigate('/dashboard');
			}
		}
	}, [codeVerified, userHasBeenLoaded, navigate, state]);

	return (
		<BorderedPaper>
			<Stack p={2} m={4}>
				<Stack direction='row'>
					<Typography variant='h6' sx={{ p: '5px', textAlign: 'center' }}>
						{t('modals:multiFactorAuth.enterYourCode')}
					</Typography>
				</Stack>
				{isLoadingUser || isVerifying ? (
					<Stack alignItems='center' justifyContent='center' mt={2}>
						<CircularProgress />
					</Stack>
				) : (
					<React.Fragment>
						<Stack direction='row' alignItems='center'	mt={2}
						>
							{t('modals:multiFactorAuth.authenticationCode')}
							<TextField
								onChange={(e) => setAuthenticationCode(e.target.value)}
								sx={{ ml: 1 }}
								id='outlined-basic'
								variant='outlined'
								size='small'
								onKeyDown={(event) => {
									if (event.key === 'Enter' && authenticationCode) {
										onVerifyCode(authUser, authenticationCode);
									}
								}}
							/>
						</Stack>
						<Stack direction='row' mt={2} justifyContent='flex-end'>
							<TextButton
								// TODO page won't navigate back to login, assuming bc state of MFA won't allow it to show login page
								onClick={() => {}}
								color='secondary.main'
							>
								{t('common:buttons.cancel')}
							</TextButton>
							<ContainedButton
								bgcolor='primary.alt'
								onClick={() => {
									onVerifyCode(authUser, authenticationCode);
								}}
							>
								{t('modals:multiFactorAuth.submit')}
							</ContainedButton>
						</Stack>
					</React.Fragment>
				)}
			</Stack>
		</BorderedPaper>
	);
};

MultiFactorAuthChallenge.propTypes = {
	authUser: PropTypes.object,
	codeVerified: PropTypes.bool,
	isVerifying: PropTypes.bool,
	verifyMultiFactorAuthCode: PropTypes.func,
	userHasBeenLoaded: PropTypes.bool,
	isLoadingUser: PropTypes.bool,
	t: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		authUser: state.authReducer.get('user'),
		challengeName: state.authReducer.get('challengeName'),
		codeVerified: state.multiFactorAuthReducer.get('codeVerified'),
		isVerifying: state.multiFactorAuthReducer.get('isVerifying'),
		logInError: state.authReducer.get('logInError'),
		userHasBeenLoaded: state.userReducer.get('userHasBeenLoaded'),
		isLoadingUser: state.userReducer.get('isLoadingUser')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		verifyMultiFactorAuthCode: (payload) => {
			dispatch(multiFactorAuthActions.verifyMultiFactorAuthCode(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation(['common', 'modals'])(MultiFactorAuthChallenge));
