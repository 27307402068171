import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { withTranslation } from 'react-i18next';

import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';

const PrimaryTableCell = styled(TableCell)(({ theme }) => ({
	backgroundColor: theme.palette.secondary.alt
}));

export const StaticTableCell = ({ t, reportName, headCell }) => {
	return (
		<PrimaryTableCell
			align={headCell.align || 'center'}
			width={headCell.width || null }
			padding={headCell.disablePadding ? 'none' : 'normal'}
			sx={{ pb: 1 }}
		>
			{headCell.name
				? <Typography variant='overline' sx={{ fontWeight: 600 }}>{t(`${reportName}.${headCell.name}`)}</Typography>
				: ''}
		</PrimaryTableCell>
	);
};

StaticTableCell.propTypes = {
	headCell: PropTypes.object,
	reportName: PropTypes.string,
	t: PropTypes.func
};

export default withTranslation('reports')(StaticTableCell);
