import axios from '../../utilities/axios';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { refreshToken } from '../../utilities/refreshToken';

import { primaryRestGateway } from '../../utilities/apiEndpointUtility';

import {
	getNotifications,
	getNotificationsCompleted,
	getNotificationsFailed
} from './notificationsSlice';

export const getNotificationsEpic = (action$) =>
	action$.pipe(
		ofType(getNotifications),
		mergeMap(async (action) => {
			await refreshToken();

			const payload = action.payload;

			const response = await axios.get(
				`${primaryRestGateway()}/api/notifications`,
				{
					params: payload
				}
			);

			return response.data;
		}),
		switchMap((response) => [getNotificationsCompleted(response)]),
		catchError((error, source) =>
			merge(of(getNotificationsFailed(error.message)), source)
		)
	);

export default [
	getNotificationsEpic
];
