import React from 'react';
import PasswordResetManager from '../../components/PasswordReset/PasswordResetManager';

import RegistrationPageWrapper from '../../components/RegistrationPageWrapper';

const PasswordReset = () => {
	return (
		<RegistrationPageWrapper>
			<PasswordResetManager />
		</RegistrationPageWrapper>
	);
};

export default PasswordReset;
