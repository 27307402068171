import axios from '../../utilities/axios';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import dayjs from 'dayjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { refreshToken } from '../../utilities/refreshToken';
import { primaryRestGateway } from '../../utilities/apiEndpointUtility';

import * as actions from './actions';
import * as actionTypes from './actionTypes';

export const getRepresentmentEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.GET_REPRESENTMENT),
		mergeMap(async (action) => {
			await refreshToken();
			const { chargebackId } = action.payload;

			const response = await axios.get(
				`${primaryRestGateway()}/api/representments/chargeback/${chargebackId}`
			);

			return response.data;
		}),
		switchMap((res) => [actions.getRepresentmentCompleted(res)]),
		catchError((error, source) =>
			merge(of(actions.getRepresentmentFailed(error.message)), source)
		)
	);

export const updateRepresentmentEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.UPDATE_REPRESENTMENT),
		mergeMap(async (action) => {
			await refreshToken();
			const { chargebackId, representmentUpdate } = action.payload;

			await axios.put(
				`${primaryRestGateway()}/api/representments/chargeback/${chargebackId}`,
				representmentUpdate
			);

			return {
				chargebackId
			};
		}),
		switchMap((res) => [
			actions.updateRepresentmentCompleted(res),
			actions.getRepresentment(res)
		]),
		catchError((error, source) =>
			merge(of(actions.updateRepresentmentFailed(error.message)), source)
		)
	);

export const buildRepresentment = (action$) =>
	action$.pipe(
		ofType(actionTypes.BUILD_REPRESENTMENT),
		mergeMap(async (action) => {
			await refreshToken();

			try {
				const { chargebackId } = action.payload;

				const response = await axios.get(
					`${primaryRestGateway()}/api/representments/build/${chargebackId}`
				);

				return response.data;
			} catch (error) {
				const responseData = error.response.data;

				if (responseData?.code === 'S1007') {
					throw new Error(`Failure Code ${responseData?.code} - ${responseData.content}`);
				} else {
					throw new Error('Build Representment Failure');
				}
			}
		}),
		switchMap((res) => [actions.buildRepresentmentCompleted(res)]),
		catchError((error, source) =>
			merge(of(actions.buildRepresentmentFailed(error.message)), source)
		)
	);

// TODO
// THIS ROUTE SHOULD BE UPDATED TO ONLY PASS THE CHARGEBACK ID AND RETURNS A TIMESTAMP
export const markSentToProcessor = (action$) =>
	action$.pipe(
		ofType(actionTypes.MARK_SENT_TO_PROCESSOR),
		mergeMap(async (action) => {
			await refreshToken();
			const { chargebackId, representmentUpdate } = action.payload;

			await axios.put(
				`${primaryRestGateway()}/api/representments/chargeback/${chargebackId}`,
				representmentUpdate
			);

			// TODO - THIS DEFINITELY NEEDS TO CHANGE
			return dayjs().format('YYYY-MM-DD');
		}),
		switchMap((res) => [actions.markSentToProcessorCompleted(res)]),
		catchError((error, source) =>
			merge(of(actions.markSentToProcessorFailed(error.message)), source)
		)
	);
