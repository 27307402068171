const _baseCellStyle = {
	fontWeight: 700,
	fontSize: '12px',
	maxWidth: 'calc((100% - 0px) / 10)'
};

const _baseFormatter = (value) => {
	return value === 0 || !value ? '-' : value;
};

export default [
	{
		titleTranslationPath: 'chargebackSummary.columnNames.lookupDate',
		field: 'dailyChargebacks',
		headerStyle: _baseCellStyle,
		formatter: _baseFormatter
	},
	{
		titleTranslationPath: 'chargebackSummary.columnNames.mtd',
		field: 'monthlyChargebacks',
		headerStyle: _baseCellStyle,
		formatter: _baseFormatter
	},
	{
		titleTranslationPath: 'chargebackSummary.columnNames.chargebackmargin',
		field: 'actualChargebackMargin',
		headerStyle: _baseCellStyle,
		formatter: _baseFormatter
	},
	{
		titleTranslationPath: 'chargebackSummary.columnNames.chargebackpercent',
		field: 'chargebackPercentage',
		headerStyle: _baseCellStyle
	}
];
