import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { withTranslation } from 'react-i18next';

import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ContainedButton from '../../buttons/ContainedButton';

const SuccessPermissionPaper = styled(Paper)({
	display: 'flex',
	position: 'absolute',
	left: '50%',
	top: '50%',
	transform: 'translate(-50%, -50%)',
	padding: 10
});

export const CompletionPanel = (props) => {
	const { t, onClose } = props;

	return (
		<SuccessPermissionPaper>
			<Stack
				p={3}
				justifyContent='space-around'
				alignItems='center'
				sx={{ width: '100%', height: '250px' }}
			>
				<Typography >
					{t('modals:userPermissionsModal.successMessage')}
				</Typography>
				<Typography variant='body2'>
					{t('modals:userPermissionsModal.informationMessage')}
				</Typography>
				<ContainedButton onClick={onClose}>
					{t('common:buttons.close')}
				</ContainedButton>
			</Stack>
		</SuccessPermissionPaper>
	);
};

CompletionPanel.propTypes = {
	isOpen: PropTypes.bool,
	onClose: PropTypes.func,
	t: PropTypes.func
};

export default withTranslation(['common', 'modals'])(CompletionPanel);
