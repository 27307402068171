import { styled } from '@mui/material/styles';

import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

const AlternatingBackgroundTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(even)': {
		backgroundColor: theme.palette.common.white
	},
	'&:nth-of-type(odd)': {
		backgroundColor: theme.palette.tables.alternateRowColor
	}
}));

const StandardStyledGrid = styled(Grid)({
	display: 'flex',
	width: 250
});

const StaticReportInputLabel = styled(InputLabel)(({ theme }) => ({
	padding: '2px',
	backgroundColor: theme.palette.common.white
}));

const FourteenDayChargebacksTotalTableCell = styled(TableCell)(({ theme }) => ({
	boxShadow: `-5px 0px 5px -3px ${theme.palette.secondary.alt}`,
	borderLeft: `1px solid ${theme.palette.secondary.alt}`
}));

const TwelveMonthReportCell = styled(TableCell, {
	shouldForwardProp: (prop) => prop !== 'columnIndex'
})(({ columnIndex, theme }) => ({
	borderRight: (columnIndex === 5 && `1px solid ${theme.palette.common.black}`) ||
		(columnIndex === 6 && `3px solid ${theme.palette.common.black}`),
	borderLeft: columnIndex === 5 && `3px solid ${theme.palette.common.black}`
}));

export {
	AlternatingBackgroundTableRow,
	FourteenDayChargebacksTotalTableCell,
	StandardStyledGrid,
	StaticReportInputLabel,
	TwelveMonthReportCell
};
