const configuration = {
	label: '',
	category: null,
	formula: null,
	measurement: null,
	paymentMethod: null,
	comparator: null,
	comparisonUnit: null,
	timeFrame: null,
	userInput: null,
	ruleListType: null,
	severity: null,
	group: null,
	processor: null,
	merchantIds: [],
	affiliateIds: [],
	runFrequency: '1d',
	ignoreNonTriggered: 1,
	autoAssignUserId: null
};

export default configuration;
