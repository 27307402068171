import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	opportunities: {
		data: [],
		isLoading: false,
		hasBeenLoaded: false,
		error: undefined
	},
	markNotChallenged: {
		isLoading: false,
		successfullyCompleted: false,
		error: undefined
	}
};

export const opportunitiesSlice = createSlice({
	name: 'opportunities',
	initialState,
	reducers: {
		getOpportunities: (state) => {
			state.opportunities.isLoading = true;
			state.opportunities.error = undefined;
		},
		getOpportunitiesCompleted: (state, action) => {
			state.opportunities.isLoading = false;
			state.opportunities.hasBeenLoaded = true;
			state.opportunities.data = action.payload;
		},
		getOpportunitiesFailed: (state, action) => {
			state.opportunities.isLoading = false;
			state.opportunities.error = action.payload;
		},
		markCasesNotChallenged: (state) => {
			state.opportunities.isLoading = true;
			state.markNotChallenged.error = undefined;
		},
		markCasesNotChallengedCompleted: (state) => {
			state.markNotChallenged.successfullyCompleted = true;
		},
		markCasesNotChallengedFailed: (state, action) => {
			state.opportunities.isLoading = false;
			state.markNotChallenged.error = action.payload;
		},
		resetMarkCasesNotChallenged: (state) => {
			state.opportunities = initialState.markNotChallenged;
		}
	}
});

export const {
	getOpportunities,
	getOpportunitiesCompleted,
	getOpportunitiesFailed,
	markCasesNotChallenged,
	markCasesNotChallengedCompleted,
	markCasesNotChallengedFailed,
	resetMarkCasesNotChallenged
} = opportunitiesSlice.actions;

export default opportunitiesSlice.reducer;
