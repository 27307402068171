import { styled } from '@mui/material/styles';

import TableRow from '@mui/material/TableRow';

const PaginatedTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(even)': {
		backgroundColor: theme.palette.common.white
	},
	'&:nth-of-type(odd)': {
		backgroundColor: theme.palette.tables.alternateRowColor
	}
}));

export default PaginatedTableRow;
