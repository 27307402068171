import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import DailyChargebackActivityTable from './Table';
import LoadingTable from './Table/LoadingTable';
import isDataEmpty from '../../../helpers/shared/isDataEmpty';
import querier from '../../../utilities/querier';
import { getAbbreviation } from '../../../helpers/shared/cardBrandHelper';
import cardBrands from '../../../constants/cardBrands';
import MostRecentDateForData from './MostRecentDateForData';

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const ReportContainer = (props) => {
	const { t, data, hasBeenLoaded, isLoading } = props;

	if (!hasBeenLoaded && !isLoading) {
		return (
			<Stack direction='row' mt={2} justifyContent='center' alignItems='center'>
				<Typography variant='body1' sx={{ color: 'secondary.alt' }}>
					{t('shared.submitToLoad')}
				</Typography>
			</Stack>
		);
	}

	if (isLoading) {
		return (
			<Paper>
				<Stack
					sx={{ width: '100%' }}
					direction='column'
					alignItems='center'
					p={1}
					spacing={2}
				>
					<LoadingTable />
					<LoadingTable />
					<LoadingTable />
					<LoadingTable />
				</Stack>
			</Paper>
		);
	}

	if (isDataEmpty(data)) {
		return (
			<Stack direction='row' mt={2} justifyContent='center'>
				<Typography variant='body1'>{t('chargebackSummary.noData')}</Typography>
			</Stack>
		);
	}

	return (
		<Paper>
			<MostRecentDateForData
				chargebackDate={data.mostRecentChargebackDate}
				settlementDate={data.mostRecentSettlementDate}
			/>
			<Stack direction='column' p={1} spacing={2}>
				{cardBrands.map((cardBrand) => (
					<DailyChargebackActivityTable
						key={cardBrand}
						cardBrand={cardBrand}
						data={querier(
							data,
							`[$.results[cardBrand = "${getAbbreviation(cardBrand)}"]]`
						)}
						dateRangeEnd={data.lookupDate}
					/>
				))}
			</Stack>
			<Stack direction='column' p={2} spacing={1}>
				<Typography variant='caption'>
					{t('dailyChargebackActivity.informationOne')}
				</Typography>
			</Stack>
		</Paper>
	);
};

ReportContainer.propTypes = {
	data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	hasBeenLoaded: PropTypes.bool,
	isLoading: PropTypes.bool,
	t: PropTypes.func
};

export default withTranslation('reports')(ReportContainer);
