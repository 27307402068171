import * as actionTypes from './actionTypes';

export const toggleCreateLayoutModal = () => ({
	type: actionTypes.TOGGLE_CREATE_LAYOUT_MODAL
});

export const toggleManageDashboardModal = () => ({
	type: actionTypes.TOGGLE_MANAGE_DASHBOARD_MODAL
});

export const toggleMultiFactorAuthSetupModal = () => ({
	type: actionTypes.TOGGLE_MULTI_FACTOR_AUTH_SETUP_MODAL
});

export const toggleUpdateAvatarModal = () => ({
	type: actionTypes.TOGGLE_UPDATE_AVATAR_MODAL
});

export const toggleRecordingsModal = () => ({
	type: actionTypes.TOGGLE_RECORDINGS_MODAL
});

export const toggleInviteUserModal = () => ({
	type: actionTypes.TOGGLE_INVITE_USER_MODAL
});

export const toggleNewNotificationModal = () => ({
	type: actionTypes.TOGGLE_NEW_NOTIFICATION_MODAL
});

export const toggleReleaseNotesModal = () => ({
	type: actionTypes.TOGGLE_RELEASE_NOTES_MODAL
});

export const setAttachmentsModalData = (payload) => ({
	type: actionTypes.SET_ATTACHMENTS_MODAL_DATA,
	payload
});

export const setPermissionsModalUser = (payload) => ({
	type: actionTypes.SET_PERMISSIONS_MODAL_USER,
	payload
});

export const setUpdateUserStatusModal = (payload) => ({
	type: actionTypes.SET_UPDATE_USER_STATUS_MODAL,
	payload
});

export const setDeleteUserModal = (payload) => ({
	type: actionTypes.SET_DELETE_USER_MODAL,
	payload
});
