import axios from '../../utilities/axios';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { refreshToken } from '../../utilities/refreshToken';
import { primaryRestGateway } from '../../utilities/apiEndpointUtility';

import * as actions from './actions';
import * as actionTypes from './actionTypes';

export const getDefaultLayoutEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.GET_DEFAULT_LAYOUT),
		mergeMap(async (action) => {
			await refreshToken();

			const response = await axios.get(
				`${primaryRestGateway()}/api/layouts/default`
			);

			return response.data;
		}),
		switchMap((res) => [actions.getDefaultLayoutCompleted(res)]),
		catchError((error, source) =>
			merge(of(actions.getDefaultLayoutFailed(error.message)), source)
		)
	);
