export const reversalReasons = [
	{
		label: '',
		value: ''
	},
	{
		label: 'No customer contact prior to CB',
		value:
			'Chargeback is invalid, customer has never requested cancellation. Customer began their trial membership on {$trial_start_date} and was informed during the sale they must call to cancel {$cs_phone} prior to the expiration of their trial membership in order to prevent the monthly membership fee from billing on a recurring basis.  Per the Terms and Conditions the customer has the right to cancel at any time by calling {{phoneNumber}}4 or visiting {{websiteUrl}}. Once cancelled, no additional fees will be billed.'
	},
	{
		label: 'Not Fraud. Customer cancelled, no refund requested',
		value:
			'Not a fraud transaction. The customer contacted us to request cancellation on {$cancel_request_date} and the order was promptly cancelled without further billing attempts.'
	},
	{
		label:
			'Customer Cancelled, refund letter sent no response received from customer',
		value:
			'The customer contacted us to request cancellation on {$cancel_request_date} and the order was promptly cancelled without further billing attempts.  A letter was sent to the customer on {$refund_letter_sent_date} to which we have received no response.'
	},
	{
		label: 'Customer cancelled, no refund requested',
		value:
			'The customer contacted us to request cancellation on {$cancel_request_date} and the order was promptly cancelled without further billing attempts.'
	},
	{
		label: 'Proof of delivery',
		value:
			"Our mutual customer began their trial {$billing_descriptor} membership on {$trial_start_date}. The welcome letter, terms and conditions and program materials which describe program benefits and how to redeem them, as well as cancel them, were sent to the customer's AVS validated address",
		'Not Fraud Statement with no customer contact':
			'Not a fraud transaction.  Customer has never contacted us at our toll free number which is displayed on their monthly billing statement to report any unauthorized activity, request to cancel this membership or to request a refund. Customer began their trial membership  on {$trial_start_date} and was informed during the sale they must call to cancel {$cs_phone} prior to the expiration of their trial membership in order to prevent the monthly membership fee from billing on a recurring basis.  Per the Terms and Conditions the customer has the right to cancel at any time by calling {{phoneNumber}} or visiting {{websiteUrl}}. Once cancelled, no additional fees will be billed.'
	},
	{
		label: 'Refund Issued',
		value:
			'This chargeback is invalid as the disputed transaction was fully refunded on {$refund_date}.  The transaction details for both the sale and refund are included with this documentation.  The invoice number referenced in the transaction details of the refund is a match to the invoice number provided in the transaction details of the sale transaction.  This clearly communicates the transaction the refund should be applied to.'
	},
	{
		label: 'Previously billed and undisputed transactions',
		value: 'Customer has been a member of {$billing_descriptor} since {$trial_start_date}. Our customer service phone number was communicated on the customer\'s billing statement on the below transaction dates and the customer failed to contact us to report a dispute or to request cancelation.'
	},
	{
		label: 'Consequence statement',
		value:
			'As a result of this chargeback, the membership has been cancelled and membership benefits are no longer active'
	},
	{
		label: 'Closing statement',
		value: 'Based on this compelling evidence, the chargeback is invalid'
	},
	{
		label: 'Not a Duplicate Transaction',
		value:
			'Not a duplicate transaction.  Beginning on {$trial_start_date} the customer joined {$billing_descriptor} which is a membership program that bills a recurring monthly fee of {$trial_billing_amount}.'
	},
	{
		label: 'Correct Transaction Amount',
		value:
			"Chargeback is invalid. Transaction Amount is correct. Customer entered into a recurring monthly membership program for the properly authorized billed amount on {$trial_start_date}. Customer was advised of the monthly billing amount during the sale call and this information was restated in the membership kit that was mailed via USPS to the customer's address on {$kit_mail_date}."
	},
	{
		label: 'Services rendered/available',
		value:
			'Chargeback is invalid as customer has had the ability to access program benefits since they began their trial membership on {$trial_start_date}.  Program benefits were described to the customer when they accepted the trial offer and were restated in the membership kit that was mailed to the customer on {$kit_mail_date} The membership kit contained a welcome letter, terms and conditions and program materials which describe our program benefits and how to redeem them, as well as cancel them.'
	},
	{
		label: 'Manual Entry',
		value: ''
	}
];
