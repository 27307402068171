import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import ErrorIcon from '@mui/icons-material/Error';

export const SubtextError = (props) => {
	const { t, errorText } = props;

	return (
		<Stack direction='row' spacing={2} justifyContent='center' my={2} width='100%'>
			<ErrorIcon color='error' />
			<Typography color='secondary'>
				{errorText || t('common:error.somethingWentWrong')}
			</Typography>
		</Stack>
	);
};

SubtextError.propTypes = {
	t: PropTypes.func,
	errorText: PropTypes.string
};

export default withTranslation()(SubtextError);
