import { mkConfig, generateCsv, download } from 'export-to-csv';

export const downloadToCsv = (toWrite, filename) => {
	const csvConfig = mkConfig({
		useKeysAsHeaders: true,
		filename: `${filename}-${Date.now()}`
	});

	const data = Array.isArray(toWrite) ? toWrite : [toWrite];

	const csv = generateCsv(csvConfig)(data);

	download(csvConfig)(csv);
};
