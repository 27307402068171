export const GET_REPRESENTMENT = 'representment/GET_REPRESENTMENT';
export const GET_REPRESENTMENT_COMPLETED =
	'representment/GET_REPRESENTMENT_COMPLETED';
export const GET_REPRESENTMENT_FAILED =
	'representment/GET_REPRESENTMENT_FAILED';

export const UPDATE_REPRESENTMENT = 'representment/UPDATE_REPRESENTMENT';
export const UPDATE_REPRESENTMENT_COMPLETED =
	'representment/UPDATE_REPRESENTMENT_COMPLETED';
export const UPDATE_REPRESENTMENT_FAILED =
	'representment/UPDATE_REPRESENTMENT_FAILED';

export const MARK_SENT_TO_PROCESSOR = 'representment/MARK_SENT_TO_PROCESSOR';
export const MARK_SENT_TO_PROCESSOR_COMPLETED =
	'representment/MARK_SENT_TO_PROCESSOR_COMPLETED';
export const MARK_SENT_TO_PROCESSOR_FAILED =
	'representment/MARK_SENT_TO_PROCESSOR_FAILED';

export const BUILD_REPRESENTMENT = 'representment/BUILD_REPRESENTMENT';
export const BUILD_REPRESENTMENT_COMPLETED =
	'representment/BUILD_REPRESENTMENT_COMPLETED';
export const BUILD_REPRESENTMENT_FAILED =
	'representment/BUILD_REPRESENTMENT_FAILED';

export const CLEAR_REPRESENTMENT = 'representment/CLEAR_REPRESENTMENT';
