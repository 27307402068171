import axios from '../../utilities/axios';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';

import { getRulesRunnerEndpoint } from '../../utilities/apiEndpointUtility';

import {
	runUntrackedUserRule,
	runUntrackedUserRuleCompleted,
	runUntrackedUserRuleFailed
} from './rulesRunnerUntrackedSlice';

export const runUntrackedUserRuleEpic = (action$) =>
	action$.pipe(
		ofType(runUntrackedUserRule),
		mergeMap(async (action) => {
			try {
				const { userRuleId, ruleRunToken } = action.payload;

				await axios.post(
					`${getRulesRunnerEndpoint()}/run/single/${userRuleId}`, {},
					{
						headers: {
							Authorization: `Bearer ${ruleRunToken}`
						}
					}
				);

				return { userRuleId };
			} catch (error) {
				throw new Error('Rule Run Failure');
			}
		}),
		switchMap((res) => [
			runUntrackedUserRuleCompleted(res)
		]),
		catchError((error, source) =>
			merge(of(runUntrackedUserRuleFailed(error.message)), source)
		)
	);

export default [
	runUntrackedUserRuleEpic
];
