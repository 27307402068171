import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { withTranslation } from 'react-i18next';
import {
	UserInformationSection,
	OrganizationInformationSection,
	MultiFactorAuthenticationSection
} from '../../components/Account';
import EditAvatarModal from '../../components/modals/EditAvatarModal';
import MultiFactorAuthSetupModal from '../../components/modals/MultiFactorAuthSetupModal';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const StyledContainer = styled(Container)({
	display: 'flex',
	flexFlow: 'column'
});

export const Account = (props) => {
	const { t } = props;

	return (
		<StyledContainer maxWidth={false}>
			<Box pt={2} pl={1}>
				<Typography variant='h4' color='primary'>
					{t('pageTitle')}
				</Typography>
			</Box>
			<Stack direction='column' divider={<Divider />} spacing={3}>
				<UserInformationSection />
				<OrganizationInformationSection />
				<MultiFactorAuthenticationSection />
			</Stack>
			<EditAvatarModal />
			<MultiFactorAuthSetupModal />
		</StyledContainer>
	);
};

Account.propTypes = {
	t: PropTypes.func
};

export default withTranslation('account')(Account);
