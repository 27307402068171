import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { registrationActions } from '../../containers/Registration';
import InvitationValidationError from './InvitationValidationError';
import attachUsernameToCPI from '../../helpers/registration/attachUsernameToCPI';
import newUserDefaultConfiguration from './constants/newUserDefaultConfiguration';
import { formatPhoneNumberDigitsOnly } from '../../utilities/formatPhoneNumber';
import RegistrationForm from './RegistrationForm';
import InvitationLoader from './InvitationLoader';
import ConfirmationForm from './ConfirmationForm';
import BorderedPaper from '../branded/BorderedPaper';

export const RegistrationManager = (props) => {
	const {
		validateInvitationError,
		validatedInvitationId,
		isRegistering,
		hasBeenRegistered,
		registrationError,
		validateInvitationId,
		submitRegistration
	} = props;

	const location = useLocation();

	const [newUserConfiguration, setNewUserConfiguration] = React.useState(
		newUserDefaultConfiguration
	);

	React.useEffect(() => {
		const queryParameters = queryString.parse(location.search);

		if (queryParameters.invitationId && queryParameters.ACID) {
			validateInvitationId({
				clientId: queryParameters.ACID,
				invitationId: queryParameters.invitationId
			});
		}
	}, [location]);

	const onSubmit = () => {
		const queryParameters = queryString.parse(location.search);

		attachUsernameToCPI(newUserConfiguration.username);

		submitRegistration({
			userPoolId: queryParameters.UPID,
			username: newUserConfiguration.username.toLocaleLowerCase(),
			firstName: newUserConfiguration.firstName,
			lastName: newUserConfiguration.lastName,
			invitationId: validatedInvitationId,
			phoneNumber: formatPhoneNumberDigitsOnly(newUserConfiguration.phone),
			department: newUserConfiguration.department,
			avatar: newUserConfiguration.avatar
		});
	};

	if (validateInvitationError) {
		return (
			<BorderedPaper>
				<InvitationValidationError />
			</BorderedPaper>
		);
	}

	if (!validatedInvitationId) {
		return (
			<BorderedPaper>
				<InvitationLoader
					validatedInvitationId={validatedInvitationId}
					validateInvitationError={validateInvitationError}
					registrationError={registrationError}
				/>
			</BorderedPaper>
		);
	}

	if (hasBeenRegistered || isRegistering) {
		return (
			<BorderedPaper>
				<ConfirmationForm
					hasBeenRegistered={hasBeenRegistered}
					isRegistering={isRegistering}
				/>
			</BorderedPaper>
		);
	}

	return (
		<BorderedPaper>
			<RegistrationForm
				newUserConfiguration={newUserConfiguration}
				setNewUserConfiguration={setNewUserConfiguration}
				onSubmit={onSubmit}
			/>
		</BorderedPaper>
	);
};

RegistrationManager.propTypes = {
	validatedInvitationId: PropTypes.string,
	isRegistering: PropTypes.bool,
	hasBeenRegistered: PropTypes.bool,
	registrationError: PropTypes.string,
	validateInvitationId: PropTypes.func,
	submitRegistration: PropTypes.func,
	validateInvitationError: PropTypes.func,
	t: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		isValidating: state.registrationReducer.getIn([
			'invitation',
			'isValidating'
		]),
		validatedInvitationId: state.registrationReducer.getIn([
			'invitation',
			'validatedInvitationId'
		]),
		validateInvitationError: state.registrationReducer.getIn([
			'invitation',
			'error'
		]),
		isRegistering: state.registrationReducer.getIn([
			'registration',
			'isRegistering'
		]),
		hasBeenRegistered: state.registrationReducer.getIn([
			'registration',
			'hasBeenRegistered'
		]),
		registrationError: state.registrationReducer.getIn([
			'registration',
			'error'
		])
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		validateInvitationId: (payload) => {
			dispatch(registrationActions.validateInvitationId(payload));
		},
		submitRegistration: (payload) => {
			dispatch(registrationActions.submitRegistration(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(RegistrationManager);
