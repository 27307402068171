import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import RepresentmentTextField from './components/RepresentmentTextField';
import BuilderSectionHeader from '../BuilderSectionHeader';
import RepresentmentBuilderBox from '../RepresentmentBuilderStack';
import { formatDateForDisplay } from '../../../utilities/dateWrapper';

import Grid from '@mui/material/Grid';

export const TransactionDetails = (props) => {
	const { t, data } = props;

	return (
		<Grid item xs={12} sm={12} md={12} lg={12}>
			<RepresentmentBuilderBox>
				<BuilderSectionHeader>{t('transactionDetails.label')}</BuilderSectionHeader>
				<Grid container>
					<Grid item>
						<RepresentmentTextField
							id='maskedCardNumber'
							label={t('transactionDetails.maskedCardNumber')}
							defaultValue={data.maskedCardNumber}
						/>
					</Grid>
					<Grid item>
						<RepresentmentTextField
							id='settledOn'
							label={t('transactionDetails.settledOn')}
							defaultValue={formatDateForDisplay(data.settledOn)}
						/>
					</Grid>
					<Grid item>
						<RepresentmentTextField
							id='settledAmount'
							label={t('transactionDetails.settledAmount')}
							defaultValue={`${data.settledAmount}`}
						/>
					</Grid>
					<Grid item>
						<RepresentmentTextField
							id='addressVerificationServiceResponse'
							label={t('transactionDetails.addressVerificationServiceResponse')}
							defaultValue={data.addressVerificationServiceResponse}
							disabled={!data.addressVerificationServiceResponse}
						/>
					</Grid>
					<Grid item>
						<RepresentmentTextField
							id='authorizationCode'
							label={t('transactionDetails.authorizationCode')}
							defaultValue={data.authorizationCode}
						/>
					</Grid>
					<Grid item>
						<RepresentmentTextField
							id='authorizedOn'
							label={t('transactionDetails.authorizedOn')}
							defaultValue={formatDateForDisplay(data.authorizedOn)}
						/>
					</Grid>
					<Grid item>
						<RepresentmentTextField
							id='acquirerReferenceNumber'
							isFlex={true}
							minWidth={350}
							label={t('transactionDetails.acquirerReferenceNumber')}
							defaultValue={data.acquirerReferenceNumber}
						/>
					</Grid>
					<Grid item>
						<RepresentmentTextField
							id='paymentCardAssociationTransactionId'
							label={t(
								'transactionDetails.paymentCardAssociationTransactionId'
							)}
							defaultValue={data.paymentCardAssociationTransactionId}
							disabled={!data.paymentCardAssociationTransactionId}
						/>
					</Grid>
					<Grid item>
						<RepresentmentTextField
							id='paymentCardVerificationValueResponse'
							label={t(
								'transactionDetails.paymentCardVerificationValueResponse'
							)}
							defaultValue={data.paymentCardVerificationValueResponse}
							disabled={!data.paymentCardVerificationValueResponse}
						/>
					</Grid>
				</Grid>
			</RepresentmentBuilderBox>
		</Grid>
	);
};

TransactionDetails.propTypes = {
	data: PropTypes.object,
	t: PropTypes.func
};

export default withTranslation('representments')(TransactionDetails);
