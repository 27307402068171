import * as actionTypes from './actionTypes';

export const getPdf = (payload) => ({
	type: actionTypes.GET_PDF,
	payload
});

export const getPdfCompleted = (payload) => ({
	type: actionTypes.GET_PDF_COMPLETED,
	payload
});

export const getPdfFailed = (payload) => ({
	type: actionTypes.GET_PDF_FAILED,
	payload
});

export const clearPdf = (payload) => ({
	type: actionTypes.CLEAR_PDF,
	payload
});
