import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	ruleRunToken: undefined,
	ruleRunTokenExpiration: undefined,
	ruleRunTokenError: undefined
};

export const rulesRunnerTokenSlice = createSlice({
	name: 'rulesRunnerToken',
	initialState,
	reducers: {
		getRuleRunToken: (state) => {
			state.ruleRunTokenError = undefined;
		},
		getRuleRunTokenCompleted: (state, action) => {
			state.ruleRunToken = action.payload.token;
			state.ruleRunTokenExpiration = action.payload.expiration;
		},
		getRuleRunTokenFailed: (state, action) => {
			state.ruleRunTokenError = action.payload;
		},
		resetRuleRunToken: (state) => {
			state.ruleRunToken = undefined;
			state.ruleRunTokenExpiration = undefined;
			state.ruleRunTokenError = undefined;
		}
	}
});

export const {
	getRuleRunToken,
	getRuleRunTokenCompleted,
	getRuleRunTokenFailed,
	resetRuleRunToken
} = rulesRunnerTokenSlice.actions;

export default rulesRunnerTokenSlice.reducer;
