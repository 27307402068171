import React from 'react';

import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

const HeaderGroup = () => {
	return (
		<Stack direction='row' sx={{ flex: 1 }} px={2}>
			<Skeleton width={'100%'} height={50} />
		</Stack>
	);
};

const LoadingTable = () => {
	return (
		<Stack direction='column' sx={{ display: 'flex', width: '100%' }}>
			<Stack direction='row' alignItems='center' sx={{ flex: 1 }}>
				<Box width={150}>
					<Skeleton width={90} height={70} />
				</Box>
				<HeaderGroup />
				<HeaderGroup />
				<HeaderGroup />
			</Stack>
			<Stack direction='row' sx={{ flex: 1 }}>
				<Box width={150} />
				<HeaderGroup />
				<HeaderGroup />
				<HeaderGroup />
			</Stack>
			<Skeleton width={'100%'} height={50} p={3} />
			<Skeleton width={'100%'} height={50} p={3} />
			<Skeleton width={'100%'} height={50} p={3} />
		</Stack>
	);
};

export default LoadingTable;
