import React from 'react';
import PropTypes from 'prop-types';

import FormControl from '@mui/material/FormControl';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export const DateSelect = ({
	label,
	value,
	onChangeDate,
	minDate,
	maxDate,
	disabled,
	disableFuture
}) => {
	return (
		<FormControl fullWidth>
			<DatePicker
				label={label}
				disabled={disabled}
				value={value}
				onChange={(newValue) => {
					onChangeDate(newValue);
				}}
				minDate={minDate}
				maxDate={maxDate}
				disableFuture={disableFuture}
				slotProps={{ textField: { variant: 'outlined' } }}
			/>
		</FormControl>
	);
};

DateSelect.propTypes = {
	label: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	onChangeDate: PropTypes.func,
	minDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	maxDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	disabled: PropTypes.bool,
	disableFuture: PropTypes.bool
};

export default DateSelect;
