import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	userAvatar: {
		path: undefined,
		src: undefined,
		isLoading: false,
		hasBeenLoaded: false,
		error: undefined
	}
};

export const userAvatarSlice = createSlice({
	name: 'userAvatar',
	initialState,
	reducers: {
		getAvatarSrc: (state, action) => {
			state.userAvatar.path = action.payload.avatar;
			state.userAvatar.isLoading = true;
			state.userAvatar.error = undefined;
		},
		getAvatarSrcCompleted: (state, action) => {
			state.userAvatar.isLoading = false;
			state.userAvatar.hasBeenLoaded = true;
			state.userAvatar.src = action.payload;
		},
		getAvatarSrcFailed: (state, action) => {
			state.userAvatar.isLoading = true;
			state.userAvatar.error = action.payload;
		}
	}
});

export const {
	getAvatarSrc,
	getAvatarSrcCompleted,
	getAvatarSrcFailed
} = userAvatarSlice.actions;

export default userAvatarSlice.reducer;
