import axios from '../../utilities/axios';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { refreshToken } from '../../utilities/refreshToken';
import { getAssetUri } from '../../utilities/assetEndpointUtility';

import {
	getAvatarSrc,
	getAvatarSrcCompleted,
	getAvatarSrcFailed
} from './userAvatarSlice';

export const getAvatarSrcEpic = (action$) =>
	action$.pipe(
		ofType(getAvatarSrc),
		mergeMap(async (action) => {
			await refreshToken();

			const { avatar } = action.payload;

			const response = await axios.get(
				`${getAssetUri()}${avatar}`,
				{ responseType: 'blob' }
			);

			const imageObjectURL = URL.createObjectURL(response.data);

			return imageObjectURL;
		}),
		switchMap((res) => [
			getAvatarSrcCompleted(res)
		]),
		catchError((error, source) =>
			merge(of(getAvatarSrcFailed(error.message)), source)
		)
	);
