import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { withTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const BaseStack = styled(Stack)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	padding: theme.spacing(2),
	margin: theme.spacing(1),
	borderRadius: 5
}));

const UnselectedOptionStack = styled(BaseStack)(({ theme }) => ({
	border: `2px solid ${theme.palette.secondary.main}`,
	color: theme.palette.primary.contrastText,
	cursor: 'pointer',
	'& .icon': {
		color: theme.palette.secondary.main
	},
	'& .label': {
		color: theme.palette.secondary.main
	},
	'&:hover': {
		backgroundColor: theme.palette.primary.light,
		borderColor: theme.palette.primary.light,
		'& .icon': {
			color: theme.palette.primary.contrastText
		},
		'& .label': {
			color: theme.palette.primary.contrastText
		}
	}
}));

const SelectedOptionStack = styled(BaseStack)(({ theme }) => ({
	border: `2px solid ${theme.palette.primary.light}`,
	backgroundColor: theme.palette.primary.light,
	color: theme.palette.primary.contrastText,
	cursor: 'pointer',
	'& .icon': {
		color: theme.palette.primary.contrastText
	},
	'& .label': {
		color: theme.palette.primary.contrastText
	}
}));

const DisabledOptionStack = styled(BaseStack)(({ theme }) => ({
	border: `2px solid ${theme.palette.secondary.light}`,
	opacity: 0.4,
	'& .icon': {
		color: theme.palette.secondary.light
	},
	'& .label': {
		color: theme.palette.secondary.light
	}
}));

export const BuildListSelectOption = (props) => {
	const { t, ruleListType, isSelected, onUpdateListSelect, isDisabled } = props;

	if (isDisabled) {
		return (
			<Grid item xs={12} sm={6} md={3}>
				<DisabledOptionStack>
					<ruleListType.icon className='icon'/>
					<Typography className='label'>
						{t(`rules:${ruleListType.name}`)}
					</Typography>
				</DisabledOptionStack>
			</Grid>
		);
	}

	const StackComponent = isSelected ? SelectedOptionStack : UnselectedOptionStack;

	return (
		<Grid item key={ruleListType.id} xs={12} sm={6} md={3}>
			<StackComponent
				onClick={() => onUpdateListSelect('ruleListType', ruleListType)}
			>
				<ruleListType.icon className='icon'/>
				<Typography className='label'>
					{t(`rules:${ruleListType.name}`)}
				</Typography>
			</StackComponent>
		</Grid>
	);
};

BuildListSelectOption.propTypes = {
	t: PropTypes.func,
	isDisabled: PropTypes.bool,
	ruleListType: PropTypes.object,
	isSelected: PropTypes.bool,
	onUpdateListSelect: PropTypes.func
};

export default withTranslation('rules')(BuildListSelectOption);
