import querier from '../../utilities/querier';

export default function (dataSource, processor) {
	let validated = false;

	if (processor && dataSource) {
		const firstDatasourceEntry = querier(
			dataSource,
			`$[paymentEnablerAcquiringBankId = ${processor}][0]`
		);

		if (firstDatasourceEntry) {
			validated = true;
		}
	}

	return validated;
}
