import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import CancelIcon from '@mui/icons-material/Cancel';

export const HeaderRow = (props) => {
	const { t, translationPath, supplementalText, title, onClickCancel } = props;

	let headerText = title || t(translationPath);

	if (supplementalText) {
		headerText = `${headerText} - ${supplementalText}`;
	}

	return (
		<Stack direction='row' spacing={2} alignItems='center'>
			<Typography variant='h4' color='primary' sx={{ pr: 2.3 }}>
				{headerText}
			</Typography>
			<Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
				<IconButton color='secondary' onClick={onClickCancel}>
					<CancelIcon />
				</IconButton>
			</Box>
		</Stack>
	);
};

HeaderRow.propTypes = {
	translationPath: PropTypes.string,
	supplementalText: PropTypes.string,
	onClickCancel: PropTypes.func,
	title: PropTypes.string,
	t: PropTypes.func
};

export default withTranslation('modals')(HeaderRow);
