import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';

const GenericPaperContainer = styled(Paper)(({ theme }) => ({
	position: 'absolute',
	outline: 'none',
	[theme.breakpoints.up('md')]: {
		left: '50%',
		top: '50%',
		transform: 'translate(-50%, -50%)',
		height: 'fit-content',
		margin: '20px',
		width: '35%'
	},
	[theme.breakpoints.down('md')]: {
		left: '20%',
		top: '20%',
		transform: 'translate(-5%, -5%)',
		height: 'fit-content',
		width: '60%'
	}
}));

const GenericModalPaperWrapper = (props) => {
	const { children, hideBackdrop, open, onClose } = props;

	return (
		<Modal hideBackdrop={hideBackdrop} open={open} onClose={onClose}>
			<GenericPaperContainer>
				{children}
			</GenericPaperContainer>
		</Modal>
	);
};

GenericModalPaperWrapper.propTypes = {
	children: PropTypes.node,
	hideBackdrop: PropTypes.bool,
	isLoading: PropTypes.bool,
	open: PropTypes.bool,
	onClose: PropTypes.func
};

export default GenericModalPaperWrapper;
