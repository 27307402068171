import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import departmentOptions from '../../../constants/departmentOptions';
import ValidationInput from '../../inputs/ValidationInput';
import registrationStages from '../constants/registrationStages';
import { formatPhoneNumberForDisplay } from '../../../utilities/formatPhoneNumber';
import { employeeFields as requiredEmployeeFields } from '../constants/requiredFields';
import BoldLargeHeader from '../../headers/BoldLargeHeader';
import ContainedButton from '../../buttons/ContainedButton';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';

export const EmployeeInformation = (props) => {
	const {
		newUserConfiguration,
		onConfigurationChange,
		setCurrentStage,
		t
	} = props;

	const isFormComplete = () => {
		return requiredEmployeeFields.every((field) => {
			return (
				newUserConfiguration[field] !== undefined &&
				newUserConfiguration[field] !== ''
			);
		});
	};

	return (
		<Box sx={{ maxWidth: 400 }}>
			<Stack direction='row' justifyContent='center' alignItems='center'>
				<BoldLargeHeader py={2}>
					{t('registration:registrationForm.newUserRegistration')}
				</BoldLargeHeader>
			</Stack>
			<Stack direction='column' justifyContent='center' p={2} mx={4}>
				<Stack direction='column' alignItems='center' spacing={3} px={4}>
					<FormControl sx={{ width: '100%' }}>
						<label>{t('registration:registrationForm.input.firstName')}</label>
						<ValidationInput
							value={newUserConfiguration.firstName}
							onChange={(value) => {
								onConfigurationChange(value, 'firstName');
							}}
							initialValidationErrors={[]}
						/>
					</FormControl>
					<FormControl sx={{ width: '100%' }}>
						<label>{t('registration:registrationForm.input.lastName')}</label>
						<ValidationInput
							value={newUserConfiguration.lastName}
							onChange={(value) => {
								onConfigurationChange(value, 'lastName');
							}}
							initialValidationErrors={[]}
						/>
					</FormControl>
					<FormControl sx={{ width: '100%' }}>
						<label>{t('registration:registrationForm.input.phone')}</label>
						<ValidationInput
							value={newUserConfiguration.phone}
							onChange={(value) => {
								onConfigurationChange(value, 'phone');
							}}
							initialValidationErrors={[]}
							displayFormatter={formatPhoneNumberForDisplay}
						/>
					</FormControl>
					<FormControl sx={{ width: '100%' }}>
						<label>{t('registration:registrationForm.input.department')}</label>
						<Select
							size='small'
							value={newUserConfiguration.department}
							onChange={(e) => {
								onConfigurationChange(e.target.value, 'department');
							}}
						>
							{departmentOptions.map((department) => {
								return (
									<MenuItem key={department} value={department}>
										{department}
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>
				</Stack>
				<Stack direction='row' justifyContent='center' mt={2}>
					<ContainedButton
						disabled={!isFormComplete()}
						onClick={() => {
							setCurrentStage(registrationStages.slyceInformation);
						}}
					>
						{t('common:buttons.next')}
					</ContainedButton>
				</Stack>
			</Stack>
		</Box>
	);
};

EmployeeInformation.propTypes = {
	newUserConfiguration: PropTypes.object,
	onConfigurationChange: PropTypes.func,
	setCurrentStage: PropTypes.func,
	t: PropTypes.func
};

export default withTranslation(['common', 'registration'])(EmployeeInformation);
