import querier from '../../utilities/querier';

export default function (representmentData, displaySections) {
	const filteredSections = [];

	const representmentDataKeys = querier(representmentData, '[$keys()]');

	displaySections.forEach((section) => {
		if (representmentDataKeys.includes(section)) {
			filteredSections.push(section);
		}
	});

	return filteredSections;
}
