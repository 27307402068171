import querier from '../../utilities/querier';

export default function (reversalReason, reasonList) {
	const preparedReasons = [];

	if (reversalReason) {
		reversalReason.forEach((reason) => {
			const matchedResult = querier(reasonList, `$[value = "${reason}"]`);

			if (matchedResult) {
				preparedReasons.push({
					key: matchedResult.label,
					value: reason
				});
			} else {
				preparedReasons.push({
					key: 'Manual Entry',
					value: reason
				});
			}
		});
	}

	return preparedReasons;
}
