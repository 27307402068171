import React from 'react';
import ForgotPasswordForm from '../../components/ForgotPassword/ForgotPasswordForm';

import RegistrationPageWrapper from '../../components/RegistrationPageWrapper';

const ForgotPassword = () => {
	return (
		<RegistrationPageWrapper>
			<ForgotPasswordForm />
		</RegistrationPageWrapper>
	);
};

export default ForgotPassword;
