import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import ValidationItem from './ValidationItem';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const PasswordValidationToolTip = (props) => {
	const {
		t,
		showSecureInput,
		toggleShowSecureInput,
		passwordValidationErrors,
		toolTipIsOpen,
		isTextEmpty
	} = props;

	const ref = React.createRef();

	const [error, setError] = React.useState(passwordValidationErrors);

	React.useEffect(() => {
		if (passwordValidationErrors) {
			setError(passwordValidationErrors);
		}
	}, [passwordValidationErrors]);

	return (
		<Tooltip
			open={toolTipIsOpen}
			arrow={true}
			placement='right'
			title={
				<React.Fragment>
					<Typography color='inherit'>
						{t('passwordReset.validation.header')}
					</Typography>
					<ul style={{ listStyle: 'none' }}>
						<ValidationItem
							validationResult={
								error.includes('min') || error.includes('max') || isTextEmpty
							}
							descriptionTranslationPath={'passwordReset.validation.characters'}
						/>
						<ValidationItem
							validationResult={
								error.includes('uppercase' || 'lowercase') || isTextEmpty
							}
							descriptionTranslationPath={'passwordReset.validation.letters'}
						/>
						<ValidationItem
							validationResult={error.includes('symbols') || isTextEmpty}
							descriptionTranslationPath={'passwordReset.validation.special'}
						/>
						<ValidationItem
							validationResult={error.includes('digits') || isTextEmpty}
							descriptionTranslationPath={'passwordReset.validation.number'}
						/>
					</ul>
				</React.Fragment>
			}
		>
			{showSecureInput ? (
				<IconButton ref={ref} onClick={toggleShowSecureInput} color='secondary'>
					<VisibilityIcon />
				</IconButton>
			) : (
				<IconButton ref={ref} onClick={toggleShowSecureInput} color='secondary'>
					<VisibilityOffIcon />
				</IconButton>
			)}
		</Tooltip>
	);
};

PasswordValidationToolTip.propTypes = {
	showSecureInput: PropTypes.bool,
	toggleShowSecureInput: PropTypes.func,
	passwordValidationErrors: PropTypes.arrayOf(PropTypes.string),
	toolTipIsOpen: PropTypes.bool,
	isTextEmpty: PropTypes.bool,
	t: PropTypes.func
};

PasswordValidationToolTip.defaultProps = {
	showTooltip: true
};

export default withTranslation('registration')(PasswordValidationToolTip);
