import React from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { withTranslation } from 'react-i18next';
import queryString from 'query-string';
import ReportHeader from '../../Report/ReportHeader';
import generateMaxDate from '../../../helpers/reports/generateMaxDate';
import { StandardStyledGrid } from '../../ReportStylings';
import convertUrlDateToDateSelect from '../../../helpers/shared/convertUrlDateToDateSelect';
import ContainedLoadingButton from '../../buttons/ContainedLoadingButton';

// Input Components
import ProcessorSelect from '../../Report/FilterController/components/ProcessorSelect';
import DateSelect from '../../Report/FilterController/components/DateSelect';

import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

const FilterController = (props) => {
	const { t, onGetReportData, isLoading } = props;

	const [lookupDate, setLookupDate] = React.useState(dayjs().add(-1, 'day'));
	const [selectedProcessor, setSelectedProcessor] = React.useState('');

	const location = useLocation();

	const getReportData = () => {
		onGetReportData(lookupDate, selectedProcessor);
	};

	const submitFilters = () => {
		const reportId = location.pathname.split('/')[2];

		window.history.replaceState(
			null,
			'Slyce360',
			`/reports/${reportId}?processorId=${selectedProcessor}&lookupDate=${dayjs(
				lookupDate
			).format('YYYY-MM-DD')}`
		);
		getReportData();
	};

	React.useEffect(() => {
		const { processorId, lookupDate } = queryString.parse(location.search);

		if (processorId) {
			setSelectedProcessor(processorId);
		}

		if (lookupDate) {
			setLookupDate(convertUrlDateToDateSelect(lookupDate));
		}
	}, [location]);

	const updateDateSelect = (newValue, callbackFunction) => {
		const formattedValue = dayjs(newValue);

		callbackFunction(formattedValue);
	};

	return (
		<Paper sx={{ mt: '20px' }}>
			<Stack direction='column' p={1} spacing={1}>
				<Stack direction='row' justifyContent='space-between' mt={1}>
					<ReportHeader label={t('reports:chargebackSummary.label')} />
				</Stack>
				<Grid
					container
					rowSpacing={2}
					columnSpacing={1}
					p={1}
					justifyContent='flex-end'
				>
					<StandardStyledGrid item>
						<DateSelect
							translationPath={'reports:filterController.lookupDate'}
							value={lookupDate}
							maxDate={generateMaxDate(1)}
							onChange={(newValue) => updateDateSelect(newValue, setLookupDate)}
						/>
					</StandardStyledGrid>
					<StandardStyledGrid item>
						<ProcessorSelect
							onChange={(newSelection, proccessorLabel) => {
								setSelectedProcessor(newSelection);
							}}
							translationPath={'reports:filterController.processor'}
							value={selectedProcessor}
							dataSource={'processors'}
						/>
					</StandardStyledGrid>
					<Grid item>
						<ContainedLoadingButton
							sx={{ height: 50 }}
							loading={isLoading}
							disabled={!lookupDate || selectedProcessor === ''}
							onClick={submitFilters}
						>
							{t('common:buttons.submit')}
						</ContainedLoadingButton>
					</Grid>
				</Grid>
			</Stack>
		</Paper>
	);
};

FilterController.propTypes = {
	t: PropTypes.func,
	onGetReportData: PropTypes.func,
	hasBeenLoaded: PropTypes.bool,
	isLoading: PropTypes.bool
};

export default withTranslation(['common', 'reports'])(FilterController);
