import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';

export const SortableTableCell = (props) => {
	const { t, translationContext, headCell, order, orderBy, handleSortRequest } = props;

	const createSortHandler = (property) => (event) => {
		handleSortRequest(event, property);
	};

	return (
		<TableCell
			key={headCell.id}
			align={headCell.align || 'center'}
			padding={headCell.disablePadding ? 'none' : 'normal'}
			width={headCell.width || null}
			sx={{ pb: 1 }}
			sortDirection={orderBy === headCell.id ? order : false}
		>
			<TableSortLabel
				active={orderBy === headCell.id}
				direction={orderBy === headCell.id ? order : 'asc'}
				onClick={createSortHandler(headCell.id)}
			>
				{headCell.labelTranslationPath
					? t(`${translationContext}:${headCell.labelTranslationPath}`)
					: ''}
				{orderBy === headCell.id ? (
					<Box component='span' sx={visuallyHidden}>
						{order === 'desc'
							? 'sorted descending'
							: 'sorted ascending'}
					</Box>
				) : null}
			</TableSortLabel>
		</TableCell>
	);
};

SortableTableCell.propTypes = {
	headCell: PropTypes.object,
	translationContext: PropTypes.string,
	order: PropTypes.string,
	orderBy: PropTypes.string,
	handleSortRequest: PropTypes.func,
	t: PropTypes.func
};

export default withTranslation()(SortableTableCell);
