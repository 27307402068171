import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { withTranslation } from 'react-i18next';

import TableCell from '@mui/material/TableCell';
import Box from '@mui/material/Box';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const StyledTableCell = styled(TableCell)({
	fontSize: '0.8rem',
	padding: '5px'
});

export const EnhancedTableHead = (props) => {
	const { t, columns } = props;

	return (
		<TableHead>
			<TableRow>
				<TableCell>
					<Box width={150} />
				</TableCell>
				{columns.map((headCell) => {
					return (
						<StyledTableCell key={headCell.key} align='center'>
							{headCell.label}
						</StyledTableCell>
					);
				})}
				<StyledTableCell
					sx={(theme) => ({
						borderLeft: `1px solid ${theme.palette.secondary.alt}`,
						boxShadow: `-5px 0px 5px -3px ${theme.palette.secondary.alt}`
					})}
					align='center'
				>
					{t('fourteenDayChargebacks.grandTotals')}
				</StyledTableCell>
			</TableRow>
		</TableHead>
	);
};

EnhancedTableHead.propTypes = {
	columns: PropTypes.array,
	t: PropTypes.func
};

export default withTranslation('reports')(EnhancedTableHead);
