import React from 'react';
import { styled } from '@mui/material/styles';
import TableauInsightWrapper from '../../components/staticReportViews/TableauInsightWrapper';

import Container from '@mui/material/Container';

const StyledContainer = styled(Container)({
	margin: 0,
	padding: 0,
	display: 'flex',
	flexFlow: 'column',
	height: '100%'
});

const InsightReports = () => {
	return (
		<StyledContainer maxWidth={false}>
			<TableauInsightWrapper />
		</StyledContainer>
	);
};

export default (InsightReports);
