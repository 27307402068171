import React from 'react';
import PropTypes from 'prop-types';
import deepCopy from '../../../../../../utilities/deepCopy';
import { notificationSeverityLevels } from '../../../../../../constants/notificationSeverityLevels';
import SeveritySelectOption from './SeveritySelectOption';

import Grid from '@mui/material/Grid';

export const SeveritySelect = (props) => {
	const {
		isDisabled,
		ruleConfiguration,
		setRuleConfiguration,
		submitErrors,
		setSubmitErrors
	} = props;

	const onSelectSeverityLevel = (value) => {
		const updatedConfiguration = deepCopy(ruleConfiguration);

		updatedConfiguration.severity = value;

		if (submitErrors) {
			const updatedSubmitErrors = submitErrors.filter((error) => {
				return error.type !== 'severity';
			});

			setSubmitErrors(updatedSubmitErrors);
		}

		setRuleConfiguration(updatedConfiguration);
	};

	return (
		<Grid
			container
			rowSpacing={1}
			sx={{ width: '100%' }}
		>
			{notificationSeverityLevels.map((severityLevel) => (
				<SeveritySelectOption
					key={severityLevel.id}
					isSelected={ruleConfiguration.severity?.id === severityLevel.id}
					option={severityLevel}
					onSelect={onSelectSeverityLevel}
					isDisabled={isDisabled}
				/>
			))}
		</Grid>
	);
};

SeveritySelect.propTypes = {
	isDisabled: PropTypes.bool,
	ruleConfiguration: PropTypes.object,
	setRuleConfiguration: PropTypes.func,
	submitErrors: PropTypes.array,
	setSubmitErrors: PropTypes.func
};

export default SeveritySelect;
