import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const NavigationCategory = (props) => {
	const { children, label, isExpanded, setIsExpanded } = props;

	const onChangeExpanded = () => {
		setIsExpanded(!isExpanded);
	};

	return (
		<Box>
			<Stack
				direction='row'
				onClick={onChangeExpanded}
				alignItems='center'
				ml={2}
				spacing={2}
				mb={1}
				sx={{ cursor: 'pointer' }}
			>
				<Typography sx={{ fontWeight: 600 }} pl={1}>
					{label}
				</Typography>
				{isExpanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
			</Stack>
			<Collapse in={isExpanded} timeout='auto' unmountOnExit>
				<Stack direction='column' ml={2}>
					{children}
				</Stack>
			</Collapse>
		</Box>
	);
};

NavigationCategory.propTypes = {
	label: PropTypes.string,
	isExpanded: PropTypes.bool,
	setIsExpanded: PropTypes.func,
	children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
};

export default NavigationCategory;
