import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';
import NavigationCategory from '../shared/NavigationCategory';
import LoadingNavigationItem from '../shared/LoadingNavigationItem';
import PopoutMenuNavigationItem from '../shared/PopoutMenuNavigationItem';

import { getReportViews } from '../../../../../containers/reportViews/reportViewsSlice';
import { toggleReportsExpansion } from '../../../../../containers/sideNavigation/sideNavigationSlice';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const Reports = ({
	t
}) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [activeMenuIndex, setActiveMenuIndex] = React.useState();
	const [activeMenuItems, setActiveMenuItems] = React.useState([]);

	const {
		isReportsExpanded
	} = useSelector(state => state.sideNavigationSlice);
	const {
		data: reportViews,
		isLoading: isLoadingReportViews,
		hasBeenLoaded: reportViewsHaveBeenLoaded,
		error: reportViewsError
	} = useSelector(state => state.reportViewsSlice.reportViews);

	React.useEffect(() => {
		dispatch(getReportViews());
	}, []);

	const onToggleExpanded = () => {
		dispatch(toggleReportsExpansion());
	};

	const handleClick = (anchorEl, index) => {
		setActiveMenuIndex(index);
		setAnchorEl(anchorEl);
	};
	const handleClose = () => {
		setActiveMenuIndex();
		setAnchorEl(null);
	};

	const onClickNavigationItem = (reportUniversalUniqueIdentifier) => {
		navigate(`/reports/${reportUniversalUniqueIdentifier}`);
	};

	if (reportViewsError) {
		return (
			<NavigationCategory
				label={t('reports.sectionHeader')}
				isExpanded={isReportsExpanded}
				setIsExpanded={onToggleExpanded}
			>
				<Box p={1}>
					<Typography color='error' sx={{ fontSize: '1.3rem' }}>
						{t('reports.failedToLoad')}
					</Typography>
				</Box>
			</NavigationCategory>
		);
	}

	const open = Boolean(anchorEl);

	return (
		<NavigationCategory
			label={t('navigation:reports')}
			isExpanded={isReportsExpanded}
			setIsExpanded={onToggleExpanded}
		>
			{ isLoadingReportViews && !reportViewsHaveBeenLoaded
				? (
					<React.Fragment>
						<LoadingNavigationItem />
						<LoadingNavigationItem />
						<LoadingNavigationItem />
					</React.Fragment>
				) : null
			}
			{reportViews.map((reportCategory, index) => (
				<PopoutMenuNavigationItem
					key={reportCategory.reportCategoryId}
					index={index}
					label={reportCategory.reportCategoryLabel}
					onClickOpenMenu={handleClick}
					setActiveMenuItems={setActiveMenuItems}
					menuItems={reportCategory.reports}
					activeMenuIndex={activeMenuIndex}
				/>
			))}
			<Menu
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				onClick={handleClose}
				slotProps={{
					paper: {
						elevation: 3,
						sx: { px: '2px' }
					}
				}
				}
				transformOrigin={{ horizontal: 0, vertical: 10 }}
				anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
			>
				{
					activeMenuItems.map((menuItem) => (
						<MenuItem
							key={menuItem.universalUniqueIdentifier}
							onClick={() => {
								onClickNavigationItem(menuItem.universalUniqueIdentifier);
							}}>
							{menuItem.label}
						</MenuItem>
					))
				}
			</Menu>
		</NavigationCategory>
	);
};

Reports.propTypes = {
	t: PropTypes.func
};

export default withTranslation('navigation')(Reports);
