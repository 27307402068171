import React from 'react';
import PropTypes from 'prop-types';

import Paper from '@mui/material/Paper';

import EditIcon from '@mui/icons-material/Edit';

const EditAvatar = (props) => {
	const { onClick } = props;

	return (
		<Paper
			onClick={onClick}
			elevation={1}
			sx={{ cursor: 'pointer', mr: '-20px', mb: '-20px' }}
		>
			<EditIcon color='primary' />
		</Paper>
	);
};

EditAvatar.propTypes = {
	onClick: PropTypes.func
};

export default EditAvatar;
