import React from 'react';
import PropTypes from 'prop-types';

import TextField from '@mui/material/TextField';

const GenericTextInput = (props) => {
	const { label, onUpdate, value, isDisabled } = props;

	return (
		<TextField
			id={`generic-text-${label}`}
			label={label}
			defaultValue={value}
			onChange={(e) => onUpdate(e.target.value)}
			disabled={!!isDisabled}
			variant='outlined'
			fullWidth
			sx={{
				bgcolor: 'common.white'
			}}
		/>
	);
};

GenericTextInput.propTypes = {
	isDisabled: PropTypes.bool,
	label: PropTypes.string,
	onUpdate: PropTypes.func,
	value: PropTypes.string
};

export default GenericTextInput;
