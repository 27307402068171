import axios from '../../utilities/axios';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { refreshToken } from '../../utilities/refreshToken';
import { primaryRestGateway } from '../../utilities/apiEndpointUtility';

import {
	getRuleBuilderCategories,
	getRuleBuilderCategoriesCompleted,
	getRuleBuilderCategoriesFailed,
	getRuleBuilderOptions,
	getRuleBuilderOptionsCompleted,
	getRuleBuilderOptionsFailed
} from './ruleBuilderSlice';
import {
	getUserRule,
	getUserRuleCompleted,
	getUserRuleFailed
} from './userRuleSlice';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import {

	deleteSavedRule,
	deleteSavedRuleCompleted,
	deleteSavedRuleFailed
} from './savedRulesSlice';

export const getRuleBuilderCategoriesEpic = (action$) =>
	action$.pipe(
		ofType(getRuleBuilderCategories),
		mergeMap(async (action) => {
			await refreshToken();

			const response = await axios.get(`${primaryRestGateway()}/api/rules/builder/categories`);

			return response.data;
		}),
		switchMap((res) => [getRuleBuilderCategoriesCompleted(res)]),
		catchError((error, source) =>
			merge(of(getRuleBuilderCategoriesFailed(error.message)), source)
		)
	);

export const getRuleBuilderOptionsEpic = (action$) =>
	action$.pipe(
		ofType(getRuleBuilderOptions),
		mergeMap(async (action) => {
			await refreshToken();

			const response = await axios.get(`${primaryRestGateway()}/api/rules/builder/options`);

			return response.data;
		}),
		switchMap((res) => [getRuleBuilderOptionsCompleted(res)]),
		catchError((error, source) =>
			merge(of(getRuleBuilderOptionsFailed(error.message)), source)
		)
	);

export const createUserRuleEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.CREATE_RULE),
		mergeMap(async (action) => {
			await refreshToken();

			const {
				newRuleConfiguration
			} = action.payload;

			await axios.post(
				`${primaryRestGateway()}/api/rules`,
				newRuleConfiguration
			);
		}),
		switchMap((res) => [
			actions.createRuleCompleted()
		]),
		catchError((error, source) =>
			merge(of(actions.createRuleFailed(error.message)), source)
		)
	);

export const updateUserRuleEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.UPDATE_RULE),
		mergeMap(async (action) => {
			await refreshToken();

			const {
				userRuleId,
				updatedRuleConfiguration
			} = action.payload;

			await axios.put(
				`${primaryRestGateway()}/api/rules/rule/${userRuleId}`,
				updatedRuleConfiguration
			);
		}),
		switchMap((res) => [
			actions.updateRuleCompleted()
		]),
		catchError((error, source) =>
			merge(of(actions.updateRuleFailed(error.message)), source)
		)
	);

export const getActiveRulesByGroup = (action$) =>
	action$.pipe(
		ofType(actionTypes.GET_ACTIVE_RULES_BY_GROUP),
		mergeMap(async (action) => {
			await refreshToken();

			const { groupId } = action.payload;

			const response = await axios.get(
				`${primaryRestGateway()}/api/rules/search?groupId=${groupId}`
			);

			return response.data;
		}),
		switchMap((res) => [
			actions.getActiveRulesByGroupCompleted(res)
		]),
		catchError((error, source) =>
			merge(of(actions.getActiveRulesByGroupFailed(error)), source)
		)
	);

// USED FOR DELETING A RULE ON THE GROUPS PAGE
export const deleteActiveRuleEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.DELETE_ACTIVE_RULE),
		mergeMap(async (action) => {
			await refreshToken();

			const { groupId, userRuleId } = action.payload;

			await axios.delete(
				`${primaryRestGateway()}/api/rules/rule/${userRuleId}`
			);

			return { groupId };
		}),
		switchMap((res) => [
			actions.deleteActiveRuleCompleted(),
			actions.getActiveRulesByGroup(res)
		]),
		catchError((error, source) =>
			merge(of(actions.deleteActiveRuleFailed(error)), source)
		)
	);
