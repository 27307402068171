import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import querier from '../../utilities/querier';
import { formatDateForDisplay } from '../../utilities/dateWrapper';

import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

export const ActionRow = (props) => {
	const {
		t,
		representmentData,
		onMarkAsSentToProcessor,
		onBuildDocument,
		isLoading
	} = props;

	const sentToProcessor = querier(
		representmentData,
		'chargebackReversal.sentToProcessorOn'
	);

	return (
		<Stack
			direction='row'
			sx={{ height: '40px' }}
			justifyContent='flex-end'
			spacing={2}
			px={2}
		>
			<LoadingButton
				variant='outlined'
				loading={isLoading}
				disabled={!!sentToProcessor}
				onClick={onMarkAsSentToProcessor}
			>
				{sentToProcessor
					? `${t('sentToProcessor')} ${formatDateForDisplay(sentToProcessor)}` : t('sendToProcessor')}
			</LoadingButton>
			<Button variant='outlined' onClick={onBuildDocument}>
				{t('buildDocument')}
			</Button>
		</Stack>
	);
};

ActionRow.propTypes = {
	representmentData: PropTypes.object,
	isLoading: PropTypes.bool,
	onMarkAsSentToProcessor: PropTypes.func,
	onBuildDocument: PropTypes.func,
	t: PropTypes.func
};

export default withTranslation('representments')(ActionRow);
