import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { withTranslation } from 'react-i18next';
import queryString from 'query-string';
import ReportHeader from '../../Report/ReportHeader';
import generateMaxDate from '../../../helpers/reports/generateMaxDate';
import setStateFromUrlParameter from '../../../helpers/reporter/setStateFromUrlParameter';
import { StandardStyledGrid } from '../../ReportStylings';

import ProcessorSelect from '../../Report/FilterController/components/ProcessorSelect';
import DateSelect from '../../Report/FilterController/components/DateSelect';
import MerchantSelect from '../../Report/FilterController/components/MerchantSelect';

import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import convertUrlDateToDateSelect from '../../../helpers/shared/convertUrlDateToDateSelect';

const FilterController = (props) => {
	const { t, onGetReportData, reportId, isLoading } = props;

	const [lookupDate, setLookupDate] = React.useState(dayjs().add(-1, 'days'));
	const [selectedProcessor, setSelectedProcessor] = React.useState('');
	const [selectedMerchants, setSelectedMerchants] = React.useState([]);
	const [urlMerchants, setUrlMerchants] = React.useState([]);
	const [allMerchantsSelected, setAllMerchantsSelected] = React.useState(false);

	const location = useLocation();

	const getReportData = (lookupDate, processorId, formattedMids) => {
		onGetReportData(lookupDate, processorId, formattedMids, reportId);
	};

	const submitFilters = () => {
		const reportViewId = location.pathname.split('/')[2];

		const formattedMids = selectedMerchants
			.map((merchant) => merchant.merchantId)
			.join(',');

		window.history.replaceState(
			null,
			'Slyce360',
			`/reports/${reportViewId}?processorId=${selectedProcessor}&lookupDate=${dayjs(
				lookupDate
			).format('YYYY-MM-DD')}&merchantIds=${
				allMerchantsSelected ? 'all' : formattedMids
			}`
		);
		getReportData(
			dayjs(lookupDate).format('YYYY-MM-DD'),
			selectedProcessor,
			allMerchantsSelected ? 'all' : formattedMids
		);
	};

	React.useEffect(() => {
		const {
			processorId,
			lookupDate,
			merchantIds,
			autoApply
		} = queryString.parse(location.search);

		setStateFromUrlParameter(processorId, setSelectedProcessor);
		setStateFromUrlParameter(
			lookupDate,
			setLookupDate,
			convertUrlDateToDateSelect
		);

		if (merchantIds) {
			const splitMids = merchantIds.split(',');

			setUrlMerchants(splitMids);

			if (merchantIds === 'all') {
				setAllMerchantsSelected(true);
			}
		}

		if (autoApply && processorId && lookupDate && merchantIds) {
			getReportData(
				convertUrlDateToDateSelect(lookupDate),
				processorId,
				merchantIds
			);
		}
	}, [location]);

	const updateDateSelect = (newValue, callbackFunction) => {
		const formattedValue = dayjs(newValue);

		callbackFunction(formattedValue);
	};

	return (
		<Paper>
			<Stack direction='column' p={1} spacing={2}>
				<Stack direction='row' justifyContent='space-between' my={1}>
					<ReportHeader
						label={t('reports:twelveMonthChargebackCompliance.label')}
					/>
				</Stack>
				<Grid
					container
					rowSpacing={2}
					columnSpacing={1}
					p={1}
					alignItems='flex-start'
					justifyContent='flex-end'
				>
					<StandardStyledGrid item>
						<DateSelect
							translationPath={'reports:filterController.lookupDate'}
							value={lookupDate}
							onChange={(newValue) => updateDateSelect(newValue, setLookupDate)}
							maxDate={generateMaxDate(1)}
						/>
					</StandardStyledGrid>
					<StandardStyledGrid item>
						<ProcessorSelect
							onChange={(newSelection) => {
								setSelectedProcessor(newSelection);
							}}
							translationPath={'reports:filterController.processor'}
							value={selectedProcessor}
							dataSource={'processors'}
						/>
					</StandardStyledGrid>
					<Grid item>
						<MerchantSelect
							onChange={(newSelection) => {
								setSelectedMerchants(newSelection);
							}}
							translationPath={'reports:filterController.merchant'}
							value={selectedMerchants}
							dataSource={'merchants'}
							selectedProcessor={selectedProcessor}
							disabled={selectedProcessor === ''}
							widthOverride={'300px'}
							urlMerchants={urlMerchants}
							setUrlMerchants={setUrlMerchants}
							allMerchantsSelected={allMerchantsSelected}
							setAllMerchantsSelected={setAllMerchantsSelected}
						/>
					</Grid>
					<Grid item>
						<LoadingButton
							loading={isLoading}
							disabled={
								!lookupDate ||
								selectedProcessor === '' ||
								selectedMerchants.length < 1
							}
							variant='contained'
							sx={{ height: '50px' }}
							onClick={submitFilters}
						>
							{t('common:buttons.submit')}
						</LoadingButton>
					</Grid>
				</Grid>
			</Stack>
		</Paper>
	);
};

FilterController.propTypes = {
	t: PropTypes.func,
	onGetReportData: PropTypes.func,
	reportId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	isLoading: PropTypes.bool
};

export default withTranslation(['common', 'reports'])(FilterController);
