import React from 'react';

import Skeleton from '@mui/material/Skeleton';

import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

const TimelineSkeleton = () => {
	return (
		<TimelineItem sx={{ mt: '20px' }}>
			<TimelineOppositeContent sx={{ flex: 0.1 }} />
			<TimelineSeparator sx={{ ml: '10px' }}>
				<TimelineDot />
				<TimelineConnector sx={{ minHeight: '40px' }} />
			</TimelineSeparator>
			<TimelineContent>
				<Skeleton variant='box' width={300} height={46} />
			</TimelineContent>
		</TimelineItem>
	);
};

export default TimelineSkeleton;
