import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { layoutActions } from '../../containers/Layouts';
import { tableauActions } from '../../containers/Tableau';
import { tableauServer } from '../../utilities/apiEndpointUtility';
import { withTranslation } from 'react-i18next';
import PageError from '../errors/PageError';
import tableauTimeout from '../../constants/tableauTimeout';

import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

const TableauDashboard = (props) => {
	const {
		error,
		generateToken,
		getDefaultLayout,
		layoutIsLoading,
		siteId,
		token,
		tokenHasLoaded,
		url,
		layoutError,
		t,
		resetToken
	} = props;

	const [vizSetupCompleted, setVizSetupCompleted] = React.useState(false);

	const tableauRef = React.useRef(null);

	React.useEffect(() => {
		if (!token && !tokenHasLoaded) {
			generateToken();
		}

		return () => {
			resetToken();
		};
	}, []);

	React.useEffect(() => {
		if (!url && !layoutIsLoading) {
			getDefaultLayout();
		}
	}, []);

	React.useEffect(() => {
		const runner = () => {
			if (tableauRef.current) {
				generateToken();
			}
			setTimeout(() => {
				runner();
			}, tableauTimeout.timeoutInMilliseconds);
		};

		runner();
	}, [tableauRef]);

	React.useEffect(() => {
		if (!vizSetupCompleted && siteId && token && url) {
			setVizSetupCompleted(true);
		} else if (vizSetupCompleted) {
			const viz = document.getElementById('tableau-viz');

			if (viz) {
				viz.src = `${tableauServer()}${siteId}${url}`;
				viz.token = token;

				setVizSetupCompleted(true);
			}
		}
	}, [url, token, vizSetupCompleted]);

	if (layoutError) {
		return <PageError errorText={t('common:error.dashboardError')} />;
	}

	if (error) {
		return <PageError />;
	}

	if (!vizSetupCompleted) {
		return (
			<Stack
				direction='column'
				justifyContent='center'
				alignItems='center'
				sx={{ width: '100%', height: '100%' }}
			>
				<CircularProgress />
			</Stack>
		);
	}

	return (
		<tableau-viz
			id='tableau-viz'
			ref={tableauRef}
			style={{
				width: '100%',
				height: '100%',
				position: 'absolute'
			}}
			toolbar='hidden'
		/>
	);
};

TableauDashboard.propTypes = {
	error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	generateToken: PropTypes.func,
	getDefaultLayout: PropTypes.func,
	layoutIsLoading: PropTypes.bool,
	tokenIsLoading: PropTypes.bool,
	tokenHasLoaded: PropTypes.bool,
	siteId: PropTypes.string,
	token: PropTypes.string,
	url: PropTypes.string,
	layoutError: PropTypes.string,
	t: PropTypes.func,
	resetToken: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		error: state.tableauReducer.get('error'),
		layoutError: state.layoutReducer.get('error'),
		url: state.layoutReducer.get('url'),
		layoutIsLoading: state.layoutReducer.get('isLoading'),
		tokenHasLoaded: state.tableauReducer.get('hasBeenLoaded'),
		tokenIsLoading: state.tableauReducer.get('isLoading'),
		siteId: state.tableauReducer.get('siteId'),
		token: state.tableauReducer.get('token')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		generateToken: (payload) => {
			dispatch(tableauActions.generateToken(payload));
		},
		getDefaultLayout: (payload) => {
			dispatch(layoutActions.getDefaultLayout(payload));
		},
		resetToken: (payload) => {
			dispatch(tableauActions.resetToken(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation('common')(TableauDashboard));
