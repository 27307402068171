export const displaySections = [
	'overview',
	'chargebackInformation',
	'customerInformation',
	'debitTransaction',
	'customerContact',
	'refundInformation',
	'ethocaAlerts',
	'cdrns',
	'previouslyBilledUndisputedTransactions',
	'proofOfDelivery'
];

export const displaySectionLabels = {
	overview: 'overview.label',
	customerContact: 'customerContact.label',
	customerInformation: 'customerInformation.label',
	chargebackInformation: 'chargebackInformation.label',
	debitTransaction: 'transactionDetails.label',
	refundInformation: 'refundInformation.label',
	ethocaAlerts: 'ethocaAlerts.label',
	cdrns: 'cdrns.label',
	previouslyBilledUndisputedTransactions: 'transactionsList.label',
	proofOfDelivery: 'proofOfDelivery.label'
};
