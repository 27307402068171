import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { withTranslation } from 'react-i18next';
import StageAccordion from '../StageAccordion';
import { groupBuildTypesList } from '../../constants/groupBuildTypes';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const BaseStack = styled(Stack)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	padding: theme.spacing(2),
	margin: theme.spacing(1),
	borderRadius: 5
}));

const UnselectedOptionStack = styled(BaseStack)(({ theme }) => ({
	border: `2px solid ${theme.palette.secondary.main}`,
	color: theme.palette.primary.contrastText,
	cursor: 'pointer',
	'& .icon, .label': {
		color: theme.palette.secondary.main
	},
	'&:hover': {
		backgroundColor: theme.palette.primary.light,
		borderColor: theme.palette.primary.light,
		'& .icon, .label': {
			color: theme.palette.primary.contrastText
		}
	}
}));

const SelectedOptionStack = styled(BaseStack)(({ theme }) => ({
	border: `2px solid ${theme.palette.primary.light}`,
	backgroundColor: theme.palette.primary.light,
	color: theme.palette.primary.contrastText,
	cursor: 'pointer',
	'& .icon, .label': {
		color: theme.palette.primary.contrastText
	}
}));

const DisabledOptionStack = styled(BaseStack)(({ theme }) => ({
	border: `2px solid ${theme.palette.secondary.light}`,
	opacity: 0.4,
	'& .icon': {
		color: theme.palette.secondary.light
	},
	'& .label': {
		color: theme.palette.secondary.light
	}
}));

export const BuildStyleSelect = (props) => {
	const {
		t,
		completedLabel,
		isStageCompleted,
		selectedBuildType,
		setSelectedBuildType,
		isStageDisabled
	} = props;

	const [isStageExpanded, setIsStageExpanded] = React.useState(true);

	const onSelectBuildType = (buildType) => {
		setSelectedBuildType(buildType);
	};

	return (
		<StageAccordion
			stageLabel={t('groupWizard.buildStyleSelect.groupType')}
			collapsedSummaryText={completedLabel}
			isAccordionExpanded={isStageExpanded}
			setisAccordionExpanded={setIsStageExpanded}
			isCompletedFlag={isStageCompleted}
			isDisabled={isStageDisabled}
		>
			<Stack direction='column' spacing={1}>
				<Grid
					container
					columnSpacing={2}
					justifyContent='center'
					sx={{ width: '100%' }}
				>
					{groupBuildTypesList.map((buildType) => {
						let StyleSelectStack = selectedBuildType?.id === buildType.id ? SelectedOptionStack : UnselectedOptionStack;

						if (isStageDisabled && selectedBuildType?.id !== buildType.id) {
							StyleSelectStack = DisabledOptionStack;
						}

						return (
							<Grid item key={buildType.id} sx={{ flex: 1 }}>
								<StyleSelectStack
									direction='column'
									padding={2}
									justifyContent='center'
									alignItems='center'
									onClick={
										isStageDisabled ? null : () => onSelectBuildType(buildType)
									}
								>
									<Typography>
										<buildType.icon
											className='icon'
										/>
									</Typography>
									<Typography className='label'>{t(buildType.labelTranslationPath)}</Typography>
								</StyleSelectStack>
							</Grid>
						);
					})}
				</Grid>
			</Stack>
		</StageAccordion>
	);
};

BuildStyleSelect.propTypes = {
	t: PropTypes.func,
	completedLabel: PropTypes.string,
	isStageCompleted: PropTypes.bool,
	selectedBuildType: PropTypes.object,
	setSelectedBuildType: PropTypes.func,
	isStageDisabled: PropTypes.bool
};

export default withTranslation('groups')(BuildStyleSelect);
