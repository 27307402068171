import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { capitalCase } from 'change-case';
import { getUsers } from '../../../containers/Users/usersSlice';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';

import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

export const AssignedUserSelect = ({ t, isDisabled, selectedUser, onChangeSelection, includeAll }) => {
	const dispatch = useDispatch();

	const {
		data,
		isLoading,
		error
	} = useSelector(state => state.usersSlice.users);

	React.useEffect(() => {
		if (!isLoading && !error) {
			dispatch(getUsers());
		}
	}, []);

	return (
		<FormControl sx={{ flex: 1 }} fullWidth>
			<InputLabel id='assigned-user-select-label'>
				{t('notifications:assignedUser')}
			</InputLabel>
			<Select
				labelId='assigned-user-select-label'
				label={t('notifications:assignedUser')}
				id='assigned-user-select-select'
				variant='outlined'
				value={selectedUser?.id ?? selectedUser}
				disabled={isLoading || isDisabled}
				onChange={(e) => {
					let newValue = e.target.value;

					if (newValue !== 'all' && newValue !== 'none') {
						newValue = data.find((user) => {
							return user?.id === newValue;
						});
					}
					onChangeSelection(newValue);
				}}
				renderValue={(value) => {
					let rendered = '';

					if (value === 'all' && includeAll) {
						rendered = capitalCase(t('common:all'));
					} else if (!value || value === 'none') {
						rendered = capitalCase(t('notifications:unassigned'));
					} else {
						const matchedUser = data.find((user) => {
							return user?.id === value;
						});

						if (matchedUser) {
							rendered = `${matchedUser?.firstName} ${matchedUser?.lastName}`;
						} else {
							rendered = t('common:loadingEllipsis');
						}
					}

					return rendered;
				}}
				endAdornment={
					<IconButton
						sx={{ display: selectedUser?.id ? 'block' : 'none', mr: 1 }}
						onClick={() => onChangeSelection(includeAll ? 'all' : 'none')}
						disabled={isDisabled}
					>
						<ClearOutlinedIcon />
					</IconButton>
				}
			>
				{ includeAll
					? <MenuItem value={'all'}>
						<Stack direction='row' spacing={2}>
							<Box width={35} />
							<Typography>{capitalCase(t('common:all'))}</Typography>
						</Stack>
					</MenuItem>
					: null
				}
				<MenuItem value={'none'}>
					<Stack direction='row' spacing={2}>
						<Box width={35} />
						<Typography>{capitalCase(t('notifications:unassigned'))}</Typography>
					</Stack>
				</MenuItem>
				{data?.map((user) => {
					return (
						<MenuItem key={user.id} value={user.id}>
							<Stack direction='row' spacing={2}>
								<Box width={35}>
									<Avatar alt={user.firstName} src={null} >{user.firstName.split('')[0]}</Avatar>
								</Box>
								<Stack>
									<Typography>{`${user.firstName} ${user.lastName}`}</Typography>
									<Typography variant='caption' color='primary'>{user.username}</Typography>
								</Stack>
							</Stack>
						</MenuItem>
					);
				})}
			</Select>
		</FormControl>
	);
};

AssignedUserSelect.propTypes = {
	selectedUser: PropTypes.oneOfType([PropTypes.object, PropTypes.number, PropTypes.string]),
	onChangeSelection: PropTypes.func,
	overrideLabel: PropTypes.string,
	t: PropTypes.func,
	includeAll: PropTypes.bool,
	isDisabled: PropTypes.bool
};

export default withTranslation(['users', 'common'])(AssignedUserSelect);
