import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import PageHeader from '../../components/headers/PageHeader';
import UsersManager from '../../components/Users/UsersManager';
import InviteUserModal from '../../components/modals/InviteUserModal';
import Restricted from '../../permissionProvider/Restricted';
import RestrictedFallback from '../../components/shared/RestrictedFallback';

import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';

export const Users = ({ t }) => {
	return (
		<Container maxWidth={false}>
			<Restricted
				to='users.usersList.view'
				Fallback={
					<RestrictedFallback overrideMessage={t('error.notAllowed')} />
				}
			>
				<Stack spacing={2} p={2}>
					<PageHeader label={t('users:users')} />
					<UsersManager />
					<InviteUserModal />
				</Stack>
			</Restricted>
		</Container>
	);
};

Users.propTypes = {
	t: PropTypes.func
};

export default withTranslation(['users', 'common'])(Users);
