export const measurements = {
	units: 'units',
	dollars: 'dollars'
};

export const comparisonUnits = {
	number: 'number',
	percent: 'percent'
};

export const standardPrefixes = [
	'',
	'(',
	') for',
	'are',
	'',
	'within'
];

export const trendingComparator = 'trendingComparator';
export const trendingComparatorPrefix = 'have';
export const trendingTimeFrame = 'trendingTimeFrame';
