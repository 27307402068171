import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';

import Typography from '@mui/material/Typography';

import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

const StyledDiv = styled('div')({
	display: 'flex',
	flexDirection: 'column',
	paddingLeft: '20px',
	maxWidth: '900px',
	width: 'fit-content'
});

const SystemDetail = (props) => {
	const { detail } = props;

	return (
		<TimelineItem sx={{ mt: '20px' }}>
			<TimelineOppositeContent sx={{ flex: 0.1 }}>
				<Typography variant='caption'>
					{dayjs(detail.createdOn).format('hh:mm A')}
				</Typography>
			</TimelineOppositeContent>
			<TimelineSeparator sx={{ ml: '10px' }}>
				<TimelineDot />
				<TimelineConnector sx={{ minHeight: '40px' }} />
			</TimelineSeparator>
			<TimelineContent>
				<StyledDiv>
					<Typography variant='body2'>{detail.message}</Typography>
				</StyledDiv>
			</TimelineContent>
		</TimelineItem>
	);
};

SystemDetail.propTypes = {
	detail: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

export default SystemDetail;
