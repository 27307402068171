import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { noCase } from 'change-case';
import TimelineIcon from './TimelineIcon';
import { notificationSeverityLevelsNamed } from '../../../../constants/notificationSeverityLevels';

import TimelineDot from '@mui/lab/TimelineDot';

const OutlinedTimelineDot = styled(TimelineDot)(({ theme }) => ({
	backgroundColor: theme.palette.common.white
}));

const NotificationTimelineDot = ({ level, iconType }) => {
	const color = notificationSeverityLevelsNamed[noCase(level)].colorThemePath;

	return (
		<OutlinedTimelineDot variant='outlined'>
			{TimelineIcon(iconType, color)}
		</OutlinedTimelineDot>
	);
};

NotificationTimelineDot.propTypes = {
	level: PropTypes.string,
	iconType: PropTypes.string
};

export default NotificationTimelineDot;
