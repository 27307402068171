import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import TableCell from '@mui/material/TableCell';

export const StaticTableCell = (props) => {
	const { t, translationContext, headCell } = props;

	return (
		<TableCell
			align={headCell.align || 'center'}
			padding={headCell.disablePadding ? 'none' : 'normal'}
			sx={{ pb: 1 }}
		>
			{headCell.labelTranslationPath
				? t(`${translationContext}:${headCell.labelTranslationPath}`)
				: ''}
		</TableCell>
	);
};

StaticTableCell.propTypes = {
	headCell: PropTypes.object,
	translationContext: PropTypes.string,
	t: PropTypes.func
};

export default withTranslation()(StaticTableCell);
