import * as actionTypes from './actionTypes';

export const getOrganization = (payload) => ({
	type: actionTypes.GET_ORGANIZATION,
	payload
});

export const getOrganizationCompleted = (payload) => ({
	type: actionTypes.GET_ORGANIZATION_COMPLETED,
	payload
});

export const getOrganizationFailed = (payload) => ({
	type: actionTypes.GET_ORGANIZATION_FAILED,
	payload
});

export const updateOrganization = (payload) => ({
	type: actionTypes.UPDATE_ORGANIZATION,
	payload
});

export const updateOrganizationCompleted = (payload) => ({
	type: actionTypes.UPDATE_ORGANIZATION_COMPLETED,
	payload
});

export const updateOrganizationFailed = (payload) => ({
	type: actionTypes.UPDATE_ORGANIZATION_FAILED,
	payload
});

export const getOrganizationUsers = (payload) => ({
	type: actionTypes.GET_ORGANIZATION_USERS,
	payload
});

export const getOrganizationUsersCompleted = (payload) => ({
	type: actionTypes.GET_ORGANIZATION_USERS_COMPLETED,
	payload
});

export const getOrganizationUsersFailed = (payload) => ({
	type: actionTypes.GET_ORGANIZATION_USERS_FAILED,
	payload
});
