import React from 'react';
import PropTypes from 'prop-types';

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export const StageAccordion = (props) => {
	const {
		stageLabel,
		isCompletedFlag,
		isDisabled,
		children,
		hasError
	} = props;

	return (
		<Stack
			component={Paper}
			disabled={isDisabled}
			sx={{ maxWidth: '900px', width: '100%', border: hasError && 1, borderColor: hasError && 'error.main' }}
		>
			<Stack direction='row' p={1} ml={1}>
				{isCompletedFlag && <CheckCircleIcon color='success' sx={{ mr: '3px', alignSelf: 'center', fontSize: '1.2rem' }} />}
				<Typography variant='overline'>{stageLabel}</Typography>
			</Stack>
			<Box p={1}>{children}</Box>
		</Stack>
	);
};

StageAccordion.propTypes = {
	stageLabel: PropTypes.string,
	isCompletedFlag: PropTypes.bool,
	isDisabled: PropTypes.bool,
	children: PropTypes.element,
	hasError: PropTypes.bool
};

export default StageAccordion;
