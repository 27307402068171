import { createSlice } from '@reduxjs/toolkit';

export const initialState = {};

export const rulesRunnerTrackedSlice = createSlice({
	name: 'rulesRunnerTracked',
	initialState,
	reducers: {
		// ONLY NEED ACTIONS - TRACKED VIA SOCKET
		runTrackedUserRule: () => {},
		runTrackedRuleCompleted: () => {},
		runTrackedRuleFailed: () => {}
	}
});

export const {
	runTrackedUserRule,
	runTrackedRuleCompleted,
	runTrackedRuleFailed

} = rulesRunnerTrackedSlice.actions;

export default rulesRunnerTrackedSlice.reducer;
