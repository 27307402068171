import React from 'react';
import { styled } from '@mui/material/styles';
import RepresentmentViewer from '../../components/RepresentmentViewer';

import Container from '@mui/material/Container';

const StyledContainer = styled(Container)({
	display: 'flex',
	flexFlow: 'column',
	height: '100%',
	padding: '0px 40px'
});

export const RepresentmentViewerPage = () => {
	return (
		<StyledContainer maxWidth={false}>
			<RepresentmentViewer />
		</StyledContainer>
	);
};

export default RepresentmentViewerPage;
