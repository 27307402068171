import * as actionTypes from './actionTypes';

export const getDataSource = (payload) => ({
	type: actionTypes.GET_DATA_SOURCE,
	payload
});

export const getDataSourceCompleted = (payload) => ({
	type: actionTypes.GET_DATA_SOURCE_COMPLETED,
	payload
});

export const getDataSourceFailed = (payload) => ({
	type: actionTypes.GET_DATA_SOURCE_FAILED,
	payload
});

export const clearDataSources = (payload) => ({
	type: actionTypes.CLEAR_DATA_SOURCES,
	payload
});
