import React from 'react';
import dayjs from 'dayjs';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import RuleCategorySelect from '../../rules/RuleCategorySelect';
import LevelSelect from '../../rules/LevelSelect';
import NotificationStatusSelect from '../NotificationStatusSelect';
import DateSelect from '../../inputs/DateSelect';
import OutlinedLoadingButton from '../../buttons/OutlinedLoadingButton';
import AssignedUserSelect from '../AssignedUserSelect';
import { getNotifications, clearNotifications } from '../../../containers/Notifications/notificationsSlice';

import Grid from '@mui/material/Grid';
import Pagination from '@mui/material/Pagination';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

export const NotificationListFilters = ({ t }) => {
	const dispatch = useDispatch();

	const [startDate, setStartDate] = React.useState(dayjs().subtract(30, 'days'));
	const [endDate, setEndDate] = React.useState(dayjs());
	const [selectedAssignedUser, setSelectedAssignedUser] = React.useState('all');
	const [selectedRuleCategory, setSelectedRuleCategory] = React.useState('all');
	const [selectedLevel, setSelectedLevel] = React.useState('all');
	const [selectedNotificationStatus, setSelectedNotificationStatus] = React.useState('all');

	const {
		isLoading,
		currentPage,
		availablePageCount
	} = useSelector(state => state.notificationsSlice.notifications);

	React.useEffect(() => {
		onGetNotifications(1);

		return () => {
			dispatch(clearNotifications());
		};
	}, []);

	const onGetNotifications = (page) => {
		dispatch(getNotifications(
			{
				startDate: dayjs(startDate).format('YYYY-MM-DD'),
				endDate: dayjs(endDate).format('YYYY-MM-DD'),
				severity: selectedLevel?.name,
				category: selectedRuleCategory?.name,
				status: selectedNotificationStatus !== 'all' ? selectedNotificationStatus : undefined,
				assignedUser: selectedAssignedUser?.id,
				resultsPerPage: 10,
				page,
				notAssigned: selectedAssignedUser === 'none' ? true : undefined
			}
		));
	};

	const	onClickApply = () => {
		onGetNotifications(1);
	};

	const handlePageChange = (event, value) => {
		onGetNotifications(value);
	};

	return (
		<Stack spacing={2} m={2}>
			<Grid component={Paper} elevation={1} container alignItems='center' p={1} spacing={1}>
				<Grid item xs={12} sm={12} md={4} lg={2}>
					<DateSelect
						value={startDate}
						onChangeDate={setStartDate}
						disabledFuture
						maxDate={endDate}
						label={t('notifications:lastUpdatedStart')}
					/>
				</Grid>
				<Grid item xs={12} sm={12} md={4} lg={2}>
					<DateSelect
						value={endDate}
						onChangeDate={setEndDate}
						disabledFuture
						minDate={startDate}
						label={t('notifications:lastUpdatedEnd')}
					/>
				</Grid>
				<Grid item xs={12} sm={12} md={4} lg={2}>
					<AssignedUserSelect
						selectedUser={selectedAssignedUser}
						onChangeSelection={setSelectedAssignedUser}
						includeAll={true}
					/>
				</Grid>
				<Grid item xs={12} sm={12} md={4} lg={2}>
					<RuleCategorySelect
						selectedRuleCategory={selectedRuleCategory}
						onChangeSelection={setSelectedRuleCategory}
					/>
				</Grid>
				<Grid item xs={12} sm={12} md={4} lg={2}>
					<LevelSelect
						selectedLevel={selectedLevel}
						onChangeSelection={setSelectedLevel}
					/>
				</Grid>
				<Grid item xs={12} sm={12} md={4} lg={2}>
					<NotificationStatusSelect
						selectedNotificationStatus={selectedNotificationStatus}
						onChangeSelection={setSelectedNotificationStatus}
					/>
				</Grid>
				<Grid item xs={0} sm={0} md={9} lg={10} sx={{ display: { xs: 'none', md: 'block', lg: 'block' } }} />
				<Grid item xs={12} sm={12} md={3} lg={2}>
					<OutlinedLoadingButton onClick={onClickApply} loading={isLoading}>
						{t('common:buttons.submit')}
					</OutlinedLoadingButton>
				</Grid>
			</Grid>
			<Stack direction='row' justifyContent='flex-end'>
				<Pagination count={availablePageCount} page={currentPage} onChange={handlePageChange} color='primary' />
			</Stack>
		</Stack>
	);
};

NotificationListFilters.propTypes = {
	t: PropTypes.func
};

export default withTranslation(['notifications', 'common'])(NotificationListFilters);
