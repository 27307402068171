export default [
	'caseId',
	'billingLastName',
	'amount',
	'paymentCardBrand',
	'paymentCardLast4',
	'billingDescriptor',
	'chargebackDate',
	'code',
	'expiration'
];
