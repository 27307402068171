import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import ContainedButton from '../../buttons/ContainedButton';

import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import CloseIcon from '@mui/icons-material/Close';

const ModalBody = (props) => {
	const { handleOnClose, onDelete, selectUserInfo, t } = props;

	return (
		<React.Fragment>
			<Stack direction='row' pt={2} px={2} justifyContent='flex-end' alignItems='center'>
				<IconButton onClick={handleOnClose}>
					<CloseIcon />
				</IconButton>
			</Stack>
			<Stack justifyContent='space-around' alignItems='center' spacing={2}>
				<Typography variant='h6' >
					{t('deleteUserModal.subHeader')}
				</Typography>
				<Typography variant='h6'>
					{`${selectUserInfo?.firstName} ${selectUserInfo?.lastName}`}
				</Typography>
				<Typography sx={{ px: 2 }}>
					{t('deleteUserModal.description')}
				</Typography>
				<Stack direction='row' justifyContent='flex-end' sx={{ p: 2, width: '100%' }}>
					<ContainedButton onClick={onDelete}>
						{t('deleteUserModal.deleteUser')}
					</ContainedButton>
				</Stack>
			</Stack>
		</React.Fragment>
	);
};

ModalBody.propTypes = {
	handleOnClose: PropTypes.func,
	onDelete: PropTypes.func,
	selectUserInfo: PropTypes.object,
	t: PropTypes.func
};

export default withTranslation('modals')(ModalBody);
