import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import AnalyticsIcon from '@mui/icons-material/Analytics';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import ErrorIcon from '@mui/icons-material/Error';

import './loading.css';

export const LoadingTimeline = (props) => {
	const {
		t,
		documentError,
		documentDetailsHaveBeenLoaded,
		documentDetailsAreLoading,
		pdfIsLoading,
		pdfHasBeenLoaded
	} = props;

	if (documentError) {
		return (
			<Stack sx={{ width: '100%', height: '100%' }} justifyContent='center' alignItems='center'>
				<ErrorIcon sx={{ fontsize: '3rem' }} color='error'/>
				<Typography
					variant='overline'
					sx={{ fontWeight: 700, fontFamily: 'monospace', fontSize: '1.5rem' }}
				>
					{`${t('viewer.notReadyToDisplay')}`}
				</Typography>
				<Typography variant='caption'>{`${t('viewer.pleaseRefresh')}`}</Typography>
			</Stack>
		);
	}

	if (documentDetailsAreLoading && !documentDetailsHaveBeenLoaded) {
		return (
			<Stack sx={{ width: '100%', height: '100%' }} justifyContent='center' alignItems='center'>
				<AnalyticsIcon sx={{ fontSize: '3rem' }} color='primary' />
				<Typography variant='overline' className='loadingElipses'>{`${t('viewer.generatingDocument')}...`}</Typography>
			</Stack>
		);
	}

	if (pdfIsLoading || !pdfHasBeenLoaded) {
		return (
			<Stack sx={{ width: '100%', height: '100%' }} justifyContent='center' alignItems='center'>
				<HourglassBottomIcon sx={{ fontSize: '3rem' }} color='primary' />
				<Typography variant='overline' className='loadingElipses'>{`${t('viewer.loadingDocument')}...`}</Typography>
			</Stack>
		);
	}
};

LoadingTimeline.propTypes = {
	t: PropTypes.func,
	documentError: PropTypes.string,
	documentDetailsHaveBeenLoaded: PropTypes.bool,
	documentDetailsAreLoading: PropTypes.bool,
	pdfIsLoading: PropTypes.bool,
	pdfHasBeenLoaded: PropTypes.bool
};

export default withTranslation('representments')(LoadingTimeline);
