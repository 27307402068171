import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import ContainedButton from '../../../buttons/ContainedButton';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export const SuccessPage = (props) => {
	const { t, onCloseModal } = props;

	const onClose = () => {
		onCloseModal();
	};

	return (
		<React.Fragment>
			<Stack alignItems='center' spacing={2}>
				<Typography variant='h5'>{t('modals:multiFactorAuth.allSetUp')}</Typography>
				<Box>
					<CheckCircleIcon color='success' sx={{ fontSize: '2rem' }} />
				</Box>
			</Stack>
			<Stack direction='row' justifyContent='flex-end'>
				<ContainedButton
					onClick={onClose}
				>
					{t('common:buttons.close')}
				</ContainedButton>
			</Stack>
		</React.Fragment>
	);
};

SuccessPage.propTypes = {
	onCloseModal: PropTypes.func,
	authUserSession: PropTypes.func,
	t: PropTypes.func
};

export default withTranslation(['common', 'modals'])(SuccessPage);
