import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import querier from '../../../../../utilities/querier';
import StageAccordion from '../../StageAccordion';
import deepCopy from '../../../../../utilities/deepCopy';
import ruleListTypes from '../../../constants/ruleListTypes';
import BuildListSelectOption from './components/BuildListSelectOption';
import AffiliateSelect from '../../../../affiliates/AfilliateSelect';
import {
	ProcessorSelectInput,
	GroupSelectInput,
	MerchantSelectInput
} from '../../inputs';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

export const BuildListSelect = (props) => {
	const {
		t,
		completedLabel,
		isStageCompleted,
		ruleConfiguration,
		setRuleConfiguration,
		temporaryListValues,
		setTemporaryListValues,
		isStageDisabled,
		submitErrors,
		setSubmitErrors
	} = props;

	const renderListSelect = (
		ruleConfiguration,
		onUpdateListSelect,
		temporaryListValues,
		setTemporaryListValues,
		isStageDisabled
	) => {
		if (ruleConfiguration.ruleListType?.name === ruleListTypes[0].name) {
			return (
				<GroupSelectInput
					value={ruleConfiguration.group}
					onChange={(value) => {
						onUpdateListSelect('group', value);
					}}
					temporaryListValues={temporaryListValues}
					setTemporaryListValues={setTemporaryListValues}
					isStageDisabled={isStageDisabled}
				/>
			);
		} else if (ruleConfiguration.ruleListType?.name === ruleListTypes[1].name) {
			return (
				<ProcessorSelectInput
					value={ruleConfiguration.processor}
					onChange={(value) => {
						onUpdateListSelect('processor', value);
					}}
					temporaryListValues={temporaryListValues}
					setTemporaryListValues={setTemporaryListValues}
					isStageDisabled={isStageDisabled}
				/>
			);
		} else if (ruleConfiguration.ruleListType?.name === ruleListTypes[2].name) {
			return (
				<MerchantSelectInput
					value={ruleConfiguration.merchantIds}
					onChange={(value) => {
						onUpdateListSelect('merchantIds', value);
					}}
					temporaryListValues={temporaryListValues}
					setTemporaryListValues={setTemporaryListValues}
					isStageDisabled={isStageDisabled}
				/>
			);
		} else if (ruleConfiguration.ruleListType?.name === ruleListTypes[3].name) {
			return (
				<AffiliateSelect
					value={ruleConfiguration.affiliateIds}
					onChange={(value) => {
						onUpdateListSelect('affiliateIds', value);
					}}
					temporaryListValues={temporaryListValues}
					setTemporaryListValues={setTemporaryListValues}
					isStageDisabled={isStageDisabled}
				/>
			);
		} else {
			return null;
		}
	};

	const onUpdateListSelect = (property, value) => {
		const updatedConfiguration = deepCopy(ruleConfiguration);

		updatedConfiguration[property] = value;

		if (submitErrors) {
			const updatedSubmitErrors = submitErrors.filter((error) => {
				return error.type !== property;
			});

			setSubmitErrors(updatedSubmitErrors);
		}

		setRuleConfiguration(updatedConfiguration);
	};

	return (
		<Stack direction='row' justifyContent='center'>
			<StageAccordion
				stageLabel={t('rules:applyTo')}
				collapsedSummaryText={completedLabel}
				isAccordionExpanded={true}
				isCompletedFlag={isStageCompleted}
				isDisabled={false}
				hasError={querier(submitErrors, '$[type = "ruleListType"]')}
			>
				<Stack spacing={3}>
					<Grid
						container
						rowSpacing={1}
					>
						{ruleListTypes.map((ruleListType) => (
							<BuildListSelectOption
								key={ruleListType.id}
								ruleListType={ruleListType}
								isSelected={
									ruleConfiguration.ruleListType?.name === ruleListType.name
								}
								onUpdateListSelect={onUpdateListSelect}
								isDisabled={isStageDisabled}
							/>
						))}
					</Grid>
					<Box sx={{ width: '100%', display: 'flex' }} mt={2}>
						{renderListSelect(
							ruleConfiguration,
							onUpdateListSelect,
							temporaryListValues,
							setTemporaryListValues,
							isStageDisabled
						)}
					</Box>
				</Stack>
			</StageAccordion>
		</Stack>
	);
};

BuildListSelect.propTypes = {
	t: PropTypes.func,
	setRuleConfiguration: PropTypes.func,
	completedLabel: PropTypes.string,
	isStageCompleted: PropTypes.bool,
	ruleConfiguration: PropTypes.object,
	temporaryListValues: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	setTemporaryListValues: PropTypes.func,
	isStageDisabled: PropTypes.bool,
	submitErrors: PropTypes.array,
	setSubmitErrors: PropTypes.func
};

export default withTranslation('rules')(BuildListSelect);
