import React from 'react';

import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const LoadingNavigationItem = (props) => {
	return (
		<Stack direction='row' p={1} justifyContent='space-between'>
			<Skeleton height={30} width={70} />
			<KeyboardArrowRightIcon />
		</Stack>
	);
};

export default LoadingNavigationItem;
