import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import queryString from 'query-string';
import ReportHeader from '../../Report/ReportHeader';
import { StandardStyledGrid } from '../../ReportStylings';
import ContainedLoadingButton from '../../buttons/ContainedLoadingButton';

// Input Components
import ProcessorSelect from '../../Report/FilterController/components/ProcessorSelect';
import ViewBySelect from '../../Report/FilterController/components/ViewBySelect';
import SingleMerchantSelect from '../../Report/FilterController/components/SingleMerchantSelect';
import CardBrandSelect from '../../Report/FilterController/components/CardBrandSelect';

import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

const FilterController = (props) => {
	const { t, onGetReportData, isLoading } = props;

	const [selectedCardBrand, setSelectedCardBrand] = React.useState('V');
	const [selectedProcessor, setSelectedProcessor] = React.useState('');
	const [selectedMerchant, setSelectedMerchant] = React.useState('');
	const [urlMerchant, setUrlMerchant] = React.useState([]);
	const [viewBy, setViewBy] = React.useState('bank');

	const location = useLocation();

	const getReportData = (cardBrand, processorId, merchantId, viewBy) => {
		onGetReportData(cardBrand, processorId, merchantId, viewBy);
	};

	const submitFilters = () => {
		const reportViewId = location.pathname.split('/')[2];

		const formattedMerchantId = selectedMerchant.merchantId;

		window.history.replaceState(
			null,
			'Slyce360',
			`/reports/${reportViewId}?cardBrand=${selectedCardBrand}&processorId=${selectedProcessor}&merchantId=${formattedMerchantId}&viewBy=${viewBy}`
		);

		getReportData(
			selectedCardBrand,
			selectedProcessor,
			formattedMerchantId,
			viewBy
		);
	};

	React.useEffect(() => {
		const {
			cardBrand,
			processorId,
			merchantId,
			viewBy,
			autoApply
		} = queryString.parse(location.search);

		if (cardBrand) {
			setSelectedCardBrand(cardBrand.split(',')[0]);
		}

		if (processorId) {
			setSelectedProcessor(processorId);
		}

		if (viewBy) {
			setViewBy(viewBy);
		}

		if (merchantId) {
			const splitMids = merchantId.split(',');

			setUrlMerchant(splitMids);
		}

		if (autoApply && cardBrand && processorId && merchantId && viewBy) {
			getReportData(cardBrand, processorId, merchantId, viewBy);
		}
	}, [location]);

	return (
		<Paper>
			<Stack direction='column' p={1} spacing={2}>
				<Stack direction='row' justifyContent='space-between' my={1}>
					<ReportHeader label={t('reports:fourteenDayChargebacks.label')} />
				</Stack>
				<Grid
					container
					rowSpacing={2}
					columnSpacing={1}
					p={1}
					alignItems='flex-start'
					justifyContent='flex-end'
				>
					<StandardStyledGrid item>
						<CardBrandSelect
							value={selectedCardBrand}
							onChange={(newValue) => setSelectedCardBrand(newValue)}
							singleSelect={true}
						/>
					</StandardStyledGrid>
					<StandardStyledGrid item>
						<ProcessorSelect
							onChange={(newSelection) => {
								setSelectedProcessor(newSelection);
							}}
							translationPath={'reports:filterController.processor'}
							value={selectedProcessor}
							dataSource={'processors'}
						/>
					</StandardStyledGrid>
					<StandardStyledGrid item>
						<SingleMerchantSelect
							onChange={(newSelection) => {
								setSelectedMerchant(newSelection);
							}}
							translationPath={'reports:filterController.merchant'}
							value={selectedMerchant}
							dataSource={'merchants'}
							widthOverride={230}
							selectedProcessor={selectedProcessor}
							disabled={selectedProcessor === ''}
							urlMerchant={urlMerchant}
							setUrlMerchant={setUrlMerchant}
						/>
					</StandardStyledGrid>
					<StandardStyledGrid item>
						<ViewBySelect
							value={viewBy}
							onChange={(newValue) => setViewBy(newValue)}
						/>
					</StandardStyledGrid>
					<Grid item>
						<ContainedLoadingButton
							sx={{ height: '50px' }}
							loading={isLoading}
							onClick={submitFilters}
							disabled={
								!selectedCardBrand ||
								!selectedProcessor === '' ||
								!selectedMerchant
							}
						>
							{t('common:buttons.submit')}
						</ContainedLoadingButton>
					</Grid>
				</Grid>
			</Stack>
		</Paper>
	);
};

FilterController.propTypes = {
	t: PropTypes.func,
	onGetReportData: PropTypes.func,
	hasBeenLoaded: PropTypes.bool,
	isLoading: PropTypes.bool
};

export default withTranslation(['common', 'reports'])(FilterController);
