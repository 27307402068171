import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { visuallyHidden } from '@mui/utils';

import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';

export const SortableTableCell = ({ t, reportName, headCell, order, orderBy, handleSortRequest }) => {
	const createSortHandler = (property) => (event) => {
		handleSortRequest(event, property);
	};

	return (
		<TableCell
			key={headCell.id}
			align={headCell.align || 'center'}
			padding={headCell.disablePadding ? 'none' : 'normal'}
			width={headCell.width || null}
			sx={{ pb: 1 }}
			sortDirection={orderBy === headCell.id ? order : false}
		>
			<TableSortLabel
				active={orderBy === headCell.id}
				direction={orderBy === headCell.id ? order : 'asc'}
				onClick={createSortHandler(headCell.id)}
			>
				{headCell.labelTranslationPath
					? <Typography variant='overline' sx={{ fontWeight: 600 }}>{t(`${reportName}.${headCell.name}`)}</Typography>
					: ''}
				{orderBy === headCell.id ? (
					<Box component='span' sx={visuallyHidden}>
						{order === 'desc'
							? 'sorted descending'
							: 'sorted ascending'}
					</Box>
				) : null}
			</TableSortLabel>
		</TableCell>
	);
};

SortableTableCell.propTypes = {
	headCell: PropTypes.object,
	reportName: PropTypes.string,
	order: PropTypes.string,
	orderBy: PropTypes.string,
	handleSortRequest: PropTypes.func,
	t: PropTypes.func
};

export default withTranslation()(SortableTableCell);
