import React from 'react';
import { styled } from '@mui/material/styles';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { getAssetUri } from '../../utilities/assetEndpointUtility';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

const BreakpointImageContainer = styled('img')(({ theme }) => ({
	[theme.breakpoints.up('sm')]: {
		height: 200
	},
	[theme.breakpoints.down('sm')]: {
		height: 80
	}
}));

const FixedImageBox = styled(Box)(({ theme }) => ({
	position: 'fixed',
	top: '10%',
	right: '10%',
	[theme.breakpoints.up('sm')]: {
		height: '25%'
	},
	[theme.breakpoints.down('sm')]: {
		height: '15%'
	}
}));

const CenteredLayoutContainer = styled(Container)({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	height: '100vh',
	width: '100%',
	backgroundImage: `url(${getAssetUri()}/public/slyce360LoginFlowBackground.png)`,
	backgroundRepeat: 'no-repeat',
	backgroundSize: 'contain'
});

const RegistrationPageWrapper = (props) => {
	const { children, t } = props;

	return (
		<CenteredLayoutContainer maxWidth={false}>
			<FixedImageBox>
				<Typography variant='h4' ml={5}>
					{t('branding.taglineIntro')}
				</Typography>
				<BreakpointImageContainer
					src={`${getAssetUri()}/public/slyce360LogoWithTagline.svg`}
				/>
			</FixedImageBox>
			{children}
		</CenteredLayoutContainer>
	);
};

RegistrationPageWrapper.propTypes = {
	children: PropTypes.node,
	t: PropTypes.func
};

export default withTranslation('registration')(RegistrationPageWrapper);
