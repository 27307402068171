import * as actionTypes from './actionTypes';

export const getGroups = (payload) => ({
	type: actionTypes.GET_GROUPS,
	payload
});

export const getGroupsCompleted = (payload) => ({
	type: actionTypes.GET_GROUPS_COMPLETED,
	payload
});

export const getGroupsFailed = (payload) => ({
	type: actionTypes.GET_GROUPS_FAILED,
	payload
});

export const getGroup = (payload) => ({
	type: actionTypes.GET_GROUP,
	payload
});

export const getGroupCompleted = (payload) => ({
	type: actionTypes.GET_GROUP_COMPLETED,
	payload
});

export const getGroupFailed = (payload) => ({
	type: actionTypes.GET_GROUP_FAILED,
	payload
});

export const addGroup = (payload) => ({
	type: actionTypes.ADD_GROUP,
	payload
});

export const addGroupCompleted = (payload) => ({
	type: actionTypes.ADD_GROUP_COMPLETED,
	payload
});

export const addGroupFailed = (payload) => ({
	type: actionTypes.ADD_GROUP_FAILED,
	payload
});

export const updateGroup = (payload) => ({
	type: actionTypes.UPDATE_GROUP,
	payload
});

export const updateGroupCompleted = (payload) => ({
	type: actionTypes.UPDATE_GROUP_COMPLETED,
	payload
});

export const updateGroupFailed = (payload) => ({
	type: actionTypes.UPDATE_GROUP_FAILED,
	payload
});

export const deleteGroup = (payload) => ({
	type: actionTypes.DELETE_GROUP,
	payload
});

export const deleteGroupCompleted = (payload) => ({
	type: actionTypes.DELETE_GROUP_COMPLETED,
	payload
});

export const deleteGroupFailed = (payload) => ({
	type: actionTypes.DELETE_GROUP_FAILED,
	payload
});

export const clearGroups = (payload) => ({
	type: actionTypes.CLEAR_GROUPS,
	payload
});
