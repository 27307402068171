import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { styled } from '@mui/material/styles';
import { withTranslation } from 'react-i18next';
import { noCase, capitalCase } from 'change-case';
import { useNavigate } from 'react-router-dom';
import { ruleBuilderCategoryIcons } from '../../../../icons';
import NotificationSeverityIcon from '../../../shared/NotificationSeverityIcon';
import OutlinedButton from '../../../buttons/OutlinedButton';
import NotificationSeverityIndicatorBox from '../../../shared/NotificationSeverityIndicatorBox';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import CircleIcon from '@mui/icons-material/Circle';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

const NotficationStack = styled(Stack)(({ theme }) => ({
	width: '100%',
	borderTop: '2px solid rgba(0,0,0,0.1)',
	borderBottom: '2px solid rgba(0,0,0,0.1)',
	borderRight: '2px solid rgba(0,0,0,0.1)',
	borderTopRightRadius: 5,
	borderBottomRightRadius: 5,
	minWidth: 500
}));

const NotificationIconBox = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	height: '100%',
	backgroundColor: 'rgba(0,0,0,0.03)',
	paddingTop: theme.spacing(2),
	paddingBottom: theme.spacing(2),
	marginRight: theme.spacing(2),
	maxWidth: 75,
	minWidth: 75,
	'& .icon': {
		fontSize: '2rem',
		color: theme.palette.secondary.main
	}
}));

const UserChip = styled(Chip)(({ theme }) => ({
	color: theme.palette.secondary.light
}));

const StatusCircle = styled(CircleIcon)(({ color, theme }) => ({
	color: theme.palette[color].light,
	opacity: color === 'primary' ? 1 : 0,
	fontSize: '0.8rem'
}));

export const NotificationListItem = ({ t, notification }) => {
	const navigate = useNavigate();

	const onClickViewNotification = () => {
		navigate(`/notifications/${notification.id}`);
	};

	const CategoryIcon = ruleBuilderCategoryIcons[noCase(notification.category)];
	const isEscalated = notification.escalationCount > 0;

	return (
		<Stack
			direction='row'
			minWidth={800}
		>
			<NotificationSeverityIndicatorBox severity={notification.severity} />
			<NotficationStack
				direction='row'
				alignItems='center'
				elevation={0}
			>
				<NotificationIconBox>
					<NotificationSeverityIcon severity={notification.severity} />
				</NotificationIconBox>
				<Box
					width={20}
					sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', 	visibility: !isEscalated && 'hidden' }}>
					<Tooltip title={t('escalated')}>
						<PriorityHighIcon color='error'/>
					</Tooltip>
				</Box>
				<Box width={75} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
					<Tooltip title={capitalCase(t(notification.category))}>
						<CategoryIcon className='icon' color='secondary' sx={{ fontSize: '2rem', opacity: 0.7 }} />
					</Tooltip>
				</Box>
				<Stack direction={{ xs: 'column', xl: 'row' }} sx={{ width: '100%' }} spacing={1} pl={1}>
					<Box sx={{ display: 'flex', flex: 1, alignItems: 'center' }}>
						<Typography sx={{ fontSize: '1rem' }}>
							{notification.initialNotification?.notificationDescription}
						</Typography>
					</Box>
					<Stack
						direction='row'
						alignItems='center'
						justifyContent='space-evenly'
						spacing={2}
					>
						<Box width={150} sx={{ display: 'flex', justifyContent: 'center' }}>
							<Typography variant='body1'>{capitalCase(notification.status)}</Typography>
						</Box>
						<Box width={100}>
							<Stack alignItems='center' justifyContent='center'>
								<Typography variant='caption' color='secondary'>{t('rules:lastUpdated')}</Typography>
								<Typography variant='body1' color='secondary' sx={{ fontWeight: 550 }}>{dayjs(notification.mostRecentUpdate?.createdOn).format('M.D.YY')}</Typography>
							</Stack>
						</Box>
						<Box width={200} sx={{ display: 'flex', justifyContent: 'center' }}>
							{ notification.assignedUser?.firstName
								? (
									<UserChip
										avatar={
											<Avatar alt={notification.assignedUser?.firstName} src={null} >
												{notification.assignedUser?.firstName.split('')[0]}
											</Avatar>
										}
										label={`${notification.assignedUser?.firstName} ${notification.assignedUser?.lastName}`}
									/>
								) : <Chip label={t('notifications:notAssigned')} />
							}
						</Box>
					</Stack>
				</Stack>
				<Stack direction={{ xs: 'column', xl: 'row' }} width={200 } alignItems='center'p={1}>
					<OutlinedButton onClick={onClickViewNotification}>{t('notifications:view')}</OutlinedButton>
				</Stack>
			</NotficationStack>
		</Stack>
	);
};

NotificationListItem.propTypes = {
	notification: PropTypes.object,
	t: PropTypes.func
};

export default withTranslation('notifications')(NotificationListItem);
