import { fromJS } from 'immutable';
import * as actionTypes from './actionTypes';
import handleDynamicDataSourcesMetrics from '../../helpers/dataSource/handleDynamicDataSourcesMetrics';

export const initialState = fromJS({
	dataSources: {}
});

export const dataSourceReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_DATA_SOURCE:
			return handleDynamicDataSourcesMetrics(state, action, 'dataSources');

		case actionTypes.GET_DATA_SOURCE_COMPLETED:
			return handleDynamicDataSourcesMetrics(state, action, 'dataSources');

		case actionTypes.GET_DATA_SOURCE_FAILED:
			return handleDynamicDataSourcesMetrics(state, action, 'dataSources');

		case actionTypes.CLEAR_DATA_SOURCES:
			return state.set('dataSource', {});

		default:
			return state;
	}
};
