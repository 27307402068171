import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import IntegerInput from '../../../inputs/IntegerInput';
import PercentageInput from '../../../inputs/PercentageInput';
import {
	comparisonUnits,
	trendingComparator
} from '../constants/ruleOptionConstants';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const UserInputPartSelect = ({
	t,
	configurationKey,
	userInput,
	selectedComparisonUnit,
	selectedComparator,
	updateOption,
	hasError
}) => {
	const [currentComparisonUnit, setCurrentComparisonUnit] = React.useState();

	const onUpdateConfiguration = (key, value) => {
		updateOption(key, value);
	};

	React.useEffect(() => {
		if (
			currentComparisonUnit &&
			currentComparisonUnit?.optionId !== selectedComparisonUnit?.optionId
		) {
			setCurrentComparisonUnit(selectedComparisonUnit);
			onUpdateConfiguration(configurationKey, '');
		} else {
			setCurrentComparisonUnit(selectedComparisonUnit);
		}
	}, [selectedComparisonUnit]);

	return (
		<React.Fragment>
			{selectedComparator?.parserKey === trendingComparator ? (
				<Box pt={1} sx={{ width: 100 }}>
					<Typography>{t('rules:moreThan')}</Typography>
				</Box>
			) : null}
			{selectedComparisonUnit?.optionName === comparisonUnits.number ? (
				<IntegerInput
					onChange={onUpdateConfiguration}
					isSmall={true}
					translationPath={'rules:number'}
					value={userInput}
					variable={configurationKey}
					hiddenLabel={true}
					hasError={hasError}
				/>
			) : null}
			{selectedComparisonUnit?.optionName === comparisonUnits.percent ? (
				<PercentageInput
					onChange={onUpdateConfiguration}
					isSmall={true}
					translationPath={'rules:percent'}
					value={userInput}
					variable={configurationKey}
					hiddenLabel={true}
					hasError={hasError}
				/>
			) : null}
		</React.Fragment>
	);
};

UserInputPartSelect.propTypes = {
	t: PropTypes.func,
	configurationKey: PropTypes.string,
	userInput: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	selectedComparisonUnit: PropTypes.object,
	selectedComparator: PropTypes.object,
	updateOption: PropTypes.func,
	hasError: PropTypes.bool
};

export default withTranslation('rules')(UserInputPartSelect);
