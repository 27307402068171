import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import BoldLargeHeader from '../headers/BoldLargeHeader';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export const PasswordSetupError = (props) => {
	const { t } = props;

	const navigate = useNavigate();

	return (
		<Box sx={{ maxWidth: 400 }}>
			<Stack
				justifyContent='center'
				alignItems='center'
				spacing={2}
				px={4}
				pb={2}
			>
				<BoldLargeHeader py={2}>
					{t('passwordSetup.error.header')}
				</BoldLargeHeader>
				<Typography color='error' sx={{ textAlign: 'center' }}>
					{t('passwordSetup.error.unableToSetup')}
				</Typography>
				<Button
					size='small'
					variant='outlined'
					onClick={() => navigate('/login')}
				>
					{t('passwordSetup.error.backToLogin')}
				</Button>
			</Stack>
		</Box>
	);
};

PasswordSetupError.propTypes = {
	t: PropTypes.func
};

export default withTranslation('registration')(PasswordSetupError);
