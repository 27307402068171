import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { capitalCase } from 'change-case';
import ruleListTypes from '../../../constants/ruleListTypes';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

export const ApplyToSelect = ({ t, selectedApplyTo, onChangeSelection }) => {
	return (
		<FormControl sx={{ flex: 1 }} fullWidth>
			<InputLabel id='apply-to-select-label'>
				{t('rules:appliedTo')}
			</InputLabel>
			<Select
				labelId='apply-to-select-label'
				label={t('rules:appliedTo')}
				id='apply-to-select'
				variant='outlined'
				value={selectedApplyTo}
				onChange={(e) => {
					onChangeSelection(e.target.value);
				}}
				renderValue={(category) => {
					return category === 'all' ? t('common:all') : capitalCase(t(`rules:${category?.name}`));
				}}
				endAdornment={
					<IconButton
						sx={{ display: selectedApplyTo ? null : 'none', mr: 1 }}
						onClick={() => onChangeSelection('all')}
					>
						<ClearOutlinedIcon />
					</IconButton>
				}
			>
				<MenuItem value={'all'}>
					<Stack direction='row' spacing={2}>
						<Box sx={{ width: 20 }} />
						<Typography>{capitalCase(t('common:all'))}</Typography>
					</Stack>
				</MenuItem>
				{ruleListTypes.map((ruleListType) => {
					return (
						<MenuItem key={ruleListType.id} value={ruleListType}>
							<Stack direction='row' spacing={2} alignItems='center'>
								<Box sx={{ width: 20 }}>
									<ruleListType.icon color='secondary' />
								</Box>
								<Typography>{capitalCase(t(ruleListType.name))}</Typography>
							</Stack>
						</MenuItem>
					);
				})}
			</Select>
		</FormControl>
	);
};

ApplyToSelect.propTypes = {
	selectedApplyTo: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	onChangeSelection: PropTypes.func,
	t: PropTypes.func
};

export default withTranslation(['rules', 'common'])(ApplyToSelect);
