import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	processors: {
		data: [],
		isLoading: false,
		hasBeenLoaded: false,
		error: undefined
	}
};

export const processorsSlice = createSlice({
	name: 'processors',
	initialState,
	reducers: {
		getProcessors: (state) => {
			state.processors.isLoading = true;
			state.processors.error = undefined;
		},
		getProcessorsCompleted: (state, action) => {
			state.processors.isLoading = false;
			state.processors.hasBeenLoaded = true;
			state.processors.data = action.payload;
		},
		getProcessorsFailed: (state, action) => {
			state.processors.isLoading = false;
			state.processors.error = action.payload;
		}
	}
});

export const {
	getProcessors,
	getProcessorsCompleted,
	getProcessorsFailed
} = processorsSlice.actions;

export default processorsSlice.reducer;
