const mapGroupItemValues = (groupItems) => {
	return groupItems.map((item) => item?.id);
};

const mapGroupItemsForUpdate = (groupItems) => {
	return groupItems.map((item) => {
		if (item.itemRowId) {
			return {
				id: item.itemRowId,
				value: item.value
			};
		} else {
			return {
				value: item.id
			};
		}
	});
};

export { mapGroupItemValues, mapGroupItemsForUpdate };
