import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { dataSourceActions } from '../../../../../containers/DataSource';
import getDynamicDataFromList from '../../../../../helpers/shared/dynamicReducers/getDynamicDataFromList';
import isDataEmpty from '../../../../../helpers/shared/isDataEmpty';
import SelectToolTip from '../shared/SelectToolTip';
import keyCodes from '../../../../../constants/keyCodes';

import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';

const GenericDatasourceSelect = (props) => {
	const {
		onChange,
		translationPath,
		value,
		dataSource,
		t,
		dataSources,
		getDataSource,
		temporaryGroupValues,
		setTemporaryGroupValues,
		listItemsIndividually,
		findTemporaryValueMatches,
		helperTextTranslationPath
	} = props;

	const matchedDataSource = getDynamicDataFromList(dataSources, dataSource);

	const [inputValue, setInputValue] = React.useState('');
	const [open, setOpen] = React.useState(false);
	const [options, setOptions] = React.useState([]);

	React.useEffect(() => {
		getDataSource({
			dataSourceKey: dataSource
		});
	}, []);

	React.useEffect(() => {
		if (open) {
			setOptions(matchedDataSource?.data);
		} else {
			setOptions([]);
		}
	}, [matchedDataSource, open]);

	React.useEffect(() => {
		if (temporaryGroupValues && !isDataEmpty(matchedDataSource?.data)) {
			const mappedValues = findTemporaryValueMatches(
				temporaryGroupValues,
				matchedDataSource
			);

			onChange(mappedValues);
			setTemporaryGroupValues();
		}
	}, [temporaryGroupValues, matchedDataSource]);

	const loading =
		(options.length === 0 && open) ||
		!matchedDataSource ||
		!matchedDataSource?.hasBeenLoaded;

	return (
		<FormControl sx={{ flex: 1 }}>
			<Autocomplete
				multiple
				filterSelectedOptions
				options={options}
				open={open}
				onOpen={() => {
					setOpen(true);
				}}
				onClose={() => {
					setOpen(false);
				}}
				loading={loading}
				disabled={!matchedDataSource || !matchedDataSource?.hasBeenLoaded}
				isOptionEqualToValue={(option, item) => {
					return option.value === item.value;
				}}
				getOptionLabel={(option) => option?.label}
				renderOption={(props, option) => {
					return (
						<MenuItem {...props} key={option.id} value={option.id}>
							{option.label}
						</MenuItem>
					);
				}}
				renderTags={
					!listItemsIndividually
						? (value) => (
							<Tooltip title={<SelectToolTip value={value} />}>
								<Chip variant='outlined' label={`${value.length} selected`} />
							</Tooltip>
						)	: undefined
				}
				value={value}
				onChange={(event, newValue, reason) => {
					if (
						reason !== 'removeOption' ||
						event.keyCode !== keyCodes.backspace
					) {
						onChange(newValue);
					}
				}}
				inputValue={inputValue}
				onInputChange={(event, newInputValue) => {
					setInputValue(newInputValue);
				}}
				sx={{ ml: 1, flex: 1 }}
				renderInput={(params) => (
					<TextField
						{...params}
						label={t(translationPath)}
						variant='outlined'
						// TODO look into scope for these translation paths
						helperText={
							helperTextTranslationPath ? t(helperTextTranslationPath) : null
						}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<React.Fragment>
									{loading ? (
										<CircularProgress color='inherit' size={20} />
									) : null}
									{params.InputProps.endAdornment}
								</React.Fragment>
							)
						}}
					/>
				)}
			/>
		</FormControl>
	);
};

GenericDatasourceSelect.propTypes = {
	onChange: PropTypes.func,
	translationPath: PropTypes.string,
	value: PropTypes.array,
	dataSource: PropTypes.string,
	t: PropTypes.func,
	dataSources: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	getDataSource: PropTypes.func,
	temporaryGroupValues: PropTypes.array,
	setTemporaryGroupValues: PropTypes.func,
	listItemsIndividually: PropTypes.bool,
	findTemporaryValueMatches: PropTypes.func,
	helperTextTranslationPath: PropTypes.string
};

const mapStateToProps = (state) => {
	return {
		dataSources: state.dataSourceReducer.get('dataSources')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getDataSource: (payload) => {
			dispatch(dataSourceActions.getDataSource(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation('groups')(GenericDatasourceSelect));
