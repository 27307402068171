export default [
	'Business',
	'Creative',
	'Finance',
	'Human Resources',
	'IT',
	'Marketing',
	'Payments',
	'Other'
];
