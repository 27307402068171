import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { withTranslation } from 'react-i18next';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

const DateSelect = (props) => {
	const { t, translationPath, value, onChange, maxDate, isDisabled } = props;

	return (
		<DatePicker
			label={t(translationPath)}
			value={dayjs(value)}
			disabled={isDisabled}
			onChange={onChange}
			renderInput={(params) => <TextField {...params} fullWidth />}
			maxDate={maxDate || null}
		/>
	);
};

DateSelect.propTypes = {
	value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	onChange: PropTypes.func,
	maxDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	t: PropTypes.func,
	isDisabled: PropTypes.bool,
	translationPath: PropTypes.string
};

export default withTranslation('reports')(DateSelect);
