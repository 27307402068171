import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import My360Header from '../../../components/my360/Header';
import BuildRule from '../../../components/RuleManagement/BuildRule';
import Restricted from '../../../permissionProvider/Restricted';
import RestrictedFallback from '../../../components/shared/RestrictedFallback';

import Stack from '@mui/material/Stack';

const BuildRulePage = ({ t }) => {
	return (
		<Stack p={1}>
			<Restricted
				to='rules.ruleManagement.add'
				Fallback={<RestrictedFallback overrideMessage={t('common:error.notAllowed')} />}
			>
				<My360Header label={t('rules:createNewRule')} />
				<BuildRule />
			</Restricted>
		</Stack>
	);
};

BuildRulePage.propTypes = {
	t: PropTypes.func
};

export default withTranslation(['rules', 'common'])(BuildRulePage);
