import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	notChallenged: {
		data: [],
		isLoading: false,
		hasBeenLoaded: false,
		error: undefined
	},
	revertNotChallenged: {
		revertIdList: [],
		error: undefined
	}
};

export const notChallengedSlice = createSlice({
	name: 'notChallenged',
	initialState,
	reducers: {
		getNotChallenged: (state) => {
			state.notChallenged.isLoading = true;
			state.notChallenged.error = undefined;
		},
		getNotChallengedCompleted: (state, action) => {
			state.notChallenged.isLoading = false;
			state.notChallenged.hasBeenLoaded = true;
			state.notChallenged.data = action.payload;
		},
		getNotChallengedFailed: (state, action) => {
			state.notChallenged.isLoading = false;
			state.notChallenged.error = action.payload;
		},
		revertNotChallenged: (state, action) => {
			state.revertNotChallenged.revertIdList = action.payload.chargebackIdList;
			state.notChallenged.isLoading = true;
			state.revertNotChallenged.error = undefined;
		},
		revertNotChallengedCompleted: (state) => {
			// INTENTIALLY LEFT BLANK
		},
		revertNotChallengedFailed: (state, action) => {
			state.revertNotChallenged.revertIdList = [];
			state.notChallenged.isLoading = false;
			state.revertNotChallenged.error = action.payload;
		},
		resetRevertNotChallenged: (state) => {
			state.revertNotChallenged = initialState.revertNotChallenged;
		}
	}
});

export const {
	getNotChallenged,
	getNotChallengedCompleted,
	getNotChallengedFailed,
	revertNotChallenged,
	revertNotChallengedCompleted,
	revertNotChallengedFailed,
	resetRevertNotChallenged
} = notChallengedSlice.actions;

export default notChallengedSlice.reducer;
