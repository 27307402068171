import React from 'react';
import PropTypes from 'prop-types';
import UserActionList from './UserActionList';
import { getAssetUri } from '../../../utilities/assetEndpointUtility';
import axios, { getAuth } from '../../../utilities/axios';
import { formatPhoneNumberForDisplay } from '../../../utilities/formatPhoneNumber';
import Restricted from '../../../permissionProvider/Restricted';

import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

export const UsersTableRow = (props) => {
	const { row } = props;

	const [userAvatar, setUserAvatar] = React.useState(null);

	React.useEffect(() => {
		const userAvatarToBlob = async (avatar) => {
			if (avatar !== null) {
				const response = await axios.get(
					`${getAssetUri()}${avatar}`,
					{ Authorization: `Bearer ${getAuth()}`, responseType: 'blob' }
				);

				const imageObjectURL = URL.createObjectURL(response.data);

				setUserAvatar(imageObjectURL);
			} else {
				setUserAvatar(null);
			}
		};

		userAvatarToBlob(row.avatar);
	}, [row.avatar]);

	return (
		<TableRow
			tabIndex={-1}
			key={row.userId}
			sx={{ opacity: !row.isEnabled && 0.5 }}
		>
			<TableCell component='th' scope='row'>
				<Stack direction='row' spacing={2}>
					<Avatar alt={row.firstName} src={userAvatar} >{row.firstName.split('')[0]}</Avatar>
					<Stack>
						<Typography>{`${row.firstName} ${row.lastName}`}</Typography>
						<Typography variant='caption' color='primary'>{row.username}</Typography>
					</Stack>
				</Stack>
			</TableCell>
			<TableCell align='left'>
				{row.email}
			</TableCell>
			<TableCell align='left'>
				{formatPhoneNumberForDisplay(row.phone)}
			</TableCell>
			<TableCell align='right'>
				<Restricted to='users.usersList.edit'>
					<UserActionList userData={row} />
				</Restricted>
			</TableCell>
		</TableRow>
	);
};

UsersTableRow.propTypes = {
	row: PropTypes.object
};

export default UsersTableRow;
