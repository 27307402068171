import fileUploadErrors from '../constants/fileUploadErrors';

export default function async(file, callback, fileSizeLimit) {
	if (file) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = function (evt) {
				if (fileSizeLimit && evt.target.result.length / 1024 > fileSizeLimit) {
					reject(fileUploadErrors.fileSizeLimit);
				} else {
					callback(evt.target.result);
					resolve();
				}
			};

			reader.readAsDataURL(file);
		});
	}
}
