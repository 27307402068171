import axios from '../../utilities/axios';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import Logger from '../../utilities/logger';
import {
	primaryRestGateway,
	secondaryRestGateway
} from '../../utilities/apiEndpointUtility';

import * as actions from './actions.js';
import * as actionTypes from './actionTypes.js';
import { refreshToken } from '../../utilities/refreshToken';

export const validateInvitationIdEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.VALIDATE_INVITATION_ID),
		mergeMap(async (action) => {
			const { clientId, invitationId } = action.payload;

			const response = await axios.get(
				`${secondaryRestGateway()}/api/invitations/validate/${clientId}/${invitationId}`
			);

			if (response.data === false) {
				throw new Error('Invitation ID Could Not Be Validated');
			}

			return invitationId;
		}),
		switchMap((res) => [actions.validateInvitationIdCompleted(res)]),
		catchError((error, source) => {
			Logger.error('Validate Invitation Id Failure', error);

			return merge(
				of(actions.validateInvitationIdFailed(error.message)),
				source
			);
		})
	);

export const submitRegistrationEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.SUBMIT_REGISTRATION),
		mergeMap(async (action) => {
			// TODO department is not set up in the users table so it is not being sent back yet
			const {
				avatar,
				firstName,
				lastName,
				invitationId,
				phoneNumber,
				userPoolId,
				username
			} = action.payload;

			const response = await axios.post(`${secondaryRestGateway()}/api/users`, {
				avatar,
				firstName,
				lastName,
				invitationId,
				phoneNumber,
				userPoolId,
				username
			});

			return response.data;
		}),
		switchMap((res) => [actions.submitRegistrationCompleted(res)]),
		catchError((error, source) => {
			Logger.error('Submit Registration Failure', error);

			return merge(of(actions.submitRegistrationFailed(error.message)), source);
		})
	);

export const cancelInvitationEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.CANCEL_INVITATION),
		mergeMap(async (action) => {
			await refreshToken();

			const { invitationId, status } = action.payload;

			const response = await axios.put(
				`${primaryRestGateway()}/api/invitations`,
				{
					invitationId,
					status
				}
			);

			return response.data;
		}),
		switchMap((res) => [actions.cancelInvitationCompleted(res)]),
		catchError((error, source) => {
			Logger.error('Cancel Invitation Failure', error);

			return merge(of(actions.cancelInvitationFailed(error.message)), source);
		})
	);
