import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

const CollapsedTotals = (props) => {
	const { summary, t } = props;

	return (
		<Stack
			direction='row'
			spacing={2}
			justifyContent='center'
			sx={{ flex: 1 }}
			divider={<Divider orientation='vertical' />}
		>
			<Stack direction='row' alignItems='center' spacing={3}>
				<Typography sx={{ fontSize: '.8rem' }}>
					{t('chargebacksSummary.total.daily')}
				</Typography>
				<Typography>{summary.dailyChargebacksSum}</Typography>
			</Stack>
			<Stack direction='row' alignItems='center' spacing={3}>
				<Typography sx={{ fontSize: '.8rem' }}>
					{t('chargebackSummary.monthToDateTotal')}
				</Typography>
				<Typography>{summary.monthlyChargebacksSum}</Typography>
			</Stack>
		</Stack>
	);
};

CollapsedTotals.propTypes = {
	cardType: PropTypes.string,
	summary: PropTypes.object,
	t: PropTypes.func
};

export default withTranslation('reports')(CollapsedTotals);
