import axios from '../../utilities/axios';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { refreshToken } from '../../utilities/refreshToken';
import { primaryRestGateway } from '../../utilities/apiEndpointUtility';

import * as actions from './actions';
import * as actionTypes from './actionTypes';

export const getReportDetailsEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.GET_REPORT_DETAILS),
		mergeMap(async (action) => {
			await refreshToken();

			const { reportUniversalUniqueIdentifier } = action.payload;

			try {
				const response = await axios.get(
					`${primaryRestGateway()}/api/reports/${reportUniversalUniqueIdentifier}`
				);

				return {
					reportUniversalUniqueIdentifier,
					data: response.data
				};
			} catch (e) {
				return {
					reportUniversalUniqueIdentifier,
					error: e.message
				};
			}
		}),
		switchMap((res) => [actions.getReportDetailsCompleted(res)]),
		catchError((error, source) =>
			merge(of(actions.getReportDetailsFailed(error.message)), source)
		)
	);

export const executeReportEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.EXECUTE_REPORT),
		mergeMap(async (action) => {
			await refreshToken();

			const {
				queryParameters,
				reportUniversalUniqueIdentifier
			} = action.payload;

			try {
				const response = await axios.get(
					`${primaryRestGateway()}/api/reports/execute/${reportUniversalUniqueIdentifier}`,
					{
						params: queryParameters
					}
				);

				return {
					reportUniversalUniqueIdentifier,
					data: response.data
				};
			} catch (e) {
				return {
					reportUniversalUniqueIdentifier,
					error: e.message
				};
			}
		}),
		switchMap((res) => [actions.executeReportCompleted(res)]),
		catchError((error, source) =>
			merge(of(actions.executeReportFailed(error.message)), source)
		)
	);
