import dayjs from 'dayjs';

const DEFAULT_FORMAT = 'YYYY-MM-DD';

export const formatDateForDisplay = (date, format) => {
	if (date) {
		return dayjs(date).format(format ?? DEFAULT_FORMAT);
	}
};

export const getCurrentTimestamp = () => {
	return dayjs().format();
};
