import React from 'react';
import PropTypes from 'prop-types';
import { formatDateForDisplay } from '../../../../utilities/dateWrapper';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

export const ScanInfoListRow = (props) => {
	const { row, uniqueId } = props;

	return (
		<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
			<TableCell>{uniqueId}</TableCell>
			<TableCell>
				{formatDateForDisplay(row.scanEventDate, 'YYYY-MM-DD HH:mm')}
			</TableCell>
			<TableCell>{row.scanEventCode}</TableCell>
			<TableCell>{row.scanEventDescription}</TableCell>
			<TableCell>{row.sectionalCenterFacility}</TableCell>
		</TableRow>
	);
};

ScanInfoListRow.propTypes = {
	row: PropTypes.object,
	uniqueId: PropTypes.number
};

export default ScanInfoListRow;
