import * as DataSourceEpics from './epic';
import * as dataSourceActions from './actions';
import * as dataSourceActionTypes from './actionTypes';
import { dataSourceReducer } from './reducer';

const dataSourceEpics = Object.values({ ...DataSourceEpics });

export {
	dataSourceActions,
	dataSourceActionTypes,
	dataSourceEpics,
	dataSourceReducer
};
