import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
	displaySections,
	displaySectionLabels
} from './constants/displaySections';
import buildDisplayControlSections from '../../helpers/currentInventory/buildDisplayControlSections';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';

export const DisplayControls = (props) => {
	const {
		t,
		displayedSections,
		setDisplayedSections,
		representmentData
	} = props;

	const handleSelection = (event, newSelections) => {
		if (
			newSelections.includes('showAll') &&
			newSelections.includes('hideAll')
		) {
			setDisplayedSections(displaySections);
		} else if (newSelections.indexOf('hideAll') > 0) {
			setDisplayedSections(['hideAll']);
		} else if (newSelections.indexOf('hideAll') === 0) {
			const hideAllIndex = newSelections.indexOf('hideAll');
			newSelections.splice(hideAllIndex, 1);
			setDisplayedSections(newSelections);
		} else {
			setDisplayedSections(newSelections);
		}
	};

	const filteredSections = buildDisplayControlSections(
		representmentData,
		displaySections
	);

	return (
		<ToggleButtonGroup
			size='small'
			value={displayedSections}
			onChange={handleSelection}
			aria-label='Display Sections'
		>
			{displayedSections.includes('hideAll') && (
				<ToggleButton sx={{ fontSize: '.7rem' }} value='showAll'>
					<VisibilityIcon />
				</ToggleButton>
			)}
			{!displayedSections.includes('hideAll') && (
				<ToggleButton sx={{ fontSize: '.7rem' }} value='hideAll'>
					<VisibilityOffIcon />
				</ToggleButton>
			)}
			{filteredSections.map((section, index) => {
				return (
					<ToggleButton
						key={index}
						sx={{ fontSize: '.7rem' }}
						value={section}
						aria-label={section}
					>
						{t(displaySectionLabels[section])}
					</ToggleButton>
				);
			})}
		</ToggleButtonGroup>
	);
};

DisplayControls.propTypes = {
	displayedSections: PropTypes.array,
	setDisplayedSections: PropTypes.func,
	representmentData: PropTypes.object,
	t: PropTypes.func
};

export default withTranslation('representments')(DisplayControls);
