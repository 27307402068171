import { fromJS } from 'immutable';
import * as actionTypes from './actionTypes';
import { handleDynamicReport } from './handlers/handleDynamicReport';

export const initialState = fromJS({
	reportDetails: {},
	reportData: {}
});

export const reporterReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_REPORT_DETAILS:
			return handleDynamicReport(state, action, 'reportDetails');

		case actionTypes.GET_REPORT_DETAILS_COMPLETED:
			return handleDynamicReport(state, action, 'reportDetails');

		case actionTypes.GET_REPORT_DETAILS_FAILED:
			return handleDynamicReport(state, action, 'reportDetails');

		case actionTypes.EXECUTE_REPORT:
			return handleDynamicReport(state, action, 'reportData');

		case actionTypes.EXECUTE_REPORT_COMPLETED:
			return handleDynamicReport(state, action, 'reportData');

		case actionTypes.EXECUTE_REPORT_FAILED:
			return handleDynamicReport(state, action, 'reportData');

		case actionTypes.CLEAR_REPORT:
			return handleDynamicReport(state, action, 'reportData');

		case actionTypes.CLEAR_REPORT_DETAILS:
			return handleDynamicReport(state, action, 'reportDetails');

		default:
			return state;
	}
};
