import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import ContainedButton from '../../buttons/ContainedButton';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export const CompletionPanel = (props) => {
	const { t, onClose } = props;

	return (
		<Stack p={3} justifyContent='space-between' alignItems='center'	sx={{ height: '350px' }}>
			<Typography variant='h6'>
				{t('modals:updateUserStatusModal.successMessage')}
			</Typography>
			<ContainedButton onClick={onClose}>
				{t('common:buttons.close')}
			</ContainedButton>
		</Stack>
	);
};

CompletionPanel.propTypes = {
	onClose: PropTypes.func,
	t: PropTypes.func
};

export default withTranslation(['common', 'modals'])(CompletionPanel);
