import * as actionTypes from './actionTypes';

export const getAvailablePermissions = (payload) => ({
	type: actionTypes.GET_AVAILABLE_PERMISSIONS,
	payload
});

export const getAvailablePermissionsCompleted = (payload) => ({
	type: actionTypes.GET_AVAILABLE_PERMISSIONS_COMPLETED,
	payload
});

export const getAvailablePermissionsFailed = (payload) => ({
	type: actionTypes.GET_AVAILABLE_PERMISSIONS_FAILED,
	payload
});
