import React from 'react';
import { withTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import NavigationCategory from '../shared/NavigationCategory';
import NavigationItem from '../shared/NavigationItem';

import {
	toggleGroupsExpansion,
	toggleRulesExpansion
} from '../../../../../containers/sideNavigation/sideNavigationSlice';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import My360Outlined from '../../../../../assets/my360/my360_text.svg';

const SideNavigation = ({ t }) => {
	const dispatch = useDispatch();

	const {
		isGroupsExpanded,
		isRulesExpanded
	} = useSelector(state => state.sideNavigationSlice);

	const onToggleExpandGroups = () => {
		dispatch(toggleGroupsExpansion());
	};

	const onToggleExpandRules = () => {
		dispatch(toggleRulesExpansion());
	};

	return (
		<Box sx={{ backgroundColor: '#c6dde4' }} py={2}>
			<Box sx={{ paddingLeft: 2 }}>
				<img src={My360Outlined} height={50} width={150} />
			</Box>
			<Stack spacing={3} mt={2}>
				<NavigationCategory
					label={t('navigation:groups')}
					isExpanded={isGroupsExpanded}
					setIsExpanded={onToggleExpandGroups}
				>
					<NavigationItem
						label={t('navigation:createNew')}
						linkTo={'/groups/create'}
					/>
					<NavigationItem
						label={t('navigation:viewExisting')}
						linkTo={'/groups'}
						useExact={true}
					/>
				</NavigationCategory>
				<NavigationCategory
					label={t('navigation:rules')}
					isExpanded={isRulesExpanded}
					setIsExpanded={onToggleExpandRules}
				>
					<NavigationItem
						label={t('navigation:createNew')}
						linkTo={'/rules/build'}
						alternativeActiveLink={'/rules/build'}
					/>
					<NavigationItem
						label={t('navigation:viewExisting')}
						linkTo={'/rules'}
						alternativeActiveLink={'/rules'}
						useExact={true}
					/>
					<NavigationItem
						label={t('navigation:ruleResults')}
						linkTo={'/reports/1815860b-108d-4167-9b61-a552f001d6c1'}
						alternativeActiveLink={'/reports/1815860b-108d-4167-9b61-a552f001d6c1'}
					/>
				</NavigationCategory>
			</Stack>
		</Box>
	);
};

SideNavigation.propTypes = {
	t: PropTypes.func
};

export default withTranslation('navigation')(SideNavigation);
