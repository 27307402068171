import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { withTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { noCasing, capitalCasing } from '../../../utilities/changeCase';
import isDataEmpty from '../../../helpers/shared/isDataEmpty';
import NotificationSeverityIndicatorBox from '../../shared/NotificationSeverityIndicatorBox';
import NotificationSeverityIcon from '../../shared/NotificationSeverityIcon';
import Restricted from '../../../permissionProvider/Restricted';
import { ruleBuilderCategoryIcons } from '../../../icons';
import AssignedUserSelect from '../../notifications/AssignedUserSelect';
import NotificationStatusSelect from '../../notifications/NotificationStatusSelect';
import PageError from '../../errors/PageError';
import ConfirmSelection from './components/ConfirmSelection';
import { notificationStatusesNamed } from '../../../constants/notificationStatuses';

import {
	getNotificationDetail,
	resetGetNotificationDetail,
	changeAssignedUser,
	resetChangeAssignedUser,
	changeNotificationStatus,
	resetChangeNotificationStatus
} from '../../../containers/notificationDetail/notificationDetailSlice';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

const CategoryIconBox = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	height: '100%',
	paddingTop: theme.spacing(2),
	paddingBottom: theme.spacing(2),
	marginRight: theme.spacing(2),
	width: 50,
	'& .icon': {
		fontSize: '2rem',
		color: theme.palette.secondary.light,
		opacity: 0.5
	}
}));

const NotificationInformation = ({ t }) => {
	const dispatch = useDispatch();
	const { notificationId } = useParams();

	const [assignedUser, setAssignedUser] = React.useState('none');
	const [status, setStatus] = React.useState('');

	const {
		data: notificationDetail,
		isLoading: isLoadingNotificationDetail,
		hasBeenLoaded: hasLoadedNotificationDetail,
		error: notificationDetailError
	} = useSelector(state => state.notificationDetailSlice.notificationDetail);
	const {
		isLoading: isChangingAssignedUser,
		hasCompleted: hasChangedAssignedUser
	} = useSelector(state => state.notificationDetailSlice.changeAssignedUser);
	const {
		isLoading: isChangingStatus,
		hasCompleted: hasChangedStatus
	} = useSelector(state => state.notificationDetailSlice.changeStatus);
	const user = useSelector(state => state.userReducer.get('user'));

	React.useEffect(() => {
		if (notificationId) {
			dispatch(getNotificationDetail({
				notificationId
			}));
		}

		return () => {
			dispatch(resetGetNotificationDetail());
		};
	}, [notificationId]);

	React.useEffect(() => {
		if (notificationDetail?.assignedUser) {
			setAssignedUser(notificationDetail.assignedUser);
		}

		if (notificationDetail?.status) {
			setStatus(notificationDetail?.status);
		}
	}, [notificationDetail]);

	React.useEffect(() => {
		if (hasChangedAssignedUser) {
			dispatch(resetChangeAssignedUser());
		}
	}, [hasChangedAssignedUser]);

	React.useEffect(() => {
		if (hasChangedStatus) {
			dispatch(resetChangeNotificationStatus());
		}
	}, [hasChangedStatus]);

	const onConfirmAssignUser = React.useCallback(() => {
		dispatch(changeAssignedUser({
			notificationId,
			notification: {
				assignedUserId: assignedUser?.id ?? null
			}
		}));
	}, [assignedUser, user]);

	const onCancelAssignUser = () => {
		if (notificationDetail.assignedUserId) {
			setAssignedUser(notificationDetail.assignedUser);
		} else {
			setAssignedUser({ id: -1, firstName: 'unassigned', lastName: '' });
		}
	};

	const onConfirmStatusChange = React.useCallback(() => {
		dispatch(changeNotificationStatus({
			notificationId,
			notification: {
				status
			}
		}));
	}, [status, user]);

	const onCancelStatusChange = () => {
		setStatus(notificationDetail?.status);
	};

	if (notificationDetailError) {
		return (
			<PageError />
		);
	}

	if (!hasLoadedNotificationDetail || isDataEmpty(notificationDetail)) {
		return (
			<Stack direction='row' justifyContent='center'>
				<CircularProgress />
			</Stack>
		);
	}

	const CategoryIcon = ruleBuilderCategoryIcons[noCasing(notificationDetail?.category)];
	const initialNotification = notificationDetail?.notificationDetails.find((detail) => {
		return detail.detailType === 'initial';
	});

	const shouldDisplayChangeUserConfirmation = (assignedUserId, notificationAssignedUserId, isChangingAssignedUser) => {
		let result = false;

		if ((assignedUserId === null || !assignedUserId) && notificationAssignedUserId === null) {
			result = false;
		} else if (!isChangingAssignedUser && assignedUser?.id !== notificationDetail?.assignedUserId) {
			result = true;
		}

		return result;
	};

	return (
		<Stack direction='row' component={Paper}>
			<NotificationSeverityIndicatorBox severity={notificationDetail.severity} />
			<Stack spacing={2} sx={{ width: '100%' }} p={1}>
				<Stack direction='row' px={1} justifyContent='space-between'>
					<Typography variant='h6'>{t('notifications:notificationDetails')}</Typography>
					<Stack direction='row' alignItems='center' justifyContent='left' spacing={1}>
						<NotificationSeverityIcon severity={notificationDetail.severity} />
						<Typography variant='body1'>{capitalCasing(notificationDetail.severity)}</Typography>
					</Stack>
				</Stack>
				<Stack direction='row' p={1} alignItems='center'>
					<CategoryIconBox>
						<Tooltip title={capitalCasing(t(notificationDetail?.category))}>
							<CategoryIcon className='icon'/>
						</Tooltip>
					</CategoryIconBox>
					<Stack>
						<Typography>{initialNotification?.notificationDescription} </Typography>
						<Typography variant='caption'>{t('notifications:userRule')} <b>{initialNotification?.userRuleDescription}</b></Typography>
					</Stack>
				</Stack>
				<Restricted to='notifications.notificationDetail.edit'>
					<Grid container p={1}>
						<Grid item xs={12} sm={6} md={6} lg={6}>
							<Box p={0.5}>
								<AssignedUserSelect
									selectedUser={assignedUser}
									onChangeSelection={setAssignedUser}
									overrideLabel={t('notifications:assignedUser')}
									includeAll={false}
									isDisabled={
										isLoadingNotificationDetail ||
										notificationDetail?.status === notificationStatusesNamed.closed.id ||
										notificationDetail?.status === notificationStatusesNamed.resolved.id
									}
								/>
								<ConfirmSelection
									shouldDisplay={shouldDisplayChangeUserConfirmation(
										assignedUser?.id,
										notificationDetail?.assignedUserId,
										isChangingAssignedUser
									)}
									onConfirm={onConfirmAssignUser}
									onCancel={onCancelAssignUser}
								/>
							</Box>
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={6}>
							<Box p={0.5}>
								<NotificationStatusSelect
									selectedNotificationStatus={status}
									onChangeSelection={setStatus}
									excludeAll={true}
									isDisabled={isLoadingNotificationDetail}
								/>
								<ConfirmSelection
									shouldDisplay={status !== notificationDetail?.status && !isChangingStatus}
									onConfirm={onConfirmStatusChange}
									onCancel={onCancelStatusChange}
								/>
							</Box>
						</Grid>
					</Grid>
				</Restricted>
			</Stack>
		</Stack>
	);
};

NotificationInformation.propTypes = {
	t: PropTypes.func,
	notification: PropTypes.object,
	users: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	user: PropTypes.object,
	onUpdateNotification: PropTypes.func,
	onAddNotificationDetail: PropTypes.func
};

export default withTranslation('notifications')(NotificationInformation);
