export const GET_USERS = 'users/GET_USERS';
export const GET_USERS_COMPLETED = 'users/GET_USERS_COMPLETED';
export const GET_USERS_FAILED = 'users/GET_USERS_FAILED';

export const GET_PENDING_USERS = 'users/GET_PENDING_USERS';
export const GET_PENDING_USERS_COMPLETED = 'users/GET_PENDING_USERS_COMPLETED';
export const GET_PENDING_USERS_FAILED = 'users/GET_PENDING_USERS_FAILED';

export const INVITE_USER = 'users/INVITE_USER';
export const INVITE_USER_COMPLETED = 'users/INVITE_USER_COMPLETED';
export const INVITE_USER_FAILED = 'users/INVITE_USER_FAILED';

export const RESET_INVITATION = 'users/RESET_INVITATION';

export const RESET_USERS = 'users/RESET_USERS';
