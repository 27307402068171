import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { withTranslation } from 'react-i18next';

import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';

import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const _arrowIconStyles = (theme) => {
	return {
		color: theme.palette.secondary.alt,
		'&:hover': {
			color: `${theme.palette.primary.main} !important`
		}
	};
};

const SecondaryArrowUpIcon = styled(KeyboardArrowUpIcon)(({ theme }) => (_arrowIconStyles(theme)));
const SecondaryArrowDownIcon = styled(KeyboardArrowDownIcon)(({ theme }) => (_arrowIconStyles(theme)));

const SecondaryDeleteIcon = styled(DeleteIcon)(({ theme }) => ({
	color: theme.palette.secondary.alt,
	'&:hover': {
		color: `${theme.palette.error.main} !important`
	}
}));

export const ReversalReasonItem = (props) => {
	const {
		t,
		children,
		onRemoveItem,
		itemIndex,
		totalItems,
		onReorderReason
	} = props;

	const isFirst = itemIndex === 0;
	const isLast = itemIndex === totalItems - 1;

	return (
		<Stack direction='row' my={20} mx={10}>
			<Stack direction='column'>
				<Tooltip
					title={t('chargebackReversalReasons.moveUp')}
					placement='left'
				>
					<SecondaryArrowUpIcon
						sx={{ visibility: isFirst && 'hidden' }}
						onClick={() => {
							onReorderReason(itemIndex, itemIndex - 1);
						}}
					/>
				</Tooltip>
				<Tooltip
					sx={{ visibility: isLast && 'hidden' }}
					title={t('chargebackReversalReasons.moveDown')}
					placement='left'
				>
					<SecondaryArrowDownIcon
						onClick={() => {
							onReorderReason(itemIndex, itemIndex + 1);
						}}
					/>
				</Tooltip>
			</Stack>
			{children}
			<Stack direction='row' alignItems='center'>
				<SecondaryDeleteIcon
					onClick={() => {
						onRemoveItem(itemIndex);
					}}
				/>
			</Stack>
		</Stack>
	);
};

ReversalReasonItem.propTypes = {
	children: PropTypes.node,
	onRemoveItem: PropTypes.func,
	itemIndex: PropTypes.number,
	totalItems: PropTypes.number,
	onReorderReason: PropTypes.func,
	t: PropTypes.func
};

export default withTranslation('representments')(ReversalReasonItem);
