import axios from '../../utilities/axios';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { refreshToken } from '../../utilities/refreshToken';
import { primaryRestGateway } from '../../utilities/apiEndpointUtility';

import * as actions from './actions';
import * as actionTypes from './actionTypes';

export const getDataSource = (action$) =>
	action$.pipe(
		ofType(actionTypes.GET_DATA_SOURCE),
		mergeMap(async (action) => {
			await refreshToken();

			const { dataSourceKey, queryParameters } = action.payload;

			let uri = `${primaryRestGateway()}/api/datasource/${dataSourceKey}`;

			if (queryParameters) {
				uri = uri + queryParameters;
			}

			const response = await axios.get(uri);

			return {
				dataSourceKey,
				data: response.data || {}
			};
		}),
		switchMap((res) => [actions.getDataSourceCompleted(res)]),
		catchError((error, source) =>
			merge(of(actions.getDataSourceFailed(error.message)), source)
		)
	);
