import { fromJS } from 'immutable';
import * as actionTypes from './actionTypes';

export const initialState = fromJS({
	organization: {
		data: {},
		isLoading: false,
		hasBeenLoaded: false,
		errors: []
	},
	users: {
		data: [],
		isLoading: false,
		hasBeenLoaded: false,
		error: undefined
	}
});

export const organizationReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_ORGANIZATION:
			return state.setIn(['organization', 'isLoading'], true);

		case actionTypes.GET_ORGANIZATION_COMPLETED:
			return state
				.setIn(['organization', 'data'], action.payload)
				.setIn(['organization', 'isLoading'], false);

		case actionTypes.GET_ORGANIZATION_FAILED:
			return state
				.setIn(['organization', 'errors'], action.payload)
				.setIn(['organization', 'isLoading'], false);

		case actionTypes.UPDATE_ORGANIZATION:
			return state.setIn(['organization', 'isLoading'], true);

		case actionTypes.UPDATE_ORGANIZATION_COMPLETED:
			return state
				.setIn(['organization', 'data'], action.payload)
				.setIn(['organization', 'isLoading'], false);

		case actionTypes.UPDATE_ORGANIZATION_FAILED:
			return state
				.setIn(['organization', 'errors'], action.payload)
				.setIn(['organization', 'isLoading'], false);

		case actionTypes.GET_ORGANIZATION_USERS:
			return state.setIn(['users', 'isLoading'], true);

		case actionTypes.GET_ORGANIZATION_USERS_COMPLETED:
			return state
				.setIn(['users', 'data'], action.payload)
				.setIn(['users', 'isLoading'], false)
				.setIn(['users', 'hasBeenLoaded'], true);

		case actionTypes.GET_ORGANIZATION_USERS_FAILED:
			return state
				.setIn(['users', 'error'], action.payload)
				.setIn(['users', 'isLoading'], false);

		default:
			return state;
	}
};
