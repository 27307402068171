export default [
	{
		value: 'A',
		label: 'Amex'
	},
	{
		value: 'D',
		label: 'Discover'
	},
	{
		value: 'M',
		label: 'Mastercard'
	},
	{
		value: 'V',
		label: 'Visa'
	}
];
