import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import ContainedButton from '../buttons/ContainedButton';
import BoldLargeHeader from '../headers/BoldLargeHeader';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const ConfirmationForm = (props) => {
	const { t, hasCompletedPasswordSetup, userHasBeenLoaded } = props;

	const navigate = useNavigate();

	return (
		<Box>
			<Stack direction='row' justifyContent='center' my={2} sx={{ px: 4 }}>
				<BoldLargeHeader>
					{hasCompletedPasswordSetup && userHasBeenLoaded
						? t('passwordSetup.success')
						: t('passwordSetup.pageTitle')}
				</BoldLargeHeader>
			</Stack>
			{hasCompletedPasswordSetup && userHasBeenLoaded ? (
				<Box p={2} mx={1}>
					<Typography variant='body1'>
						{t('passwordSetup.completed')}
					</Typography>
				</Box>
			) : (
				<Stack direction='row' justifyContent='center' p={3}>
					<CircularProgress />
				</Stack>
			)}
			<Stack direction='row' justifyContent='center' mb={3}>
				<ContainedButton
					bgcolor='primary.alt'
					disabled={!hasCompletedPasswordSetup || !userHasBeenLoaded}
					onClick={() => {
						navigate('/dashboard');
					}}
				>
					{t('passwordSetup.continueToDashboard')}
				</ContainedButton>
			</Stack>
		</Box>
	);
};

ConfirmationForm.propTypes = {
	hasCompletedPasswordSetup: PropTypes.bool,
	userHasBeenLoaded: PropTypes.bool,
	t: PropTypes.func
};

export default withTranslation('registration')(ConfirmationForm);
