import React from 'react';
import RegistrationManager from '../../components/Registration/RegistrationManager';

import RegistrationPageWrapper from '../../components/RegistrationPageWrapper';

const Registration = () => {
	return (
		<RegistrationPageWrapper>
			<RegistrationManager />
		</RegistrationPageWrapper>
	);
};

export default Registration;
