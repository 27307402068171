import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';

const AffiliateTooltip = (props) => {
	const { value } = props;

	const validatedEntries =
		value?.filter((entry) => { return !(!entry || !entry.label); }) || [];

	return (
		<React.Fragment>
			{validatedEntries.map((entry) => {
				return (
					<Typography
						key={entry.id}
						variant='caption'
						paragraph={true}
						m={0}
					>{entry.label}</Typography>
				);
			})}
		</React.Fragment>
	);
};

AffiliateTooltip.propTypes = {
	value: PropTypes.array
};

export default AffiliateTooltip;
