import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	users: {
		data: [],
		isLoading: false,
		hasBeenLoaded: false,
		error: undefined
	}
};

export const usersSlice = createSlice({
	name: 'users',
	initialState,
	reducers: {
		getUsers: (state) => {
			state.users.isLoading = true;
			state.users.error = undefined;
		},
		getUsersCompleted: (state, action) => {
			state.users.isLoading = false;
			state.users.hasBeenLoaded = true;
			state.users.data = action.payload;
		},
		getUsersFailed: (state, action) => {
			state.users.isLoading = false;
			state.users.error = action.payload;
		}
	}
});

export const {
	getUsers,
	getUsersCompleted,
	getUsersFailed
} = usersSlice.actions;

export default usersSlice.reducer;
