import React from 'react';
import { styled } from '@mui/material/styles';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { rulesActions } from '../../../containers/Rules';
import Restricted from '../../../permissionProvider/Restricted';
import ConfirmDeleteModal from '../../modals/ConfirmDeleteModal';

import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import DeleteIcon from '@mui/icons-material/Delete';

const StyledModal = styled(Modal)(({ theme }) => ({
	display: 'flex',
	position: 'fixed',
	[theme.breakpoints.up('md')]: {
		left: '30%',
		top: '30%',
		transform: 'translate(-15%, -15%)',
		height: '70%',
		width: '70%'
	},
	[theme.breakpoints.down('md')]: {
		left: '10%',
		top: '10%',
		transform: 'translate(-5%, -5%)',
		height: '90%',
		width: '90%'
	}
}));

const StyledPaper = styled(Paper)({
	display: 'flex',
	flexFlow: 'column',
	width: '100%',
	padding: '1rem'
});

const StyledBox = styled(Box)(({ theme }) => ({
	backgroundColor: theme.palette.common.offWhite,
	textAlign: 'center',
	height: '100%',
	overflow: 'auto'
}));

export const ConfirmGroupDeleteModal = (props) => {
	const {
		t,
		isOpen,
		groupMarkedForDeletion,
		onConfirmDelete,
		closeModal,
		activeRules,
		activeRulesAreLoading,
		activeRulesHaveBeenLoaded,
		activeRulesError,
		getActiveRulesByGroup,
		deleteActiveRule
	} = props;

	const [markRuleForDeletionId, setMarkRuleForDeletionId] = React.useState();

	React.useEffect(() => {
		if (groupMarkedForDeletion) {
			getActiveRulesByGroup({
				groupId: groupMarkedForDeletion
			});
		}
	}, [groupMarkedForDeletion]);

	const onCloseModal = () => {
		closeModal();
	};

	const onConfirmDeleteRule = () => {
		deleteActiveRule({
			groupId: groupMarkedForDeletion,
			userRuleId: markRuleForDeletionId
		});

		setMarkRuleForDeletionId();
	};

	if (activeRulesError) {
		return (
			<StyledModal
				open={isOpen}
				onClose={onCloseModal}
				aria-labelledby='confirm-group-delete-modal'
			>
				<StyledPaper elevation={1}>
					<Stack direction='row' justifyContent='center' my={2}>
						<Typography>{t('groups:groupManager.error')}</Typography>
					</Stack>
					<Stack direction='row' justifyContent='flex-end' spacing={2}>
						<Button onClick={onCloseModal}>{t('common:buttons.cancel')}</Button>
					</Stack>
				</StyledPaper>
			</StyledModal>
		);
	}

	if (!groupMarkedForDeletion) {
		return (
			<StyledModal
				open={isOpen}
				onClose={onCloseModal}
				aria-labelledby='confirm-group-delete-modal'
			>
				<StyledPaper elevation={1} />
			</StyledModal>
		);
	}

	const noActiveRules =
		activeRulesHaveBeenLoaded &&
		!activeRulesAreLoading &&
		activeRules?.length === 0;

	return (
		<StyledModal
			open={isOpen}
			onClose={onCloseModal}
			aria-labelledby='confirm-group-delete-modal'
		>
			<StyledPaper elevation={1}>
				<Stack ml={2} sx={{ height: '100%', overflow: 'hidden' }}>
					<Typography sx={{ opacity: noActiveRules ? 0.5 : null }}>
						{t('groups:groupManager.mustDeleteRules')}
					</Typography>
					<StyledBox my={2} p={2}>
						{!activeRulesHaveBeenLoaded || activeRulesAreLoading ? (
							<CircularProgress />
						) : null}
						{!activeRulesAreLoading && activeRules?.length > 0 ? (
							<Stack spacing={1} margin={2} divider={<Divider />}>
								{activeRules?.map((rule) => {
									return (
										<Stack direction='row' justifyContent='space-between' key={rule.userRuleId}>
											<Stack alignItems='flex-start' spacing={1}>
												<Typography>{rule.label}</Typography>
												<Typography variant='caption'>
													{rule.ruleText}
												</Typography>
											</Stack>
											<Restricted to='groups.groups.delete'>
												<IconButton
													sx={{ '&:hover': { color: 'error.main' } }}
													onClick={() => {
														setMarkRuleForDeletionId(rule.userRuleId);
													}}
												>
													<Tooltip
														placement='top'
														title={t('groups:groupManager.delete')}
													>
														<DeleteIcon />
													</Tooltip>
												</IconButton>
											</Restricted>
										</Stack>
									);
								})}
							</Stack>
						) : null}
						{noActiveRules ? (
							<Typography>{t('groups:groupManager.noActiveRules')}</Typography>
						) : null}
					</StyledBox>
					<Typography>
						{t('groups:groupManager.removeFromReporting')}
					</Typography>
					<Stack direction='row' justifyContent='flex-end' spacing={2}>
						<Button onClick={onCloseModal}>{t('common:buttons.cancel')}</Button>
						<Button
							variant='contained'
							disabled={
								!activeRulesHaveBeenLoaded ||
								activeRules?.length > 0 ||
								activeRulesAreLoading
							}
							onClick={onConfirmDelete}
						>
							{t('common:buttons.confirm')}
						</Button>
					</Stack>
				</Stack>
				<ConfirmDeleteModal
					isOpen={!!markRuleForDeletionId}
					onConfirmDelete={onConfirmDeleteRule}
					closeModal={() => {
						setMarkRuleForDeletionId();
					}}
				>
					{/* TODO need to put in rules translation here */}
					<Typography paragraph>{t('rules:ruleList.deleteRule')}</Typography>
					<Typography paragraph>{t('rules:ruleList.continue')}</Typography>
				</ConfirmDeleteModal>
			</StyledPaper>
		</StyledModal>
	);
};

ConfirmGroupDeleteModal.propTypes = {
	t: PropTypes.func,
	isOpen: PropTypes.bool,
	groupMarkedForDeletion: PropTypes.number,
	onConfirmDelete: PropTypes.func,
	closeModal: PropTypes.func,
	activeRules: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	activeRulesAreLoading: PropTypes.bool,
	activeRulesHaveBeenLoaded: PropTypes.bool,
	activeRulesError: PropTypes.string,
	getActiveRulesByGroup: PropTypes.func,
	deleteActiveRule: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		activeRules: state.rulesReducer.getIn(['groupActiveRules', 'data']),
		activeRulesAreLoading: state.rulesReducer.getIn([
			'groupActiveRules',
			'isLoading'
		]),
		activeRulesHaveBeenLoaded: state.rulesReducer.getIn([
			'groupActiveRules',
			'hasBeenLoaded'
		]),
		activeRulesError: state.rulesReducer.getIn(['groupActiveRules', 'error'])
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getActiveRulesByGroup: (payload) => {
			dispatch(rulesActions.getActiveRulesByGroup(payload));
		},
		deleteActiveRule: (payload) => {
			dispatch(rulesActions.deleteActiveRule(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation(['common', 'groups', 'rules'])(ConfirmGroupDeleteModal));
