import axios from '../../utilities/axios';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { refreshToken } from '../../utilities/refreshToken';

import { primaryRestGateway } from '../../utilities/apiEndpointUtility';

import {
	getUserRule,
	getUserRuleCompleted,
	getUserRuleFailed,
	updateActiveStatus,
	updateActiveStatusCompleted,
	updateActiveStatusFailed,
	deleteUserRule,
	deleteUserRuleCompleted,
	deleteUserRuleFailed
} from './userRuleSlice';

export const getUserRuleEpic = (action$) =>
	action$.pipe(
		ofType(getUserRule),
		mergeMap(async (action) => {
			await refreshToken();

			const { userRuleId } = action.payload;

			const response = await axios.get(
				`${primaryRestGateway()}/api/rules/rule/${userRuleId}`);

			return response.data;
		}),
		switchMap((res) => [getUserRuleCompleted(res)]),
		catchError((error, source) =>
			merge(of(getUserRuleFailed(error.message)), source)
		)
	);

export const updateActiveStatusEpic = (action$) =>
	action$.pipe(
		ofType(updateActiveStatus),
		mergeMap(async (action) => {
			await refreshToken();

			const { userRuleId } = action.payload;

			const response = await axios.put(
				`${primaryRestGateway()}/api/rules/status/${userRuleId}`);

			return response.data;
		}),
		switchMap((res) => [
			updateActiveStatusCompleted(res)
		]),
		catchError((error, source) =>
			merge(of(updateActiveStatusFailed(error.message)), source)
		)
	);

export const deleteUserRuleEpic = (action$) =>
	action$.pipe(
		ofType(deleteUserRule),
		mergeMap(async (action) => {
			await refreshToken();

			const { userRuleId } = action.payload;

			const response = await axios.delete(
				`${primaryRestGateway()}/api/rules/rule/${userRuleId}`
			);

			return response.data;
		}),
		switchMap((res) => [
			deleteUserRuleCompleted(res)
		]),
		catchError((error, source) =>
			merge(of(deleteUserRuleFailed(error.message)), source)
		)
	);

export default [
	getUserRuleEpic,
	updateActiveStatusEpic,
	deleteUserRuleEpic
];
