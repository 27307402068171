import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';
import ErrorIndicator from '../../errors/ErrorIndicator';

import {
	deleteUserRule
} from '../../../containers/Rules/userRuleSlice';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

export const ConfirmDeleteModal = ({
	t,
	isOpen,
	userRuleId,
	closeModal,
	isDisabled,
	children
}) => {
	const dispatch = useDispatch();

	const {
		isLoading: isDeletingUserRule,
		hasCompleted: successfullyDeletedUserRule,
		error: deleteUserRuleError
	} = useSelector(state => state.userRuleSlice.deleteUserRule);

	React.useEffect(() => {
		if (successfullyDeletedUserRule) {
			closeModal();
		}
	}, [successfullyDeletedUserRule]);

	const onCloseModal = () => {
		closeModal();
	};

	const onConfirmDelete = () => {
		dispatch(deleteUserRule({
			userRuleId
		}));
	};

	return (
		<Modal
			open={isOpen}
			onClose={onCloseModal}
			aria-labelledby='modal-modal-title'
			aria-describedby='modal-modal-description'
			sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flex: 1 }}
		>
			<Paper sx={{ display: 'flex', flexDirection: 'column', p: 2, maxWidth: '80vw' }} elevation={1}>
				<ErrorIndicator error={deleteUserRuleError}/>
				{ isDeletingUserRule ? (
					<Stack>
						<CircularProgress />
					</Stack>
				) : (
					<Stack>
						<Box sx={{ textAlign: 'center' }}>{children}</Box>
						<Stack direction='row' justifyContent='flex-end' spacing={2}>
							<Button onClick={onCloseModal}>{t('buttons.cancel')}</Button>
							<Button
								variant='contained'
								onClick={onConfirmDelete}
								disabled={!!isDisabled}
							>
								{t('buttons.confirm')}
							</Button>
						</Stack>
					</Stack>
				)
				}

			</Paper>
		</Modal>
	);
};

ConfirmDeleteModal.propTypes = {
	children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
	closeModal: PropTypes.func,
	isDisabled: PropTypes.bool,
	isOpen: PropTypes.bool,
	onConfirmDelete: PropTypes.func,
	t: PropTypes.func,
	userRuleId: PropTypes.number
};

export default withTranslation('common')(ConfirmDeleteModal);
