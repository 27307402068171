import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import querier from '../../../../utilities/querier';
import { dataSourceActions } from '../../../../containers/DataSource';
import getDynamicDataFromList from '../../../../helpers/shared/dynamicReducers/getDynamicDataFromList';
import { StaticReportInputLabel } from '../../../ReportStylings';

import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';

const ProcessorSelect = (props) => {
	const {
		onChange,
		translationPath,
		value,
		dataSource,
		t,
		dataSources,
		getDataSource
	} = props;

	const matchedDataSource = getDynamicDataFromList(dataSources, dataSource);

	React.useEffect(() => {
		getDataSource({
			dataSourceKey: dataSource
		});
	}, []);

	React.useEffect(() => {
		const firstDataSource = querier(matchedDataSource, 'data[0]');

		if (firstDataSource?.id && value === '') {
			onChange(firstDataSource.value);
		}
	}, [matchedDataSource]);

	const isLoadingDataSource =
		!matchedDataSource || !matchedDataSource?.hasBeenLoaded;

	return (
		<FormControl sx={{ flex: 1 }}>
			<StaticReportInputLabel id={'processor-select-label'}>
				{t(translationPath)}
			</StaticReportInputLabel>
			<Select
				value={isLoadingDataSource ? '' : value}
				disabled={isLoadingDataSource}
				onChange={(e) => {
					onChange(e.target.value);
				}}
				startAdornment={
					<InputAdornment position='start'>
						{isLoadingDataSource ? (
							<CircularProgress color='inherit' size={20} />
						) : null}
					</InputAdornment>
				}
			>
				{!isLoadingDataSource &&
					matchedDataSource?.data.map((processor) => {
						return (
							<MenuItem key={processor.value} value={processor.value}>
								<span>{processor.description}</span>
							</MenuItem>
						);
					})}
			</Select>
		</FormControl>
	);
};

ProcessorSelect.propTypes = {
	t: PropTypes.func,
	onChange: PropTypes.func,
	translationPath: PropTypes.string,
	variable: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	dataSource: PropTypes.string,
	dataSources: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	getDataSource: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		dataSources: state.dataSourceReducer.get('dataSources')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getDataSource: (payload) => {
			dispatch(dataSourceActions.getDataSource(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation('reports')(ProcessorSelect));
