import React from 'react';
import PropTypes from 'prop-types';
import PageError from '../errors/PageError';

// TODO we could just use the PageError component as the permission fallback component
// they look the same - not going to switch it out now as its not part of my scope

export const RestrictedFallback = (props) => {
	const { overrideMessage } = props;

	return (
		<PageError errorText={overrideMessage} />
	);
};

RestrictedFallback.propTypes = {
	overrideMessage: PropTypes.string
};

export default RestrictedFallback;
