export const TOGGLE_CREATE_LAYOUT_MODAL = 'modal/TOGGLE_CREATE_LAYOUT_MODAL';
export const TOGGLE_MANAGE_DASHBOARD_MODAL =
	'modal/TOGGLE_MANAGE_DASHBOARD_MODAL';
export const TOGGLE_MULTI_FACTOR_AUTH_SETUP_MODAL =
	'modal/TOGGLE_MULTI_FACTOR_AUTH_SETUP_MODAL';
export const TOGGLE_UPDATE_AVATAR_MODAL = 'modal/TOGGLE_UPDATE_AVATAR_MODAL';
export const TOGGLE_RECORDINGS_MODAL = 'modal/TOGGLE_RECORDINGS_MODAL';
export const TOGGLE_INVITE_USER_MODAL = 'modal/TOGGLE_INVITE_USER_MODAL';
export const TOGGLE_NEW_NOTIFICATION_MODAL =
	'modal/TOGGLE_NEW_NOTIFICATION_MODAL';
export const SET_ATTACHMENTS_MODAL_DATA = 'modal/SET_ATTACHMENTS_MODAL_DATA';
export const TOGGLE_RELEASE_NOTES_MODAL = 'modal/TOGGLE_RELEASE_NOTES_MODAL';
export const SET_PERMISSIONS_MODAL_USER = 'modal/SET_PERMISSIONS_MODAL_USER';
export const SET_UPDATE_USER_STATUS_MODAL =
	'modal/SET_UPDATE_USER_STATUS_MODAL';
export const SET_DELETE_USER_MODAL = 'modal/SET_DELETE_USER_MODAL';
